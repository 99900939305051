import * as am5 from "@amcharts/amcharts5";

import DummyPostImage from "../assets/images/default/postImg.png";
import DummyVideoThumbnailImage from "../assets/images/default/videoThumbnailImg.png";
import VerifiedIcon from "../assets/images/default/verifiedIcon.png";
import TwitterHeaderImage from "../assets/images/default/twitterHeaderImg.png";
//
// SOCIAL ICONS
import CongoDRC from "../assets/images/flags/Congo(DRC).svg";
import CongoRep from "../assets/images/flags/Congo(Repulic).svg";

import Africa from "../assets/images/flags/Africa.svg";
import Angola from "../assets/images/flags/Angola.svg";
import Argentina from "../assets/images/flags/Argentina.svg";
import Bangladesh from "../assets/images/flags/Bangladesh.svg";
import Belgium from "../assets/images/flags/Belgium.svg";
import Bhutan from "../assets/images/flags/Bhutan.svg";
import Botswana from "../assets/images/flags/Botswana.svg";
import Brazil from "../assets/images/flags/Brazil.svg";
import Brunei from "../assets/images/flags/Brunei.svg";
import BurkinaFaso from "../assets/images/flags/BurkinaFaso.svg";
import Burundi from "../assets/images/flags/Burundi.svg";
import Cambodia from "../assets/images/flags/Cambodia.svg";
import Cameroon from "../assets/images/flags/Cameroon.svg";
import Canada from "../assets/images/flags/Canada.svg";
import Chile from "../assets/images/flags/Chile.svg";
import China from "../assets/images/flags/China.svg";
import CoastIvory from "../assets/images/flags/CoastIvory.svg";
import Cuba from "../assets/images/flags/Cuba.svg";
import CzechRepublic from "../assets/images/flags/CzechRepublic.svg";
import Denmark from "../assets/images/flags/Denmark.svg";
import Ecuador from "../assets/images/flags/Ecuador.svg";
import Ethiopia from "../assets/images/flags/Ethiopia.svg";
import Fiji from "../assets/images/flags/Fiji.svg";
import Gabon from "../assets/images/flags/Gabon.svg";
import Haiti from "../assets/images/flags/Haiti.svg";
import HongKong from "../assets/images/flags/HongKong.svg";
import Indonesia from "../assets/images/flags/Indonesia.svg";
import Iran from "../assets/images/flags/Iran.svg";
import Iraq from "../assets/images/flags/Iraq.svg";

import Ireland from "../assets/images/flags/Ireland.svg";
import Israel from "../assets/images/flags/Israel.svg";
import Jordan from "../assets/images/flags/Jordan.svg";
import Kyrgyzstan from "../assets/images/flags/Kyrgyzstan.svg";
import Laos from "../assets/images/flags/Laos.svg";
import Liberia from "../assets/images/flags/Liberia.svg";
import Madagascar from "../assets/images/flags/Madagascar.svg";
import Malaysia from "../assets/images/flags/Malaysia.svg";
import Maldives from "../assets/images/flags/Maldives.svg";
import Malta from "../assets/images/flags/Malta.svg";
import Mauritania from "../assets/images/flags/Mauritania.svg";
import Mauritius from "../assets/images/flags/Mauritius.svg";
import Mexico from "../assets/images/flags/Mexico.svg";
import Myanmar from "../assets/images/flags/Myanmar.svg";
import NewZealand from "../assets/images/flags/NewZealand.svg";
import Nigeria from "../assets/images/flags/Nigeria.svg";
import Norway from "../assets/images/flags/Norway.svg";
import Oman from "../assets/images/flags/Oman.svg";
import Pakistan from "../assets/images/flags/Pakistan.svg";
import PalestinianTerritories from "../assets/images/flags/PalestinianTerritories.svg";
import Paraguay from "../assets/images/flags/Paraguay.svg";
import Peru from "../assets/images/flags/Peru.svg";
import Philippines from "../assets/images/flags/Philippines.svg";
import SaudiArabia from "../assets/images/flags/SaudiArabia.svg";
import SierraLeone from "../assets/images/flags/SierraLeone.svg";
import Singapore from "../assets/images/flags/Singapore.svg";
import Slovakia from "../assets/images/flags/Slovakia.svg";
import Somalia from "../assets/images/flags/Somalia.svg";
import SouthAfrica from "../assets/images/flags/SouthAfrica.svg";
import SouthKorea from "../assets/images/flags/SouthKorea.svg";
import Spain from "../assets/images/flags/Spain.svg";
import Sudan from "../assets/images/flags/Sudan.svg";
import Suriname from "../assets/images/flags/Suriname.svg";
import Syria from "../assets/images/flags/Syria.svg";
import Taiwan from "../assets/images/flags/Taiwan.svg";
import Tajikistan from "../assets/images/flags/Tajikistan.svg";
import TrinidadTobago from "../assets/images/flags/TrinidadTobago.svg";
import Tunisia from "../assets/images/flags/Tunisia.svg";
import Turkey from "../assets/images/flags/Turkey.svg";
import Uganda from "../assets/images/flags/Uganda.svg";
import Ukraine from "../assets/images/flags/Ukraine.svg";
import UnitedArabEmirates from "../assets/images/flags/UnitedArabEmirates.svg";
import UnitedKingdom from "../assets/images/flags/UnitedKingdom.svg";
import UnitedStates from "../assets/images/flags/UnitedStates.svg";
import Uzbekistan from "../assets/images/flags/Uzbekistan.svg";
import Yemen from "../assets/images/flags/Yemen.svg";
import Zambia from "../assets/images/flags/Zambia.svg";
import Zimbabwe from "../assets/images/flags/Zimbabwe.svg";


// var arr = [
//   { title: "CongoDRC", iconUrl: CongoDRC },
//   { title: "CongoRep", iconUrl: CongoRep },
//   { tile: "Africa", iconUrl: Africa },
//   { tile: "Angola", iconUrl: Angola },
//   { tile: "Argentina", iconUrl: Argentina },
//   { tile: "Bangladesh", iconUrl: Bangladesh },
//   { tile: "Belgium", iconUrl: Belgium },
//   { tile: "Bhutan", iconUrl: Bhutan },
//   { tile: "Botswana", iconUrl: Botswana },
//   { tile: "Brazil", iconUrl: Brazil },
//   { tile: "Brunei", iconUrl: Brunei },
//   { tile: "BurkinaFaso", iconUrl: BurkinaFaso },
//   { tile: "Burundi", iconUrl: Burundi },
//   { tile: "Cambodia", iconUrl: Cambodia },
//   { tile: "Cameroon", iconUrl: Cameroon },
//   { tile: "Canada", iconUrl: Canada },
//   { tile: "Chile", iconUrl: Chile },
//   { tile: "China", iconUrl: China },
//   { tile: "CoastIvory", iconUrl: CoastIvory },
//   { tile: "Cuba", iconUrl: Cuba },
//   { tile: "CzechRepublic", iconUrl: CzechRepublic },
//   { tile: "Denmark", iconUrl: Denmark },
//   { tile: "Ecuador", iconUrl: Ecuador },
//   { tile: "Ethiopia", iconUrl: Ethiopia },
//   { tile: "Fiji", iconUrl: Fiji },
//   { tile: "Gabon", iconUrl: Gabon },
//   { tile: "Haiti", iconUrl: Haiti },
//   { tile: "HongKong", iconUrl: HongKong },
//   { tile: "Indonesia", iconUrl: Indonesia },
//   { tile: "Iran", iconUrl: Iran },
//   { tile: "Ireland", iconUrl: Ireland },
//   { tile: "Israel", iconUrl: Israel },
//   { tile: "Jordan", iconUrl: Jordan },
//   { tile: "Kyrgyzstan", iconUrl: Kyrgyzstan },
//   { tile: "Laos", iconUrl: Laos },
//   { tile: "Liberia", iconUrl: Liberia },
//   { tile: "Madagascar", iconUrl: Madagascar },
//   { tile: "Malaysia", iconUrl: Malaysia },
//   { tile: "Maldives", iconUrl: Maldives },
//   { tile: "Malta", iconUrl: Malta },
//   { tile: "Mauritania", iconUrl: Mauritania },
//   { tile: "Mauritius", iconUrl: Mauritius },
//   { tile: "Mexico", iconUrl: Mexico },
//   { tile: "Myanmar", iconUrl: Myanmar },
//   { tile: "NewZealand", iconUrl: NewZealand },
//   { tile: "Nigeria", iconUrl: Nigeria },
//   { tile: "Norway", iconUrl: Norway },
//   { tile: "Oman", iconUrl: Oman },
//   { tile: "Pakistan", iconUrl: Pakistan },
//   { tile: "PalestinianTerritories", iconUrl: PalestinianTerritories },
//   { tile: "Paraguay", iconUrl: Paraguay },
//   { tile: "Peru", iconUrl: Peru },
//   { tile: "Philippines", iconUrl: Philippines },
//   { tile: "SaudiArabia", iconUrl: SaudiArabia },
//   { tile: "SierraLeone", iconUrl: SierraLeone },
//   { tile: "Singapore", iconUrl: Singapore },
//   { tile: "Slovakia", iconUrl: Slovakia },
//   { tile: "Somalia", iconUrl: Somalia },
//   { tile: "SouthAfrica", iconUrl: SouthAfrica },
//   { tile: "SouthKorea", iconUrl: SouthKorea },
//   { tile: "Spain", iconUrl: Spain },
//   { tile: "Sudan", iconUrl: Sudan },
//   { tile: "Suriname", iconUrl: Suriname },
//   { tile: "Syria", iconUrl: Syria },
//   { tile: "Taiwan", iconUrl: Taiwan },
//   { tile: "Tajikistan", iconUrl: Tajikistan },
//   { tile: "TrinidadTobago", iconUrl: TrinidadTobago },
//   { tile: "Tunisia", iconUrl: Tunisia },
//   { tile: "Turkey", iconUrl: Turkey },
//   { tile: "Uganda", iconUrl: Uganda },
//   { tile: "Ukraine", iconUrl: Ukraine },
//   { tile: "UnitedArabEmirates", iconUrl: UnitedArabEmirates },
//   { tile: "UnitedKingdom", iconUrl: UnitedKingdom },
//   { tile: "UnitedStates", iconUrl: UnitedStates },
//   { tile: "Uzbekistan", iconUrl: Uzbekistan },
//   { tile: "Yemen", iconUrl: Yemen },
//   { tile: "Zambia", iconUrl: Zambia },
//   { tile: "Zimbabwe", iconUrl: Zimbabwe },


// ]

import Afghanistan from "../assets/images/flags/Afghanistan.svg";
import Algeria from "../assets/images/flags/Algeria.svg";
import Armenia from "../assets/images/flags/Armenia.svg";
import Australia from "../assets/images/flags/Australia.svg";
import Bahrain from "../assets/images/flags/Bahrain.svg";
import Egypt from "../assets/images/flags/Egypt.svg";
import France from "../assets/images/flags/France.svg";
import Georgia from "../assets/images/flags/Georgia.svg";
import Germany from "../assets/images/flags/Germany.svg";
import Greece from "../assets/images/flags/Greece.svg";
import India from "../assets/images/flags/India.svg";
import Italy from "../assets/images/flags/Italy.svg";
import Japan from "../assets/images/flags/Japan.svg";
import Kazakhstan from "../assets/images/flags/Kazakhstan.svg";
import Kenya from "../assets/images/flags/Kenya.svg";
import Kuwait from "../assets/images/flags/Kuwait.svg";
import Morocco from "../assets/images/flags/Morocco.svg";
import Nepal from "../assets/images/flags/Nepal.svg";
import Netherlands from "../assets/images/flags/Netherlands.svg";
import Poland from "../assets/images/flags/Poland.svg";
import Portugal from "../assets/images/flags/Portugal.svg";
import Qatar from "../assets/images/flags/Qatar.svg";
import Romania from "../assets/images/flags/Romania.svg";
import Russia from "../assets/images/flags/Russia.svg";
import SriLanka from "../assets/images/flags/SriLanka.svg";
import Sweden from "../assets/images/flags/Sweden.svg";
import Switzerland from "../assets/images/flags/Switzerland.svg";
import Tanzania from "../assets/images/flags/Tanzania.svg";
import Thailand from "../assets/images/flags/Thailand.svg";
import Vietnam from "../assets/images/flags/Vietnam.svg";

// SOCIAL ICONS
import FacebookIcon from "../assets/images/social/facebook.png";
import InstagramIcon from "../assets/images/social/instagram.png";
import TwitterIcon from "../assets/images/social/twitter.png";
import XIcon from "../assets/images/social/x-icon.webp";
import LinkedinIcon from "../assets/images/social/linkedin.png";
import YoutubeIcon from "../assets/images/social/youtube.png";
// import GoogleIcon from "../assets/images/social/google.png";
import GoogleIcon from "../assets/images/social/google-new.png";
import OttIcon from "../assets/images/social/ott.png";
import ShopIcon from "../assets/images/social/shop.png";
import BingIcon from "../assets/images/social/bing.png";
import DV360Icon from "../assets/images/social/dv360.png";
import RollworksIcon from "../assets/images/social/rollworks.png";

import DefaultFlag from "../assets/images/flags/defaultFlag.png";

// WHITE SOCIAL ICONS
import WhiteFacebookIcon from "../assets/images/social/white/facebook.png";
import WhiteInstagramIcon from "../assets/images/social/white/instagram.png";
import WhiteTwitterIcon from "../assets/images/social/white/twitter.png";
import WhiteLinkedinIcon from "../assets/images/social/white/linkedin.png";
import WhiteYoutubeIcon from "../assets/images/social/white/youtube.png";

import SampleInfluencersData from "../assets/files/mi/influencers/influencers-sample-data.csv";

export const API_URL = "https://v3.demoapi.icogz.com/api/";

export const isMap = "india"
export const CountryOrigin = "in";

export const COKE_COMPANY_ID = "0d5ecfb8-3c81-4701-965c-e2cc3d77ccb6";
export const HALEON_COMPANY_ID = "9d1713a4-f43d-438b-b25f-d44415a596df";
export const OLX_AUTOS_COMPANY_ID = "73b5973b-8412-417f-98b3-e4622c5c4dc3";
export const IDFC_COMPANY_ID = "17b00bdb-56ed-4e92-a926-43bdd9316f49";
export const IMPRIVATA_COMPANY_ID = "51768ab0-a5af-4a95-abb2-1e65cf41ab87"
export const DEMO_US_COMPANY_ID = 'ca22d7eb-06b6-4d9e-ab76-889ae8e6d94a';

// USER IDS
export const DEMO_US_USER_ID = '86a2da25-480b-4d65-bda7-49ebbfccd7b7';
export const DEMO_UK_USER_ID = 'edef02db-9005-4e64-9d65-bb3cfff3d071';
export const DEMO_UAE_USER_ID = 'ad51632d-8137-4cda-b498-b3beed3bd953';
export const DEMO_IND_USER_ID = '6d6a8956-a2f7-459d-afde-114a998c511a';
export const DEMO_FMCG_USER_ID = 'c9cc11f8-64cf-4972-be3e-4cdfa7f3e203';

export const FMCG_CATEGORY_ID = "0d5ecfb8-3c81-4701-965c-e2cc3d77ccb6";

export const BRAND_ID = "e2fd950a-19f7-4722-a78e-79bd5b94a802";
// export const BRAND_ID = null;//"aba95a40-20d7-4efe-a0ca-c7e33662a875" // FOR COKE
export const COKE_BRAND_ID = "4e41ef77-70e7-4444-aff1-282011b1278f";
export const VIDEO_ID = "423373823051399";
export const VIDEO_DURATION_TYPE = "long";
export const PLATFORM = "Facebook";
export const USER_COUNTRY = "India";

export const INFLUENCERSSAMPLEDATA = SampleInfluencersData;

export const RequiredField = "This is a required field";
export const InvalidInputMsg = "Invalid input";
export const MaxLimitReachedInputMsg = "Max input limit is :";
export const MinimumInputMsg = "Minimum input limit is :";

export const EmptyFirstNameMsg = "Please enter your first name";
export const EmptyLastNameMsg = "Please enter your last name";
export const InvalidNameMsg = "Please enter valid name";

export const EmptyEmailIdMsg = "Please enter your email id";
export const EmailAlreadyExistsMsg = "Email already exists!";
export const InvalidEmailMsg = "Enter in the format: name@example.com";

export const EmptyPasswordMsg = "Please enter your email id";
export const PasswordLengthMsg = "minimum length 8";
export const InvlidPassMsg =
  "Password must contains at least 1 lowercase letter, 1 uppercase letter, 1 special character & 1 number";
export const PasswordNoLowercaseMsg = "at least 1 lowercase letter";
export const PasswordNoUppercaseMsg = "at least 1 uppercase letter";
export const PasswordNoSpecialCharMsg = "at least 1 special character";
export const PasswordNoNumberMsg = "at least 1 number";

export const EmptyMobileNoMsg = "Please enter your mobile number";
export const InvalidMobileNoMsg = "Please enter valid mobile number";
export const shortMobileNoMsg = "Please enter minimum 10 numbers";

export const EmptyCompanyNameMsg = "Please enter company name";

export const EmptyCompanyWebsiteMsg = "Please enter company website";
export const InvalidCompanyWebsiteMsg =
  "Enter in the format: https://www.icogz.com";

export const InvalidCompanyCategoryMsg = "Please enter valid company category";

export const EmptyCompanyEmailIdMsg = "Please enter your company email id";
export const InvalidCompanyEmailMsg = "Enter in the format: name@example.com";

// AUTH REGEX
export const OnlyCharRegex = /^[A-Za-z]+$/;
export const MobileNoRegex = /^[0-9]+$/;
export const OnlyNosRegex = /^[0-9]+$/;
export const EmailRegex =
  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
export const WebsiteLinkRegex =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

// DEFAULT IMAGES
export const DefaultPostImage = DummyPostImage;

export const DefaultProfileImage = DummyPostImage;

export const DefaultVideoThumbnailImage = DummyVideoThumbnailImage;

export const DefaultVerifiedIcon = VerifiedIcon;

export const DefaultTwitterHeaderImg = TwitterHeaderImage;

export const DefaultFlagImage = [
  { title: "Afghanistan", iconUrl: Afghanistan },
  { title: "Algeria", iconUrl: Algeria },
  { title: "Armenia", iconUrl: Armenia },
  { title: "Australia", iconUrl: Australia },
  { title: "Bahrain", iconUrl: Bahrain },
  { title: "Egypt", iconUrl: Egypt },
  { title: "France", iconUrl: France },
  { title: "Georgia", iconUrl: Georgia },
  { title: "Germany", iconUrl: Germany },
  { title: "Greece", iconUrl: Greece },
  { title: "India", iconUrl: India },
  { title: "Italy", iconUrl: Italy },
  { title: "Japan", iconUrl: Japan },
  { title: "Kazakhstan", iconUrl: Kazakhstan },
  { title: "Kenya", iconUrl: Kenya },
  { title: "Kuwait", iconUrl: Kuwait },
  { title: "Morocco", iconUrl: Morocco },
  { title: "Nepal", iconUrl: Nepal },
  { title: "Netherlands", iconUrl: Netherlands },
  { title: "Poland", iconUrl: Poland },
  { title: "Portugal", iconUrl: Portugal },
  { title: "Qatar", iconUrl: Qatar },
  { title: "Romania", iconUrl: Romania },
  { title: "Russia", iconUrl: Russia },
  { title: "Sri Lanka", iconUrl: SriLanka },
  { title: "Sweden", iconUrl: Sweden },
  { title: "Switzerland", iconUrl: Switzerland },
  { title: "Tanzania", iconUrl: Tanzania },
  { title: "Thailand", iconUrl: Thailand },
  { title: "Vietnam", iconUrl: Vietnam },


  { title: "Congo [Drc]", iconUrl: CongoDRC },
  { title: "Congo [Republic]", iconUrl: CongoRep },

  { title: "Africa", iconUrl: Africa },
  { title: "Angola", iconUrl: Angola },
  { title: "Argentina", iconUrl: Argentina },
  { title: "Bangladesh", iconUrl: Bangladesh },
  { title: "Belgium", iconUrl: Belgium },
  { title: "Bhutan", iconUrl: Bhutan },
  { title: "Botswana", iconUrl: Botswana },
  { title: "Brazil", iconUrl: Brazil },
  { title: "Brunei", iconUrl: Brunei },
  { title: "Burkina Faso", iconUrl: BurkinaFaso },
  { title: "Burundi", iconUrl: Burundi },
  { title: "Cambodia", iconUrl: Cambodia },
  { title: "Cameroon", iconUrl: Cameroon },
  { title: "Canada", iconUrl: Canada },
  { title: "Chile", iconUrl: Chile },
  { title: "China", iconUrl: China },
  { title: "Côte D'ivoire", iconUrl: CoastIvory },
  { title: "Cuba", iconUrl: Cuba },
  { title: "Czech Republic", iconUrl: CzechRepublic },
  { title: "Denmark", iconUrl: Denmark },
  { title: "Ecuador", iconUrl: Ecuador },
  { title: "Ethiopia", iconUrl: Ethiopia },
  { title: "Fiji", iconUrl: Fiji },
  { title: "Gabon", iconUrl: Gabon },
  { title: "Haiti", iconUrl: Haiti },
  { title: "Hong Kong", iconUrl: HongKong },
  { title: "Indonesia", iconUrl: Indonesia },
  { title: "Iraq", iconUrl: Iraq },
  { title: "Iran", iconUrl: Iran },
  { title: "Ireland", iconUrl: Ireland },
  { title: "Israel", iconUrl: Israel },
  { title: "Jordan", iconUrl: Jordan },
  { title: "Kyrgyzstan", iconUrl: Kyrgyzstan },
  { title: "Laos", iconUrl: Laos },
  { title: "Liberia", iconUrl: Liberia },
  { title: "Madagascar", iconUrl: Madagascar },
  { title: "Malaysia", iconUrl: Malaysia },
  { title: "Maldives", iconUrl: Maldives },
  { title: "Malta", iconUrl: Malta },
  { title: "Mauritania", iconUrl: Mauritania },
  { title: "Mauritius", iconUrl: Mauritius },
  { title: "Mexico", iconUrl: Mexico },
  { title: "Myanmar [Burma]", iconUrl: Myanmar },
  { title: "New Zealand", iconUrl: NewZealand },
  { title: "Nigeria", iconUrl: Nigeria },
  { title: "Norway", iconUrl: Norway },
  { title: "Oman", iconUrl: Oman },
  { title: "Pakistan", iconUrl: Pakistan },
  { title: "Palestinian Territories", iconUrl: PalestinianTerritories },
  { title: "Paraguay", iconUrl: Paraguay },
  { title: "Peru", iconUrl: Peru },
  { title: "Philippines", iconUrl: Philippines },
  { title: "Saudi Arabia", iconUrl: SaudiArabia },
  { title: "Sierra Leone", iconUrl: SierraLeone },
  { title: "Singapore", iconUrl: Singapore },
  { title: "Slovakia", iconUrl: Slovakia },
  { title: "Somalia", iconUrl: Somalia },
  { title: "South Africa", iconUrl: SouthAfrica },
  { title: "South Korea", iconUrl: SouthKorea },
  { title: "Spain", iconUrl: Spain },
  { title: "Sudan", iconUrl: Sudan },
  { title: "Suriname", iconUrl: Suriname },
  { title: "Syria", iconUrl: Syria },
  { title: "Taiwan", iconUrl: Taiwan },
  { title: "Tajikistan", iconUrl: Tajikistan },
  { title: "Trinidad And Tobago", iconUrl: TrinidadTobago },
  { title: "Tunisia", iconUrl: Tunisia },
  { title: "Turkey", iconUrl: Turkey },
  { title: "Uganda", iconUrl: Uganda },
  { title: "Ukraine", iconUrl: Ukraine },
  { title: "United Arab Emirates", iconUrl: UnitedArabEmirates },
  { title: "United Kingdom", iconUrl: UnitedKingdom },
  { title: "United States", iconUrl: UnitedStates },
  { title: "Uzbekistan", iconUrl: Uzbekistan },
  { title: "Yemen", iconUrl: Yemen },
  { title: "Zambia", iconUrl: Zambia },
  { title: "Zimbabwe", iconUrl: Zimbabwe },
];

export const defaultSocialIconsUrls = [
  { title: "Facebook", iconUrl: FacebookIcon },
  { title: "Instagram", iconUrl: InstagramIcon },
  { title: "Twitter", iconUrl: TwitterIcon },
  { title: "Linkedin", iconUrl: LinkedinIcon },
  { title: "Youtube", iconUrl: YoutubeIcon },
  { title: "Google", iconUrl: GoogleIcon },
  { title: "OTT", iconUrl: OttIcon },
  { title: "Direct buys", iconUrl: ShopIcon },
  { title: "DV 360", iconUrl: DV360Icon },
  { title: "Marketplaces", iconUrl: ShopIcon },
  { title: "Bing", iconUrl: BingIcon },
  { title: "Rollworks", iconUrl: RollworksIcon },
  { title: "FACEBOOK", iconUrl: FacebookIcon },
  { title: "INSTAGRAM", iconUrl: InstagramIcon },
  { title: "TWITTER", iconUrl: TwitterIcon },
  { title: "LINKEDIN", iconUrl: LinkedinIcon },
  { title: "YOUTUBE", iconUrl: YoutubeIcon },
  { title: "GOOGLE", iconUrl: GoogleIcon },
  { title: "OTT", iconUrl: OttIcon },
  { title: "MARKETPLACES", iconUrl: ShopIcon },

];

export const defaultWhiteSocialIconsUrls = [
  { title: "Facebook", iconUrl: WhiteFacebookIcon },
  { title: "Instagram", iconUrl: WhiteInstagramIcon },
  { title: "Twitter", iconUrl: WhiteTwitterIcon },
  { title: "Linkedin", iconUrl: WhiteLinkedinIcon },
  { title: "Youtube", iconUrl: WhiteYoutubeIcon },
];

export const FacebookLogo = FacebookIcon;
export const InstagramLogo = InstagramIcon;
export const TwitterLogo = TwitterIcon;
export const LinkedinLogo = LinkedinIcon;
export const YoutubeLogo = YoutubeIcon;
export const GoogleLogo = GoogleIcon;

// CHART CONSTANT COLOR SET
export const Primary400 = 0x4cc3fd;
export const Primary500 = 0x11a1fd;
export const Primary600 = 0x0c7dd9;
export const Primary700 = 0x085db6;

export const SecondaryRed400 = 0xe96e7a;
export const SecondaryRed500 = 0xd51f30;
export const SecondaryRed600 = 0xb51c2a;
export const SecondaryRed700 = 0x85010d;

export const SecondaryPurple400 = 0x8298fb;
export const SecondaryPurple500 = 0x5a75f9;
export const SecondaryPurple600 = 0x4259d4;
export const SecondaryPurple700 = 0x3247b5;

export const SecondaryGreen400 = 0x3cd795;
export const SecondaryGreen500 = 0x07c180;
export const SecondaryGreen600 = 0x06a57d;
export const SecondaryGreen700 = 0x038673;

export const SecondaryYellow400 = 0xffb866;
export const SecondaryYellow500 = 0xff9931;
export const SecondaryYellow600 = 0xdb7823;
export const SecondaryYellow700 = 0xb45917;

export const am5ColorPrimary400 = am5.color(0x4cc3fd);
export const am5ColorPrimary500 = am5.color(0x11a1fd);
export const am5ColorPrimary600 = am5.color(0x0c7dd9);
export const am5ColorPrimary700 = am5.color(0x085db6);

export const am5ColorSecondaryRed400 = am5.color(0xe96e7a);
export const am5ColorSecondaryRed500 = am5.color(0xd51f30);
export const am5ColorSecondaryRed600 = am5.color(0xb51c2a);
export const am5ColorSecondaryRed700 = am5.color(0x85010d);

export const am5ColorSecondaryPurple400 = am5.color(0x8298fb);
export const am5ColorSecondaryPurple500 = am5.color(0x5a75f9);
export const am5ColorSecondaryPurple600 = am5.color(0x4259d4);
export const am5ColorSecondaryPurple700 = am5.color(0x3247b5);

export const am5ColorSecondaryGreen400 = am5.color(0x3cd795);
export const am5ColorSecondaryGreen500 = am5.color(0x07c180);
export const am5ColorSecondaryGreen600 = am5.color(0x06a57d);
export const am5ColorSecondaryGreen700 = am5.color(0x038673);

export const am5ColorSecondaryYellow400 = am5.color(0xffb866);
export const am5ColorSecondaryYellow500 = am5.color(0xff9931);
export const am5ColorSecondaryYellow600 = am5.color(0xdb7823);
export const am5ColorSecondaryYellow700 = am5.color(0xb45917);

export const Gray500 = 0xcacaca;

export const BlueColorStart500 = [
  Primary500,
  SecondaryPurple500,
  SecondaryGreen500,
  SecondaryYellow500,
  am5ColorPrimary600,
  am5ColorSecondaryPurple600,
  am5ColorSecondaryGreen600,
  am5ColorSecondaryYellow600
];
export const PurpleColorStart500 = [
  SecondaryPurple500,
  SecondaryGreen500,
  SecondaryYellow500,
  am5ColorPrimary600,
  am5ColorSecondaryPurple600,
  am5ColorSecondaryGreen600,
  am5ColorSecondaryYellow600
];
export const GreenColorStart500 = [
  SecondaryGreen500,
  SecondaryYellow500,
  Primary500,
  SecondaryPurple500,
];
export const YellowColorStart500 = [
  SecondaryYellow500,
  Primary500,
  SecondaryPurple500,
  SecondaryGreen500,
];

export const LoaderGray = [
  Gray500,
  Gray500,
  Gray500,
  Gray500,
  Gray500,
  Gray500,
  Gray500,
  Gray500,
  Gray500,
  Gray500,
  Gray500,
];

// HEATMAP
export const HeatMapGreenColorSet = [SecondaryGreen400, SecondaryGreen700];
export const HeatMapBlueColorSet = [Primary400, Primary700];

// PIE OF PIE
export const PieOfPieSeriesColorSet = [
  SecondaryPurple400,
  SecondaryGreen400,
  SecondaryYellow400,
  Primary400,
  SecondaryPurple500,
  SecondaryGreen500,
  SecondaryYellow500,
  Primary500,
  SecondaryPurple600,
  SecondaryGreen600,
  SecondaryYellow600,
  Primary600,
  SecondaryPurple700,
  SecondaryGreen700,
  SecondaryYellow700,
  Primary700,
];
export const PieOfPieSeriesLegendColorSet = [
  "#8298fb",
  "#3cd795",
  "#ffb866",
  "#4cc3fd",
  "#5a75f9",
  "#07c180",
  "#ff9931",
  "#11a1fd",
  "#4259d4",
  "#06a57d",
  "#db7823",
  "#0c7dd9",
  "#3247b5",
  "#038673",
  "#b45917",
  "#085db6",
];
export const PieOfPieSubSeriesColorSet = [
  Primary700,
  SecondaryYellow700,
  SecondaryGreen700,
  SecondaryPurple700,
  Primary600,
  SecondaryYellow600,
  SecondaryGreen600,
  SecondaryPurple600,
  Primary500,
  SecondaryYellow500,
  SecondaryGreen500,
  SecondaryPurple500,
  Primary400,
  SecondaryYellow400,
  SecondaryGreen400,
  SecondaryPurple400,
];

export const PieOfPieSubSeriesLegendColorSet = [
  "#4cc3fd", // PRIMARY 400
  "#ffb866", // SECONDARY YELLOW 400
  "#3cd795", // SECONDARY GREEN 400
  "#8298fb", // SECONDARY PURPLE 400

  "#11a1fd", // PRIMARY 500
  "#ff9931", // SECONDARY YELLOW 500
  "#07c180", // SECONDARY GREEN 500
  "#5a75f9", // SECONDARY PURPLE 500

  "#0c7dd9", // PRIMARY 600
  "#db7823", // SECONDARY YELLOW 600
  "#06a57d", // SECONDARY GREEN 600
  "#4259d4", // SECONDARY PURPLE 600

  "#085db6", // PRIMARY 700
  "#b45917", // SECONDARY YELLOW 700
  "#038673", // SECONDARY GREEN 700
  "#3247b5", // SECONDARY PURPLE 700
  "#4cc3fd", // PRIMARY 400
  "#ffb866", // SECONDARY YELLOW 400
  "#3cd795", // SECONDARY GREEN 400
  "#8298fb", // SECONDARY PURPLE 400

  "#11a1fd", // PRIMARY 500
  "#ff9931", // SECONDARY YELLOW 500
  "#07c180", // SECONDARY GREEN 500
  "#5a75f9", // SECONDARY PURPLE 500

  "#0c7dd9", // PRIMARY 600
  "#db7823", // SECONDARY YELLOW 600
  "#06a57d", // SECONDARY GREEN 600
  "#4259d4", // SECONDARY PURPLE 600

  "#085db6", // PRIMARY 700
  "#b45917", // SECONDARY YELLOW 700
  "#038673", // SECONDARY GREEN 700
  "#3247b5", // SECONDARY PURPLE 700

  "#4cc3fd", // PRIMARY 400
  "#ffb866", // SECONDARY YELLOW 400
  "#3cd795", // SECONDARY GREEN 400
  "#8298fb", // SECONDARY PURPLE 400

  "#11a1fd", // PRIMARY 500
  "#ff9931", // SECONDARY YELLOW 500
  "#07c180", // SECONDARY GREEN 500
  "#5a75f9", // SECONDARY PURPLE 500

  "#0c7dd9", // PRIMARY 600
  "#db7823", // SECONDARY YELLOW 600
  "#06a57d", // SECONDARY GREEN 600
  "#4259d4", // SECONDARY PURPLE 600

  "#085db6", // PRIMARY 700
  "#b45917", // SECONDARY YELLOW 700
  "#038673", // SECONDARY GREEN 700
  "#3247b5", // SECONDARY PURPLE 700
  "#4cc3fd", // PRIMARY 400
  "#ffb866", // SECONDARY YELLOW 400
  "#3cd795", // SECONDARY GREEN 400
  "#8298fb", // SECONDARY PURPLE 400

  "#11a1fd", // PRIMARY 500
  "#ff9931", // SECONDARY YELLOW 500
  "#07c180", // SECONDARY GREEN 500
  "#5a75f9", // SECONDARY PURPLE 500

  "#0c7dd9", // PRIMARY 600
  "#db7823", // SECONDARY YELLOW 600
  "#06a57d", // SECONDARY GREEN 600
  "#4259d4", // SECONDARY PURPLE 600

  "#085db6", // PRIMARY 700
  "#b45917", // SECONDARY YELLOW 700
  "#038673", // SECONDARY GREEN 700
  "#3247b5", // SECONDARY PURPLE 700
  "#4cc3fd", // PRIMARY 400
  "#ffb866", // SECONDARY YELLOW 400
  "#3cd795", // SECONDARY GREEN 400
  "#8298fb", // SECONDARY PURPLE 400

  "#11a1fd", // PRIMARY 500
  "#ff9931", // SECONDARY YELLOW 500
  "#07c180", // SECONDARY GREEN 500
  "#5a75f9", // SECONDARY PURPLE 500

  "#0c7dd9", // PRIMARY 600
  "#db7823", // SECONDARY YELLOW 600
  "#06a57d", // SECONDARY GREEN 600
  "#4259d4", // SECONDARY PURPLE 600

  "#085db6", // PRIMARY 700
  "#b45917", // SECONDARY YELLOW 700
  "#038673", // SECONDARY GREEN 700
  "#3247b5", // SECONDARY PURPLE 700
  "#4cc3fd", // PRIMARY 400
  "#ffb866", // SECONDARY YELLOW 400
  "#3cd795", // SECONDARY GREEN 400
  "#8298fb", // SECONDARY PURPLE 400

  "#11a1fd", // PRIMARY 500
  "#ff9931", // SECONDARY YELLOW 500
  "#07c180", // SECONDARY GREEN 500
  "#5a75f9", // SECONDARY PURPLE 500

  "#0c7dd9", // PRIMARY 600
  "#db7823", // SECONDARY YELLOW 600
  "#06a57d", // SECONDARY GREEN 600
  "#4259d4", // SECONDARY PURPLE 600

  "#085db6", // PRIMARY 700
  "#b45917", // SECONDARY YELLOW 700
  "#038673", // SECONDARY GREEN 700
  "#3247b5", // SECONDARY PURPLE 700

];

// PIE CHART
export const DonutChartColorSet = [
  am5ColorSecondaryPurple400,
  am5ColorSecondaryGreen400,
  am5ColorSecondaryYellow400,
  am5ColorPrimary400,
  am5ColorSecondaryPurple500,
  am5ColorSecondaryGreen500,
  am5ColorSecondaryYellow500,
  am5ColorPrimary500,
  am5ColorSecondaryPurple600,
  am5ColorSecondaryGreen600,
  am5ColorSecondaryYellow600,
  am5ColorPrimary600,
  am5ColorSecondaryPurple700,
  am5ColorSecondaryGreen700,
  am5ColorSecondaryYellow700,
  am5ColorPrimary700,
];
export const DonutChartColorSet2 = [
  am5ColorPrimary600,
  am5ColorSecondaryYellow600,
  am5ColorSecondaryGreen600,
  am5ColorSecondaryPurple600,
  am5ColorPrimary500,
  am5ColorSecondaryYellow500,
  am5ColorSecondaryGreen500,
  am5ColorSecondaryPurple500,
  am5ColorPrimary400,
  am5ColorSecondaryYellow400,
  am5ColorSecondaryGreen400,
  am5ColorSecondaryPurple400,
];

export const DonutChartColorSet3 = [
  am5ColorSecondaryGreen600,
  am5ColorSecondaryYellow400,
  am5ColorSecondaryPurple500,
  am5ColorSecondaryGreen500,
  am5ColorSecondaryYellow500,
  am5ColorPrimary500,
  am5ColorSecondaryPurple600,
  am5ColorSecondaryGreen600,
  am5ColorSecondaryYellow600,
  am5ColorPrimary600,
  am5ColorSecondaryPurple700,
  am5ColorSecondaryGreen700,
  am5ColorSecondaryYellow700,
  am5ColorPrimary700,
];

// SOLID GAUGE
export const SolidGaugeDefaultColorSet = [
  SecondaryPurple400,
  SecondaryGreen400,
  SecondaryYellow400,
  Primary400,
];
export const SolidGaugeColorSet1 = [
  SecondaryGreen500,
  SecondaryYellow500,
  Primary500,
  SecondaryPurple500,
];

export const DefaultFlagIcon = DefaultFlag;



export const CURRENCY_METRICS = [
  "ecomm spends",
  "spends",
  "spend",
  "cpc",
  "cpm",
  "cprp",
  "clicks / conversion",
  "cost per video view",
  "cost per view",
  "total media cost (advertiser currency)",
  "cost per complete view",
  "tv spends",
  "digital spends: meta",
  "digital spends: dv360",
  "digital spends",
  "digital meta spends",
  "digital dv360 spends",
  "digital google spends",
  "tv cprp",
  "meta spend",
  "google spend",
  "sales value",
]

export const PERCENTAGE_METRIC = [
  "market share percent",
  "market share",
  "ctr",
  "engagement rate",
  "hsm share",
  "prime time share",
  "engagement rate (%)",
  "cr (%)",
  "ctr (%)",
  "average view percentage",
  "reach rate",
  "share of voice",
  "view rate",
  "vtr",
  "cvtr",
  "complete view rate",
  "post click conversion rate",
  "video view rate",
  "tv sov",
  "tv soe",
  "soe",
  "engagment rate: meta",
  "engagement rate: meta",
  "engagment rate: dv360",
  "engagement rate: dv360",
  "ctr: meta",
  "ctr: dv360",
  "view through rate: meta",
  "view through rate: dv360",
  "monthly sales vol. % change",
  "tv spends % change",
  "sov",
  "bounce rate",
  "meta engagement rate",
  "meta ctr",
  "google ctr",
  "meta vtr",
  "google vtr",
  "meta organic er",
  "meta paid er",
  "benchmarking 1:average",
  "benchmarking 2:campaign start fate",
  "grp percent",
  "benchmark",
  "video completion rate",
  "audience retention rate",
  "engagement rate",
  "tv spends %",
  "digital meta spends %",
  "digital dv360 spends %",
  "tv impressions %",
  "digital dv360 impressions %",
  "digital meta impressions %",
  "digital google impressions %",
  "digital google spends %",
  "digital meta impression %",
  "sales vol. change"
]

export const TONNES_METRIC = [
  "monthly sales vol.",
  "sales Vol.",
  "sales volume"
]




export const NO_PERCENTAGE_METRIC = [
  "CPCV",
  "CPV",
  "Cost Per Video View"
]

export const TIME_METRIC = [
  "avg duration",
  "avg time viewed(in mins)"
]

export const VIEWS_METRIC = [
  "average view duration"
]


export const IND_CENTER_POINTS = ["82,22"];
export const IND_NORTH_POINTS = ["72.6416015625, 37.71859032558816"];
export const IND_SOUTH_POINTS = ["93.1640625, 5.7908968128719565"];

export const US_CENTER_POINTS = ["-98.5795, 49.3844"];
export const US_NORTH_POINTS = ["-140.99778, 60.0000"];
export const US_SOUTH_POINTS = ["-66.93457, 24.396308"];

export const UK_CENTER_POINTS = ["-2.0, 54.0"];;
export const UK_NORTH_POINTS = ["-6.0, 60.0"];
export const UK_SOUTH_POINTS = ["0.0, 50.0"];