import React, { Component } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Wrapper from '../../../helpers/wrapper';
import { Link } from 'react-router-dom'

import IconContribution from '../../../../assets/images/icons/contributionIcon';
import IconImpressions from '../../../../assets/images/icons/impressionsIcon';
import IconSmiley from '../../../../assets/images/icons/smileyIcon';
import IconDownload from '../../../../assets/images/icons/downloadIcon'
import { Container, Image } from "react-bootstrap";
import { NumberFormatter } from '../../../shared/utilities/numberformatter'
import CompareLineChart from "../../../shared/components/charts/CompareLineChart";
import RiTreegraph from '../../../shared/components/charts/RiTreegraph';
import StickyLogo from "../../../../assets/images/icons/icon.png";
import DatePicker from "../../../shared/components/datepicker/datepicker";


import '../../../shared/components/loader/loader.scss';
import { Loader } from '../../../shared/components/loader/loader';
import ChartExport from "../../../shared/components/export/ChartExport";
import { API_URL } from '../../../../../constants/constants';


export default class BusinessSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: [{ "date": "2019-5-12", "value1": "50", "value2": "48", "previousdate": "2019-5-5" }, { "date": "2019-5-13", "value1": "53", "value2": "51", "previousdate": "2019-5-6" }, { "date": "2019-5-14", "value1": "56", "value2": "58", "previousdate": "2019-5-7" }, { "date": "2019-5-15", "value1": "52", "value2": "53", "previousdate": "2019-5-8" }, { "date": "2019-5-16", "value1": "48", "value2": "44", "previousdate": "2019-5-9" }, { "date": "2019-5-17", "value1": "47", "value2": "42", "previousdate": "2019-5-10" }, { "date": "2019-5-18", "value1": "59", "value2": "55", "previousdate": "2019-5-11" }],
      business: [{ "category": "Overall", "value": 80, "full": 100 }, { "category": "Zone", "value": 35, "full": 100 }, { "category": "City", "value": 92, "full": 100 }, { "category": "Store", "value": 68, "full": 100 }],
      sale: [{ "category": "Overall", "value": 80, "full": 100 }, { "category": "Zone", "value": 35, "full": 100 }, { "category": "City", "value": 92, "full": 100 }, { "category": "Store", "value": 68, "full": 100 }],
      //treegraph: [{"name":"PB","children":[{"name":"PB1","value":350},{"name":"PB2","value":400}]},{"name":"FLFL","children":[{"name":"FLFL1","value":350},{"name":"FLFL2","value":400}]},{"name":"Footwear","children":[{"name":"Men","value":350},{"name":"Women","value":400}]},{"name":"Sports","children":[{"name":"Men","value":350},{"name":"Women","value":200}]},{"name":"Luggage & Bagpacks","children":[{"name":"Luggage","value":350},{"name":"Bagpacks","value":150}]},{"name":"Accessaries","children":[{"name":"Men","value":350},{"name":"Women","value":180}]}],
      gaugedata: [{ "hand1.value": "50" }, { "hand2.value": "80" }, { "hand3.value": "40" }, { "hand4.value": "15" }],
      linesparkgraph: [{ "date": "2012-07-27", "value": 13 }, { "date": "2012-07-28", "value": 10 }, { "date": "2012-07-29", "value": 15 }, { "date": "2012-07-30", "value": 15 }, { "date": "2012-07-31", "value": 15 }, { "date": "2012-08-01", "value": 15 }, { "date": "2012-08-02", "value": 15 }, { "date": "2012-08-03", "value": 19 }, { "date": "2012-08-04", "value": 16 }, { "date": "2012-08-05", "value": 15 }, { "date": "2012-08-06", "value": 19 }, { "date": "2012-08-07", "value": 17 }, { "date": "2012-08-08", "value": 18 }],

      treegraph1: [{ "name": "PB", "children": [{ "name": "PB1", "children": [{ "name": "PB2", "value": 350 }] }] }],
      //{"name":"PB2","value":400}]},{"name":"FLFL","children":[{"name":"FLFL1","value":350},{"name":"FLFL2","value":400}]},{"name":"Footwear","children":[{"name":"Men","value":350},{"name":"Women","value":400}]},{"name":"Sports","children":[{"name":"Men","value":350},{"name":"Women","value":200}]},{"name":"Luggage & Bagpacks","children":[{"name":"Luggage","value":350},{"name":"Bagpacks","value":150}]},{"name":"Accessaries","children":[{"name":"Men","value":350},{"name":"Women","value":180}]}],

      is_sales_nsv_multiline_loaded: false,
      is_sales_nsv_multilineinfo_loaded: false,
      //is_sales_business_comparision_loaded: false,
      is_category_nsv_deep_dive_loaded: false,
      is_getfilter_loaded: false,
      multiline_asp: [],

      // map_zone: 'North Zone',
      // map_city: 'Rajkot',
      // storecode: 789,
      // storename: 'BF-RAJKOT',
      // state: 'Gujrat',

      // category: '',
      // store_type: '',
      // store_size: ''
      Deep_Dive_level: 0,
      Deep_Dive_Name: [],
      category_dropdown_value: "nsv",
    }
  }

  componentDidMount() {

    this.state.is_sales_nsv_multiline_loaded = false;
    this.state.is_sales_nsv_multilineinfo_loaded = false;
    // this.state.is_sales_business_comparision_loaded = false;
    this.state.is_category_nsv_deep_dive_loaded = false;



    this.getNSVTrendMultilinegraph();
    this.getNSVTrendMultilinegraphInfo();
    // this.getBusinessComparision();
    this.getCategoryNSVDeepDiveMap();

    this.handleCallback();
    this.forceUpdate();
  }

  isNull(object) {
    for (const [key, value] of Object.entries(object)) {
      if (typeof value === "object" && value !== null) {
        this.isNull(value);
      } else if (!value) {
        object[key] = 0;
      }
    }
    return object;
  }

  // NSV TREND MULTILINE
  getNSVTrendMultilinegraph() {
    // DATEPICKER
    let start_date = this.props.startDate;
    let end_date = this.props.endDate;
    let previous_start_date = this.props.lastStartDate;
    let previous_end_date = this.props.lastEndDate;

    //MAP
    let map_zone = this.props.mapZone;
    let map_city = this.props.mapCity;
    let storecode = this.props.mapStoreCode;
    let storename = this.props.mapStoreName;
    let state = this.props.mapState;

    //MAP FILTER
    let category = this.props.mapStoreCategory;
    let store_type = this.props.mapStoreType;
    let store_size = this.props.mapStoreSize;

    // const data = {

    //   'start_date': '2021-04-01',
    //   'end_date': '2021-04-11',

    // }
    const url = API_URL + "ri/sales_nsvtrend_multiline/"

    fetch(url, {

      method: 'POST',
      body: 'start_date=' + start_date +
        '&end_date=' + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&storecode=" + storecode +
        "&storename=" + storename +
        "&state=" + state +
        "&category=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,

      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': ' Bearer ' + localStorage.getItem("token")

      },
    })
      .then(res => res.json())
      .then((result) => {

        let current_nsv_multiline_list = result['current_nsv_trend_multiline_list']

        let last_nsv_multiline_list = result['last_nsv_trend_multiline_list']

        let nsv_trend_multiline_list = [];
        //let cost_per_click_spark_list = [];

        current_nsv_multiline_list.map((info, index) => {



          try {

            let a = {

              "date": info['datetime'],
              "value1": info['nsv'],
              "value2": last_nsv_multiline_list[index]['nsv'],
              "previousdate": last_nsv_multiline_list[index]['datetime']
            }
            nsv_trend_multiline_list.push(a);
          }
          catch (e) {

          }

        })


        this.setState({
          is_sales_nsv_multiline_loaded: true,
          user: nsv_trend_multiline_list,
        });
      })

  }

  // NSV TREND MULTILINE INFO
  getNSVTrendMultilinegraphInfo() {
    // DATEPICKER
    let start_date = this.props.startDate;
    let end_date = this.props.endDate;
    let previous_start_date = this.props.lastStartDate;
    let previous_end_date = this.props.lastEndDate;

    //MAP
    let map_zone = this.props.mapZone;
    let map_city = this.props.mapCity;
    let storecode = this.props.mapStoreCode;
    let storename = this.props.mapStoreName;
    let state = this.props.mapState;

    //MAP FILTER
    let category = this.props.mapStoreCategory;
    let store_type = this.props.mapStoreType;
    let store_size = this.props.mapStoreSize;


    // }
    const url = API_URL + "ri/sales_nsvtrend_multilineinfo/"
    fetch(url, {
      method: 'POST',
      //body: JSON.stringify(data),
      body: "start_date=" + start_date +
        "&end_date=" + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&storecode=" + storecode +
        "&storename=" + storename +
        "&state=" + state +
        "&category=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    }

    )
      .then(res => res.json())
      .then((result) => {



        let current_net_margin;
        let current_markdown;
        let current_nsv_ach;
        let current_asp;

        let current_nsv = result['nsv_trend_multiline_info_list'][0]['nsv']

        let current_target_nsv = result['nsv_trend_multiline_info_list'][0]['target_nsv']
        let current_bills = result['nsv_trend_multiline_info_list'][0]['bills']
        let current_rgm = result['nsv_trend_multiline_info_list'][0]['rgm']
        let current_tax = result['nsv_trend_multiline_info_list'][0]['tax']
        let current_gsv = result['nsv_trend_multiline_info_list'][0]['gsv']
        let current_units_sold = result['nsv_trend_multiline_info_list'][0]['units_sold']






        current_net_margin = ((current_rgm / (current_nsv - current_tax)) * 100).toFixed(2);
        current_markdown = (((current_gsv - current_nsv) / current_gsv) * 100).toFixed(2);
        current_nsv_ach = ((current_nsv / current_target_nsv) * 100).toFixed(2);
        current_asp = (current_nsv / current_units_sold).toFixed(2);

        this.setState({
          is_sales_nsv_multilineinfo_loaded: true,
          current_nsv: current_nsv,
          current_target_nsv: current_target_nsv,
          current_net_margin: current_net_margin,
          current_bills: current_bills,
          current_markdown: current_markdown,
          current_rgm: current_rgm,
          current_nsv_ach: current_nsv_ach,
          current_units_sold: current_units_sold,
          current_asp: current_asp,
        });

      },
        (error) => {
        }
      )

  }

  //BUSINESS COMPARISION btf N Promo
  // getBusinessComparision() {
  //   // DATEPICKER
  //   let start_date = this.props.startDate;
  //   let end_date = this.props.endDate;
  //   let previous_start_date = this.props.lastStartDate;
  //   let previous_end_date = this.props.lastEndDate;

  //   //MAP
  //   let map_zone = this.props.mapZone;
  //   let map_city = this.props.mapCity;
  //   let storecode = this.props.mapStoreCode;
  //   let storename = this.props.mapStoreName;
  //   let state = this.props.mapState;

  //   //MAP FILTER
  //   let category = this.props.mapStoreCategory;
  //   let store_type = this.props.mapStoreType;
  //   let store_size = this.props.mapStoreSize;


  //   //   const data = {
  //   //     'start_date': '2021-04-01',
  //   //     'end_date': '2021-04-10',

  //   // }
  //   const url = API_URL + "ri/sales_business_comparision/"
  //   fetch(url, {
  //     method: 'POST',
  //     // body: JSON.stringify(data),
  //     body: "start_date=" + start_date +
  //       "&end_date=" + end_date +
  //       "&last_start_date=" + previous_start_date +
  //       "&last_end_date=" + previous_end_date +
  //       "&map_zone=" + map_zone +
  //       "&map_city=" + map_city +
  //       "&storecode=" + storecode +
  //       "&storename=" + storename +
  //       "&state=" + state +
  //       "&category=" + category +
  //       "&store_type=" + store_type +
  //       "&store_size=" + store_size,
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //   }

  //   )
  //     .then(res => res.json())
  //     .then((result) => {



  //       let current_ats;

  //       let current_btfnsv;
  //       let current_nonbtfnsv;
  //       let current_btfbills;
  //       let current_nonbtfbills;
  //       //let current_ats_promo;
  //       let current_btfats;
  //       let current_nonbtfats;
  //       let current_nsvmix_btf;
  //       let current_nsvmix_nonbtf;
  //       //let current_nsvmix_promo;

  //       let current_mnsv = result['current_business_comparision_btf_list'][0]['nsv'];
  //       let current_nsv_m = (current_mnsv) * 100000;
  //       let current_mbills = result['current_business_comparision_btf_list'][0]['bills'];

  //       //let current_pnsv = result['current_business_comparision_promo_list'][0]['nsv']

  //       //let current_pbills = result['current_business_comparision_promo_list'][0]['bills']

  //       //let current_promonsv = result['current_business_comparision_promo_list'][0]['promo_nsv']

  //       current_btfbills = ((60 * current_mbills) / 100).toFixed(2);
  //       current_nonbtfbills = ((40 * current_mbills) / 100).toFixed(2);
  //       current_btfnsv = ((60 * current_nsv_m) / 100).toFixed(2);
  //       current_nonbtfnsv = ((40 * current_nsv_m) / 100).toFixed(2);
  //       current_ats = (current_nsv_m / current_mbills).toFixed(2);
  //       current_btfats = ((60 * current_ats) / 100).toFixed(2);
  //       current_nonbtfats = ((40 * current_ats) / 100).toFixed(2);

  //       current_nsvmix_btf = ((current_btfnsv / current_nsv_m) * 100).toFixed(2);
  //       current_nsvmix_nonbtf = ((current_nonbtfnsv / current_nsv_m) * 100).toFixed(2);


  //       // current_ats_promo = (current_pnsv/current_pbills).toFixed(2);

  //       //current_nsvmix_promo = ((current_pnsv/current_promonsv)*100).toFixed(2);



  //       this.setState({
  //         is_sales_business_comparision_loaded: true,
  //         current_mnsv: current_mnsv,
  //         current_nsv_m: current_nsv_m,
  //         current_mbills: current_mbills,
  //         current_btfbills: current_btfbills,
  //         current_nonbtfbills: current_nonbtfbills,
  //         current_btfnsv: current_btfnsv,
  //         current_nonbtfnsv: current_nonbtfnsv,
  //         current_ats: current_ats,
  //         current_btfats: current_btfats,
  //         current_nonbtfats: current_nonbtfats,
  //         current_nsvmix_btf: current_nsvmix_btf,
  //         current_nsvmix_nonbtf: current_nsvmix_nonbtf,
  //         // current_pnsv:current_pnsv,
  //         // current_pbills:current_pbills,
  //         // current_promonsv:current_promonsv,


  //         // current_ats_promo:current_ats_promo,
  //         // current_nsvmix_promo:current_nsvmix_promo,



  //       });

  //     },
  //       (error) => {
  //       }
  //     )


  // }

  //CATEGORY NSV DEEP-DRIVE
  getCategoryNSVDeepDiveMap() {
    // DATEPICKER
    let start_date = this.props.startDate;
    let end_date = this.props.endDate;
    let previous_start_date = this.props.lastStartDate;
    let previous_end_date = this.props.lastEndDate;

    //MAP
    let map_zone = this.props.mapZone;
    let map_city = this.props.mapCity;
    let storecode = this.props.mapStoreCode;
    let storename = this.props.mapStoreName;
    let state = this.props.mapState;

    //MAP FILTER
    let category = this.props.mapStoreCategory;
    let store_type = this.props.mapStoreType;
    let store_size = this.props.mapStoreSize;
    let Deep_Dive_level = this.state.Deep_Dive_level;
    let Deep_Dive_Name = this.state.Deep_Dive_Name;

    // const data = {
    //   'start_date': '2022-01-20',
    //   'end_date': '2022-01-25',

    // }
    const url = API_URL + "ri/sales_nsv_deepdive/"

    fetch(url, {
      method: 'POST',
      //body: JSON.stringify(data),
      body: "start_date=" + start_date +
        "&end_date=" + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&storecode=" + storecode +
        "&storename=" + storename +
        "&state=" + state +
        "&category=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size +
        "&level=" + Deep_Dive_level +
        "&name=" + Deep_Dive_Name,


      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    }
    )
      .then(res => res.json())
      .then((result) => {
        let category_nsv_deep_dive_list = result;


        // let aspColor = "";
        // aspColor =
        //   value < 70
        //     ? "#d51f30"
        //     : value < 94
        //     ? "#db7823"
        //     : "#038673";

        this.setState({
          is_category_nsv_deep_dive_loaded: true,
          treegraph1: category_nsv_deep_dive_list,
          // treegraph: [{"name":"PB","children":[{"name":"PB1","value":350},{"name":"PB2","value":400}]},{"name":"FLFL","children":[{"name":"FLFL1","value":350},{"name":"FLFL2","value":400}]},{"name":"Footwear","children":[{"name":"Men","value":350},{"name":"Women","value":400}]},{"name":"Sports","children":[{"name":"Men","value":350},{"name":"Women","value":200}]},{"name":"Luggage & Bagpacks","children":[{"name":"Luggage","value":350},{"name":"Bagpacks","value":150}]},{"name":"Accessaries","children":[{"name":"Men","value":350},{"name":"Women","value":180}]}]
        });
      })

  }


  componentDidUpdate(prevProps) {
    if (prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
      this.handleCallback();
    }
    if (prevProps.mapStoreCode !== this.props.mapStoreCode) {
      this.handleRISalesSummaryMapCallBack();
    }
    if (prevProps.mapStoreCategory !== this.props.mapStoreCategory || prevProps.mapStoreType !== this.props.mapStoreType || prevProps.mapStoreSize !== this.props.mapStoreSize) {
      this.handleRiMapFilterCallback();
    }
    if (prevProps.mapZone !== this.props.mapZone || prevProps.mapCity !== this.props.mapCity || prevProps.mapStoreCode !== this.props.mapStoreCode) {
      this.handleCallbackMap();
    }
  }

  handleCallback = () => {
    this.state.is_sales_nsv_multiline_loaded = false;
    this.state.is_sales_nsv_multilineinfo_loaded = false;
    //this.state.is_sales_business_comparision_loaded = false;
    this.state.is_category_nsv_deep_dive_loaded = false;

    this.getNSVTrendMultilinegraph();
    this.getNSVTrendMultilinegraphInfo();
    //this.getBusinessComparision();
    this.getCategoryNSVDeepDiveMap();

    this.forceUpdate();
  }

  handleRISalesSummaryMapCallBack = () => {
    this.state.is_sales_nsv_multiline_loaded = false;
    this.state.is_sales_nsv_multilineinfo_loaded = false;
    //this.state.is_sales_business_comparision_loaded = false;
    this.state.is_category_nsv_deep_dive_loaded = false;

    this.getNSVTrendMultilinegraph();
    this.getNSVTrendMultilinegraphInfo();
    // this.getBusinessComparision();
    this.getCategoryNSVDeepDiveMap();

    this.forceUpdate();
  }

  handleRiMapFilterCallback = () => {
    this.state.is_sales_nsv_multiline_loaded = false;
    this.state.is_sales_nsv_multilineinfo_loaded = false;
    // this.state.is_sales_business_comparision_loaded = false;
    this.state.is_category_nsv_deep_dive_loaded = false;

    this.getNSVTrendMultilinegraph();
    this.getNSVTrendMultilinegraphInfo();
    // this.getBusinessComparision();
    this.getCategoryNSVDeepDiveMap();

    this.forceUpdate();
  }

  handleCallbackMap = () => {
    this.state.is_sales_nsv_multiline_loaded = false;
    this.state.is_sales_nsv_multilineinfo_loaded = false;

    this.state.is_category_nsv_deep_dive_loaded = false;

    this.getNSVTrendMultilinegraph();
    this.getNSVTrendMultilinegraphInfo();
    // this.getBusinessComparision();
    this.getCategoryNSVDeepDiveMap();

    this.forceUpdate();
  }

  //Rupesh Deep Dive code
  handleNSVdeepDiveCallback = (childData) => {

    let DeepDiveBreadCrumb = this.state.Deep_Dive_Name;

    DeepDiveBreadCrumb.push(childData.replace('&', '~andtoreplace~'));

    this.state.Deep_Dive_Name = DeepDiveBreadCrumb;
    this.state.Deep_Dive_level = DeepDiveBreadCrumb.length;

    //this.state.Deep_Dive_Name = childData;
    // let Deep_Dive_Name = this.state.Deep_Dive_Name;
    // let Deep_Dive_level = this.state.Deep_Dive_level;

    if (DeepDiveBreadCrumb.length <= 9) {
      this.state.is_category_nsv_deep_dive_loaded = false;

      this.getCategoryNSVDeepDiveMap();
      this.forceUpdate();

    } else {
      alert('Maximum level reached');
    }
  }

  handleNSVdeepDiveCallback2 = (childData) => {
    let DeepDiveBreadCrumb = this.state.Deep_Dive_Name;

    var removed = DeepDiveBreadCrumb.splice(childData);

    this.state.Deep_Dive_Name = DeepDiveBreadCrumb;
    this.state.Deep_Dive_level = DeepDiveBreadCrumb.length;

    if (DeepDiveBreadCrumb.length <= 9) {
      this.state.is_category_nsv_deep_dive_loaded = false;

      this.getCategoryNSVDeepDiveMap();
      this.forceUpdate();
    } else {
      alert('Maximum level reached');
    }
  }

  render() {


    return (
      <Wrapper>

        <div className="icz-row">
          <Col className="icz-sectioncardwrapper" sm={12} lg={12}>
            <div className="icz-sectioncard">
              <div className="icz-cardheader">
                <Col className="icz-cardtitle">
                  NSV Trend
                </Col>
                <Col className="icz-cardfilter">
                  <div className="icz-cardbtnwrapper">
                    <ChartExport ChartId='nsv_trend' exportName="NSV Trend"
                      export_href={this.state.export_href} downloadName=" NSV-Trend.csv"
                      export_data={this.state.user}
                    />
                  </div>
                </Col>
              </div>
              <div className="icz-dchartsection d-flex icogz-row" id="nsvtrendexoprt">
                {this.state.is_sales_nsv_multiline_loaded == true && this.state.is_sales_nsv_multilineinfo_loaded == true ?
                  <>
                    <Col className="" sm={12} lg={9}>
                      <CompareLineChart chart_id="nsv_trend" chart_class="icz-sectionchart" graph-data={this.state.user} Ytitle={"NSV"} metric_1={"Current Period"} metric_2={"Previous Period"} isnumber={'money'} />
                    </Col>
                    <Col className="icz-chartdetailssection" sm={12} lg={3}>
                      <div className="icz-detailsdatasection  icz-detailscroll">
                        <div className="icz-detailsdatatab">
                          <div className="icz-iconwrapper">
                            <IconImpressions className="icz-icon" />
                          </div>
                          <div className="icz-titlewrapper">
                            <div className="icz-title">
                              <NumberFormatter Number={parseFloat(this.state.current_nsv).toFixed(2)} IsNumber={'money'} />
                            </div>
                            <div className="icz-subtitle">
                              NSV
                            </div>
                          </div>
                        </div>
                        <div className="icz-detailsdatatab">
                          <div className="icz-iconwrapper">
                            <IconSmiley className="icz-icon" />
                          </div>
                          <div className="icz-titlewrapper">
                            <div className="icz-title">
                              <NumberFormatter Number={parseFloat(this.state.current_nsv_ach).toFixed(2)} IsNumber={'percentage'} />
                            </div>
                            <div className="icz-subtitle">
                              NSV Ach%
                            </div>
                          </div>
                        </div>
                        <div className="icz-detailsdatatab">
                          <div className="icz-iconwrapper">
                            <IconContribution className="icz-icon" />
                          </div>
                          <div className="icz-titlewrapper">
                            <div className="icz-title">
                              <NumberFormatter Number={parseFloat(this.state.current_bills).toFixed(2)} IsNumber={'number'} />
                            </div>
                            <div className="icz-subtitle">
                              Bills
                            </div>
                          </div>
                        </div>
                        <div className="icz-detailsdatatab">
                          <div className="icz-iconwrapper">
                            <IconImpressions className="icz-icon" />
                          </div>
                          <div className="icz-titlewrapper">
                            <div className="icz-title">
                              <NumberFormatter Number={parseFloat(this.state.current_asp).toFixed(2)} IsNumber={'money'} />
                              {/* <NumberFormatter Number={this.state.current_asp} IsNumber={true} /> */}
                            </div>
                            <div className="icz-subtitle">
                              ASP
                            </div>
                          </div>
                        </div>
                        <div className="icz-detailsdatatab">
                          <div className="icz-iconwrapper">
                            <IconImpressions className="icz-icon" />
                          </div>
                          <div className="icz-titlewrapper">
                            <div className="icz-title">
                              <NumberFormatter Number={parseFloat(this.state.current_markdown).toFixed(2)} IsNumber={'percentage'} />
                            </div>
                            <div className="icz-subtitle">
                              Markdown%
                            </div>
                          </div>
                        </div>
                        <div className="icz-detailsdatatab">
                          <div className="icz-iconwrapper">
                            <IconImpressions className="icz-icon" />
                          </div>
                          <div className="icz-titlewrapper">
                            <div className="icz-title">
                              <NumberFormatter Number={parseFloat(this.state.current_net_margin).toFixed(2)} IsNumber={'percentage'} />
                            </div>
                            <div className="icz-subtitle">
                              Net Margin%
                            </div>
                          </div>
                        </div>
                        <div className="icz-detailsdatatab">
                          <div className="icz-iconwrapper">
                            <IconImpressions className="icz-icon" />
                          </div>
                          <div className="icz-titlewrapper">
                            <div className="icz-title">
                              <NumberFormatter
                                Number={parseFloat(this.state.current_rgm).toFixed(2)}
                                IsNumber={'money'} />
                            </div>
                            <div className="icz-subtitle">
                              RGM
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </>
                  :
                  // LOADER
                  <Loader />
                }
              </div>
            </div>
          </Col>
        </div>

        <div className="icz-row">
          <div className="icz-sectioncardwrapper w-100">
            <div className="icz-sectioncard">
              <div className="icz-cardheader">
                <Col className="icz-cardtitle">
                  Business Comparison
                </Col>
                <div class="icz-cardfilter">
                  <ChartExport
                    ChartId='businessComparison'
                    export_data={[]}
                    downloadName="Business Comparison"
                  />
                </div>
              </div>
              {/* {this.state.is_sales_business_comparision_loaded == true ? */}
              <Col id="businessComparison" lg={12} className="d-flex">
                <Col lg={6} sm={12} className="icz-bcomparison">
                  <Col lg={12} sm={12} className="icz-bcomparisontitlewrapper">
                    <Col lg={4} className="align-items-start"><h3 className="icz-bcomparisontitle">Promo</h3></Col>
                    <Col lg={4}><h3 className="icz-bcomparisontitle"></h3></Col>
                    <Col lg={4}><h3 className="icz-bcomparisontitle">Non-Promo</h3></Col>
                  </Col>
                  <Col lg={12} sm={12} className="icz-bcomparisontitlewrapper icz-bcomparisondata icz-bcomparebg1">
                    <Col lg={4}><h5 className="icz-bcomparisonsubtitle">15 Crs</h5></Col>
                    <Col lg={4}><h5 className="icz-bcomparisonsubtitle">NSV</h5></Col>
                    <Col lg={4}><h5 className="icz-bcomparisonsubtitle">10 Crs</h5></Col>
                  </Col>

                  <Col lg={12} sm={12} className="icz-bcomparisontitlewrapper icz-bcomparisondata icz-bcomparebg2">
                    <Col lg={4}><h5 className="icz-bcomparisonsubtitle">60K</h5></Col>
                    <Col lg={4}><h5 className="icz-bcomparisonsubtitle">Bills</h5></Col>
                    <Col lg={4}><h5 className="icz-bcomparisonsubtitle">40K</h5></Col>
                  </Col>

                  <Col lg={12} sm={12} className="icz-bcomparisontitlewrapper icz-bcomparisondata icz-bcomparebg3">
                    <Col lg={4}><h5 className="icz-bcomparisonsubtitle">2,500</h5></Col>
                    <Col lg={4}><h5 className="icz-bcomparisonsubtitle">ATS</h5></Col>
                    <Col lg={4}><h5 className="icz-bcomparisonsubtitle">2,500</h5></Col>
                  </Col>

                  <Col lg={12} sm={12} className="icz-bcomparisontitlewrapper icz-bcomparisondata icz-bcomparebg4">
                    <Col lg={4}><h5 className="icz-bcomparisonsubtitle">60%</h5></Col>
                    <Col lg={4}><h5 className="icz-bcomparisonsubtitle">NSV Mix%</h5></Col>
                    <Col lg={4}><h5 className="icz-bcomparisonsubtitle">40%</h5></Col>
                  </Col>
                </Col>

                <Col lg={6} sm={12} className="icz-bcomparison">
                  <Col lg={12} sm={12} className="icz-bcomparisontitlewrapper">
                    <Col lg={4} className="align-items-start"><h3 className="icz-bcomparisontitle">BTF</h3></Col>
                    <Col lg={4}><h3 className="icz-bcomparisontitle"></h3></Col>
                    <Col lg={4}><h3 className="icz-bcomparisontitle">Non-BTF</h3></Col>
                  </Col>
                  <Col lg={12} sm={12} className="icz-bcomparisontitlewrapper icz-bcomparisondata icz-bcomparebg5">
                    {/* <Col lg={4}><h5 className="icz-bcomparisonsubtitle">{this.state.current_btfnsv}</h5></Col> */}
                    <Col lg={4}><h5 className="icz-bcomparisonsubtitle">10Cr</h5></Col>

                    <Col lg={4}><h5 className="icz-bcomparisonsubtitle">NSV</h5></Col>
                    {/* <Col lg={4}><h5 className="icz-bcomparisonsubtitle">{this.state.current_nonbtfnsv}</h5></Col> */}

                    <Col lg={4}><h5 className="icz-bcomparisonsubtitle">15Cr</h5></Col>
                  </Col>

                  <Col lg={12} sm={12} className="icz-bcomparisontitlewrapper icz-bcomparisondata icz-bcomparebg6">
                    {/* <Col lg={4}><h5 className="icz-bcomparisonsubtitle">{this.state.current_btfbills}</h5></Col> */}
                    <Col lg={4}><h5 className="icz-bcomparisonsubtitle">40K</h5></Col>

                    <Col lg={4}><h5 className="icz-bcomparisonsubtitle">Bills</h5></Col>
                    {/* <Col lg={4}><h5 className="icz-bcomparisonsubtitle">{this.state.current_nonbtfbills}</h5></Col> */}

                    <Col lg={4}><h5 className="icz-bcomparisonsubtitle">60K</h5></Col>
                  </Col>

                  <Col lg={12} sm={12} className="icz-bcomparisontitlewrapper icz-bcomparisondata icz-bcomparebg7">
                    {/* <Col lg={4}><h5 className="icz-bcomparisonsubtitle">{this.state.current_btfats}</h5></Col> */}
                    <Col lg={4}><h5 className="icz-bcomparisonsubtitle">2,500</h5></Col>
                    <Col lg={4}><h5 className="icz-bcomparisonsubtitle">ATS</h5></Col>
                    {/* <Col lg={4}><h5 className="icz-bcomparisonsubtitle">{this.state.current_nonbtfats}</h5></Col> */}
                    <Col lg={4}><h5 className="icz-bcomparisonsubtitle">2,500</h5></Col>
                  </Col>

                  <Col lg={12} sm={12} className="icz-bcomparisontitlewrapper icz-bcomparisondata icz-bcomparebg8">
                    {/* <Col lg={4}><h5 className="icz-bcomparisonsubtitle">{this.state.current_nsvmix_btf}%</h5></Col> */}
                    <Col lg={4}><h5 className="icz-bcomparisonsubtitle">40%</h5></Col>

                    <Col lg={4}><h5 className="icz-bcomparisonsubtitle">NSV Mix%</h5></Col>
                    {/* <Col lg={4}><h5 className="icz-bcomparisonsubtitle">{this.state.current_nsvmix_nonbtf}%</h5></Col> */}

                    <Col lg={4}><h5 className="icz-bcomparisonsubtitle">60%</h5></Col>
                  </Col>
                </Col>
              </Col>
              {/* :
                // LOADER
                <Loader />
              } */}
            </div>

          </div>
        </div>

        <div className="icz-row">
          <div className="icz-sectioncardwrapper w-100">
            <div className="icz-sectioncard">
              <Col sm={12} className="icz-cardheader">
                <Col lg={10} className="icz-cardtitle">
                  Category NSV Deep-dive
                </Col>

                <Col lg={2} className="icz-cardfilter">
                  <div className="icz-cardbtnwrapper">
                    <ChartExport ChartId='icz-treemap' exportName="Category-NSV-Deep-dive"
                      export_href={this.state.export_href} downloadName="Category-NSV-Deep-dive.csv"
                    />
                  </div>
                </Col>
              </Col>
              {this.state.is_category_nsv_deep_dive_loaded == true ?
                <>
                  <div className="">
                    <RiTreegraph card_id="icz-treemap" card_class="icz-sectionchart"
                      graph-data={this.state.treegraph1}
                      parentCallback={this.handleNSVdeepDiveCallback}
                      parentCallback2={this.handleNSVdeepDiveCallback2}
                      Level_Counter={this.state.Deep_Dive_level}
                      Deep_Dive_BreadCrumb={this.state.Deep_Dive_Name}
                      isnumber={'money'}
                      dropdownValue={this.state.category_dropdown_value}
                    />
                  </div>
                  <div>
                    <Col lg={12} className="icz-ridatabullet">
                      <ul className="icz-ridatalist-inline">
                        <li>Area = NSV</li>
                        <li><div className="icz-ridatalist-bullet5" /> High ASP</li>
                        <li><div className="icz-ridatalist-bullet2" /> Med ASP</li>
                        <li><div className="icz-ridatalist-bullet6" /> Low ASP</li>
                      </ul>
                    </Col>
                  </div>
                </>
                :
                // LOADER
                <Loader />
              }

            </div>
          </div>
        </div>

        <div className="icz-riknwbttnwrapper">
          <Link to='/roi/business-overview' className="btn icz-riknowmore">Know more</Link>
        </div>

      </Wrapper >
    );
  }
}