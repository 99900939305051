import { Component } from "react";
import SomethingWentWrong from "../components/ui/loaders/SomethingWentWrong";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log("ErrorBoundary", error, errorInfo)
    }

    render() {
        if (this.state.hasError) {
            return <SomethingWentWrong chartType={this.props.chartType} />

        }

        return this.props.children;
    }
}

export default ErrorBoundary;