import React, { Component } from "react";
import { connect } from "react-redux";
import Wrapper from "../../../helpers/wrapper";
import { Col, Container, Image } from "react-bootstrap";
import "../../ecom/ecom.scss";
import Piechart from "../../../shared/components/charts/Piechart";
import Linegraph_sparkline from "../../../shared/components/charts/Linegraph_sparkline";
import { ChartCard } from "../../../shared/components/cards/chartcard/chartcard";
import { Carousel } from "../../../shared/components/carousel/carousel";
import "../../../shared/components/cards/card.scss";
import IconWebsite from "../../../../assets/images/icons/websiteIcon";
import MultipleValueAxes from "../../../shared/components/charts/MultipleValueAxesChart";
// import MultipleValueAxes_1 from '../../../shared/components/charts/MultipleValueAxesChart_1';
import { DailydataDropdown } from "../../../shared/components/dropdown/dailydatadropdown";
import Treemap from "../../../shared/components/charts/TreeMap";
import Bubble_chart from "../../../shared/components/charts/Bubble_chart";
import Cluster_chart from "../../../shared/components/charts/Cluster_chart";
import CohortGraph from "react-cohort-graph";
import { Link } from "react-router-dom";
import DataTable from "../../../shared/components/Datatable/DataTable";
import DatePicker from "../../../shared/components/datepicker/datepicker";
import IconPdf from "../../../../assets/images/icons/pdfIcon";

import StickyLogo from "../../../../assets/images/icons/icon.png";
import { Loader } from "../../../shared/components/loader/loader";
import '../../../layout/pageheader/pageheader.scss';

import { API_URL } from "../../../../../constants/constants";
import CompareLineChart from "../../../shared/components/charts/CompareLineChart";
import HeatMap from "../../../shared/components/charts/HeatMap";
import ChartExport from "../../../shared/components/export/ChartExport";
import { NoDataAvailable } from '../../../shared/components/loader/noDataAvailable';
import ExportDropdown from "../../../shared/components/export/ExportDropdown";
import PageHeader from "../../../../../components/layout/pageHeader";
import { CountryCode } from "../../../../../constants/countryCurrency";

export class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trafficeChannel: "",

      dropdown_options: [
        { value: "Bargain_hunters", label: "Bargain Hunters" },
        { value: "need_attention", label: "Need Attention" },
        { value: "lost", label: "Lost" },
        { value: "loyalists", label: "Loyalists" },
        { value: "promising", label: "Promising" },
        { value: "at_risk", label: "At Risk" },
        { value: "champions", label: "Champions" },
        { value: "potential_loyalists", label: "Potential Loyalists" },
        { value: "hibernating", label: "Hibernating" },
        { value: "about_to_sleep", label: "About To Sleep" },
      ],
      trafficpievalue: [
        { name: "Bargain Hunters", value: 30 },
        { name: "Need Attention", value: 30 },
        { name: "Lost", value: 50 },
        { name: "Promising", value: 40 },
        { name: "Loyalists", value: 60 },
      ],
      bargraphsession: [
        { name: "Jan", points: 100 },
        { name: "Feb", points: 200 },
        { name: "march", points: 110 },
        { name: "April", points: 100 },
        { name: "May", points: 100 },
        { name: "June", points: 100 },
        { name: "July", points: 100 },
        { name: "Aug", points: 100 },
        { name: "Sep", points: 130 },
        { name: "Oct", points: 140 },
        { name: "Nov", points: 200 },
        { name: "Dec", points: 180 },
      ],
      linesparkgraph: [
        { date: "2012-07-27", value: 13 },
        { date: "2012-07-28", value: 10 },
        { date: "2012-07-29", value: 15 },
        { date: "2012-07-30", value: 15 },
        { date: "2012-07-31", value: 15 },
        { date: "2012-08-01", value: 15 },
        { date: "2012-08-02", value: 15 },
        { date: "2012-08-03", value: 19 },
        { date: "2012-08-04", value: 16 },
        { date: "2012-08-05", value: 15 },
        { date: "2012-08-06", value: 19 },
        { date: "2012-08-07", value: 17 },
        { date: "2012-08-08", value: 18 },
      ],
      scroll: "",
      funnelgraph: [
        { name: "Sessions with Category Page Views", value: 150 },
        { name: "Sessions with Product detail page views", value: 300 },
        { name: "Sessions with Add to Cart", value: 150 },
        { name: "Sessions with visit to Checkout Page", value: 180 },
        { name: "Sessions with Visit to Address Page", value: 50 },
        { name: "Sessions with Purchase", value: 10 },
        { name: "Sessions with Visit to Payment Page", value: 20 },
      ],
      treegraph: [
        { name: "Home", children: [{ name: "Kitchen", value: 150 }] },
        {
          name: "Men",
          children: [
            { name: "Casual", value: 350 },
            { name: "Western", value: 400 },
          ],
        },
        {
          name: "Women",
          children: [
            { name: "Western", children: [{ name: "Jeans", value: 240 }] },
            { name: "Casual", value: 100 },
          ],
        },
        { name: "Lifestyle", value: 180 },
      ],
      clustergraph: [
        { network: "Bogo", MAU: 22552 },
        { network: "Google+75", MAU: 20552 },
        { network: "Instagram250", MAU: 13552 },
        { network: "Freedel", MAU: 19552 },
        { network: "First500", MAU: 20552 },
      ],
      bubblechart: [
        { hour: "12pm", weekday: "Sun", value: 2990 },
        { hour: "1pm", weekday: "Sun", value: 2990 },
        { hour: "2pm", weekday: "Sun", value: 1990 },
        { hour: "3pm", weekday: "Sun", value: 990 },
        { hour: "4pm", weekday: "Sun", value: 2000 },
        { hour: "5pm", weekday: "Sun", value: 7890 },
        { hour: "6pm", weekday: "Sun", value: 1290 },
        { hour: "7pm", weekday: "Sun", value: 1090 },
        { hour: "8pm", weekday: "Sun", value: 2990 },
        { hour: "9pm", weekday: "Sun", value: 2990 },
        { hour: "10pm", weekday: "Sun", value: 2990 },
        { hour: "11pm", weekday: "Sun", value: 2990 },
        { hour: "12pm", weekday: "Sun", value: 2990 },
        { hour: "1am", weekday: "Sun", value: 1890 },
        { hour: "2am", weekday: "Sun", value: 1990 },
        { hour: "3am", weekday: "Sun", value: 1000 },
        { hour: "4am", weekday: "Sun", value: 2100 },
        { hour: "5am", weekday: "Sun", value: 2990 },
        { hour: "6am", weekday: "Sun", value: 2100 },
        { hour: "7am", weekday: "Sun", value: 2990 },
        { hour: "8am", weekday: "Sun", value: 1200 },
        { hour: "9am", weekday: "Sun", value: 900 },
        { hour: "10am", weekday: "Sun", value: 2000 },
        { hour: "11am", weekday: "Sun", value: 1100 },
        { hour: "12am", weekday: "Sun", value: 2990 },
        { hour: "12pm", weekday: "Mon", value: 2990 },
        { hour: "1pm", weekday: "Mon", value: 2990 },
        { hour: "2pm", weekday: "Mon", value: 1990 },
        { hour: "3pm", weekday: "Mon", value: 990 },
        { hour: "4pm", weekday: "Mon", value: 2000 },
        { hour: "5pm", weekday: "Mon", value: 7890 },
        { hour: "6pm", weekday: "Mon", value: 1290 },
        { hour: "7pm", weekday: "Mon", value: 1090 },
        { hour: "8pm", weekday: "Mon", value: 2990 },
        { hour: "9pm", weekday: "Mon", value: 2990 },
        { hour: "10pm", weekday: "Mon", value: 2990 },
        { hour: "11pm", weekday: "Mon", value: 2990 },
        { hour: "12pm", weekday: "Mon", value: 2990 },
        { hour: "1am", weekday: "Mon", value: 1890 },
        { hour: "2am", weekday: "Mon", value: 1990 },
        { hour: "3am", weekday: "Mon", value: 1000 },
        { hour: "4am", weekday: "Mon", value: 2100 },
        { hour: "5am", weekday: "Mon", value: 2990 },
        { hour: "6am", weekday: "Mon", value: 2100 },
        { hour: "7am", weekday: "Mon", value: 2990 },
        { hour: "8am", weekday: "Mon", value: 1200 },
        { hour: "9am", weekday: "Mon", value: 900 },
        { hour: "10am", weekday: "Mon", value: 2000 },
        { hour: "11am", weekday: "Mon", value: 1100 },
        { hour: "12am", weekday: "Mon", value: 2990 },
        { hour: "12pm", weekday: "Wed", value: 2990 },
        { hour: "1pm", weekday: "Wed", value: 2990 },
        { hour: "2pm", weekday: "Wed", value: 1990 },
        { hour: "3pm", weekday: "Wed", value: 990 },
        { hour: "4pm", weekday: "Wed", value: 2000 },
        { hour: "5pm", weekday: "Wed", value: 7890 },
        { hour: "6pm", weekday: "Wed", value: 1290 },
        { hour: "7pm", weekday: "Wed", value: 1090 },
        { hour: "8pm", weekday: "Wed", value: 2990 },
        { hour: "9pm", weekday: "Wed", value: 2990 },
        { hour: "10pm", weekday: "Wed", value: 2990 },
        { hour: "11pm", weekday: "Wed", value: 2990 },
        { hour: "12pm", weekday: "Wed", value: 2990 },
        { hour: "1am", weekday: "Wed", value: 1890 },
        { hour: "2am", weekday: "Wed", value: 1990 },
        { hour: "3am", weekday: "Wed", value: 1000 },
        { hour: "4am", weekday: "Wed", value: 2100 },
        { hour: "5am", weekday: "Wed", value: 2990 },
        { hour: "6am", weekday: "Wed", value: 2100 },
        { hour: "7am", weekday: "Wed", value: 2990 },
        { hour: "8am", weekday: "Wed", value: 1200 },
        { hour: "9am", weekday: "Wed", value: 900 },
        { hour: "10am", weekday: "Wed", value: 2000 },
        { hour: "11am", weekday: "Wed", value: 1100 },
        { hour: "12am", weekday: "Wed", value: 2990 },
        { hour: "12pm", weekday: "Wed", value: 2990 },
        { hour: "1pm", weekday: "Thu", value: 2990 },
        { hour: "2pm", weekday: "Thu", value: 1990 },
        { hour: "3pm", weekday: "Thu", value: 990 },
        { hour: "4pm", weekday: "Thu", value: 2000 },
        { hour: "5pm", weekday: "Thu", value: 7890 },
        { hour: "6pm", weekday: "Thu", value: 1290 },
        { hour: "7pm", weekday: "Thu", value: 1090 },
        { hour: "8pm", weekday: "Thu", value: 2990 },
        { hour: "9pm", weekday: "Thu", value: 2990 },
        { hour: "10pm", weekday: "Thu", value: 2990 },
        { hour: "11pm", weekday: "Thu", value: 2990 },
        { hour: "12pm", weekday: "Thu", value: 2990 },
        { hour: "1am", weekday: "Thu", value: 1890 },
        { hour: "2am", weekday: "Thu", value: 1990 },
        { hour: "3am", weekday: "Thu", value: 1000 },
        { hour: "4am", weekday: "Thu", value: 2100 },
        { hour: "5am", weekday: "Thu", value: 2990 },
        { hour: "6am", weekday: "Thu", value: 2100 },
        { hour: "7am", weekday: "Thu", value: 2990 },
        { hour: "8am", weekday: "Thu", value: 1200 },
        { hour: "9am", weekday: "Thu", value: 900 },
        { hour: "10am", weekday: "Thu", value: 2000 },
        { hour: "11am", weekday: "Thu", value: 1100 },
        { hour: "12am", weekday: "Thu", value: 2990 },
      ],
      headers: [
        { name: "Personas", field: "Personas", sortable: "false" },
        { name: "Count", field: "Count", sortable: "false" },
        { name: "Revenue", filed: "revenue", sort: true },
        { name: "Transactions", filed: "transactions", sort: true },
        { name: "Average Order Value", filed: "ATS", sort: true },
        { name: "Average basket Size", filed: "NSU", sort: true },
        { name: "Top Category", filed: "Top Category", sort: true },
        // { name: "Top Brand", filed: "Top Brand", sort: true },
        {
          name: "Avg Time Between Purchases",
          filed: "Avg Time Between Purchases",
          sort: true,
        },
        { name: "Recency", filed: "Recency", sort: true },
      ],
      comments: "",
      table_header: [
        { name: "Personas", field: "name", sortable: true },
        { name: "Count", field: "count", sortable: true },
        { name: `Revenue (${this.getCountryCurrency()})`, field: "sales", sortable: true },
        { name: "Transactions", field: "bills", sortable: true },
        { name: `Average Order Value (${this.getCountryCurrency()})`, field: "ats", sortable: true },
        { name: "Average basket Size", field: "nsu", sortable: true },
        { name: "Top Category", field: "topcategory", sortable: true },
        { name: "Top Brand", field: "topbrand", sortable: true },
        { name: "Avg Time Between Purchases", field: "avg", sortable: true },
        { name: "Recency", field: "recency", sortable: true },
        { name: "Coupon Penetration", field: "coupon", sortable: true },
      ],
      user: [
        {
          date: "2019-5-12",
          value1: "50",
          value2: "48",
          previousdate: "2019-5-5",
        },
        {
          date: "2019-5-13",
          value1: "53",
          value2: "51",
          previousdate: "2019-5-6",
        },
        {
          date: "2019-5-14",
          value1: "56",
          value2: "58",
          previousdate: "2019-5-7",
        },
        {
          date: "2019-5-15",
          value1: "52",
          value2: "53",
          previousdate: "2019-5-8",
        },
        {
          date: "2019-5-16",
          value1: "48",
          value2: "44",
          previousdate: "2019-5-9",
        },
        {
          date: "2019-5-17",
          value1: "47",
          value2: "42",
          previousdate: "2019-5-10",
        },
        {
          date: "2019-5-18",
          value1: "59",
          value2: "55",
          previousdate: "2019-5-11",
        },
      ],
      table_data: [
        {
          name: "Bargain Hunters",
          count: "100k",
          sales: "200M",
          bills: "30%",
          ats: "45",
          nsu: "20",
          topcategory: "Electronics",
          topbrand: "Mobile",
          avg: "10",
          recency: "20",
          coupon: "10",
        },
        {
          name: "Need Attention",
          count: "100k",
          sales: "200M",
          bills: "30%",
          ats: "45",
          nsu: "20",
          topcategory: "Electronics",
          topbrand: "Mobile",
          avg: "10",
          recency: "20",
          coupon: "10",
        },
        {
          name: "Lost",
          count: "100k",
          sales: "200M",
          bills: "30%",
          ats: "45",
          nsu: "20",
          topcategory: "Electronics",
          topbrand: "Mobile",
          avg: "10",
          recency: "20",
          coupon: "10",
        },
        {
          name: "Promising",
          count: "100k",
          sales: "200M",
          bills: "30%",
          ats: "45",
          nsu: "20",
          topcategory: "Electronics",
          topbrand: "Mobile",
          avg: "10",
          recency: "20",
          coupon: "10",
        },
      ],
      // table_data: '',
      // is_table_data_loaded: false,

      is_scroll: false,

      first_start_date: props.start_date,
      first_end_date: props.end_date,
      second_start_date: props.previous_start_date,
      second_end_date: props.previous_end_date,
      isDatePickerOpen: false,
      channel_value: "",
      is_export: 0,

      //   Boolean Variable Assign Here
      is_trafficpievalue_loaded: false,
      is_linesparkgraph_loaded: false,
      is_trendsales_loaded: false,
      is_treegraph_loaded: false,
      is_cohort_loaded: true,
      is_clustergraph_loaded: false,
      is_bubblechart_loaded: false,
      is_table_data_loaded: false,

      // EXPORT BOOLEAN VARIBALE
      is_category_export_loaded: false,
      is_trendsales_export_loaded: false,
      is_topdiscount_code_export_loaded: false,
      formatMoney(n) {
        return (Math.round(n * 100) / 100).toLocaleString();
      },
    };
  }
  top_discount_graph;

  componentWillReceiveProps(nextProps) {
    if (this.props != nextProps) {
      this.setState({
        first_start_date: nextProps.start_date,
        first_end_date: nextProps.end_date,
        second_start_date: nextProps.previous_start_date,
        second_end_date: nextProps.previous_end_date,
      });
    }
  }

  // THESE ARE FUNCTION
  componentDidMount() {
    this.getPersonaChannelGrouping();
    this.getCustomerMatric();
    this.getTrenSales();
    this.getCategorySalesShares();
    this.getCohortAnalysis();
    this.getTopDiscountCodeSales();
    this.getPurchaseActivityPatteren();
    this.getPurchaseActivityTable();
    this.getCategory_export();
    this.getTrenSales_export();
    this.getDiscountCodeExport();

    this.forceUpdate();
    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.start_date !== this.props.start_date ||
      prevProps.end_date !== this.props.end_date ||
      prevProps.previous_start_date !== this.props.previous_start_date ||
      prevProps.previous_end_date !== this.props.previous_end_date
    ) {

      // this.state.is_trafficpievalue_loaded = false;
      this.state.is_linesparkgraph_loaded = false;
      this.state.is_trendsales_loaded = false;
      this.state.is_treegraph_loaded = false;
      this.state.is_clustergraph_loaded = false;
      this.state.is_bubblechart_loaded = false;
      this.state.is_table_data_loaded = false;

      this.setState({
        is_trafficpievalue_loaded: false,
      })

      this.getPersonaChannelGrouping();
      this.getCustomerMatric();
      this.getTrenSales();
      this.getCategorySalesShares();
      this.getCohortAnalysis();
      this.getTopDiscountCodeSales();
      this.getPurchaseActivityPatteren();
      this.getPurchaseActivityTable();
      this.getCategory_export();
      this.getTrenSales_export();
      this.getDiscountCodeExport();
    }
  }

  // Channel Filter
  getPersonaChannelGrouping() {
    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;

    const url = API_URL + "ecom/sales-persona-grouping/";
    fetch(url, {
      method: "POST",
      body:
        "start_date=" +
        start_date +
        "&end_date=" +
        end_date +
        "&last_start_date=" +
        previous_start_date +
        "&last_end_date=" +
        previous_end_date,

      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let persona_list = result["persona_list"];
        let persona_date = [];
        persona_list.map((info, index) => {
          try {
            let a = {
              name:
                info["customer_persona"].charAt(0).toUpperCase() +
                info["customer_persona"].slice(1).toLowerCase(),
              value: info["grand_total"],
            };
            persona_date.push(a);
          } catch (e) { }
        });
        this.setState({
          is_trafficpievalue_loaded: true,
          persona_date_graph: persona_date,
        });
      });
  }
  // ALl MATRIX CARD
  getCustomerMatric() {
    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;
    let customer_persona = this.state.channel_value;

    const url = API_URL + "ecom/customer-matrice/";
    fetch(url, {
      method: "POST",
      body:
        "start_date=" +
        start_date +
        "&end_date=" +
        end_date +
        "&last_start_date=" +
        previous_start_date +
        "&last_end_date=" +
        previous_end_date +
        "&customer_persona=" +
        customer_persona,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let current_avg_order_value;
        let last_avg_order_value;
        let current_avg_revenue_per_user;
        let last_avg_revenue_per_user;
        let current_avg_price_unit;
        let last_avg_price_unit;

        let current_customer = result["current_matric_list"][0]["unique_count"];
        let last_customer = result["last_matric_list"][0]["unique_count"];

        let current_revenue = result["current_matric_list"][0]["grand_total"];
        let last_revenue = result["last_matric_list"][0]["grand_total"]
          ? result["last_matric_list"][0]["grand_total"]
          : 0;

        let current_transaction =
          result["current_matric_list"][0]["transaction"];
        let last_transaction = result["last_matric_list"][0]["transaction"];

        let current_quantity = result["current_matric_list"][0]["quantity"];
        let last_quantity = result["last_matric_list"][0]["quantity"];

        current_avg_order_value =
          current_revenue / current_transaction
            ? current_revenue / current_transaction
            : 0;
        last_avg_order_value =
          last_revenue / last_transaction ? last_revenue / last_transaction : 0;

        current_avg_revenue_per_user =
          current_revenue / current_customer
            ? current_revenue / current_customer
            : 0;
        last_avg_revenue_per_user =
          last_revenue / last_customer ? last_revenue / last_customer : 0;

        current_avg_price_unit =
          current_revenue / current_quantity
            ? current_revenue / current_quantity
            : 0;
        last_avg_price_unit =
          last_revenue / last_quantity ? last_revenue / last_quantity : 0;

        ///////////////////////////SPARKLINE BINDING HERE //////////////////////////////////////////

        let customer_matrice_sparkline = result["sparkline_list"];

        let customer_list = [];
        let revenue_list = [];
        let transaction_list = [];
        let avg_order_value = [];
        let quantity_list = [];
        let avg_price_unit_list = [];
        let avg_revenue_per_user_list = [];

        customer_matrice_sparkline.map((info, index) => {
          try {
            let customer = {
              date: info["date"],
              value: info["unique_count"],
            };
            customer_list.push(customer);

            let revenue = {
              date: info["date"],
              value: info["grand_total"],
            };
            revenue_list.push(revenue);
            let transaction = {
              date: info["date"],
              value: info["transaction"],
            };
            transaction_list.push(transaction);

            let avg_order = {
              date: info["date"],
              value: info["grand_total"] / info["transaction"],
            };
            avg_order_value.push(avg_order);

            let quantity = {
              date: info["date"],
              value: info["quantity"],
            };
            quantity_list.push(quantity);
            let avg_price_unit = {
              date: info["date"],
              value: info["grand_total"] / info["quantity"],
            };
            avg_price_unit_list.push(avg_price_unit);

            let avg_per_user = {
              date: info["date"],
              value: info["grand_total"] / info["unique_count"],
            };
            avg_revenue_per_user_list.push(avg_per_user);
          } catch (e) { }
        });

        this.setState({
          is_linesparkgraph_loaded: true,
          current_revenue: current_revenue,
          last_revenue: last_revenue,
          current_transaction: current_transaction,
          last_transaction: last_transaction,
          current_avg_order_value: current_avg_order_value,
          last_avg_order_value: last_avg_order_value,
          current_customer: current_customer,
          last_customer: last_customer,
          current_avg_revenue_per_user: current_avg_revenue_per_user,
          last_avg_revenue_per_user: last_avg_revenue_per_user,
          current_avg_price_unit: current_avg_price_unit,
          last_avg_price_unit: last_avg_price_unit,
          // SPARKLINE BINDING HERE
          revenue_graph: revenue_list,
          transaction_graph: transaction_list,
          avg_order_graph: avg_order_value,
          quantity_graph: quantity_list,
          avg_price_unit_graph: avg_price_unit_list,
          customer_graph: customer_list,
          avg_revenue_per_user_graph: avg_revenue_per_user_list,
        });
      });
  }

  // TREND SALES CHART
  getTrenSales() {
    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;
    let customer_persona = this.state.channel_value;

    const url = API_URL + "ecom/tred-in-sales/";
    fetch(url, {
      method: "POST",
      body:
        "start_date=" +
        start_date +
        "&end_date=" +
        end_date +
        "&last_start_date=" +
        previous_start_date +
        "&last_end_date=" +
        previous_end_date +
        "&customer_persona=" +
        customer_persona,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let trends_list = result["current_trend_sales_query_list"];
        let previous_list = result["last_trend_sales_query_list"];
        let trend_data = [];

        trends_list.map((info, index) => {
          // "date": "2019-5-12", "value1": "50", "value2": "48", "previousdate": "2019-5-5"
          try {
            let a = {
              date: info["date"],
              value1: info["grand_total"],
              value2: previous_list[index]["grand_total"],
              previousdate: previous_list[index]["date"],
            };
            trend_data.push(a);
          } catch (e) { }
        });
        this.setState({
          is_trendsales_loaded: true,
          trend_data_graph: trend_data,
        });
      });
  }

  // CATEGORY SALES SHARE TREE MAP CHART EXPORT
  getCategory_export() {
    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;
    let customer_persona = this.state.channel_value;
    let is_expor_csv = 1;

    const url = API_URL + "ecom/category-sales-share/";
    fetch(url, {
      method: "POST",
      body:
        "start_date=" +
        start_date +
        "&end_date=" +
        end_date +
        "&last_start_date=" +
        previous_start_date +
        "&last_end_date=" +
        previous_end_date +
        "&customer_persona=" +
        customer_persona +
        "&is_export=" +
        is_expor_csv,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let category_sales_share = result["category_sales_share_list"];

        // EXPORT FUNCTION IMPLEMENT HERE

        // var export_Header_keys = [];
        // if (category_sales_share.length > 0) {
        //   Object.keys(category_sales_share[0]).forEach(function (key) {
        //     if (export_Header_keys.indexOf(key) == -1) {
        //       var x = '{ label: "' + key + '", key: "' + key + '" }';
        //       export_Header_keys.push(x);
        //     }
        //   });
        // }
        // get keys as array

        // if (category_sales_share[0].length != 0) {
        //   const keys = Object.keys(category_sales_share[0]);

        //   const commaSeparatedString = [
        //     keys.join(","),
        //     category_sales_share
        //       .map((row) => keys.map((key) => row[key]).join(","))
        //       .join(""),
        //   ].join("");
        //   const csvBlob = new Blob([commaSeparatedString]);
        //   csvBlobhref = URL.createObjectURL(csvBlob);


        //
        let csvBlobhref = "";
        if (category_sales_share[0].length != 0) {
          const keys = Object.keys(category_sales_share[0]);

          const commaSeparatedString = [keys.join(","), category_sales_share.map(row => keys.map(key => row[key]).join(",")).join("\n")].join("\n")

          const csvBlob = new Blob([commaSeparatedString]);
          csvBlobhref = URL.createObjectURL(csvBlob);

        }

        this.setState({
          export_href: csvBlobhref,
          is_category_export_loaded: true,
        });

      });
  }
  // CATEGORY SALES SHARE TREE MAP CHART
  getCategorySalesShares() {
    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;
    let customer_persona = this.state.channel_value;

    const url = API_URL + "ecom/category-sales-share/";
    fetch(url, {
      method: "POST",
      body:
        "start_date=" +
        start_date +
        "&end_date=" +
        end_date +
        "&last_start_date=" +
        previous_start_date +
        "&last_end_date=" +
        previous_end_date +
        "&customer_persona=" +
        customer_persona,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          let category_sales_share = result["category_sales_share_list"];
          // [{ "name": "Home", "children": [{ "name": "Kitchen", "value": 150 }]
          let list_category_0 = [];

          category_sales_share.map((info) => {
            if (!list_category_0.includes(info["category_0"])) {
              list_category_0.push(info["category_0"]);
            }
          });
          let final_list = [];
          list_category_0.map((category_0) => {
            let children_category_0 = [];
            let list2 = []; // Create variable
            category_sales_share.map((info) => {
              if (info["category_0"] == category_0) {
                let children_category_3 = [];

                if (!list2.includes(info["category_1"])) {
                  // add if
                  list2.push(info["category_1"]); // add this also

                  // Preparing Level 3
                  category_sales_share.map((info3) => {
                    if (info["category_1"] == info3["category_1"]) {
                      children_category_3.push({
                        name: info3["category_2"],
                        value: info3["grand_total"],
                      });
                    }
                  });

                  children_category_0.push({
                    name: info["category_1"],
                    children: children_category_3,
                  });
                }
              }
            });

            let d = { name: category_0, children: children_category_0 };
            //   let d = { name: category_0, children: list2 };
            final_list.push(d);
          });

          this.setState({
            is_treegraph_loaded: true,
            tree_graph: final_list,
          });
        },
        (error) => { }
      );
  }
  // CHOORT ANALYSIS CHART
  getCohortAnalysis() {
    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;
    let customer_persona = this.state.channel_value;

    const url = API_URL + "ecom/earned-matric/";
    fetch(url, {
      method: "POST",
      body:
        "start_date=" +
        start_date +
        "&end_date=" +
        end_date +
        "&last_start_date=" +
        previous_start_date +
        "&last_end_date=" +
        previous_end_date +
        "&customer_persona=" +
        customer_persona,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then((res) => res.json())
      .then((result) => { });
  }
  // TOP DISOUCNT CODE SALES
  getTopDiscountCodeSales() {
    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;
    let customer_persona = this.state.channel_value;

    const url = API_URL + "ecom/discount-code/";
    fetch(url, {
      method: "POST",
      body:
        "start_date=" +
        start_date +
        "&end_date=" +
        end_date +
        "&last_start_date=" +
        previous_start_date +
        "&last_end_date=" +
        previous_end_date +
        "&customer_persona=" +
        customer_persona,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then((res) => res.json())
      .then((result) => {
        //  [{ "network": "Bogo", "MAU": 22552 }
        let top_discount_list = result["top_discount_codes_quer_list"];
        let top_list = [];

        top_discount_list.map((info, index) => {
          if (info["coupon_discount_code"] != "") {
            try {
              let a = {
                network: info["coupon_discount_code"],
                MAU: info["count"],
              };

              top_list.push(a);
            } catch (e) { }
          }
        });
        this.setState({
          is_clustergraph_loaded: true,
          top_discount_graph: top_list,
        });
      });
  }
  // PURCHASE ACTIVITY PATTER HEAT MAP
  getPurchaseActivityPatteren() {
    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;
    let customer_persona = this.state.channel_value;

    const url = API_URL + "ecom/purchase-chart/";
    fetch(url, {
      method: "POST",
      body:
        "start_date=" +
        start_date +
        "&end_date=" +
        end_date +
        "&last_start_date=" +
        previous_start_date +
        "&last_end_date=" +
        previous_end_date +
        "&customer_persona=" +
        customer_persona,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let purchase_activity_list = result["purchase_activity_list"];
        let purchase_activity_data = [];

        // [{ "hour": "12pm", "weekday": "Sun", "value": 2990 }
        purchase_activity_list.map((info, index) => {
          try {
            let a = {
              hour: info["hours"],
              weekday: info["week_day"],
              value: info["total_order"],
            };
            purchase_activity_data.push(a);
          } catch (e) { }
        });

        this.setState({
          is_bubblechart_loaded: true,
          purchase_activity_graph: purchase_activity_data,
        });
      });
  }
  // PURCHASE ACTIVITY PATTER HEAT TABLE
  getPurchaseActivityTable() {
    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;
    let customer_persona = this.state.channel_value;

    const url = API_URL + "ecom/purchase-table/";
    fetch(url, {
      method: "POST",
      body:
        "start_date=" +
        start_date +
        "&end_date=" +
        end_date +
        "&last_start_date=" +
        previous_start_date +
        "&last_end_date=" +
        previous_end_date +
        "&customer_persona=" +
        customer_persona,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let purchase_table_list = result["purchase_table_query_list"];
        let table_data_list = [];

        purchase_table_list.map((info, index) => {
          let avg_order_value =
            info["gran_total"] && info["bills"]
              ? info["gran_total"] / info["bills"]
              : 0;

          try {
            let a = {
              name: info["customer_persona"],
              count: info["count"],
              sales: this.state.formatMoney(info["gran_total"]),
              bills: info["bills"],
              ats: avg_order_value.toFixed(),
              nsu: (info["quantity"] / info["bills"]).toFixed(2),
              topcategory: info["categry_1"],
              topbrand: 0,
              avg: 1,
              recency: 2,
              coupon: 2,
            };
            table_data_list.push(a);
          } catch (e) { }
        });
        this.setState({
          is_table_data_loaded: true,
          table_data_graph: table_data_list,
        });
      });
  }


  getTrenSales_export() {
    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;
    let customer_persona = this.state.channel_value;
    let is_expor_csv = true;

    const url = API_URL + "ecom/tred-in-sales/";
    fetch(url, {
      method: "POST",
      body:
        "start_date=" +
        start_date +
        "&end_date=" +
        end_date +
        "&last_start_date=" +
        previous_start_date +
        "&last_end_date=" +
        previous_end_date +
        "&customer_persona=" +
        customer_persona +
        "&is_export=" +
        is_expor_csv,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let trends_list = result["current_trend_sales_query_list"];
        let csvBlobhref = "";
        if (trends_list[0].length != 0) {
          const keys = Object.keys(trends_list[0]);

          const commaSeparatedString = [keys.join(","), trends_list.map(row => keys.map(key => row[key]).join(",")).join("\n")].join("\n")

          const csvBlob = new Blob([commaSeparatedString]);
          csvBlobhref = URL.createObjectURL(csvBlob);

        }


        this.setState({
          trend_export_href: csvBlobhref,
          is_trendsales_export_loaded: true,
        });

      });
  }

  getDiscountCodeExport() {

    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;
    let customer_persona = this.state.channel_value;
    let is_expor_csv = 1;

    const url = API_URL + "ecom/discount-code/";
    fetch(url, {
      method: "POST",
      body:
        "start_date=" +
        start_date +
        "&end_date=" +
        end_date +
        "&last_start_date=" +
        previous_start_date +
        "&last_end_date=" +
        previous_end_date +
        "&customer_persona=" +
        customer_persona +
        "&is_export=" +
        is_expor_csv,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let top_discount_list = result["top_discount_codes_quer_list"];

        let csvBlobhref = "";
        if (top_discount_list[0].length != 0) {
          const keys = Object.keys(top_discount_list[0]);

          const commaSeparatedString = [keys.join(","), top_discount_list.map(row => keys.map(key => row[key]).join(",")).join("\n")].join("\n")

          const csvBlob = new Blob([commaSeparatedString]);
          csvBlobhref = URL.createObjectURL(csvBlob);
        }


        this.setState({
          topdiscount_code_export_href: csvBlobhref,
          is_topdiscount_code_export_loaded: true,
        });

      });


  }

  isNull(object) {
    for (const [key, value] of Object.entries(object)) {
      if (typeof value === "object" && value !== null) {
        this.isNull(value);
      } else if (!value) {
        object[key] = 0;
      }
    }
    return object;
  }

  datepickerHandleCallback = (childData) => {
    this.state.first_start_date = childData[0];
    this.state.first_end_date = childData[1];
    this.state.second_start_date = childData[2];
    this.state.second_end_date = childData[3];

    // this.state.is_trafficpievalue_loaded = false;
    this.state.is_linesparkgraph_loaded = false;
    this.state.is_trendsales_loaded = false;
    this.state.is_treegraph_loaded = false;
    this.state.is_clustergraph_loaded = false;
    this.state.is_bubblechart_loaded = false;
    this.state.is_table_data_loaded = false;

    this.setState({
      is_trafficpievalue_loaded: false,
    })

    // Function here
    this.getPersonaChannelGrouping();
    this.getCustomerMatric();
    this.getTrenSales();
    this.getCategorySalesShares();
    this.getTopDiscountCodeSales();
    this.getPurchaseActivityPatteren();
    this.getPurchaseActivityTable();

    this.forceUpdate();
    // this.datepickerHandleCallback2();
  };

  // datepickerHandleCallback2 = () => {
  //   this.setState({ isDatePickerOpen: !this.state.isDatePickerOpen });
  // };

  handleScroll = () => {
    if (window.pageYOffset > 1) {
      if (!this.state.is_scroll) {
        this.setState({ is_scroll: true });
      }
    } else {
      if (this.state.is_scroll) {
        this.setState({ is_scroll: false });
      }
    }
  };

  handlePieclick = (childData) => {
    if (this.state.pie_click_data === childData) {
      this.state.pie_click_data = ""
    } else {
      this.state.pie_click_data = childData;
    }
    this.state.trafficeChannel = childData;
    this.setState({
      is_linesparkgraph_loaded: false,
      is_trendsales_loaded: false,
      is_treegraph_loaded: false,
      is_clustergraph_loaded: false,
      is_bubblechart_loaded: false,
      is_table_data_loaded: false,
    });

    this.getCustomerMatric();
    this.getTrenSales();
    this.getCategorySalesShares();
    this.getCohortAnalysis();
    this.getTopDiscountCodeSales();
    this.getPurchaseActivityPatteren();
    this.getPurchaseActivityTable();
  };

  getCountryCurrency = () => {

    let userCountryCode = localStorage.getItem('static-country-code');
    var currValue = ''

    CountryCode.map((data) => {
      if (data.countryCode === userCountryCode) {
        currValue = data.currencySymbol
      }
    });

    return currValue;
  }


  render() {
    const CarouselSettings = {
      arrows: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      // initialSlide: 0,
      responsive: [
        {
          breakpoint: 1202,
          settings: {
            arrows: true,
            slidesToShow: 3,
            slidesToScroll: 0,
          },
        },
        {
          breakpoint: 800,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <Wrapper>
        <div className="grid grid_cols_4 drawer_grid ri_summary_bsImport">
          <div className="left_drawer col_span_1 fixed-left-grid mapRightWrapper">
            <div className="icz-rimapsection bg-white">
              <Col sm={12} className="icz-cardheader p-20 d-flex">
                <Col className="icz-cardtitle">
                  <div className="icz-title-wrap p-0">
                    <div className="text-left">
                      <h5 className="icz-cardtitle text-start mb-0">
                        Sales Share By Persona
                      </h5>
                    </div>
                  </div>
                </Col>
              </Col>
              {this.state.is_trafficpievalue_loaded === true ? (
                <div className="h-100 d-flex justify-content-center align-items-center">
                  <Piechart
                    card_id="icz-traffic-piechart"
                    card_class="icz-leftsectionchart"
                    graph-data={this.state.persona_date_graph}
                    parentCallback={this.handlePieclick}
                  />
                </div>
              ) : (
                <Loader />
              )}
            </div>
          </div>
          <div className="right_side col_span_3 relative-right-grid icz-ridatacontainer">
            <div className="content">
              <div className="content_section">
                <PageHeader pageTitle={"Customer"} />
                <div className="page_content">
                  <div className="grid grid_cols_1 dashboard_home">
                    {this.state.trafficeChannel === "" ? (
                      ""
                    ) : (
                      <div className="icz-title-wrap d-flex">
                        <Col sm={12}>
                          <div className="icz-row">
                            <Col>
                              <h5 className="icz-card-title align-items-start">
                                {this.state.trafficeChannel}
                              </h5>
                            </Col>
                          </div>
                        </Col>
                      </div>
                    )}

                    <div className="grid grid_cols_1">
                      <div className="grid col_span_4 icz-carouselcontainer">
                        {this.state.is_linesparkgraph_loaded === true ? (
                          <Carousel
                            Settings={CarouselSettings}
                            class={"icz-cardcontainer"}
                            SliderCards={[
                              <ChartCard
                                index="1"
                                CardClass="icz-cardwrapper"
                                CardIcon={<IconWebsite className="icz-icon" />}
                                CardTitle="Customers"
                                RowOneCurrentValue={parseFloat(
                                  this.state.current_customer
                                ).toFixed(2)}
                                RowOnePreviousValue={parseFloat(
                                  this.state.last_customer
                                ).toFixed(2)}
                                RowOneTitle=""
                                IsNumber={"number"}
                                IsStandard={true}
                                chart={
                                  <Linegraph_sparkline
                                    card_id="icz-customer"
                                    card_class="icz-cardchart"
                                    graph-data={this.state.customer_graph}
                                  />
                                }
                              />,

                              <ChartCard
                                index="2"
                                CardClass="icz-cardwrapper"
                                CardIcon={<IconWebsite className="icz-icon" />}
                                CardTitle={`Revenue (${this.getCountryCurrency()})`}
                                RowOneCurrentValue={parseFloat(
                                  this.state.current_revenue
                                ).toFixed(2)}
                                RowOnePreviousValue={parseFloat(
                                  this.state.last_revenue
                                ).toFixed(2)}
                                RowOneTitle=""
                                IsNumber={"number"}
                                IsStandard={true}
                                chart={
                                  <Linegraph_sparkline
                                    card_id="icz-total-sales"
                                    card_class="icz-cardchart"
                                    graph-data={this.state.revenue_graph}
                                  />
                                }
                              />,
                              <ChartCard
                                index="3"
                                CardClass="icz-cardwrapper"
                                CardIcon={<IconWebsite className="icz-icon" />}
                                CardTitle="Transactions"
                                RowOneCurrentValue={parseFloat(
                                  this.state.current_transaction
                                ).toFixed(2)}
                                RowOnePreviousValue={parseFloat(
                                  this.state.last_transaction
                                ).toFixed(2)}
                                RowOneTitle=""
                                IsNumber={"number"}
                                IsStandard={true}
                                chart={
                                  <Linegraph_sparkline
                                    card_id="icz-transaction"
                                    card_class="icz-cardchart"
                                    graph-data={this.state.transaction_graph}
                                  />
                                }
                              />,
                              <ChartCard
                                index="4"
                                CardClass="icz-cardwrapper"
                                CardIcon={<IconWebsite className="icz-icon" />}
                                CardTitle={`Average Order Value (${this.getCountryCurrency()})`}
                                RowOneCurrentValue={parseFloat(
                                  this.state.current_avg_order_value
                                ).toFixed(2)}
                                RowOnePreviousValue={parseFloat(
                                  this.state.last_avg_order_value
                                ).toFixed(2)}
                                RowOneTitle=""
                                IsNumber={"number"}
                                IsStandard={true}
                                chart={
                                  <Linegraph_sparkline
                                    card_id="icz-avg-ordervalue"
                                    card_class="icz-cardchart"
                                    graph-data={this.state.avg_order_graph}
                                  />
                                }
                              />,
                              <ChartCard
                                index="5"
                                CardClass="icz-cardwrapper"
                                CardIcon={<IconWebsite className="icz-icon" />}
                                CardTitle="Avg Revenue Per User"
                                RowOneCurrentValue={parseFloat(
                                  this.state.current_avg_revenue_per_user
                                ).toFixed(2)}
                                RowOnePreviousValue={parseFloat(
                                  this.state.last_avg_revenue_per_user
                                ).toFixed(2)}
                                RowOneTitle=""
                                IsNumber={"number"}
                                IsStandard={true}
                                chart={
                                  <Linegraph_sparkline
                                    card_id="icz-avg-revenue-user"
                                    card_class="icz-cardchart"
                                    graph-data={this.state.avg_revenue_per_user_graph}
                                  />
                                }
                              />,

                              <ChartCard
                                index="6"
                                CardClass="icz-cardwrapper"
                                CardIcon={<IconWebsite className="icz-icon" />}
                                CardTitle={`LTV/Customer (${this.getCountryCurrency()})`}
                                RowOneCurrentValue="28000"
                                RowOnePreviousValue="30000"
                                RowOneTitle=""
                                IsNumber={"number"}
                                IsStandard={true}
                                chart={
                                  <Linegraph_sparkline
                                    card_id="Ltv_customer"
                                    card_class="icz-cardchart"
                                    graph-data={this.state.linesparkgraph}
                                  />
                                }
                              />,
                              <ChartCard
                                index="7"
                                CardClass="icz-cardwrapper"
                                CardIcon={<IconWebsite className="icz-icon" />}
                                CardTitle="Avg Time Between Orders"
                                RowOneCurrentValue="86"
                                RowOnePreviousValue="95"
                                RowOneTitle=""
                                IsNumber={"number"}
                                IsStandard={true}
                                chart={
                                  <Linegraph_sparkline
                                    card_id="icz-avgtime-orders"
                                    card_class="icz-cardchart"
                                    graph-data={this.state.linesparkgraph}
                                  />
                                }
                              />,

                              <ChartCard
                                index="8"
                                CardClass="icz-cardwrapper"
                                CardIcon={<IconWebsite className="icz-icon" />}
                                CardTitle={`Avg Price Per Unit Bought (${this.getCountryCurrency()})`}
                                RowOneCurrentValue={parseFloat(
                                  this.state.current_avg_price_unit
                                ).toFixed(2)}
                                RowOnePreviousValue={parseFloat(
                                  this.state.last_avg_price_unit
                                ).toFixed(2)}
                                RowOneTitle=""
                                IsNumber={"number"}
                                IsStandard={true}
                                chart={
                                  <Linegraph_sparkline
                                    card_id="icz-avgprice-bought"
                                    card_class="icz-cardchart"
                                    graph-data={this.state.avg_price_unit_graph}
                                  />
                                }
                              />,
                            ]}
                          ></Carousel>
                        ) : (
                          <Loader />
                        )}
                      </div>
                    </div>

                    <div className="grid grid_cols_4">
                      <div className="grid col_span_2 icz-sectioncardwrapper">
                        <div className="icz-sectioncard d-flex flex-row flex-wrap">
                          <Col sm={12} className="icz-cardheader">
                            <Col className="icz-cardtitle">Trend In Sales</Col>
                            <Col className="icz-cardfilter icz-chartexport">
                              <ChartExport ChartId='nsv_trend' export_data={this.state.trend_data_graph} downloadName="Trend In Sales" />
                            </Col>
                          </Col>

                          {this.state.is_trendsales_loaded == true ?
                            <div className="w-100">
                              <CompareLineChart
                                chart_id="nsv_trend"
                                chart_class="icz-sectionchart"
                                graph-data={this.state.trend_data_graph}
                                Ytitle={`Revenue(${this.getCountryCurrency()})`}
                                metric_1={"Current Period"}
                                metric_2={"Previous Period"}
                                isnumber={"money"}
                              />
                            </div>
                            : <Loader />}

                        </div>
                      </div>
                      <div className="grid col_span_2 icz-sectioncardwrapper">
                        <div className="icz-sectioncard">
                          <Col sm={12} className="icz-cardheader">
                            <Col className="icz-cardtitle">Category Sale Share</Col>
                            <Col className="icz-cardfilter icz-chartexport">
                              <ChartExport ChartId='category_sales_share' export_data={this.state.tree_graph} downloadName="Category Sale Share" />
                            </Col>
                          </Col>
                          <div className="">
                            {this.state.is_treegraph_loaded === true ? (
                              <Treemap
                                card_id="category_sales_share"
                                card_class="icz-sectionchart"
                                graph-data={this.state.tree_graph}
                              />
                            ) : (
                              <Loader />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="grid grid_cols_4">
                      <div className="grid col_span_2 icz-sectioncardwrapper">
                        <div className="icz-sectioncard">
                          <div className="icz-cardheader">
                            <Col className="icz-cardtitle">Cohort Analysis</Col>
                            <Col className="icz-cardfilter icz-chartexport">
                              <ChartExport ChartId='cohort_analysis' export_data={[]} downloadName="Cohort Analysis" />
                            </Col>
                          </div>

                          <div className="icz-cohortgraph ">
                            {this.state.is_cohort_loaded === true ? (
                              <CohortGraph
                                className="cohort_analysis"
                                data={{
                                  months: {
                                    June: [11567, 331, 135, 116, 90, 48],
                                    May: [11132, 334, 154, 78, 65, 13],
                                    Apr: [11497, 340, 111, 66, 20],
                                    March: [11593, 247, 87, 39],
                                    Feb: [8710, 206, 38],
                                    Jan: [7067, 89],
                                  },
                                }}
                                defaultValueType={["value"]}
                                scrollX={true}
                              />
                            ) : (
                              <Loader />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="grid col_span_2 icz-sectioncardwrapper">
                        <div className="icz-sectioncard">
                          <Col sm={12} className="icz-cardheader">
                            <Col className="icz-cardtitle">
                              Top Discount Codes Used
                            </Col>
                            <Col className="icz-cardfilter icz-chartexport">
                              <ChartExport ChartId='top_discount_codes_used' export_data={this.state.top_discount_graph} downloadName="Top Discount Codes Used" />
                            </Col>
                          </Col>

                          {this.state.is_clustergraph_loaded == true ?
                            <div className="">
                              <Cluster_chart
                                card_id="top_discount_codes_used"
                                card_class="icz-sectionchart"
                                graph-data={this.state.top_discount_graph}
                              />
                            </div>
                            : <Loader />}
                        </div>
                      </div>
                    </div>



                    <div className="grid grid_cols_1">
                      <Col className="icz-sectioncardwrapper" sm={12} lg={12}>
                        <div className="icz-sectioncard">
                          <Col sm={12} className="icz-cardheader">
                            <Col className="icz-cardtitle">
                              Purchase Activity Pattern
                            </Col>
                            <Col className="icz-cardfilter icz-chartexport">
                              <ChartExport ChartId='purchase_audience_activity' export_data={this.state.purchase_activity_graph} downloadName="Purchase Activity Pattern" />
                            </Col>
                          </Col>

                          {this.state.is_bubblechart_loaded == true ?
                            this.state.purchase_activity_graph.length === 0 ?
                              <NoDataAvailable />
                              :
                              <div className="">
                                <HeatMap
                                  chart_id="purchase_audience_activity"
                                  chart_class="icz-sectionchart"
                                  graph-data={this.state.purchase_activity_graph}
                                />
                              </div>
                            : <Loader />}


                        </div>
                      </Col>
                    </div>

                    <div className="grid grid_cols_1 icz-sectioncardwrapper">
                      <div className="grid col_span_4">
                        <div className="icz-sectioncard">
                          <div className="icz-table">
                            {this.state.is_table_data_loaded === true ? (
                              <DataTable
                                isPageInfoEnable={true}
                                Headers={this.state.table_header}
                                TableSize={5}
                                TableData={this.state.table_data_graph}
                                className="MainDiv icz-table"
                              />
                            ) : (
                              <Loader />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </Wrapper>
    );
  }
}

function ReduxStateToProps(state) {
  return {
    start_date: state.date.startDate,
    end_date: state.date.endDate,
    previous_start_date: state.date.prevStartDate,
    previous_end_date: state.date.prevEndDate,
  };
}

export default connect(ReduxStateToProps)(Customer)
