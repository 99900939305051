// import { useContext, useEffect, useMemo, useState } from "react";
// import { useSelector } from "react-redux";
// import { SwiperSlide } from "swiper/react";

// // STORAGE
// import AuthContext from "../../../../store/authContext";
// import FullPageExportContext from "../../../../store/fullPageExportContext";

// // COMPONENTS
// import axios from "../../../../components/helpers/axios";
// import Wrapper from "../../../../components/helpers/Wrapper";
// import Card from "../../../../components/ui/Card";
// import Slider from "../../../../components/ui/Slider";
// import Dropdown from "../../../../components/ui/dropdown/Dropdown";
// import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
// import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";

// // LOADERS
// import Loader from "../../../../components/ui/loaders/Loader";
// import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
// import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// // CHARTS
// import LineChart from "../../../../components/ui/charts/LineChart";
// import Datatable from "../../../../components/ui/tables/Datatable";
// import BarChart from "../../../../components/ui/charts/BarChart";
// import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";

// // ASSETS
// import MaleIcon from "../../../../assets/icons/MaleIcon";
// import FemaleIcon from "../../../../assets/icons/FemaleIcon";

// // UTILS
// import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
// import {
//   IAEvent_Dropdown_Visit,
//   IAEvent_Secondary_Tab_Visit,
// } from "../../../../utils/IAEvents";
// import ErrorBoundary from "../../../../utils/ErrorBoundary";
// import {
//   BlueColorStart500,
//   COKE_COMPANY_ID,
//   HALEON_COMPANY_ID,
// } from "../../../../constants/constants";
// import MultiAxisMultiLine from "../../../../components/ui/charts/MultiAxisMultiLine";
// import Tab from "../../../../components/ui/Tab";
// import PartitionedLineChart from "../../../../components/ui/charts/PartitionedLineChart";
// import ExpandIcon from "../../../../assets/icons/ExpandIcon";
// import Modal from "../../../../components/ui/Modal";

// const CampaignFacebookOverall = ({ topCardData }) => {

//   // PAGE CONSTANTS
//   let pageModule = "Marketing Intelligence"
//   let pageSubModule = "Campaign"
//   let pagePlatform = "Facebook"
//   let pagePrimaryTab = "Overall"

//   const authCtx = useContext(AuthContext);
//   const { setFullPageExport, setIsExportDisabled } = useContext(FullPageExportContext);

//   // USER DATA
//   const userData = useSelector((state) => state.userData.user);
//   let companyID = userData?.company?.id;

//   const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)


//   let AudienceDistributionSectionTitle = ""
//   if (companyID === HALEON_COMPANY_ID) {
//     AudienceDistributionSectionTitle = "Audience Distribution - Meta level";
//   } else {
//     AudienceDistributionSectionTitle = "Audience Distribution";
//   }

//   // SELECTED BRANDS FROM DROPDOWN
//   const globalSelectedBrandIds = useSelector(
//     (state) => state.globalSelectedBrandIds.selectedBrandIds
//   );

//   let BRAND_ID = null;
//   if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
//     BRAND_ID = null;
//   } else {
//     BRAND_ID = globalSelectedBrandIds.join(",");
//   }

//   // GLOBAL CAMPAIGN DROPDOWN
//   const selectedCampaignPlatformsFromFilter = useSelector(
//     (state) => state.campaignModuleFilter.selectedCampaignPlatform
//   );
//   const selectedCampaignObjectivesFromFilter = useSelector(
//     (state) => state.campaignModuleFilter.selectedCampaignObjectives
//   );
//   const selectedCampaignsFromFilter = useSelector(
//     (state) => state.campaignModuleFilter.selectedCampaigns
//   );
//   const selectedBucketsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedBuckets);

//   const selectedCampaignIdandPlatform = useSelector(
//     (state) => state.campaignModuleFilter.selectedCampaignIDandPlatform
//   );

//   // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
//   let selectedPlatform = null;
//   if (selectedCampaignPlatformsFromFilter.length === 0 || selectedCampaignPlatformsFromFilter === undefined) {
//     selectedPlatform = null;
//   } else {
//     selectedPlatform = selectedCampaignPlatformsFromFilter.join(",");
//   }

//   // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
//   let selectedObjectives = null;
//   if (selectedCampaignObjectivesFromFilter.length === 0 || selectedCampaignObjectivesFromFilter === undefined) {
//     selectedObjectives = null;
//   } else {
//     selectedObjectives = selectedCampaignObjectivesFromFilter.join(",");
//   }

//   // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
//   let selectedCampaigns = null;
//   if (selectedCampaignsFromFilter.length === 0 || selectedCampaignsFromFilter === undefined) {
//     selectedCampaigns = null;
//   } else {
//     selectedCampaigns = selectedCampaignsFromFilter.join(",");
//   }

//   let selectedIdandPlatform = null;
//   if (selectedCampaignIdandPlatform === undefined || selectedCampaignIdandPlatform.length === 0) {
//     selectedIdandPlatform = null;
//   } else {
//     selectedIdandPlatform = JSON.stringify(selectedCampaignIdandPlatform);
//   }

//   // currentDate / endDate / prevCurrentDate / prevEndDate
//   const selectedDates = useSelector((state) => state.date);

//   // METRIC CARDS
//   const [metricCards, setMetricCards] = useState([]);
//   const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
//   const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
//   const [metricCardserror, setMetricCardsError] = useState(null);

//   // REACH METRIC CARDS
//   const [reachMetricCards, setReachMetricCards] = useState([]);
//   const [isReachMetricCardsLoading, setReachMetricCardsIsLoading] = useState(false);
//   const [isReachMetricCardsLoaded, setReachMetricCardsIsLoaded] = useState(false);
//   const [reachMetricCardserror, setReachMetricCardsError] = useState(null);

//   // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (DONUT)
//   const [childDonutSliceToggle, setChildDonutSliceToggle] = useState(null);
//   const [childDonutCampaignObjective, setChildDonutCampaignObjective] = useState([]);
//   const [childDonutCampaignObjectiveLoading, setChildDonutCampaignObjectiveLoading] = useState([]);
//   const [childDonutCampaignObjectiveLoaded, setChildDonutCampaignObjectiveLoaded] = useState([]);
//   const [childDonutCampaignObjectiveError, setChildDonutCampaignObjectiveError] = useState([]);


//   // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (LINECHART)
//   const [lineChartCampaignObjective, setLineChartCampaignObjective] = useState([]);
//   const [lineChartCampaignObjectiveLabel, setLineChartCampaignObjectiveLabel] = useState([]);
//   const [lineChartCampaignObjectiveLoading, setLineChartCampaignObjectiveLoading] = useState([]);
//   const [lineChartCampaignObjectiveLoaded, setLineChartCampaignObjectiveLoaded] = useState([]);
//   const [lineChartCampaignObjectiveError, setLineChartCampaignObjectiveError] = useState([]);


//   // MID SECTION DROPDOWN
//   // DROPDOWN OPTIONS
//   const midSectionDropdonwOptions = [
//     { value: "impressions", label: "Impressions" },
//     { value: "clicks", label: "Clicks" },
//     { value: "conversions", label: "Conversions" },
//     { value: "engagements", label: "Engagements" },
//     { value: "spends", label: "Spends" },
//   ];

//   // DROPDOWN DEFAULT STATE
//   const [
//     selectedMidSectionDropdownOption,
//     setSelectedMidSectionDropdownDropdownOption,
//   ] = useState(midSectionDropdonwOptions[0]);



//   // DROPDOWN OPTIONS SELECTION HANDLER
//   const midSectionDropdownSelectionHandler = (e) => {
//     if (e === null) {
//       setSelectedMidSectionDropdownDropdownOption("");
//     } else {
//       setSelectedMidSectionDropdownDropdownOption(e);
//     }
//     IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Facebook", "Overall", null, "Performance By KPI", e.label)

//   };

//   // DEVICE DISTRIBUTION
//   const [deviceDistribution, setDeviceDistribution] = useState([]);
//   const [deviceDistributionAllDropdownsData, setDeviceDistributionAllDropdownsData] = useState([]);
//   const [isDeviceDistributionLoading, setDeviceDistributionIsLoading] = useState(false);
//   const [isDeviceDistributionLoaded, setDeviceDistributionIsLoaded] = useState(false);
//   const [deviceDistributionerror, setDeviceDistributionError] = useState(null);

//   // MALE COUNT
//   const [maleCount, setMaleCount] = useState([]);
//   const [maleCountAllDropdownsData, setMaleCountAllDropdownsData] = useState([]);
//   const [maleCountLabels, setMaleCountLabels] = useState([]);
//   const [maleCountAllDropdownsDataLabels, setMaleCountAllDropdownsDataLabels] = useState([]);
//   const [totalMaleCount, setTotalMaleCount] = useState(0);
//   const [totalMaleCountAllDropdownsData, setTotalMaleCountAllDropdownsData] = useState([]);
//   const [isMaleCountLoading, setMaleCountIsLoading] = useState(false);
//   const [isMaleCountLoaded, setMaleCountIsLoaded] = useState(false);
//   const [maleCounterror, setMaleCountError] = useState(null);

//   // FEMALE COUNT
//   const [femaleCount, setFemaleCount] = useState([]);
//   const [femaleCountAllDropdownsData, setFemaleCountAllDropdownsData] = useState([]);
//   const [femaleCountLabels, setFemaleCountLabels] = useState([]);
//   const [femaleCountAllDropdownsDataLabels, setFemaleCountAllDropdownsDataLabels] = useState([]);
//   const [totalFemaleCount, setTotalFemaleCount] = useState(0);
//   const [totalFemaleCountAllDropdownsData, setTotalFemaleCountAllDropdownsData] = useState([]);
//   const [isFemaleCountLoading, setFemaleCountIsLoading] = useState(false);
//   const [isFemaleCountLoaded, setFemaleCountIsLoaded] = useState(false);
//   const [femaleCounterror, setFemaleCountError] = useState(null);

//   // COUNTRY
//   const [countryData, setCountryData] = useState([]);
//   const [countryAllDropdownsData, setCountryAllDropdownsData] = useState([]);
//   const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
//   const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
//   const [countryDataerror, setCountryDataError] = useState(null);

//   let CountryTableDataHeaders = [];

//   let HaleonCountryTableDataHeaders = [
//     { name: "Country", field: "country" },
//     { name: "Impressions", field: "impressions" },
//     { name: "Clicks", field: "clicks" },
//     { name: "Conversions", field: "conversions" },
//     { name: "Engagements", field: "total_engagement" },
//     { name: "Spends", field: "spends" },
//     { name: "Engagement Rate", field: "engagement_rate" },
//     { name: "CTR", field: "ctr" },
//     { name: "Interaction Rate", field: "interaction_rate" }
//   ];

//   let DefaultCountryTableDataHeaders = [
//     { name: "Country", field: "name" },
//     { name: selectedMidSectionDropdownOption.label, field: "value" },
//     { name: "Change", field: "percentage_growth" },
//   ];

//   if (companyID === HALEON_COMPANY_ID) {
//     CountryTableDataHeaders = HaleonCountryTableDataHeaders
//   } else {
//     CountryTableDataHeaders = DefaultCountryTableDataHeaders
//   }

//   // STATE
//   const [stateData, setStateData] = useState([]);
//   const [stateAllDropdownsData, setStateAllDropdownsData] = useState([]);
//   const [isStateDataLoading, setStateDataIsLoading] = useState(false);
//   const [isStateDataLoaded, setStateDataIsLoaded] = useState(false);
//   const [stateDataerror, setStateDataError] = useState(null);

//   let StateTableDataHeaders = [];

//   let HaleonStateTableDataHeaders = [
//     { name: "State", field: "region" },
//     { name: "Impressions", field: "impressions" },
//     { name: "Clicks", field: "clicks" },
//     { name: "Conversions", field: "conversions" },
//     { name: "Engagements", field: "total_engagement" },
//     { name: "Spends", field: "spends" },
//     { name: "Engagement Rate", field: "engagement_rate" },
//     { name: "CTR", field: "ctr" },
//     { name: "Interaction Rate", field: "interaction_rate" }
//   ];

//   let DefaultStateTableDataHeaders = [
//     { name: "State", field: "name" },
//     { name: selectedMidSectionDropdownOption.label, field: "value" },
//     { name: "Change", field: "percentage_growth" }
//   ];

//   if (companyID === HALEON_COMPANY_ID) {
//     StateTableDataHeaders = HaleonStateTableDataHeaders
//   } else {
//     StateTableDataHeaders = DefaultStateTableDataHeaders
//   }

//   // CAMPAIGN SUMMARY
//   const [campaignSummary, setCampaignSummary] = useState([]);
//   const [campaignSummaryAllDropdownsData, setCampaignSummaryAllDropdownsData] = useState([]);
//   const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] = useState(false);
//   const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
//   const [campaignSummaryerror, setCampaignSummaryError] = useState(null);

//   // CAMPAIGN SUMMARY FOR REACH
//   const [reachCampaignSummary, setReachCampaignSummary] = useState([]);
//   const [isReachCampaignSummaryLoading, setReachCampaignSummaryIsLoading] = useState(false);
//   const [isReachCampaignSummaryLoaded, setReachCampaignSummaryIsLoaded] = useState(false);
//   const [reachCampaignSummaryerror, setReachCampaignSummaryError] = useState(null);

//   // TABLE HEADERS FOR COKE
//   let cokeSummaryTableHeaders = [
//     { name: "Name", field: "name" },
//     { name: "Objective", field: "objective" },
//     { name: "Impressions", field: "impressions" },
//     { name: "Clicks", field: "clicks" },
//     { name: "Spends", field: "spends" },
//     { name: "Conversions", field: "conversions" },
//     { name: "CTR", field: "ctr" },
//     { name: "CPM", field: "cpm" },
//     { name: "Benchmark CPM", field: "benchmark_cpm" },
//     { name: "CPC", field: "cpc" },
//     { name: "Benchmark CPC", field: "benchmark_cpc" },
//     { name: "CPV", field: "cpv" },
//     { name: "Benchmark CPV", field: "benchmark_cpv" },
//     { name: "CR", field: "cr" },
//     { name: "Views", field: "views" },
//     { name: "View Rate", field: "view_rate" },
//     { name: "Benchmark View Rate", field: "benchmark_view_rate" },
//     { name: "Frequency", field: "frequency" },
//     { name: "Reach Rate", field: "reach_rate" },
//     { name: "Engagement Rate", field: "engagement_rate" }
//   ]

//   let cokeCamapignSummaryTableHeaders = [
//     { name: "Name", field: "name" },
//     { name: "Objective", field: "objective" },
//     { name: "Impressions", field: "impressions" },
//     { name: "Clicks", field: "clicks" },
//     { name: "Spends", field: "spends" },
//     { name: "Conversions", field: "conversions" },
//     { name: "CTR", field: "ctr" },
//     { name: "CPM", field: "cpm" },
//     { name: "Benchmark CPM", field: "benchmark_cpm" },
//     { name: "CPC", field: "cpc" },
//     { name: "Benchmark CPC", field: "benchmark_cpc" },
//     { name: "CPV", field: "cpv" },
//     { name: "Benchmark CPV", field: "benchmark_cpv" },
//     { name: "CR", field: "cr" },
//     { name: "Views", field: "views" },
//     { name: "View Rate", field: "view_rate" },
//     { name: "Benchmark View Rate", field: "benchmark_view_rate" },
//     { name: "Frequency", field: "frequency" },
//     { name: "Reach", field: "reach" },
//     { name: "Reach Rate", field: "reach_rate" },
//     { name: "Engagement Rate", field: "engagement_rate" }
//   ]

//   let defaultSummaryTableHeaders = [
//     { name: "Name", field: "name" },
//     { name: "Objective", field: "objective" },
//     { name: "Impressions", field: "impressions" },
//     { name: "Clicks", field: "clicks" },
//     { name: "Spends", field: "spends" },
//     { name: "Conversions", field: "conversions" },
//     { name: "CTR", field: "ctr" },
//     { name: "CPM", field: "cpm" },
//     { name: "CPC", field: "cpc" },
//     { name: "CPV", field: "cpv" },
//     { name: "CR", field: "cr" },
//     { name: "Views", field: "views" },
//     { name: "Engagement Rate", field: "engagement_rate" }
//   ];

//   // CAMPAIGN SUMMARY DROPDOWN
//   // CAMPAIGN SUMMARY DROPDOWN OPTIONS
//   const campaignSummaryOptions = [
//     { value: "campaign", label: "Campaign" },
//     { value: "ad", label: "Ad" },
//     { value: "ad_group", label: "Ad group" },
//   ];

//   // CAMPAIGN SUMMARY DROPDOWN DEFAULT STATE
//   const [
//     selectedCampaignSummaryDropdownOption,
//     setSelectedCampaignSummaryDropdownOption,
//   ] = useState(campaignSummaryOptions[0]);

//   // CAMPAIGN SUMMARY DROPDOWN OPTIONS SELECTION HANDLER
//   const campaignSummaryDropdownSelectionHandler = (e) => {
//     if (e === null) {
//       setSelectedCampaignSummaryDropdownOption("");
//     } else {
//       setSelectedCampaignSummaryDropdownOption(e);
//     }
//     IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Facebook", "Overall", null, "Summary", e.label)

//   };

//   // CHANGE CAMPAIGN SUMMARY DATA AS PER THE DROPDOWN VALUE
//   const fetchCampaignSummaryDropdownHandler = async () => {
//     if (selectedCampaignSummaryDropdownOption.value === "ad") {
//       setCampaignSummary(
//         campaignSummaryAllDropdownsData === undefined || campaignSummaryAllDropdownsData.length === 0 ? [] :
//           campaignSummaryAllDropdownsData.ad === undefined || campaignSummaryAllDropdownsData.ad.length === 0 ? [] :
//             campaignSummaryAllDropdownsData.ad
//       );
//     } else if (selectedCampaignSummaryDropdownOption.value === "ad_group") {
//       setCampaignSummary(
//         campaignSummaryAllDropdownsData === undefined || campaignSummaryAllDropdownsData.length === 0 ? [] :
//           campaignSummaryAllDropdownsData.ad_group === undefined || campaignSummaryAllDropdownsData.ad_group.length === 0 ? [] :
//             campaignSummaryAllDropdownsData.ad_group
//       );
//     } else if (selectedCampaignSummaryDropdownOption.value === "campaign") {
//       setCampaignSummary(
//         campaignSummaryAllDropdownsData === undefined || campaignSummaryAllDropdownsData.length === 0 ? [] :
//           campaignSummaryAllDropdownsData.campaign === undefined || campaignSummaryAllDropdownsData.campaign.length === 0 ? [] :
//             campaignSummaryAllDropdownsData.campaign
//       );
//     } else {
//       setCampaignSummary([]);
//     }

//   };

//   let campaignSummaryTableHeaders = []

//   if (companyID === COKE_COMPANY_ID || companyID === HALEON_COMPANY_ID) {
//     if (selectedCampaignSummaryDropdownOption.value === "campaign") {
//       campaignSummaryTableHeaders = cokeCamapignSummaryTableHeaders
//     } else {
//       campaignSummaryTableHeaders = cokeSummaryTableHeaders
//     }
//   } else {
//     campaignSummaryTableHeaders = defaultSummaryTableHeaders
//   }

//   // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
//   useEffect(() => {
//     fetchCampaignSummaryDropdownHandler();
//   }, [BRAND_ID, selectedDates, selectedCampaignSummaryDropdownOption, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);


//   useEffect(() => {
//     (metricCards !== undefined || metricCards.length !== 0) && (companyID === COKE_COMPANY_ID || companyID === HALEON_COMPANY_ID) && fetchReachMetricCardsHandler();
//   }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform, metricCards])

//   // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
//   useEffect(() => {
//     fetchMetricCardsHandler();
//     fetchDeviceDistributionHandler();
//     fetchAudienceDistributionHandler();
//     fetchCountryHandler();
//     fetchStateHandler();
//     fetchCampaignSummaryHandler();
//     fetchChildDonutCampaignHandler();
//     setSelectedCampaignSummaryDropdownOption(campaignSummaryOptions[0]); // TO SET THE SUMMARY TABLE DROPDOWN TO DEFAULT STATE
//   }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform]);

//   // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
//   const fetchMidSectionDropdownHandler = async () => {
//     if (selectedMidSectionDropdownOption.value === "clicks") {

//       setDeviceDistribution(
//         deviceDistributionAllDropdownsData.length === 0
//           ? []
//           : deviceDistributionAllDropdownsData.clicks
//       );
//       setMaleCount(
//         maleCountAllDropdownsData.length === 0
//           ? []
//           : maleCountAllDropdownsData.clicks
//       );
//       setMaleCountLabels(
//         maleCountAllDropdownsDataLabels.length === 0
//           ? []
//           : maleCountAllDropdownsDataLabels.clicks
//       );
//       setTotalMaleCount(totalMaleCountAllDropdownsData.length === 0 ? [] : totalMaleCountAllDropdownsData.clicks);
//       setFemaleCount(
//         femaleCountAllDropdownsData.length === 0
//           ? []
//           : femaleCountAllDropdownsData.clicks
//       );
//       setFemaleCountLabels(
//         femaleCountAllDropdownsDataLabels.length === 0
//           ? []
//           : femaleCountAllDropdownsDataLabels.clicks
//       );
//       setTotalFemaleCount(
//         totalFemaleCountAllDropdownsData.length === 0
//           ? []
//           : totalFemaleCountAllDropdownsData.clicks
//       );

//       if (companyID !== HALEON_COMPANY_ID) {
//         setCountryData(countryAllDropdownsData.length === 0 ? [] : countryAllDropdownsData.clicks);
//         setStateData(stateAllDropdownsData.length === 0 ? [] : stateAllDropdownsData.clicks);
//       }
//     } else if (selectedMidSectionDropdownOption.value === "conversions") {
//       setDeviceDistribution(
//         deviceDistributionAllDropdownsData.length === 0
//           ? []
//           : deviceDistributionAllDropdownsData.conversions
//       );
//       setMaleCount(
//         maleCountAllDropdownsData.length === 0
//           ? []
//           : maleCountAllDropdownsData.conversions
//       );
//       setMaleCountLabels(
//         maleCountAllDropdownsDataLabels.length === 0
//           ? []
//           : maleCountAllDropdownsDataLabels.conversions
//       );
//       setTotalMaleCount(
//         totalMaleCountAllDropdownsData.length === 0
//           ? []
//           : totalMaleCountAllDropdownsData.conversions
//       );
//       setFemaleCount(
//         femaleCountAllDropdownsData.length === 0
//           ? []
//           : femaleCountAllDropdownsData.conversions
//       );
//       setFemaleCountLabels(
//         femaleCountAllDropdownsDataLabels.length === 0
//           ? []
//           : femaleCountAllDropdownsDataLabels.conversions
//       );
//       setTotalFemaleCount(
//         totalFemaleCountAllDropdownsData.length === 0
//           ? []
//           : totalFemaleCountAllDropdownsData.conversions
//       );
//       if (companyID !== HALEON_COMPANY_ID) {
//         setCountryData(
//           countryAllDropdownsData.length === 0
//             ? []
//             : countryAllDropdownsData.conversions
//         );
//         setStateData(
//           stateAllDropdownsData.length === 0
//             ? []
//             : stateAllDropdownsData.conversions
//         );
//       }
//     } else if (selectedMidSectionDropdownOption.value === "engagements") {

//       setDeviceDistribution(
//         deviceDistributionAllDropdownsData.length === 0
//           ? []
//           : deviceDistributionAllDropdownsData.engagement
//       );
//       setMaleCount(
//         maleCountAllDropdownsData.length === 0
//           ? []
//           : maleCountAllDropdownsData.engagement
//       );
//       setMaleCountLabels(
//         maleCountAllDropdownsDataLabels.length === 0
//           ? []
//           : maleCountAllDropdownsDataLabels.engagement
//       );
//       setTotalMaleCount(
//         totalMaleCountAllDropdownsData.length === 0
//           ? []
//           : totalMaleCountAllDropdownsData.engagement
//       );
//       setFemaleCount(
//         femaleCountAllDropdownsData.length === 0
//           ? []
//           : femaleCountAllDropdownsData.engagement
//       );
//       setFemaleCountLabels(
//         femaleCountAllDropdownsDataLabels.length === 0
//           ? []
//           : femaleCountAllDropdownsDataLabels.engagement
//       );
//       setTotalFemaleCount(
//         totalFemaleCountAllDropdownsData.length === 0
//           ? []
//           : totalFemaleCountAllDropdownsData.engagement
//       );
//       if (companyID !== HALEON_COMPANY_ID) {
//         setCountryData(
//           countryAllDropdownsData.length === 0
//             ? []
//             : countryAllDropdownsData.engagement
//         );
//         setStateData(
//           stateAllDropdownsData.length === 0 ? [] : stateAllDropdownsData.engagement
//         );
//       }
//     } else if (selectedMidSectionDropdownOption.value === "impressions") {

//       setDeviceDistribution(
//         deviceDistributionAllDropdownsData.length === 0
//           ? []
//           : deviceDistributionAllDropdownsData.impressions
//       );
//       setMaleCount(
//         maleCountAllDropdownsData.length === 0
//           ? []
//           : maleCountAllDropdownsData.impressions
//       );
//       setMaleCountLabels(
//         maleCountAllDropdownsDataLabels.length === 0
//           ? []
//           : maleCountAllDropdownsDataLabels.impressions
//       );
//       setTotalMaleCount(
//         totalMaleCountAllDropdownsData.length === 0
//           ? []
//           : totalMaleCountAllDropdownsData.impressions
//       );
//       setFemaleCount(
//         femaleCountAllDropdownsData.length === 0
//           ? []
//           : femaleCountAllDropdownsData.impressions
//       );
//       setFemaleCountLabels(
//         femaleCountAllDropdownsDataLabels.length === 0
//           ? []
//           : femaleCountAllDropdownsDataLabels.impressions
//       );
//       setTotalFemaleCount(
//         totalFemaleCountAllDropdownsData.length === 0
//           ? []
//           : totalFemaleCountAllDropdownsData.impressions
//       );
//       if (companyID !== HALEON_COMPANY_ID) {
//         setCountryData(
//           countryAllDropdownsData.length === 0
//             ? []
//             : countryAllDropdownsData.impressions
//         );
//         setStateData(
//           stateAllDropdownsData.length === 0
//             ? []
//             : stateAllDropdownsData.impressions
//         );
//       }
//     } else if (selectedMidSectionDropdownOption.value === "spends") {

//       setDeviceDistribution(
//         deviceDistributionAllDropdownsData.length === 0
//           ? []
//           : deviceDistributionAllDropdownsData.spend
//       );
//       setMaleCount(
//         maleCountAllDropdownsData.length === 0
//           ? []
//           : maleCountAllDropdownsData.spend
//       );
//       setMaleCountLabels(
//         maleCountAllDropdownsDataLabels.length === 0
//           ? []
//           : maleCountAllDropdownsDataLabels.spend
//       );
//       setTotalMaleCount(
//         totalMaleCountAllDropdownsData.length === 0
//           ? []
//           : totalMaleCountAllDropdownsData.spend
//       );
//       setFemaleCount(femaleCountAllDropdownsData.length === 0 ? [] : femaleCountAllDropdownsData.spend);
//       setFemaleCountLabels(femaleCountAllDropdownsDataLabels.length === 0 ? [] : femaleCountAllDropdownsDataLabels.spend);
//       setTotalFemaleCount(totalFemaleCountAllDropdownsData.length === 0 ? [] : totalFemaleCountAllDropdownsData.spend);
//       if (companyID !== HALEON_COMPANY_ID) {
//         setCountryData(countryAllDropdownsData.length === 0 ? [] : countryAllDropdownsData.spend);
//         setStateData(stateAllDropdownsData.length === 0 ? [] : stateAllDropdownsData.spend);
//       }
//     } else {
//       setDeviceDistribution([]);
//       setMaleCount([]);
//       setMaleCountLabels([]);
//       setTotalMaleCount([]);
//       setFemaleCount([]);
//       setFemaleCountLabels([]);
//       setTotalFemaleCount([]);

//       if (companyID !== HALEON_COMPANY_ID) {
//         setCountryData([]);
//         setStateData([]);
//       }
//     }
//   };

//   // CHNAGE  DATA AS PER DROPWON VALUE
//   useEffect(() => {
//     fetchMidSectionDropdownHandler();
//   }, [BRAND_ID, selectedDates, selectedMidSectionDropdownOption, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // METRIC CARDS

//   // TO FETCH METRIC CARDS FROM API
//   const fetchMetricCardsHandler = async () => {
//     setMetricCardsIsLoading(true);
//     setMetricCardsIsLoaded(false);
//     setMetricCardsError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get("performance/facebook/metric-card/", config);
//       setMetricCards(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.data === undefined || res.data.data.length === 0 ? [] :
//             res.data.data
//       );
//       setMetricCardsIsLoading(false);
//       setMetricCardsIsLoaded(true);
//       setMetricCardsError(null);
//     } catch (error) {
//       setMetricCards([]);
//       setMetricCardsIsLoading(false);
//       setMetricCardsIsLoaded(false);
//       setMetricCardsError(error.code);
//     }
//   };


//   // TO FETCH REACH METRIC CARDS FROM API
//   const fetchReachMetricCardsHandler = async () => {
//     setReachMetricCardsIsLoading(true);
//     setReachMetricCardsIsLoaded(false);
//     setReachMetricCardsError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get("performance/facebook/facebook-instagram-reach/", config);
//       setReachMetricCards(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.facebook === undefined || res.data.facebook.length === 0 ? [] :
//             res.data.facebook.data === undefined || res.data.facebook.data.length === 0 ? [] :
//               res.data.facebook.data
//       );
//       setReachMetricCardsIsLoading(false);
//       setReachMetricCardsIsLoaded(true);
//       setReachMetricCardsError(null);
//     } catch (error) {
//       setReachMetricCards([]);
//       setReachMetricCardsIsLoading(false);
//       setReachMetricCardsIsLoaded(false);
//       setReachMetricCardsError(error.code);
//     }
//   };

//   let static_data = [
//     {
//       "label": "Reach",
//       "currentValue": "loading",
//       "previousValue": "loading"
//     },
//     {
//       "label": "Reach Rate",
//       "currentValue": "loading",
//       "previousValue": "loading"
//     },
//     {
//       "label": "Frequency",
//       "currentValue": "loading",
//       "previousValue": "loading"
//     }
//   ]

//   let error_static_data = [
//     {
//       "label": "Reach",
//       "currentValue": "error",
//       "previousValue": "error"
//     },
//     {
//       "label": "Reach Rate",
//       "currentValue": "error",
//       "previousValue": "error"
//     },
//     {
//       "label": "Frequency",
//       "currentValue": "error",
//       "previousValue": "error"
//     }
//   ]

//   let combineMetricCardsData = useMemo(() => {
//     let temp = [];

//     if (isReachMetricCardsLoading && !isReachMetricCardsLoaded) {
//       temp = metricCards.concat(static_data)
//     } else if (reachMetricCardserror) {
//       temp = metricCards.concat(error_static_data)
//     } else {
//       temp = metricCards.concat(reachMetricCards)
//     }

//     return temp
//   }, [metricCards, reachMetricCards, isReachMetricCardsLoading, isReachMetricCardsLoaded]);


//   // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
//   let metricCardsSection = (
//     <Wrapper>
//       <div className="grid grid_cols_4 grid_margin_bottom">
//         <div className="grid col_span_4 section_header">
//           <div className="section_info">
//             <h2 className="section_card_title">Summary
//             </h2>
//           </div>
//         </div>
//         <div className="grid col_span_4 grid_margin_bottom">
//           <Loader loaderType="cardLoader" />
//         </div>
//       </div>
//     </Wrapper>
//   );

//   // IF DATA IS LOADED
//   if (isMetricCardsLoaded && !isMetricCardsLoading) {
//     if (NoDataAvailableChecker(combineMetricCardsData)) {
//       metricCardsSection = (
//         <Wrapper>
//           <div className="grid grid_cols_4 grid_margin_bottom">
//             <div className="grid col_span_4 section_header">
//               <div className="section_info">  <h2 className="section_card_title">Summary
//               </h2></div>
//             </div>
//             <div className="grid col_span_4 grid_margin_bottom">
//               <NoDataAvailableLoader chartType="cardChartType" />
//             </div>
//           </div>
//         </Wrapper>
//       );
//     } else {
//       metricCardsSection = (
//         <Wrapper>
//           <ErrorBoundary chartType="cardChartType">
//             <Slider sliderTitle={"Summary"}>
//               {combineMetricCardsData.map((data, index) => (
//                 <SwiperSlide key={index}>
//                   <Card
//                     module="campaign"
//                     platform="facebook"
//                     section="overall"
//                     cardTitle={data.label}
//                     currentValue={data.currentValue}
//                     previousValue={data.previousValue}
//                     rowOneTitle={"Previous"}
//                     numberVariant={"number"}
//                   />
//                 </SwiperSlide>
//               ))}
//             </Slider>
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }

//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (metricCardserror) {
//     metricCardsSection = (
//       <Wrapper>
//         <div className="grid grid_cols_4 grid_margin_bottom">
//           <div className="grid col_span_4 section_header">
//             <div className="section_info">  <h2 className="section_card_title">Summary
//             </h2></div>
//           </div>
//           <div className="grid col_span_4 grid_margin_bottom">
//             <ServerErrorsLoader
//               chartType="cardChartType"
//               error={metricCardserror}
//             />
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isMetricCardsLoading) {
//     metricCardsSection = (
//       <Wrapper>
//         <div className="grid grid_cols_4 grid_margin_bottom">
//           <div className="grid col_span_4 section_header">
//             <div className="section_info">  <h2 className="section_card_title">Summary
//             </h2></div>
//           </div>
//           <div className="grid col_span_4 grid_margin_bottom">
//             <Loader loaderType="cardLoader" />
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }


//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (DONUT)

//   const fetchChildDonutCampaignHandler = async () => {
//     setChildDonutCampaignObjectiveLoading(true);
//     setChildDonutCampaignObjectiveLoaded(false);
//     setChildDonutCampaignObjectiveError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/facebook/campaign-objective/",
//         config
//       );

//       setChildDonutCampaignObjective(res.data === undefined || res.data.length === 0 ? [] : res.data);
//       setChildDonutCampaignObjectiveLoading(false);
//       setChildDonutCampaignObjectiveLoaded(true);
//       setChildDonutCampaignObjectiveError(null);
//     } catch (error) {
//       setChildDonutCampaignObjective([]);
//       setChildDonutCampaignObjectiveLoading(false);
//       setChildDonutCampaignObjectiveLoaded(false);
//       setChildDonutCampaignObjectiveError(error.code);
//     }
//   };

//   let childDonutCampaignChart = (
//     <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
//   );

//   // IF DATA IS LOADED
//   if (
//     childDonutCampaignObjectiveLoaded &&
//     !childDonutCampaignObjectiveLoading
//   ) {
//     if (
//       NoDataAvailableChecker(childDonutCampaignObjective)
//     ) {
//       childDonutCampaignChart = (
//         <Wrapper><NoDataAvailableLoader chartType="donutChartType" /></Wrapper>
//       );
//     } else {
//       childDonutCampaignChart = (
//         <Wrapper>
//           <ErrorBoundary chartType="donutChartType">
//             <DonutPieChart
//               chartClass="section_card_chart"
//               chartData={childDonutCampaignObjective}
//               chartId="CampaignDonutChild"
//               onSliceClick={(data) => setChildDonutSliceToggle(data.object_key)}
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (childDonutCampaignObjectiveError) {
//     childDonutCampaignChart = (
//       <Wrapper>
//         <ServerErrorsLoader
//           chartType="donutChartType"
//           error={childDonutCampaignObjectiveError}
//         />
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (childDonutCampaignObjectiveLoading) {
//     childDonutCampaignChart = <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
//   }


//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (LINECHART)
//   const [isLineChartCampaignOpen, setIsLineChartCampaignOpen] = useState(false)

//   const [lineChartCampaignDisabledvalues, setLineChartCampaignDisabledvalues] = useState([])


//   const fetchLineChartCampaignHandler = async () => {
//     setLineChartCampaignObjectiveLoading(true);
//     setLineChartCampaignObjectiveLoaded(false);
//     setLineChartCampaignObjectiveError(null);

//     var objectiveConfig = (function () {
//       if (selectedObjectives === null && (childDonutSliceToggle === null || childDonutSliceToggle === undefined)) {
//         return { "objective": null }
//       } else if (selectedObjectives !== null && (childDonutSliceToggle === undefined || childDonutSliceToggle === null)) {
//         return { "objective": selectedObjectives }
//       } else {
//         return { "objective": childDonutSliceToggle }
//       }
//     })()

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         // objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
//         // objective: childDonutSliceToggle,
//         ...objectiveConfig
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/facebook/objective-linechart/",
//         config
//       );

//       setLineChartCampaignObjective(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.data === undefined || res.data.data.length === 0 ? [] :
//             res.data.data
//       );
//       setLineChartCampaignObjectiveLabel(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.dimension === undefined || res.data.dimension.length === 0 ? [] :
//             res.data.dimension
//       );
//       setLineChartCampaignObjectiveLoading(false);
//       setLineChartCampaignObjectiveLoaded(true);
//       setLineChartCampaignObjectiveError(null);
//     } catch (error) {
//       setLineChartCampaignObjective([]);
//       setLineChartCampaignObjectiveLabel([]);
//       setLineChartCampaignObjectiveLoading(false);
//       setLineChartCampaignObjectiveLoaded(false);
//       setLineChartCampaignObjectiveError(error.code);
//     }
//   };

//   let lineChartCampaignSection = (
//     <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
//   );
//   let lineChartCampaignSection2 = (
//     <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
//   );
//   // IF DATA IS LOADED
//   if (lineChartCampaignObjectiveLoaded && !lineChartCampaignObjectiveLoading) {
//     if (
//       NoDataAvailableChecker(lineChartCampaignObjective) || NoDataAvailableChecker(lineChartCampaignObjectiveLabel)
//     ) {
//       lineChartCampaignSection = (
//         <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
//       );
//       lineChartCampaignSection2 = (
//         <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
//       );
//     } else {

//       if (isMultiLineCluster) {
//         lineChartCampaignSection = (
//           <Wrapper>
//             <ErrorBoundary chartType="lineChartType">
//               <LineChart
//                 chartClass="section_card_chart"
//                 chartData={lineChartCampaignObjective}
//                 chartId="multiLineChart"
//                 chartLabels={lineChartCampaignObjectiveLabel}
//                 onBulletsClick={function noRefCheck() { }}
//                 showLabels
//                 showLegend
//                 impressionLabel="impression"
//                 showImpression={companyID === HALEON_COMPANY_ID ? true : false}
//                 disabledLegendArray={lineChartCampaignDisabledvalues}
//                 setDisabledValues={setLineChartCampaignDisabledvalues}
//               />

//             </ErrorBoundary>
//           </Wrapper>
//         );

//         lineChartCampaignSection2 = (
//           <Wrapper>
//             <ErrorBoundary chartType="multiAxisMultiLineChartType">
//               <PartitionedLineChart
//                 chartClass="section_card_chart"
//                 chartData={lineChartCampaignObjective}
//                 chartId="multiLineChart_2"
//                 chartLabels={lineChartCampaignObjectiveLabel}
//                 showLegend={true}
//                 disabledLegendArray={lineChartCampaignDisabledvalues}
//                 setDisabledValues={setLineChartCampaignDisabledvalues}
//                 showLabels={true}
//               />
//             </ErrorBoundary>
//           </Wrapper>
//         );
//       } else {
//         lineChartCampaignSection = (
//           <Wrapper>
//             <ErrorBoundary chartType="lineChartType">
//               <LineChart
//                 chartClass="section_card_chart"
//                 chartData={lineChartCampaignObjective}
//                 chartId="multiLineChart"
//                 chartLabels={lineChartCampaignObjectiveLabel}
//                 onBulletsClick={function noRefCheck() { }}
//                 showLabels
//                 showLegend
//                 impressionLabel="impression"
//                 showImpression={companyID === HALEON_COMPANY_ID ? true : false}
//               />
//             </ErrorBoundary>
//           </Wrapper>
//         );
//       }
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (lineChartCampaignObjectiveError) {
//     lineChartCampaignSection = (
//       <Wrapper>
//         <ServerErrorsLoader
//           chartType="lineChartType"
//           error={lineChartCampaignObjectiveError}
//         />
//       </Wrapper>
//     );

//     lineChartCampaignSection2 = (
//       <Wrapper>
//         <ServerErrorsLoader
//           chartType="lineChartType"
//           error={lineChartCampaignObjectiveError}
//         />
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (lineChartCampaignObjectiveLoading) {
//     lineChartCampaignSection = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
//     lineChartCampaignSection2 = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>

//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // DEVICE DISTRIBUTION

//   // TO FETCH DATA FROM API
//   const fetchDeviceDistributionHandler = async () => {
//     setDeviceDistributionIsLoading(true);
//     setDeviceDistributionIsLoaded(false);
//     setDeviceDistributionError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/facebook/device-distribution/",
//         config
//       );
//       setDeviceDistribution(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.data === undefined || res.data.data.length === 0 ? [] :
//             res.data.data.impressions === undefined || res.data.data.impressions.length === 0 ? [] :
//               res.data.data.impressions
//       );
//       setDeviceDistributionAllDropdownsData(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.data === undefined || res.data.data.length === 0 ? [] :
//             res.data.data
//       );
//       setDeviceDistributionIsLoading(false);
//       setDeviceDistributionIsLoaded(true);
//       setDeviceDistributionError(null);
//     } catch (error) {
//       setDeviceDistributionAllDropdownsData([]);
//       setDeviceDistributionIsLoading(false);
//       setDeviceDistributionIsLoaded(false);
//       setDeviceDistributionError(error.code);
//     }
//   };

//   // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
//   let deviceDistributionChart = (
//     <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
//   );

//   // IF DATA IS LOADED
//   if (isDeviceDistributionLoaded && !isDeviceDistributionLoading) {
//     if (NoDataAvailableChecker(deviceDistribution)) {
//       deviceDistributionChart = (
//         <Wrapper><NoDataAvailableLoader chartType="donutChartType" /></Wrapper>
//       );
//     } else {
//       deviceDistributionChart = (
//         <DonutPieChart
//           chartId={"reactions_sentiment"}
//           chartClass={"section_card_chart"}
//           chartData={deviceDistribution}
//           showLegend={true}
//           showLabels={true}
//           showVerticalLabels={true}
//         />
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (deviceDistributionerror) {
//     deviceDistributionChart = (
//       <Wrapper>
//         <ServerErrorsLoader
//           chartType="donutChartType"
//           error={deviceDistributionerror}
//         />
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isDeviceDistributionLoading) {
//     deviceDistributionChart = <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // AUDIENCE DISTRIBUTION: MALE / FEMALE

//   // TO FETCH MALE / FEMALE DATA FROM API
//   const fetchAudienceDistributionHandler = async () => {
//     setMaleCountIsLoading(true);
//     setMaleCountIsLoaded(false);
//     setMaleCountError(null);

//     setFemaleCountIsLoading(true);
//     setFemaleCountIsLoaded(false);
//     setFemaleCountError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/facebook/audience-demographics/",
//         config
//       );

//       setMaleCount(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.male_list === undefined || res.data.male_list.length === 0 ? [] :
//             res.data.male_list.data === undefined || res.data.male_list.data.length === 0 ? [] :
//               res.data.male_list.data.impressions === undefined || res.data.male_list.data.impressions.length === 0 ? [] :
//                 res.data.male_list.data.impressions
//       );
//       setMaleCountLabels(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.dimension === undefined || res.data.dimension.length === 0 ? [] :
//             res.data.dimension.impressions === undefined || res.data.dimension.impressions.length === 0 ? [] :
//               res.data.dimension.impressions
//       );
//       setTotalMaleCount(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.male_count === undefined || res.data.male_count.length === 0 ? [] :
//             res.data.male_count.impressions === undefined || res.data.male_count.impressions.length === 0 ? [] :
//               res.data.male_count.impressions
//       );
//       setFemaleCount(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.female_list === undefined || res.data.female_list.length === 0 ? [] :
//             res.data.female_list.data.impressions === undefined || res.data.female_list.data.impressions.length === 0 ? [] :
//               res.data.female_list.data.impressions
//       );
//       setFemaleCountLabels(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.dimension === undefined || res.data.dimension.length === 0 ? [] :
//             res.data.dimension.impressions === undefined || res.data.dimension.impressions.length === 0 ? [] :
//               res.data.dimension.impressions
//       );
//       setTotalFemaleCount(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.female_count === undefined || res.data.female_count.length === 0 ? [] :
//             res.data.female_count.impressions === undefined || res.data.female_count.impressions.length === 0 ? [] :
//               res.data.female_count.impressions
//       );

//       setMaleCountAllDropdownsData(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.male_list === undefined || res.data.male_list.length === 0 ? [] :
//             res.data.male_list.data === undefined || res.data.male_list.data.length === 0 ? [] :
//               res.data.male_list.data
//       );
//       setMaleCountAllDropdownsDataLabels(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.dimension === undefined || res.data.dimension.length === 0 ? [] :
//             res.data.dimension
//       );
//       setTotalMaleCountAllDropdownsData(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.male_count === undefined || res.data.male_count.length === 0 ? [] :
//             res.data.male_count
//       );
//       setMaleCountIsLoading(false);
//       setMaleCountIsLoaded(true);
//       setMaleCountError(null);

//       setFemaleCountAllDropdownsData(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.female_list === undefined || res.data.female_list.length === 0 ? [] :
//             res.data.female_list.data === undefined || res.data.female_list.data.length === 0 ? [] :
//               res.data.female_list.data
//       );
//       setFemaleCountAllDropdownsDataLabels(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.dimension === undefined || res.data.dimension.length === 0 ? [] :
//             res.data.dimension
//       );
//       setTotalFemaleCountAllDropdownsData(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.female_count === undefined || res.data.female_count.length === 0 ? [] :
//             res.data.female_count
//       );
//       setFemaleCountIsLoading(false);
//       setFemaleCountIsLoaded(true);
//       setFemaleCountError(null);
//     } catch (error) {
//       setMaleCountAllDropdownsData([]);
//       setMaleCountAllDropdownsDataLabels([]);
//       setTotalMaleCountAllDropdownsData([]);
//       setMaleCountIsLoading(false);
//       setMaleCountIsLoaded(false);
//       setMaleCountError(error.code);

//       setFemaleCountAllDropdownsData([]);
//       setFemaleCountAllDropdownsDataLabels([]);
//       setTotalFemaleCountAllDropdownsData([]);
//       setFemaleCountIsLoading(false);
//       setFemaleCountIsLoaded(false);
//       setFemaleCountError(error.code);
//     }
//   };

//   // AUDIENCE DISTRIBUTION: MALE
//   let maleAudienceDistribution = (
//     <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
//   );
//   let maleAudienceDistributionIcon = (
//     <Wrapper><Loader loaderType="genderLoader" /></Wrapper>
//   );

//   let totalMaleCountPercentage = 0;
//   if (isNaN(totalMaleCount / (totalMaleCount + totalFemaleCount)) === true) {
//     totalMaleCountPercentage = 0;
//   } else {
//     totalMaleCountPercentage =
//       totalMaleCount / (totalMaleCount + totalFemaleCount);
//   }

//   let totalFemaleCountPercentage = 0;
//   if (isNaN(totalFemaleCount / (totalMaleCount + totalFemaleCount)) === true) {
//     totalFemaleCountPercentage = 0;
//   } else {
//     totalFemaleCountPercentage =
//       totalFemaleCount / (totalMaleCount + totalFemaleCount);
//   }

//   // IF DATA IS AVAILABLE
//   if (isMaleCountLoaded && !isMaleCountLoading) {
//     if (NoDataAvailableChecker(maleCount) || NoDataAvailableChecker(maleCountLabels)) {
//       maleAudienceDistribution = (
//         <Wrapper><NoDataAvailableLoader chartType="barChartType" /></Wrapper>
//       );
//     } else {
//       maleAudienceDistribution = (
//         <Wrapper>
//           <ErrorBoundary>
//             <BarChart
//               chartId="audience_male"
//               chartClass="section_card_chart"
//               chartData={maleCount}
//               chartLabels={maleCountLabels}
//               showLegend={false}
//               /*  showLabels={true}
//                showHalfVerticalLabels={true}  */
//               showVerticalLabels={true}
//               minimumGridDistance={0}
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }

//     if (NoDataAvailableChecker(totalMaleCountPercentage)) {
//       maleAudienceDistributionIcon = (
//         <Wrapper>
//           <NoDataAvailableLoader chartType="genderChartType" />
//         </Wrapper>
//       );
//     } else {
//       maleAudienceDistributionIcon = (
//         <Wrapper>
//           <div className="section_icon flex column">
//             <ErrorBoundary chartType="genderChartType">
//               <MaleIcon percentage={totalMaleCountPercentage * 100} />
//             </ErrorBoundary>
//           </div>
//           <div className="p_20">
//             <h3>
//               <ErrorBoundary>
//                 {+Math.abs(totalMaleCountPercentage * 100)
//                   .toFixed(2)
//                   .replace(/\.0$/, "") + "%"}
//               </ErrorBoundary>
//             </h3>
//           </div>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (maleCounterror) {
//     maleAudienceDistribution = (
//       <Wrapper> <ServerErrorsLoader chartType="barChartType" error={maleCounterror} /></Wrapper>
//     );
//     maleAudienceDistributionIcon = (
//       <Wrapper><ServerErrorsLoader chartType="genderChartType" error={maleCounterror} /></Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isMaleCountLoading) {
//     maleAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
//     maleAudienceDistributionIcon = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>
//   }

//   // AUDIENCE DISTRIBUTION: FEMALE
//   let femaleAudienceDistribution = (
//     <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
//   );
//   let femaleAudienceDistributionIocn = (
//     <Wrapper><Loader loaderType="genderLoader" /></Wrapper>
//   );

//   // IF DATA IS AVAILABLE
//   if (isFemaleCountLoaded && !isFemaleCountLoading) {
//     if (
//       NoDataAvailableChecker(femaleCount) || NoDataAvailableChecker(femaleCountLabels)
//     ) {
//       femaleAudienceDistribution = (
//         <Wrapper><NoDataAvailableLoader chartType="barChartType" /></Wrapper>
//       );
//     } else {
//       femaleAudienceDistribution = (
//         <Wrapper>
//           <ErrorBoundary chartType="barChartType">
//             <BarChart
//               chartId="audience_female"
//               chartClass="section_card_chart"
//               chartData={femaleCount}
//               chartLabels={femaleCountLabels}
//               showLegend={false}
//               /*  showLabels={true}
//                showHalfVerticalLabels={true}  */
//               showVerticalLabels={true}
//               minimumGridDistance={0}
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }

//     if (NoDataAvailableChecker(totalMaleCountPercentage)) {
//       femaleAudienceDistributionIocn = (
//         <Wrapper>
//           <NoDataAvailableLoader chartType="genderChartType" />
//         </Wrapper>
//       );
//     } else {
//       femaleAudienceDistributionIocn = (
//         <Wrapper>
//           <div className="section_icon flex column">
//             <ErrorBoundary chartType="genderChartType">
//               <FemaleIcon percentage={totalFemaleCountPercentage * 100} />
//             </ErrorBoundary>
//           </div>
//           <div className="p_20">
//             <h3>
//               <ErrorBoundary>
//                 {+Math.abs(totalFemaleCountPercentage * 100)
//                   .toFixed(2)
//                   .replace(/\.0$/, "") + "%"}
//               </ErrorBoundary>
//             </h3>
//           </div>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (femaleCounterror) {
//     femaleAudienceDistribution = (
//       <Wrapper>
//         <ServerErrorsLoader chartType="barChartType" error={femaleCounterror} />
//       </Wrapper>
//     );
//     femaleAudienceDistributionIocn = (
//       <Wrapper>
//         <ServerErrorsLoader
//           chartType="genderChartType"
//           error={femaleCounterror}
//         />
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isFemaleCountLoading) {
//     femaleAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>;
//     femaleAudienceDistributionIocn = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>;
//   }



//   const tabData = ["By Country", "By State"];
//   const [selectedTab, setSelectedTab] = useState(tabData[0]);
//   const handleCallback = (event) => {
//     if (selectedTab !== event) {
//       setSelectedTab(event);
//     }
//     IAEvent_Secondary_Tab_Visit(
//       "Marketing Intelligence",
//       "Campaign",
//       "Facebook",
//       "Overall",
//       event
//     );
//   };




//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // COUNTRY MAP

//   const fetchCountryHandler = async () => {
//     setCountryDataIsLoading(true);
//     setCountryDataIsLoaded(false);
//     setCountryDataError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     let apiURL;
//     if (companyID === HALEON_COMPANY_ID) {
//       apiURL = "performance/facebook/performance-table-by-country/"
//     } else {
//       apiURL = "performance/facebook/audience-country/"
//     }

//     try {
//       const res = await axios.get(apiURL, config);

//       if (companyID === HALEON_COMPANY_ID) {
//         setCountryData(
//           res.data === undefined || res.data.length === 0 ? [] :
//             res.data.summary === undefined || res.data.summary.length === 0 ? [] :
//               res.data.summary
//         );
//       } else {
//         setCountryData(
//           res.data === undefined || res.data.length === 0 ? [] :
//             res.data.data === undefined || res.data.data.length === 0 ? [] :
//               res.data.data.impressions === undefined || res.data.data.impressions.length === 0 ? [] :
//                 res.data.data.impressions
//         );

//         setCountryAllDropdownsData(
//           res.data === undefined || res.data.length === 0 ? [] :
//             res.data.data === undefined || res.data.data.length === 0 ? [] :
//               res.data.data
//         );
//       }

//       setCountryDataIsLoading(false);
//       setCountryDataIsLoaded(true);
//       setCountryDataError(null);
//     } catch (error) {
//       /* setCountryAllDropdownsData([]); */
//       setCountryDataIsLoading(false);
//       setCountryDataIsLoaded(false);
//       setCountryDataError(error.code);
//     }
//   };





//   // AUDIENCE DISTRIBUTION: MALE
//   let countryMap = (
//     <Wrapper>
//       <div className="grid grid_cols_4 fullpage_section_card ">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <h2 className="section_card_title">By Country</h2>
//           </div>
//         </div>
//         <div className="grid col_span_4">
//           <div className="section_card_body">
//             <Loader loaderType="smallTableLoader" />
//           </div>
//         </div>
//       </div>
//     </Wrapper>
//   );

//   // IF DATA IS AVAILABLE
//   if (isCountryDataLoaded && !isCountryDataLoading) {
//     if (
//       NoDataAvailableChecker(countryData) ||
//       NoDataAvailableChecker(CountryTableDataHeaders)
//     ) {
//       countryMap = (
//         <Wrapper>
//           <div className="grid grid_cols_4 fullpage_section_card ">
//             <div className="grid col_span_4">
//               <div className="section_card_header">
//                 <h2 className="section_card_title">By Country</h2>
//               </div>
//             </div>
//             <div className="grid col_span_4">
//               <div className="section_card_body">
//                 <NoDataAvailableLoader chartType="tableChartType" />
//               </div>
//             </div>
//           </div>
//         </Wrapper>
//       );
//     } else {
//       countryMap = (
//         <Wrapper>
//           <ErrorBoundary chartType="tableChartType">
//             <Datatable
//               tableTitle="By Country"
//               /*  isHalfSection={true} */
//               isCountryFlagVisible={true}
//               tableHeader={CountryTableDataHeaders}
//               tableData={countryData}
//               tableLength={5}
//               isFooterShow={true}
//               searchPlaceHolder={"Search Country"}
//               tableID={"audience_distribution_country"}
//               tableExportDropdown={
//                 <ExportDropdown
//                   moduleName={pageModule}
//                   subModuleName={pageSubModule}
//                   platform={pagePlatform}
//                   tab={pagePrimaryTab}
//                   sectionId={"audience_distribution"}
//                   sectionName={`${(companyID === HALEON_COMPANY_ID) ? "HaleonCountryTable" : "CountryTableDynaLabels"}_Audience Distribution - Country_${selectedMidSectionDropdownOption.label}`}
//                   sectionData={countryData}
//                 />
//               }
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (countryDataerror) {
//     countryMap = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card ">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">By Country</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <ServerErrorsLoader chartType="tableChartType" error={countryDataerror} />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isCountryDataLoading) {
//     countryMap = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">By Country</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <Loader loaderType="smallTableLoader" />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // STATE TABLE

//   const fetchStateHandler = async () => {
//     setStateDataIsLoading(true);
//     setStateDataIsLoaded(false);
//     setStateDataError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     let apiURL;
//     if (companyID === HALEON_COMPANY_ID) {
//       apiURL = "performance/facebook/performance-table-by-region/"
//     } else {
//       apiURL = "performance/facebook/audience-region/"
//     }

//     try {
//       const res = await axios.get(apiURL, config);

//       if (companyID === HALEON_COMPANY_ID) {
//         setStateData(
//           res.data === undefined || res.data.length === 0 ? [] :
//             res.data.summary === undefined || res.data.summary.length === 0 ? [] :
//               res.data.summary
//         );
//       } else {
//         setStateData(
//           res.data === undefined || res.data.length === 0 ? [] :
//             res.data.data === undefined || res.data.data.length === 0 ? [] :
//               res.data.data.impressions === undefined || res.data.data.impressions.length === 0 ? [] :
//                 res.data.data.impressions
//         );

//         setStateAllDropdownsData(
//           res.data === undefined || res.data.length === 0 ? [] :
//             res.data.data === undefined || res.data.data.length === 0 ? [] :
//               res.data.data
//         );
//       }

//       setStateDataIsLoading(false);
//       setStateDataIsLoaded(true);
//       setStateDataError(null);
//     } catch (error) {
//       /*  setStateAllDropdownsData([]); */
//       setStateDataIsLoading(false);
//       setStateDataIsLoaded(false);
//       setStateDataError(error.code);
//     }
//   };

//   // AUDIENCE DISTRIBUTION: MALE
//   let stateTable = (
//     <Wrapper>
//       <div className="grid grid_cols_4 fullpage_section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <h2 className="section_card_title">By State</h2>
//           </div>
//         </div>
//         <div className="grid col_span_4">
//           <div className="section_card_body">
//             <Loader loaderType="smallTableLoader" />
//           </div>
//         </div>
//       </div>
//     </Wrapper>
//   );

//   // IF DATA IS AVAILABLE
//   if (isStateDataLoaded && !isStateDataLoading) {
//     if (
//       NoDataAvailableChecker(stateData) ||
//       NoDataAvailableChecker(StateTableDataHeaders)
//     ) {
//       stateTable = (
//         <Wrapper>
//           <div className="grid grid_cols_4 fullpage_section_card ">
//             <div className="grid col_span_4">
//               <div className="section_card_header">
//                 <h2 className="section_card_title">By State</h2>
//               </div>
//             </div>
//             <div className="grid col_span_4">
//               <div className="section_card_body">
//                 <NoDataAvailableLoader chartType="tableChartType" />
//               </div>
//             </div>
//           </div>
//         </Wrapper>
//       );
//     } else {
//       stateTable = (
//         <Wrapper>
//           <ErrorBoundary chartType="tableChartType">
//             <Datatable
//               tableTitle="By State"
//               /*  isHalfSection={true} */
//               isDynamicFlagVisible={true}
//               tableHeader={StateTableDataHeaders}
//               tableData={stateData}
//               tableLength={5}
//               isFooterShow={true}
//               searchPlaceHolder={"Search State"}
//               tableID={"audience_distribution_state"}
//               tableExportDropdown={
//                 <ExportDropdown
//                   moduleName={pageModule}
//                   subModuleName={pageSubModule}
//                   platform={pagePlatform}
//                   tab={pagePrimaryTab}
//                   sectionId={"audience_distribution_state"}
//                   sectionName={`${(companyID === HALEON_COMPANY_ID) ? "HaleonStateTable" : "StateTableDynaLabels"}_Audience Distribution - State_${selectedMidSectionDropdownOption.label}`}
//                   sectionData={stateData}
//                 />
//               }
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (stateDataerror) {
//     stateTable = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card ">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">By State</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <ServerErrorsLoader chartType="tableChartType" error={stateDataerror} />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isStateDataLoading) {
//     stateTable = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">By State</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <Loader loaderType="smallTableLoader" />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // CAMPAIGN SUMMARY

//   // TO FETCH METRIC CARDS FROM API
//   const fetchCampaignSummaryHandler = async () => {
//     setCampaignSummaryIsLoading(true);
//     setCampaignSummaryIsLoaded(false);
//     setCampaignSummaryError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/facebook/performance-table/",
//         config
//       );

//       setCampaignSummary(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.campaign === undefined || res.data.campaign.length === 0 ? [] :
//             res.data.campaign
//       );
//       setCampaignSummaryAllDropdownsData(res.data === undefined || res.data.length === 0 ? [] : res.data);
//       setCampaignSummaryIsLoading(false);
//       setCampaignSummaryIsLoaded(true);
//       setCampaignSummaryError(null);
//     } catch (error) {
//       setCampaignSummaryAllDropdownsData([]);
//       setCampaignSummaryIsLoading(false);
//       setCampaignSummaryIsLoaded(false);
//       setCampaignSummaryError(error.code);
//     }
//   };

//   let campaignIdsTemp = [];

//   // EXTRACT THE CAMPAIGN IDS FROM THE CAMPAIGN SUMMARY TABLE
//   const campaignIdsFromTable = useMemo(() => {

//     // TO GET THE UNIQUE CAMPAIGN IDS FROM THE SUMMARY TABLE
//     if (campaignSummary === undefined || campaignSummary === null) return [];
//     campaignSummary.map((data) => {
//       campaignIdsTemp.push(data.id)
//     })

//     // JOIN THE LIST OF ARRAY OF CAMPAIGN IDS
//     return campaignIdsTemp.join(",");
//   }, [campaignSummary]);  // UPDATES ONLY WHEN SUMMARY TABLE IS CHANGED

//   useEffect(() => {
//     (campaignIdsFromTable.length !== 0 && selectedCampaignSummaryDropdownOption.value === "campaign" && (companyID === COKE_COMPANY_ID || companyID === HALEON_COMPANY_ID)) && fetchReachCampaignSummaryHandler()
//   }, [campaignIdsFromTable])

//   // TO FETCH METRIC CARDS FROM API
//   const fetchReachCampaignSummaryHandler = async () => {
//     setReachCampaignSummaryIsLoading(true);
//     setReachCampaignSummaryIsLoaded(false);
//     setReachCampaignSummaryError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
//         campaign: campaignIdsFromTable,
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/facebook/facebook-instagram-campaign-reach/",
//         config
//       );

//       setReachCampaignSummary(
//         res.data === undefined || res.data.length === 0 ? [] :
//           res.data.facebook === undefined || res.data.facebook.length === 0 ? [] :
//             res.data.facebook
//       );
//       setReachCampaignSummaryIsLoading(false);
//       setReachCampaignSummaryIsLoaded(true);
//       setReachCampaignSummaryError(null);
//     } catch (error) {
//       setReachCampaignSummary([]);
//       setReachCampaignSummaryIsLoading(false);
//       setReachCampaignSummaryIsLoaded(false);
//       setReachCampaignSummaryError(error.code);
//     }
//   };

//   campaignSummary.map((data, dataIndex) => {
//     if (isReachCampaignSummaryLoading && !isReachCampaignSummaryLoaded) {
//       campaignSummary[dataIndex].reach_rate = "loading";
//       campaignSummary[dataIndex].frequency = "loading";
//       campaignSummary[dataIndex]["reach"] = "loading";
//     }
//     else if (reachCampaignSummaryerror) {
//       campaignSummary[dataIndex].reach_rate = "error";
//       campaignSummary[dataIndex].frequency = "error";
//       campaignSummary[dataIndex]["reach"] = "error";
//     }
//     else {
//       if (reachCampaignSummary[data.id] !== undefined) {
//         campaignSummary[dataIndex].reach_rate = reachCampaignSummary[data.id].reach_rate;
//         campaignSummary[dataIndex].frequency = reachCampaignSummary[data.id].frequency;
//         campaignSummary[dataIndex]["reach"] = reachCampaignSummary[data.id].reach;
//       }
//       else {
//         campaignSummary[dataIndex].reach_rate = "error";
//         campaignSummary[dataIndex].frequency = "error";
//         campaignSummary[dataIndex]["reach"] = "error";
//       }
//     }
//   })

//   let campaignSummaryTable = (
//     <Wrapper>
//       <div className="grid grid_cols_4 fullpage_section_card section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <h2 className="section_card_title">Summary
//               <SectionDefinationsBadge
//                 title={"Summary"}
//                 module={pageSubModule}
//                 platform={pagePlatform}
//                 section={pagePrimaryTab}
//               />
//             </h2>
//           </div>
//         </div>
//         <div className="grid col_span_4">
//           <div className="section_card_body">
//             <Loader loaderType="smallTableLoader" />
//           </div>
//         </div>
//       </div>
//     </Wrapper>
//   );

//   // let TableHeader = [];

//   // IF DATA IS LOADED
//   if (isCampaignSummaryLoaded && !isCampaignSummaryLoading) {
//     if (NoDataAvailableChecker(campaignSummary) || NoDataAvailableChecker(campaignSummaryTableHeaders)) {
//       campaignSummaryTable =
//         (
//           <Wrapper>
//             <div className="grid grid_cols_4 fullpage_section_card section_card">
//               <div className="grid col_span_4">
//                 <div className="section_card_header">
//                   <h2 className="section_card_title">Summary
//                     <SectionDefinationsBadge
//                       title={"Summary"}
//                       module={pageSubModule}
//                       platform={pagePlatform}
//                       section={pagePrimaryTab}
//                     />
//                   </h2>
//                 </div>
//               </div>
//               <div className="grid col_span_4">
//                 <div className="section_card_body">
//                   <NoDataAvailableLoader chartType="tableChartType" />
//                 </div>
//               </div>
//             </div>
//           </Wrapper>
//         )

//     } else {
//       campaignSummaryTable = (
//         <Wrapper>
//           <ErrorBoundary chartType="tableChartType">
//             <Datatable
//               tableTitle="Summary"
//               tablePagePlatform={pagePlatform}
//               tablePageSubModule={pageSubModule}
//               tablePagePrimaryTab={pagePrimaryTab}
//               tableHeader={campaignSummaryTableHeaders}
//               tableData={campaignSummary}
//               tableLength={10}
//               isFooterShow={true}
//               searchPlaceHolder={"Search Campaign"}
//               tableDropdown={
//                 <Dropdown
//                   ismulti={false}
//                   placeholder={"Select"}
//                   options={campaignSummaryOptions}
//                   className="form_dropdown section_dropdown"
//                   value={selectedCampaignSummaryDropdownOption}
//                   selectedOptions={selectedCampaignSummaryDropdownOption}
//                   setStatedropdown={campaignSummaryDropdownSelectionHandler}
//                 />
//               }
//               tableID={"search_campaign_section"}
//               tableExportDropdown={
//                 <ExportDropdown
//                   sectionId={"search_campaign_section"}
//                   moduleName={pageModule}
//                   subModuleName={pageSubModule}
//                   platform={pagePlatform}
//                   tab={pagePrimaryTab}
//                   sectionName={`${(companyID === COKE_COMPANY_ID || companyID === HALEON_COMPANY_ID) ? "CampaignTableCokeHaleon" : "CampaignTableDynaLabels"}_Summary Table_${selectedCampaignSummaryDropdownOption.label.toLowerCase()}`}
//                   sectionData={campaignSummary}
//                 />
//               }
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }

//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (campaignSummaryerror) {
//     campaignSummaryTable = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">Summary
//                 <SectionDefinationsBadge
//                   title={"Summary"}
//                   module={pageSubModule}
//                   platform={pagePlatform}
//                   section={pagePrimaryTab}
//                 />
//               </h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <ServerErrorsLoader chartType="tableChartType" error={campaignSummaryerror} />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isCampaignSummaryLoading) {
//     campaignSummaryTable =
//       (
//         <Wrapper>
//           <div className="grid grid_cols_4 fullpage_section_card section_card">
//             <div className="grid col_span_4">
//               <div className="section_card_header">
//                 <h2 className="section_card_title">Summary
//                   <SectionDefinationsBadge
//                     title={"Summary"}
//                     module={pageSubModule}
//                     platform={pagePlatform}
//                     section={pagePrimaryTab}
//                   />
//                 </h2>
//               </div>
//             </div>
//             <div className="grid col_span_4">
//               <div className="section_card_body">
//                 <Loader loaderType="smallTableLoader" />
//               </div>
//             </div>
//           </div>
//         </Wrapper>
//       )
//   }


//   useEffect(() => {
//     fetchLineChartCampaignHandler();
//   }, [BRAND_ID, selectedDates, childDonutSliceToggle, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

//   useEffect(() => {
//     setChildDonutSliceToggle(null)
//   }, [selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

//   var campaignObjectCSV = {
//     "CampaignObjDonut_Campaign Objective Donut": childDonutCampaignObjective,
//     [`CampObjectiveDynaLabels_Campaign Objective Line_${childDonutSliceToggle === undefined || childDonutSliceToggle === null ? "empty" : childDonutSliceToggle.replace("_", "-")}`]: lineChartCampaignObjective,
//   };

//   var maleFemaleDataCSV = {
//     "Audience Total Count": [{ ["Male - " + selectedMidSectionDropdownOption.label]: totalMaleCount, ["Female - " + selectedMidSectionDropdownOption.label]: totalFemaleCount }],
//     [`AgeBarChartCampaignDynalabels_Audience Distribution - Male_${selectedMidSectionDropdownOption.value}`]: maleCount,
//     [`AgeBarChartCampaignDynalabels_Audience Distribution - Female_${selectedMidSectionDropdownOption.value}`]: femaleCount,
//   };



//   var deviceDistributionExport = {
//     [`CampaignDeviceDistDynaLabels_Device Distribution_${selectedMidSectionDropdownOption.value}`]: deviceDistribution,
//   }



//   var allData = {
//     ...topCardData,
//     "SummaryCard_Summary Card": combineMetricCardsData,
//     ...campaignObjectCSV,
//     ...maleFemaleDataCSV,
//     [`${(companyID === HALEON_COMPANY_ID) ? "HaleonCountryTable" : "CountryTableDynaLabels"}_Audience Distribution - Country_${selectedMidSectionDropdownOption.label}`]: countryData,
//     [`${(companyID === HALEON_COMPANY_ID) ? "HaleonStateTable" : "StateTableDynaLabels"}_Audience Distribution - State_${selectedMidSectionDropdownOption.label}`]: stateData,
//     ...deviceDistributionExport,
//     [`${(companyID === COKE_COMPANY_ID || companyID === HALEON_COMPANY_ID) ? "CampaignTableCokeHaleon" : "CampaignTableDynaLabels"}_Summary Table_${selectedCampaignSummaryDropdownOption.label.toLowerCase()}`]: campaignSummary,
//   };

//   useEffect(() => {
//     setFullPageExport(allData);
//   }, [
//     combineMetricCardsData,
//     childDonutCampaignObjective,
//     lineChartCampaignObjective,
//     maleCount,
//     femaleCount,
//     stateData,
//     countryData,
//     deviceDistribution,
//     campaignSummary,
//   ]);


//   useEffect(() => {
//     if (
//       (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
//       (childDonutCampaignObjectiveError !== null ? true : childDonutCampaignObjectiveLoaded && (childDonutCampaignObjectiveLoading === false)) &&
//       (lineChartCampaignObjectiveError !== null ? true : lineChartCampaignObjectiveLoaded && (lineChartCampaignObjectiveLoading === false)) &&
//       (maleCounterror !== null ? true : isMaleCountLoaded && (isMaleCountLoading === false)) &&
//       (femaleCounterror !== null ? true : isFemaleCountLoaded && (isFemaleCountLoading === false)) &&
//       (stateDataerror !== null ? true : isStateDataLoaded && (isStateDataLoading === false)) &&
//       (countryDataerror !== null ? true : isCountryDataLoaded && (isCountryDataLoading === false)) &&
//       (deviceDistributionerror !== null ? true : isDeviceDistributionLoaded && (isDeviceDistributionLoading === false)) &&
//       (campaignSummaryerror !== null ? true : isCampaignSummaryLoaded && (isCampaignSummaryLoading === false))
//     ) {
//       setIsExportDisabled(false)
//     } else {
//       setIsExportDisabled(true)
//     }
//   }, [
//     metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
//     childDonutCampaignObjectiveError, childDonutCampaignObjectiveLoaded, childDonutCampaignObjectiveLoading,
//     lineChartCampaignObjectiveError, lineChartCampaignObjectiveLoaded, lineChartCampaignObjectiveLoading,
//     maleCounterror, isMaleCountLoaded, isMaleCountLoading,
//     femaleCounterror, isFemaleCountLoaded, isFemaleCountLoading,
//     stateDataerror, isStateDataLoaded, isStateDataLoading,
//     countryDataerror, isCountryDataLoaded, isCountryDataLoading,
//     deviceDistributionerror, isDeviceDistributionLoaded, isDeviceDistributionLoading,
//     campaignSummaryerror, isCampaignSummaryLoaded, isCampaignSummaryLoading,
//   ])


//   return (
//     <Wrapper>
//       {metricCardsSection}
//       <div
//         id="campaign_objective_section"
//         className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
//       >
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <div className="section_info">
//               <h2 className="section_card_title">Objective Wise Distribution</h2>
//               <SectionDefinationsBadge
//                 title={"Objective Wise Distribution"}
//                 module="campaign"
//                 platform="facebook"
//                 section="overall"
//               />
//             </div>
//             <Modal
//               open={isLineChartCampaignOpen}
//               setOpen={setIsLineChartCampaignOpen}
//               exportDropdown={
//                 <ExportDropdown
//                   moduleName={pageModule}
//                   subModuleName={pageSubModule}
//                   platform={pagePlatform}
//                   tab={pagePrimaryTab}
//                   sectionId={"multiLineChart_2"}
//                   sectionName={"Campaign Objective Wise distribution"}
//                   sectionData={campaignObjectCSV}
//                 />}
//               parentId={"campaign_objective_section"}
//               title={`Objective Wise Distribution`}
//               chart={lineChartCampaignSection2}
//             />
//             <div data-html2canvas-ignore={true}>
//               {isMultiLineCluster && <button onClick={() => setIsLineChartCampaignOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
//                 <ExpandIcon />
//               </button>}
//               <ExportDropdown
//                 moduleName={pageModule}
//                 subModuleName={pageSubModule}
//                 platform={pagePlatform}
//                 tab={pagePrimaryTab}
//                 sectionId={"campaign_objective_section"}
//                 sectionName={"Campaign Objective Wise distribution"}
//                 sectionData={campaignObjectCSV}
//               />
//             </div>
//           </div>
//         </div>
//         <div className="grid grid_cols_4">
//           <div className="grid col_span_1 ">
//             <div className="section_card_body">
//               <div className="section_card_chart_wrapper">
//                 {childDonutCampaignChart}
//               </div>
//             </div>
//           </div>
//           <div className="grid col_span_3 ">
//             <div className="section_card_body">
//               <div className="section_card_chart_wrapper">
//                 {lineChartCampaignSection}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="section_card section_dropdown_header">
//         <div className="grid grid_cols_1">
//           <div className="section_card_header">
//             <h2 className="section_card_title">Performance By KPI</h2>
//             <Dropdown
//               ismulti={false}
//               placeholder={"Select"}
//               options={midSectionDropdonwOptions}
//               className="form_dropdown section_dropdown"
//               value={selectedMidSectionDropdownOption}
//               selectedOptions={selectedMidSectionDropdownOption}
//               setStatedropdown={midSectionDropdownSelectionHandler}
//             />
//           </div>
//         </div>
//       </div>
//       <div className="grid grid_cols_4 grid_margin_bottom">
//         <div
//           id="audience_distribution"
//           className="grid col_span_3 section_card"
//         >
//           <div className="section_card_header">
//             <div className="section_info">
//               <h2 className="section_card_title">
//                 {AudienceDistributionSectionTitle} {": " + selectedMidSectionDropdownOption.label} </h2>
//               <SectionDefinationsBadge
//                 title={AudienceDistributionSectionTitle + " By Gender"}
//                 module="campaign"
//                 platform="facebook"
//                 section="overall"
//               />
//             </div>

//             <div data-html2canvas-ignore={true}>
//               <ExportDropdown
//                 moduleName={pageModule}
//                 subModuleName={pageSubModule}
//                 platform={pagePlatform}
//                 tab={pagePrimaryTab}
//                 sectionId={"audience_distribution"}
//                 sectionName={"Audience Distribution"}
//                 sectionData={maleFemaleDataCSV}

//               />
//             </div>
//           </div>
//           <div className="grid grid_cols_4">
//             <div className="grid col_span_2 card_border_right flex column">
//               <div className="section_card_header">
//                 <h2 className="section_card_title">Male

//                 </h2>
//               </div>
//               <div className="grid grid_cols_4 section_card_body">
//                 <div className="grid col_span_1 flex column justify_center align_center">
//                   {maleAudienceDistributionIcon}
//                 </div>
//                 <div className="grid col_span_3 section_card_chart_wrapper">
//                   {maleAudienceDistribution}
//                 </div>
//               </div>
//             </div>
//             <div className="grid col_span_2 flex column">
//               <div className="grid col_span_4 section_card_header">
//                 <h2 className="section_card_title">Female

//                 </h2>
//               </div>
//               <div className="grid grid_cols_4 section_card_body">
//                 <div className="grid col_span_1 flex column justify_center align_center">
//                   {femaleAudienceDistributionIocn}
//                 </div>
//                 <div className="grid col_span_3 section_card_chart_wrapper">
//                   {femaleAudienceDistribution}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div id="device_distribution" className="grid col_span_1 section_card">
//           <div className="section_card_header">
//             <div className="section_info">
//               <h2 className="section_card_title">
//                 Device Distribution{": " + selectedMidSectionDropdownOption.label}</h2>
//               <SectionDefinationsBadge
//                 title={"Device Distribution"}
//                 module="campaign"
//                 platform="facebook"
//                 section="overall"
//               />
//             </div>

//             <div data-html2canvas-ignore={true}>
//               <ExportDropdown
//                 moduleName={pageModule}
//                 subModuleName={pageSubModule}
//                 platform={pagePlatform}
//                 tab={pagePrimaryTab}
//                 sectionId={"device_distribution"}
//                 sectionName={"CampaignDeviceDistDynaLabels_Device Distribution"}
//                 sectionData={deviceDistributionExport}
//               />
//             </div>
//           </div>
//           <div className="grid grid_cols_1 section_card_body">
//             <div className="section_card_chart_wrapper">
//               {deviceDistributionChart}
//             </div>
//           </div>
//         </div>
//       </div>
//       <div
//         id="audience_distribution_place"

//       >
//         <div className="section_card section_dropdown_header">
//           <div className="grid grid_cols_1">
//             <div className="section_card_header">
//               <div className="section_info">
//                 <h2 className="section_card_title">
//                   {AudienceDistributionSectionTitle} {/* : {selectedMidSectionDropdownOption.label} */}
//                 </h2>
//                 <SectionDefinationsBadge
//                   title={AudienceDistributionSectionTitle}
//                   module="campaign"
//                   platform="facebook"
//                   section="overall"
//                 />
//               </div>
//             </div>
//             {/*  <div data-html2canvas-ignore={true}>
//               <ExportDropdown
//                 moduleName={pageModule}
//                 subModuleName={pageSubModule}
//                 platform={pagePlatform}
//                 tab={pagePrimaryTab}
//                 sectionId={"audience_distribution_place"}
//                 sectionName={"Audience Distribution"}
//                 sectionData={cityCountryData}
//               />
//             </div> */}
//           </div>
//         </div>

//         {companyID === HALEON_COMPANY_ID ?
//           <Wrapper>
//             <div className="grid  grid_margin_bottom ">
//               <Tab
//                 varient={"primary"}
//                 tabData={tabData}
//                 activeTab={selectedTab}
//                 handleCallback={(event) => handleCallback(event)}
//               />
//             </div>
//             <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
//               {selectedTab === "By Country" && (
//                 <Wrapper>
//                   {countryMap}
//                 </Wrapper>
//               )}
//               {selectedTab === "By State" && (
//                 <Wrapper>
//                   {stateTable}
//                 </Wrapper>
//               )}
//             </div>
//           </Wrapper>
//           :
//           <div className="grid grid_cols_4 grid_margin_bottom">
//             <div className="grid col_span_2 card_border_right flex column">
//               {countryMap}
//             </div>
//             <div className="grid col_span_2 flex column">
//               {stateTable}
//             </div>
//           </div>
//         }

//       </div>
//       {campaignSummaryTable}
//     </Wrapper>
//   );
// };

// export const CampaignFacebookOverallCoke = ({ topCardData }) => {
//   // PAGE CONSTANTS
//   let pageModule = "Marketing Intelligence";
//   let pageSubModule = "Campaign";
//   let pagePlatform = "Facebook";
//   let pagePrimaryTab = "Overall";

//   const authCtx = useContext(AuthContext);
//   const { setFullPageExport, setIsExportDisabled } = useContext(FullPageExportContext);

//   const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)

//   // USER DATA
//   const userData = useSelector((state) => state.userData.user);
//   let companyID = userData?.company?.id;

//   let AudienceDistributionSectionTitle = "";
//   if (companyID === HALEON_COMPANY_ID) {
//     AudienceDistributionSectionTitle = "Audience Distribution - Meta level";
//   } else {
//     AudienceDistributionSectionTitle = "Audience Distribution";
//   }

//   // SELECTED BRANDS FROM DROPDOWN
//   const globalSelectedBrandIds = useSelector(
//     (state) => state.globalSelectedBrandIds.selectedBrandIds
//   );

//   let BRAND_ID = null;
//   if (
//     globalSelectedBrandIds.length === 0 ||
//     globalSelectedBrandIds === undefined
//   ) {
//     BRAND_ID = null;
//   } else {
//     BRAND_ID = globalSelectedBrandIds.join(",");
//   }

//   // GLOBAL CAMPAIGN DROPDOWN
//   const selectedCampaignPlatformsFromFilter = useSelector(
//     (state) => state.campaignModuleFilter.selectedCampaignPlatform
//   );
//   const selectedCampaignObjectivesFromFilter = useSelector(
//     (state) => state.campaignModuleFilter.selectedCampaignObjectives
//   );
//   const selectedCampaignsFromFilter = useSelector(
//     (state) => state.campaignModuleFilter.selectedCampaigns
//   );
//   const selectedBucketsFromFilter = useSelector(
//     (state) => state.campaignModuleFilter.selectedBuckets
//   );
//   const selectedCampaignIdandPlatform = useSelector(
//     (state) => state.campaignModuleFilter.selectedCampaignIDandPlatform
//   );

//   // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
//   let selectedPlatform = null;
//   if (
//     selectedCampaignPlatformsFromFilter.length === 0 ||
//     selectedCampaignPlatformsFromFilter === undefined
//   ) {
//     selectedPlatform = null;
//   } else {
//     selectedPlatform = selectedCampaignPlatformsFromFilter.join(",");
//   }

//   // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
//   let selectedObjectives = null;
//   if (
//     selectedCampaignObjectivesFromFilter.length === 0 ||
//     selectedCampaignObjectivesFromFilter === undefined
//   ) {
//     selectedObjectives = null;
//   } else {
//     selectedObjectives = selectedCampaignObjectivesFromFilter.join(",");
//   }

//   // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
//   let selectedCampaigns = null;
//   if (
//     selectedCampaignsFromFilter.length === 0 ||
//     selectedCampaignsFromFilter === undefined
//   ) {
//     selectedCampaigns = null;
//   } else {
//     selectedCampaigns = selectedCampaignsFromFilter.join(",");
//   }

//   let selectedIdandPlatform = null;
//   if (selectedCampaignIdandPlatform === undefined || selectedCampaignIdandPlatform.length === 0) {
//     selectedIdandPlatform = null;
//   } else {
//     selectedIdandPlatform = JSON.stringify(selectedCampaignIdandPlatform);
//   }

//   // currentDate / endDate / prevCurrentDate / prevEndDate
//   const selectedDates = useSelector((state) => state.date);

//   // METRIC CARDS
//   const [metricCards, setMetricCards] = useState([]);
//   const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
//   const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
//   const [metricCardserror, setMetricCardsError] = useState(null);

//   // REACH METRIC CARDS
//   const [reachMetricCards, setReachMetricCards] = useState([]);
//   const [isReachMetricCardsLoading, setReachMetricCardsIsLoading] =
//     useState(false);
//   const [isReachMetricCardsLoaded, setReachMetricCardsIsLoaded] =
//     useState(false);
//   const [reachMetricCardserror, setReachMetricCardsError] = useState(null);

//   // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (DONUT)
//   const [childDonutSliceToggle, setChildDonutSliceToggle] = useState(null);
//   const [childDonutCampaignObjective, setChildDonutCampaignObjective] =
//     useState([]);
//   const [
//     childDonutCampaignObjectiveLoading,
//     setChildDonutCampaignObjectiveLoading,
//   ] = useState([]);
//   const [
//     childDonutCampaignObjectiveLoaded,
//     setChildDonutCampaignObjectiveLoaded,
//   ] = useState([]);
//   const [
//     childDonutCampaignObjectiveError,
//     setChildDonutCampaignObjectiveError,
//   ] = useState([]);

//   // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (LINECHART)
//   const [lineChartCampaignObjective, setLineChartCampaignObjective] = useState(
//     []
//   );
//   const [lineChartCampaignObjectiveLabel, setLineChartCampaignObjectiveLabel] =
//     useState([]);
//   const [
//     lineChartCampaignObjectiveLoading,
//     setLineChartCampaignObjectiveLoading,
//   ] = useState([]);
//   const [
//     lineChartCampaignObjectiveLoaded,
//     setLineChartCampaignObjectiveLoaded,
//   ] = useState([]);
//   const [lineChartCampaignObjectiveError, setLineChartCampaignObjectiveError] =
//     useState([]);

//   // MID SECTION DROPDOWN
//   // DROPDOWN OPTIONS
//   const midSectionDropdonwOptions = [
//     { value: "impressions", label: "Impressions" },
//     { value: "clicks", label: "Clicks" },
//     { value: "conversions", label: "Conversions" },
//     { value: "engagements", label: "Engagements" },
//     { value: "spends", label: "Spends" },
//   ];

//   // DROPDOWN DEFAULT STATE
//   const [
//     selectedMidSectionDropdownOption,
//     setSelectedMidSectionDropdownDropdownOption,
//   ] = useState(midSectionDropdonwOptions[0]);

//   // DROPDOWN OPTIONS SELECTION HANDLER
//   const midSectionDropdownSelectionHandler = (e) => {
//     if (e === null) {
//       setSelectedMidSectionDropdownDropdownOption("");
//     } else {
//       setSelectedMidSectionDropdownDropdownOption(e);
//     }
//     IAEvent_Dropdown_Visit(
//       "Marketing Intelligence",
//       "Campaigns",
//       "Facebook",
//       "Overall",
//       null,
//       "Performance By KPI",
//       e.label
//     );
//   };

//   // DEVICE DISTRIBUTION
//   const [deviceDistribution, setDeviceDistribution] = useState([]);
//   const [
//     deviceDistributionAllDropdownsData,
//     setDeviceDistributionAllDropdownsData,
//   ] = useState([]);
//   const [isDeviceDistributionLoading, setDeviceDistributionIsLoading] =
//     useState(false);
//   const [isDeviceDistributionLoaded, setDeviceDistributionIsLoaded] =
//     useState(false);
//   const [deviceDistributionerror, setDeviceDistributionError] = useState(null);

//   // MALE COUNT
//   const [maleCount, setMaleCount] = useState([]);
//   const [maleCountAllDropdownsData, setMaleCountAllDropdownsData] = useState(
//     []
//   );
//   const [maleCountLabels, setMaleCountLabels] = useState([]);
//   const [maleCountAllDropdownsDataLabels, setMaleCountAllDropdownsDataLabels] =
//     useState([]);
//   const [totalMaleCount, setTotalMaleCount] = useState(0);
//   const [totalMaleCountAllDropdownsData, setTotalMaleCountAllDropdownsData] =
//     useState([]);
//   const [isMaleCountLoading, setMaleCountIsLoading] = useState(false);
//   const [isMaleCountLoaded, setMaleCountIsLoaded] = useState(false);
//   const [maleCounterror, setMaleCountError] = useState(null);

//   // FEMALE COUNT
//   const [femaleCount, setFemaleCount] = useState([]);
//   const [femaleCountAllDropdownsData, setFemaleCountAllDropdownsData] =
//     useState([]);
//   const [femaleCountLabels, setFemaleCountLabels] = useState([]);
//   const [
//     femaleCountAllDropdownsDataLabels,
//     setFemaleCountAllDropdownsDataLabels,
//   ] = useState([]);
//   const [totalFemaleCount, setTotalFemaleCount] = useState(0);
//   const [
//     totalFemaleCountAllDropdownsData,
//     setTotalFemaleCountAllDropdownsData,
//   ] = useState([]);
//   const [isFemaleCountLoading, setFemaleCountIsLoading] = useState(false);
//   const [isFemaleCountLoaded, setFemaleCountIsLoaded] = useState(false);
//   const [femaleCounterror, setFemaleCountError] = useState(null);

//   // COUNTRY
//   const [countryData, setCountryData] = useState([]);
//   const [countryAllDropdownsData, setCountryAllDropdownsData] = useState([]);
//   const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
//   const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
//   const [countryDataerror, setCountryDataError] = useState(null);

//   let CountryTableDataHeaders = [];

//   let HaleonCountryTableDataHeaders = [
//     { name: "Country", field: "country" },
//     { name: "Impressions", field: "impressions" },
//     { name: "Clicks", field: "clicks" },
//     { name: "Conversions", field: "conversions" },
//     { name: "Engagements", field: "total_engagement" },
//     { name: "Spends", field: "spends" },
//     { name: "Engagement Rate", field: "engagement_rate" },
//     { name: "CTR", field: "ctr" },
//     { name: "Interaction Rate", field: "interaction_rate" },
//   ];

//   let DefaultCountryTableDataHeaders = [
//     { name: "Country", field: "name" },
//     { name: selectedMidSectionDropdownOption.label, field: "value" },
//     { name: "Change", field: "percentage_growth" },
//   ];

//   if (companyID === HALEON_COMPANY_ID) {
//     CountryTableDataHeaders = HaleonCountryTableDataHeaders;
//   } else {
//     CountryTableDataHeaders = DefaultCountryTableDataHeaders;
//   }

//   // STATE
//   const [stateData, setStateData] = useState([]);
//   const [stateAllDropdownsData, setStateAllDropdownsData] = useState([]);
//   const [isStateDataLoading, setStateDataIsLoading] = useState(false);
//   const [isStateDataLoaded, setStateDataIsLoaded] = useState(false);
//   const [stateDataerror, setStateDataError] = useState(null);

//   let StateTableDataHeaders = [];

//   let HaleonStateTableDataHeaders = [
//     { name: "State", field: "region" },
//     { name: "Impressions", field: "impressions" },
//     { name: "Clicks", field: "clicks" },
//     { name: "Conversions", field: "conversions" },
//     { name: "Engagements", field: "total_engagement" },
//     { name: "Spends", field: "spends" },
//     { name: "Engagement Rate", field: "engagement_rate" },
//     { name: "CTR", field: "ctr" },
//     { name: "Interaction Rate", field: "interaction_rate" },
//   ];

//   let DefaultStateTableDataHeaders = [
//     { name: "State", field: "name" },
//     { name: selectedMidSectionDropdownOption.label, field: "value" },
//     { name: "Change", field: "percentage_growth" },
//   ];

//   if (companyID === HALEON_COMPANY_ID) {
//     StateTableDataHeaders = HaleonStateTableDataHeaders;
//   } else {
//     StateTableDataHeaders = DefaultStateTableDataHeaders;
//   }

//   // CAMPAIGN SUMMARY
//   const [campaignSummary, setCampaignSummary] = useState([]);
//   const [campaignSummaryAllDropdownsData, setCampaignSummaryAllDropdownsData] =
//     useState([]);
//   const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] =
//     useState(false);
//   const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
//   const [campaignSummaryerror, setCampaignSummaryError] = useState(null);

//   // CAMPAIGN SUMMARY FOR REACH
//   const [reachCampaignSummary, setReachCampaignSummary] = useState([]);
//   const [isReachCampaignSummaryLoading, setReachCampaignSummaryIsLoading] =
//     useState(false);
//   const [isReachCampaignSummaryLoaded, setReachCampaignSummaryIsLoaded] =
//     useState(false);
//   const [reachCampaignSummaryerror, setReachCampaignSummaryError] =
//     useState(null);

//   // TABLE HEADERS FOR COKE
//   let cokeSummaryTableHeaders = [
//     { name: "Name", field: "name" },
//     { name: "Objective", field: "objective" },
//     { name: "Impressions", field: "impressions" },
//     { name: "Clicks", field: "clicks" },
//     { name: "Spends", field: "spends" },
//     { name: "Conversions", field: "conversions" },
//     { name: "CTR", field: "ctr" },
//     { name: "CPM", field: "cpm" },
//     { name: "Benchmark CPM", field: "benchmark_cpm" },
//     { name: "CPC", field: "cpc" },
//     { name: "Benchmark CPC", field: "benchmark_cpc" },
//     { name: "CPV", field: "cpv" },
//     { name: "Benchmark CPV", field: "benchmark_cpv" },
//     { name: "CR", field: "cr" },
//     { name: "Views", field: "views" },
//     { name: "View Rate", field: "view_rate" },
//     { name: "Benchmark View Rate", field: "benchmark_view_rate" },
//     { name: "Frequency", field: "frequency" },
//     { name: "Reach Rate", field: "reach_rate" },
//     { name: "Engagement Rate", field: "engagement_rate" },
//   ];

//   let cokeCamapignSummaryTableHeaders = [
//     { name: "Name", field: "name" },
//     { name: "Objective", field: "objective" },
//     { name: "Impressions", field: "impressions" },
//     { name: "Clicks", field: "clicks" },
//     { name: "Spends", field: "spends" },
//     { name: "Conversions", field: "conversions" },
//     { name: "CTR", field: "ctr" },
//     { name: "CPM", field: "cpm" },
//     { name: "Benchmark CPM", field: "benchmark_cpm" },
//     { name: "CPC", field: "cpc" },
//     { name: "Benchmark CPC", field: "benchmark_cpc" },
//     { name: "CPV", field: "cpv" },
//     { name: "Benchmark CPV", field: "benchmark_cpv" },
//     { name: "CR", field: "cr" },
//     { name: "Views", field: "views" },
//     { name: "View Rate", field: "view_rate" },
//     { name: "Benchmark View Rate", field: "benchmark_view_rate" },
//     { name: "Frequency", field: "frequency" },
//     { name: "Reach", field: "reach" },
//     { name: "Reach Rate", field: "reach_rate" },
//     { name: "Engagement Rate", field: "engagement_rate" },
//   ];

//   let defaultSummaryTableHeaders = [
//     { name: "Name", field: "name" },
//     { name: "Objective", field: "objective" },
//     { name: "Impressions", field: "impressions" },
//     { name: "Clicks", field: "clicks" },
//     { name: "Spends", field: "spends" },
//     { name: "Conversions", field: "conversions" },
//     { name: "CTR", field: "ctr" },
//     { name: "CPM", field: "cpm" },
//     { name: "CPC", field: "cpc" },
//     { name: "CPV", field: "cpv" },
//     { name: "CR", field: "cr" },
//     { name: "Views", field: "views" },
//     { name: "Engagement Rate", field: "engagement_rate" },
//   ];

//   // CAMPAIGN SUMMARY DROPDOWN
//   // CAMPAIGN SUMMARY DROPDOWN OPTIONS
//   const campaignSummaryOptions = [
//     { value: "campaign", label: "Campaign" },
//     { value: "ad", label: "Ad" },
//     { value: "ad_group", label: "Ad group" },
//   ];

//   // CAMPAIGN SUMMARY DROPDOWN DEFAULT STATE
//   const [
//     selectedCampaignSummaryDropdownOption,
//     setSelectedCampaignSummaryDropdownOption,
//   ] = useState(campaignSummaryOptions[0]);

//   // CAMPAIGN SUMMARY DROPDOWN OPTIONS SELECTION HANDLER
//   const campaignSummaryDropdownSelectionHandler = (e) => {
//     if (e === null) {
//       setSelectedCampaignSummaryDropdownOption("");
//     } else {
//       setSelectedCampaignSummaryDropdownOption(e);
//     }
//     IAEvent_Dropdown_Visit(
//       "Marketing Intelligence",
//       "Campaigns",
//       "Facebook",
//       "Overall",
//       null,
//       "Summary",
//       e.label
//     );
//   };

//   // CHANGE CAMPAIGN SUMMARY DATA AS PER THE DROPDOWN VALUE
//   const fetchCampaignSummaryDropdownHandler = async () => {
//     if (selectedCampaignSummaryDropdownOption.value === "ad") {
//       setCampaignSummary(
//         campaignSummaryAllDropdownsData === undefined ||
//           campaignSummaryAllDropdownsData.length === 0
//           ? []
//           : campaignSummaryAllDropdownsData.ad === undefined ||
//             campaignSummaryAllDropdownsData.ad.length === 0
//             ? []
//             : campaignSummaryAllDropdownsData.ad
//       );
//     } else if (selectedCampaignSummaryDropdownOption.value === "ad_group") {
//       setCampaignSummary(
//         campaignSummaryAllDropdownsData === undefined ||
//           campaignSummaryAllDropdownsData.length === 0
//           ? []
//           : campaignSummaryAllDropdownsData.ad_group === undefined ||
//             campaignSummaryAllDropdownsData.ad_group.length === 0
//             ? []
//             : campaignSummaryAllDropdownsData.ad_group
//       );
//     } else if (selectedCampaignSummaryDropdownOption.value === "campaign") {
//       setCampaignSummary(
//         campaignSummaryAllDropdownsData === undefined ||
//           campaignSummaryAllDropdownsData.length === 0
//           ? []
//           : campaignSummaryAllDropdownsData.campaign === undefined ||
//             campaignSummaryAllDropdownsData.campaign.length === 0
//             ? []
//             : campaignSummaryAllDropdownsData.campaign
//       );
//     } else {
//       setCampaignSummary([]);
//     }
//   };

//   let campaignSummaryTableHeaders = [];

//   if (companyID === COKE_COMPANY_ID || companyID === HALEON_COMPANY_ID) {
//     if (selectedCampaignSummaryDropdownOption.value === "campaign") {
//       campaignSummaryTableHeaders = cokeCamapignSummaryTableHeaders;
//     } else {
//       campaignSummaryTableHeaders = cokeSummaryTableHeaders;
//     }
//   } else {
//     campaignSummaryTableHeaders = defaultSummaryTableHeaders;
//   }

//   // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
//   useEffect(() => {
//     fetchCampaignSummaryDropdownHandler();
//   }, [
//     BRAND_ID,
//     selectedDates,
//     selectedCampaignSummaryDropdownOption,
//     selectedBucketsFromFilter,
//     selectedCampaigns,
//     selectedObjectives,
//     selectedIdandPlatform,
//     selectedPlatform,
//   ]);

//   useEffect(() => {
//     (metricCards !== undefined || metricCards.length !== 0) &&
//       fetchReachMetricCardsHandler();
//   }, [
//     BRAND_ID,
//     metricCards,
//     selectedDates,
//     selectedBucketsFromFilter,
//     selectedObjectives,
//     selectedIdandPlatform,
//     selectedCampaigns,
//     selectedPlatform,
//   ]);

//   // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
//   useEffect(() => {
//     fetchMetricCardsHandler();
//     fetchDeviceDistributionHandler();
//     fetchAudienceDistributionHandler();
//     fetchCountryHandler();
//     fetchStateHandler();
//     fetchCampaignSummaryHandler();
//     fetchChildDonutCampaignHandler();
//     setSelectedCampaignSummaryDropdownOption(campaignSummaryOptions[0]); // TO SET THE SUMMARY TABLE DROPDOWN TO DEFAULT STATE
//   }, [
//     BRAND_ID,
//     selectedDates,
//     selectedBucketsFromFilter,
//     selectedObjectives,
//     selectedIdandPlatform,
//     selectedCampaigns,
//     selectedPlatform,
//   ]);

//   // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
//   const fetchMidSectionDropdownHandler = async () => {
//     if (selectedMidSectionDropdownOption.value === "clicks") {
//       setDeviceDistribution(
//         deviceDistributionAllDropdownsData.length === 0
//           ? []
//           : deviceDistributionAllDropdownsData.clicks
//       );
//       setMaleCount(
//         maleCountAllDropdownsData.length === 0
//           ? []
//           : maleCountAllDropdownsData.clicks
//       );
//       setMaleCountLabels(
//         maleCountAllDropdownsDataLabels.length === 0
//           ? []
//           : maleCountAllDropdownsDataLabels.clicks
//       );
//       setTotalMaleCount(
//         totalMaleCountAllDropdownsData.length === 0
//           ? []
//           : totalMaleCountAllDropdownsData.clicks
//       );
//       setFemaleCount(
//         femaleCountAllDropdownsData.length === 0
//           ? []
//           : femaleCountAllDropdownsData.clicks
//       );
//       setFemaleCountLabels(
//         femaleCountAllDropdownsDataLabels.length === 0
//           ? []
//           : femaleCountAllDropdownsDataLabels.clicks
//       );
//       setTotalFemaleCount(
//         totalFemaleCountAllDropdownsData.length === 0
//           ? []
//           : totalFemaleCountAllDropdownsData.clicks
//       );

//       if (companyID !== HALEON_COMPANY_ID) {
//         setCountryData(
//           countryAllDropdownsData.length === 0
//             ? []
//             : countryAllDropdownsData.clicks
//         );
//         setStateData(
//           stateAllDropdownsData.length === 0 ? [] : stateAllDropdownsData.clicks
//         );
//       }
//     } else if (selectedMidSectionDropdownOption.value === "conversions") {
//       setDeviceDistribution(
//         deviceDistributionAllDropdownsData.length === 0
//           ? []
//           : deviceDistributionAllDropdownsData.conversions
//       );
//       setMaleCount(
//         maleCountAllDropdownsData.length === 0
//           ? []
//           : maleCountAllDropdownsData.conversions
//       );
//       setMaleCountLabels(
//         maleCountAllDropdownsDataLabels.length === 0
//           ? []
//           : maleCountAllDropdownsDataLabels.conversions
//       );
//       setTotalMaleCount(
//         totalMaleCountAllDropdownsData.length === 0
//           ? []
//           : totalMaleCountAllDropdownsData.conversions
//       );
//       setFemaleCount(
//         femaleCountAllDropdownsData.length === 0
//           ? []
//           : femaleCountAllDropdownsData.conversions
//       );
//       setFemaleCountLabels(
//         femaleCountAllDropdownsDataLabels.length === 0
//           ? []
//           : femaleCountAllDropdownsDataLabels.conversions
//       );
//       setTotalFemaleCount(
//         totalFemaleCountAllDropdownsData.length === 0
//           ? []
//           : totalFemaleCountAllDropdownsData.conversions
//       );
//       if (companyID !== HALEON_COMPANY_ID) {
//         setCountryData(
//           countryAllDropdownsData.length === 0
//             ? []
//             : countryAllDropdownsData.conversions
//         );
//         setStateData(
//           stateAllDropdownsData.length === 0
//             ? []
//             : stateAllDropdownsData.conversions
//         );
//       }
//     } else if (selectedMidSectionDropdownOption.value === "engagements") {
//       setDeviceDistribution(
//         deviceDistributionAllDropdownsData.length === 0
//           ? []
//           : deviceDistributionAllDropdownsData.engagement
//       );
//       setMaleCount(
//         maleCountAllDropdownsData.length === 0
//           ? []
//           : maleCountAllDropdownsData.engagement
//       );
//       setMaleCountLabels(
//         maleCountAllDropdownsDataLabels.length === 0
//           ? []
//           : maleCountAllDropdownsDataLabels.engagement
//       );
//       setTotalMaleCount(
//         totalMaleCountAllDropdownsData.length === 0
//           ? []
//           : totalMaleCountAllDropdownsData.engagement
//       );
//       setFemaleCount(
//         femaleCountAllDropdownsData.length === 0
//           ? []
//           : femaleCountAllDropdownsData.engagement
//       );
//       setFemaleCountLabels(
//         femaleCountAllDropdownsDataLabels.length === 0
//           ? []
//           : femaleCountAllDropdownsDataLabels.engagement
//       );
//       setTotalFemaleCount(
//         totalFemaleCountAllDropdownsData.length === 0
//           ? []
//           : totalFemaleCountAllDropdownsData.engagement
//       );
//       if (companyID !== HALEON_COMPANY_ID) {
//         setCountryData(
//           countryAllDropdownsData.length === 0
//             ? []
//             : countryAllDropdownsData.engagement
//         );
//         setStateData(
//           stateAllDropdownsData.length === 0
//             ? []
//             : stateAllDropdownsData.engagement
//         );
//       }
//     } else if (selectedMidSectionDropdownOption.value === "impressions") {
//       setDeviceDistribution(
//         deviceDistributionAllDropdownsData.length === 0
//           ? []
//           : deviceDistributionAllDropdownsData.impressions
//       );
//       setMaleCount(
//         maleCountAllDropdownsData.length === 0
//           ? []
//           : maleCountAllDropdownsData.impressions
//       );
//       setMaleCountLabels(
//         maleCountAllDropdownsDataLabels.length === 0
//           ? []
//           : maleCountAllDropdownsDataLabels.impressions
//       );
//       setTotalMaleCount(
//         totalMaleCountAllDropdownsData.length === 0
//           ? []
//           : totalMaleCountAllDropdownsData.impressions
//       );
//       setFemaleCount(
//         femaleCountAllDropdownsData.length === 0
//           ? []
//           : femaleCountAllDropdownsData.impressions
//       );
//       setFemaleCountLabels(
//         femaleCountAllDropdownsDataLabels.length === 0
//           ? []
//           : femaleCountAllDropdownsDataLabels.impressions
//       );
//       setTotalFemaleCount(
//         totalFemaleCountAllDropdownsData.length === 0
//           ? []
//           : totalFemaleCountAllDropdownsData.impressions
//       );
//       if (companyID !== HALEON_COMPANY_ID) {
//         setCountryData(
//           countryAllDropdownsData.length === 0
//             ? []
//             : countryAllDropdownsData.impressions
//         );
//         setStateData(
//           stateAllDropdownsData.length === 0
//             ? []
//             : stateAllDropdownsData.impressions
//         );
//       }
//     } else if (selectedMidSectionDropdownOption.value === "spends") {
//       setDeviceDistribution(
//         deviceDistributionAllDropdownsData.length === 0
//           ? []
//           : deviceDistributionAllDropdownsData.spend
//       );
//       setMaleCount(
//         maleCountAllDropdownsData.length === 0
//           ? []
//           : maleCountAllDropdownsData.spend
//       );
//       setMaleCountLabels(
//         maleCountAllDropdownsDataLabels.length === 0
//           ? []
//           : maleCountAllDropdownsDataLabels.spend
//       );
//       setTotalMaleCount(
//         totalMaleCountAllDropdownsData.length === 0
//           ? []
//           : totalMaleCountAllDropdownsData.spend
//       );
//       setFemaleCount(
//         femaleCountAllDropdownsData.length === 0
//           ? []
//           : femaleCountAllDropdownsData.spend
//       );
//       setFemaleCountLabels(
//         femaleCountAllDropdownsDataLabels.length === 0
//           ? []
//           : femaleCountAllDropdownsDataLabels.spend
//       );
//       setTotalFemaleCount(
//         totalFemaleCountAllDropdownsData.length === 0
//           ? []
//           : totalFemaleCountAllDropdownsData.spend
//       );
//       if (companyID !== HALEON_COMPANY_ID) {
//         setCountryData(
//           countryAllDropdownsData.length === 0
//             ? []
//             : countryAllDropdownsData.spend
//         );
//         setStateData(
//           stateAllDropdownsData.length === 0 ? [] : stateAllDropdownsData.spend
//         );
//       }
//     } else {
//       setDeviceDistribution([]);
//       setMaleCount([]);
//       setMaleCountLabels([]);
//       setTotalMaleCount([]);
//       setFemaleCount([]);
//       setFemaleCountLabels([]);
//       setTotalFemaleCount([]);

//       if (companyID !== HALEON_COMPANY_ID) {
//         setCountryData([]);
//         setStateData([]);
//       }
//     }
//   };

//   // CHNAGE  DATA AS PER DROPWON VALUE
//   useEffect(() => {
//     fetchMidSectionDropdownHandler();
//   }, [
//     BRAND_ID,
//     selectedDates,
//     selectedMidSectionDropdownOption,
//     selectedBucketsFromFilter,
//     selectedCampaigns,
//     selectedObjectives,
//     selectedIdandPlatform,
//     selectedPlatform,
//   ]);

//   // PLANNED VS DELIVERED TABLE

//   /*  const plannedVsDeliveredTableHeaders = [
//      { name: "Campaign Name", field: "campaign_names" },
//      { name: "Start date", field: "start_date" },
//      { name: "End date", field: "end_date" },

//      { name: "Market/TG", field: "type" },
//      { name: "Markets(State)", field: "region" },

//      { name: "Planned Reach", field: "planned_reach" },
//      { name: "Delivered Reach", field: "delivered_reach" },
//      { name: "% achieved", field: "achieved_reach" },

//      { name: "Planned Impressions", field: "planned_impressions" },
//      { name: "Delivered Impressions", field: "delivered_impressions" },
//      { name: "% achieved", field: "achieved_impressions" },

//      { name: "Planned Frequency", field: "planned_frequency" },
//      { name: "Delivered Frequency", field: "delivered_frequency" },
//      { name: "% achieved", field: "achieved_frequency" },

//      { name: "Planned CTR %", field: "planned_ctr" },
//      { name: "Delivered CTR %", field: "delivered_ctr" },
//      { name: "% achieved", field: "achieved_ctr" },

//      { name: "Planned VTR %", field: "planned_vtr" },
//      { name: "Delivered VTR %", field: "delivered_vtr" },
//      { name: "% achieved", field: "achieved_vtr" },

//      { name: "Planned Spends", field: "planned_spends" },
//      { name: "Delivered Spends", field: "delivered_vtr" },
//      { name: "% achieved", field: "achieved_spend" },
//    ]; */




//   // PLANNED VS DELIVERED METRICS DATA
//   const [plannedVsDeliveredMetricsData, setPlannedVsDeliveredMetricsData] =
//     useState([]);
//   const [
//     plannedVsDeliveredMetricsDataLabels,
//     setPlannedVsDeliveredMetricsDataLabels,
//   ] = useState([]);

//   const [
//     isplannedVsDeliveredMetricsLoading,
//     setPlannedVsDeliveredMetricsIsLoading,
//   ] = useState(false);
//   const [
//     isplannedVsDeliveredMetricsLoaded,
//     setPlannedVsDeliveredMetricsIsLoaded,
//   ] = useState(false);
//   const [plannedVsDeliveredMetricsError, setPlannedVsDeliveredMetricsError] =
//     useState(null);

//   const [plannedVsDeliveredTableData, setPlannedVsDeliveredTableData] =
//     useState([]);
//   const [
//     plannedVsDeliveredTableDataHeaders,
//     setPlannedVsDeliveredTableDataHaeders,
//   ] = useState([]);
//   const [
//     isPlannedVsDeliveredTableDataLoading,
//     setPlannedVsDeliveredTableDataIsLoading,
//   ] = useState(false);
//   const [
//     isPlannedVsDeliveredTableDataLoaded,
//     setPlannedVsDeliveredTableDataIsLoaded,
//   ] = useState(false);
//   const [
//     plannedVsDeliveredTableDataerror,
//     setPlannedVsDeliveredTableDataError,
//   ] = useState(null);

//   let dropdownOptionsMarketTg = [
//     { value: "market", label: "Market" },
//     { value: "tg", label: "TG" },
//   ];

//   // DROPDOWN STATE
//   const [selectedMarketTgDropdownOption, setSelectedMarketTgDropdownOption] =
//     useState(dropdownOptionsMarketTg[0]);

//   // DROPDOWN OPTIONS SELECTION HANDLER
//   const dropdownMainSelectionHandler = (e) => {
//     if (e === null) {
//       setSelectedMarketTgDropdownOption("");
//     } else {
//       setSelectedMarketTgDropdownOption(e);
//     }
//   };


//   const [regionDropdownOptionsOnLoad, setRegionDropdownOptionsOnLoad] =
//     useState([]);
//   const [regionDropdownOptions, setRegionDropdownOptions] = useState([]);
//   const [regionDropdownOptionsIsLoading, setRegionDropdownOptionsIsLoading] =
//     useState(true);
//   const [regionDropdownOptionsIsLoaded, setRegionDropdownOptionsIsLoaded] =
//     useState(false);



//   const fetchRegionDropdownHandler = async () => {
//     setRegionDropdownOptionsIsLoading(true);
//     setRegionDropdownOptionsIsLoaded(false);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         //view_id: viewId,
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/facebook/country-region-filter/",
//         config
//       );

//       setRegionDropdownOptions(
//         res.data === undefined || res.data.length === 0 ? [] : res.data
//       );
//       setRegionDropdownOptionsOnLoad(
//         res.data === undefined || res.data.length === 0 ? [] : res.data
//       );
//       setSelectedRegionDropdownOption(
//         res.data === undefined || res.data.length === 0 ? [] : res.data[0]
//       );
//       setRegionDropdownOptionsIsLoading(false);
//       setRegionDropdownOptionsIsLoaded(true);



//     } catch (error) {
//       setRegionDropdownOptions([]);
//       setRegionDropdownOptionsIsLoading(false);
//       setRegionDropdownOptionsIsLoaded(false);

//       setPlannedVsDeliveredMetricsIsLoading(false);
//       setPlannedVsDeliveredMetricsIsLoaded(false);
//       setPlannedVsDeliveredMetricsError(error.code);

//       setPlannedVsDeliveredTableDataIsLoading(false);
//       setPlannedVsDeliveredTableDataIsLoaded(false);
//       setPlannedVsDeliveredTableDataError(error.code);


//     }
//   };

//   useEffect(() => {
//     fetchRegionDropdownHandler();
//   }, []);

//   // DROPDOWN STATE
//   const [selectedRegionDropdownOption, setSelectedRegionDropdownOption] =
//     useState([]);

//   // DROPDOWN OPTIONS SELECTION HANDLER
//   const dropdownRegionSelectionHandler = (e) => {
//     if (e === null) {
//       setSelectedRegionDropdownOption("");
//     } else {
//       setSelectedRegionDropdownOption(e);
//     }

//     IAEvent_Dropdown_Visit(
//       pageModule,
//       pageSubModule,
//       pagePlatform,
//       pagePrimaryTab,
//       null,
//       "PlannedVsDelivered",
//       e.label
//     );
//   };

//   // DROPDOWN STATE

//   // TO CHANGE DATA AS PER THE DROPDOWN VALUE
//   const fetchMarketTgDropdownHandler = () => {
//     if (selectedMarketTgDropdownOption.value === "market") {
//       setRegionDropdownOptionsOnLoad(regionDropdownOptions);
//       setSelectedRegionDropdownOption(regionDropdownOptions[0]);
//     } else if (selectedMarketTgDropdownOption.value === "tg") {
//       setRegionDropdownOptionsOnLoad([
//         { value: "all", label: "All" },
//         { value: "18-34 male", label: "18-34 Male" },
//       ]);
//       setSelectedRegionDropdownOption({ value: "all", label: "All" });
//     } else {
//       setRegionDropdownOptionsOnLoad([]);
//     }
//   };

//   // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
//   useEffect(() => {
//     fetchMarketTgDropdownHandler();
//   }, [
//     BRAND_ID,
//     selectedDates,
//     selectedBucketsFromFilter,
//     selectedObjectives,
//     selectedIdandPlatform,
//     selectedCampaigns,
//     selectedPlatform,
//     selectedMarketTgDropdownOption

//     /* selectedRegionDropdownOption, */
//     /* dropdownOptionsMarketTg, */
//   ]);

//   useEffect(() => {
//     if (selectedMarketTgDropdownOption.value === "market") {
//       setRegionDropdownOptionsOnLoad(regionDropdownOptions);
//       setSelectedRegionDropdownOption(regionDropdownOptions[0]);
//     } else if (selectedMarketTgDropdownOption.value === "tg") {
//       setRegionDropdownOptionsOnLoad([
//         { value: "all", label: "All" },
//         { value: "18-34 male", label: "18-34 Male" },
//       ]);
//       setSelectedRegionDropdownOption({ value: "all", label: "All" });
//     }
//   }, [BRAND_ID, selectedDates, selectedMarketTgDropdownOption]);
//   useEffect(() => {
//     if (!regionDropdownOptionsIsLoading && regionDropdownOptionsIsLoaded) {
//       fetchPlannedVsDeliveredMetricsHandler();
//       fetchPlannedVsDeliveredTableDataHandler();
//     }
//   }, [
//     regionDropdownOptionsIsLoaded,
//     regionDropdownOptionsIsLoading,
//     selectedRegionDropdownOption
//   ]);

//   useEffect(() => {
//     setSelectedMarketTgDropdownOption([]);
//     setSelectedRegionDropdownOption([]);
//     setSelectedMarketTgDropdownOption({ value: "market", label: "Market" });
//     setSelectedRegionDropdownOption(regionDropdownOptions[0]);
//   }, [BRAND_ID, selectedDates,

//   ]);


//   useEffect(() => {
//     setSelectedMarketTgDropdownOption({ value: "market", label: "Market" });
//     setSelectedRegionDropdownOption(regionDropdownOptions[0]);
//     if (selectedMarketTgDropdownOption.value === "market" && selectedRegionDropdownOption.value === "India") {
//       fetchPlannedVsDeliveredMetricsHandler();
//       fetchPlannedVsDeliveredTableDataHandler();
//     }
//   }, [selectedBucketsFromFilter,
//     selectedObjectives,
//     selectedIdandPlatform,
//     selectedCampaigns,
//     selectedPlatform]);

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // METRIC CARDS

//   // TO FETCH METRIC CARDS FROM API
//   const fetchMetricCardsHandler = async () => {
//     setMetricCardsIsLoading(true);
//     setMetricCardsIsLoaded(false);
//     setMetricCardsError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag:
//           selectedBucketsFromFilter.length === 0 ||
//             selectedBucketsFromFilter === undefined
//             ? null
//             : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get("performance/facebook/metric-card/", config);
//       setMetricCards(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.data === undefined || res.data.data.length === 0
//             ? []
//             : res.data.data
//       );
//       setMetricCardsIsLoading(false);
//       setMetricCardsIsLoaded(true);
//       setMetricCardsError(null);
//     } catch (error) {
//       setMetricCards([]);
//       setMetricCardsIsLoading(false);
//       setMetricCardsIsLoaded(false);
//       setMetricCardsError(error.code);
//     }
//   };

//   // TO FETCH REACH METRIC CARDS FROM API
//   const fetchReachMetricCardsHandler = async () => {
//     setReachMetricCardsIsLoading(true);
//     setReachMetricCardsIsLoaded(false);
//     setReachMetricCardsError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag:
//           selectedBucketsFromFilter.length === 0 ||
//             selectedBucketsFromFilter === undefined
//             ? null
//             : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/facebook/facebook-instagram-reach/",
//         config
//       );
//       setReachMetricCards(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.facebook === undefined || res.data.facebook.length === 0
//             ? []
//             : res.data.facebook.data === undefined ||
//               res.data.facebook.data.length === 0
//               ? []
//               : res.data.facebook.data
//       );
//       setReachMetricCardsIsLoading(false);
//       setReachMetricCardsIsLoaded(true);
//       setReachMetricCardsError(null);
//     } catch (error) {
//       setReachMetricCards([]);
//       setReachMetricCardsIsLoading(false);
//       setReachMetricCardsIsLoaded(false);
//       setReachMetricCardsError(error.code);
//     }
//   };

//   let static_data = [
//     {
//       label: "Reach",
//       currentValue: "loading",
//       previousValue: "loading",
//     },
//     {
//       label: "Reach Rate",
//       currentValue: "loading",
//       previousValue: "loading",
//     },
//     {
//       label: "Frequency",
//       currentValue: "loading",
//       previousValue: "loading",
//     },
//   ];

//   let error_static_data = [
//     {
//       label: "Reach",
//       currentValue: "error",
//       previousValue: "error",
//     },
//     {
//       label: "Reach Rate",
//       currentValue: "error",
//       previousValue: "error",
//     },
//     {
//       label: "Frequency",
//       currentValue: "error",
//       previousValue: "error",
//     },
//   ];

//   let combineMetricCardsData = useMemo(() => {
//     let temp = [];

//     if (isReachMetricCardsLoading && !isReachMetricCardsLoaded) {
//       temp = metricCards.concat(static_data);
//     } else if (reachMetricCardserror) {
//       temp = metricCards.concat(error_static_data);
//     } else {
//       temp = metricCards.concat(reachMetricCards);
//     }

//     return temp;
//   }, [
//     metricCards,
//     reachMetricCards,
//     isReachMetricCardsLoading,
//     isReachMetricCardsLoaded,
//   ]);

//   // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
//   let metricCardsSection = (
//     <Wrapper>
//       <div className="grid grid_cols_4 grid_margin_bottom">
//         <div className="grid col_span_4 section_header">
//           <div className="section_info">
//             <h2 className="section_card_title">Summary</h2>
//           </div>
//         </div>
//         <div className="grid col_span_4 grid_margin_bottom">
//           <Loader loaderType="cardLoader" />
//         </div>
//       </div>
//     </Wrapper>
//   );

//   // IF DATA IS LOADED
//   if (isMetricCardsLoaded && !isMetricCardsLoading) {
//     if (NoDataAvailableChecker(combineMetricCardsData)) {
//       metricCardsSection = (
//         <Wrapper>
//           <div className="grid grid_cols_4 grid_margin_bottom">
//             <div className="grid col_span_4 section_header">
//               <div className="section_info">
//                 {" "}
//                 <h2 className="section_card_title">Summary</h2>
//               </div>
//             </div>
//             <div className="grid col_span_4 grid_margin_bottom">
//               <NoDataAvailableLoader chartType="cardChartType" />
//             </div>
//           </div>
//         </Wrapper>
//       );
//     } else {
//       metricCardsSection = (
//         <Wrapper>
//           <ErrorBoundary chartType="cardChartType">
//             <Slider sliderTitle={"Summary"}>
//               {combineMetricCardsData.map((data, index) => (
//                 <SwiperSlide key={index}>
//                   <Card
//                     module="campaign"
//                     platform="facebook"
//                     section="overall"
//                     cardTitle={data.label}
//                     currentValue={data.currentValue}
//                     previousValue={data.previousValue}
//                     rowOneTitle={"Previous"}
//                     numberVariant={"number"}
//                   />
//                 </SwiperSlide>
//               ))}
//             </Slider>
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (metricCardserror) {
//     metricCardsSection = (
//       <Wrapper>
//         <div className="grid grid_cols_4 grid_margin_bottom">
//           <div className="grid col_span_4 section_header">
//             <div className="section_info">
//               {" "}
//               <h2 className="section_card_title">Summary</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4 grid_margin_bottom">
//             <ServerErrorsLoader
//               chartType="cardChartType"
//               error={metricCardserror}
//             />
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isMetricCardsLoading) {
//     metricCardsSection = (
//       <Wrapper>
//         <div className="grid grid_cols_4 grid_margin_bottom">
//           <div className="grid col_span_4 section_header">
//             <div className="section_info">
//               {" "}
//               <h2 className="section_card_title">Summary</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4 grid_margin_bottom">
//             <Loader loaderType="cardLoader" />
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (DONUT)

//   const fetchChildDonutCampaignHandler = async () => {
//     setChildDonutCampaignObjectiveLoading(true);
//     setChildDonutCampaignObjectiveLoaded(false);
//     setChildDonutCampaignObjectiveError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag:
//           selectedBucketsFromFilter.length === 0 ||
//             selectedBucketsFromFilter === undefined
//             ? null
//             : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/facebook/campaign-objective/",
//         config
//       );

//       setChildDonutCampaignObjective(
//         res.data === undefined || res.data.length === 0 ? [] : res.data
//       );
//       setChildDonutCampaignObjectiveLoading(false);
//       setChildDonutCampaignObjectiveLoaded(true);
//       setChildDonutCampaignObjectiveError(null);
//     } catch (error) {
//       setChildDonutCampaignObjective([]);
//       setChildDonutCampaignObjectiveLoading(false);
//       setChildDonutCampaignObjectiveLoaded(false);
//       setChildDonutCampaignObjectiveError(error.code);
//     }
//   };

//   let childDonutCampaignChart = (
//     <Wrapper>
//       <Loader loaderType="donutChartLoader" />
//     </Wrapper>
//   );

//   // IF DATA IS LOADED
//   if (
//     childDonutCampaignObjectiveLoaded &&
//     !childDonutCampaignObjectiveLoading
//   ) {
//     if (NoDataAvailableChecker(childDonutCampaignObjective)) {
//       childDonutCampaignChart = (
//         <Wrapper>
//           <NoDataAvailableLoader chartType="donutChartType" />
//         </Wrapper>
//       );
//     } else {
//       childDonutCampaignChart = (
//         <Wrapper>
//           <ErrorBoundary chartType="donutChartType">
//             <DonutPieChart
//               chartClass="section_card_chart"
//               chartData={childDonutCampaignObjective}
//               chartId="CampaignDonutChild"
//               onSliceClick={(data) => setChildDonutSliceToggle(data.object_key)}
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (childDonutCampaignObjectiveError) {
//     childDonutCampaignChart = (
//       <Wrapper>
//         <ServerErrorsLoader
//           chartType="donutChartType"
//           error={childDonutCampaignObjectiveError}
//         />
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (childDonutCampaignObjectiveLoading) {
//     childDonutCampaignChart = (
//       <Wrapper>
//         <Loader loaderType="donutChartLoader" />
//       </Wrapper>
//     );
//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (LINECHART)
//   const [isLineChartCampaignOpen, setIsLineChartCampaignOpen] = useState(false)

//   let defaultLineChartCampaignDisabledvalues = [];

//   if (companyID === COKE_COMPANY_ID) {
//     defaultLineChartCampaignDisabledvalues = []
//   } else {
//     defaultLineChartCampaignDisabledvalues = ["Impressions", "Clicks", "Spends", "Conversions"]
//   }

//   const [lineChartCampaignDisabledvalues, setLineChartCampaignDisabledvalues] = useState(defaultLineChartCampaignDisabledvalues)

//   const fetchLineChartCampaignHandler = async () => {
//     setLineChartCampaignObjectiveLoading(true);
//     setLineChartCampaignObjectiveLoaded(false);
//     setLineChartCampaignObjectiveError(null);

//     var objectiveConfig = (function () {
//       if (
//         selectedObjectives === null &&
//         (childDonutSliceToggle === null || childDonutSliceToggle === undefined)
//       ) {
//         return { objective: null };
//       } else if (
//         selectedObjectives !== null &&
//         (childDonutSliceToggle === undefined || childDonutSliceToggle === null)
//       ) {
//         return { objective: selectedObjectives };
//       } else {
//         return { objective: childDonutSliceToggle };
//       }
//     })();

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         // objective: selectedObjectives,
//         platform: selectedPlatform,
//         platform_campaign: selectedIdandPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag:
//           selectedBucketsFromFilter.length === 0 ||
//             selectedBucketsFromFilter === undefined
//             ? null
//             : selectedBucketsFromFilter.join(","),
//         // objective: childDonutSliceToggle,
//         ...objectiveConfig,
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/facebook/objective-linechart/",
//         config
//       );

//       setLineChartCampaignObjective(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.data === undefined || res.data.data.length === 0
//             ? []
//             : res.data.data
//       );
//       setLineChartCampaignObjectiveLabel(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.dimension === undefined || res.data.dimension.length === 0
//             ? []
//             : res.data.dimension
//       );
//       setLineChartCampaignObjectiveLoading(false);
//       setLineChartCampaignObjectiveLoaded(true);
//       setLineChartCampaignObjectiveError(null);
//     } catch (error) {
//       setLineChartCampaignObjective([]);
//       setLineChartCampaignObjectiveLabel([]);
//       setLineChartCampaignObjectiveLoading(false);
//       setLineChartCampaignObjectiveLoaded(false);
//       setLineChartCampaignObjectiveError(error.code);
//     }
//   };

//   let lineChartCampaignSection = (
//     <Wrapper>
//       <Loader loaderType="singleLineChartLoader" />
//     </Wrapper>
//   );

//   let lineChartCampaignSection2 = (
//     <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
//   );

//   // IF DATA IS LOADED
//   if (lineChartCampaignObjectiveLoaded && !lineChartCampaignObjectiveLoading) {
//     if (
//       NoDataAvailableChecker(lineChartCampaignObjective) ||
//       NoDataAvailableChecker(lineChartCampaignObjectiveLabel)
//     ) {
//       lineChartCampaignSection = (
//         <Wrapper>
//           <NoDataAvailableLoader chartType="lineChartType" />
//         </Wrapper>
//       );

//       lineChartCampaignSection2 = (
//         <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
//       );
//     } else {
//       if (isMultiLineCluster) {
//         lineChartCampaignSection = (
//           <Wrapper>
//             <ErrorBoundary chartType="lineChartType">
//               <LineChart
//                 chartClass="section_card_chart"
//                 chartData={lineChartCampaignObjective}
//                 chartId="multiLineChart"
//                 chartLabels={lineChartCampaignObjectiveLabel}
//                 onBulletsClick={function noRefCheck() { }}
//                 showLabels
//                 showLegend
//                 impressionLabel="impression"
//                 showImpression={companyID === HALEON_COMPANY_ID ? true : false}
//                 disabledLegendArray={lineChartCampaignDisabledvalues}
//                 setDisabledValues={setLineChartCampaignDisabledvalues}
//               />

//             </ErrorBoundary>
//           </Wrapper>
//         );

//         lineChartCampaignSection2 = (
//           <Wrapper>
//             <ErrorBoundary chartType="multiAxisMultiLineChartType">
//               <PartitionedLineChart
//                 chartClass="section_card_chart"
//                 chartData={lineChartCampaignObjective}
//                 chartId="multiLineChart_2"
//                 chartLabels={lineChartCampaignObjectiveLabel}
//                 showLegend={true}
//                 disabledLegendArray={lineChartCampaignDisabledvalues}
//                 setDisabledValues={setLineChartCampaignDisabledvalues}
//                 showLabels={true}

//               />
//             </ErrorBoundary>
//           </Wrapper>
//         );
//       } else {

//         lineChartCampaignSection = (
//           <Wrapper>
//             <ErrorBoundary chartType="lineChartType">
//               <LineChart
//                 chartClass="section_card_chart"
//                 chartData={lineChartCampaignObjective}
//                 chartId="multiLineChart"
//                 chartLabels={lineChartCampaignObjectiveLabel}
//                 onBulletsClick={function noRefCheck() { }}
//                 showLabels
//                 showLegend
//                 impressionLabel="impression"
//                 showImpression={companyID === HALEON_COMPANY_ID ? true : false}
//               />
//             </ErrorBoundary>
//           </Wrapper>
//         );
//       }
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (lineChartCampaignObjectiveError) {
//     lineChartCampaignSection = (
//       <Wrapper>
//         <ServerErrorsLoader
//           chartType="lineChartType"
//           error={lineChartCampaignObjectiveError}
//         />
//       </Wrapper>
//     );

//     lineChartCampaignSection2 = (
//       <Wrapper>
//         <ServerErrorsLoader
//           chartType="lineChartType"
//           error={lineChartCampaignObjectiveError}
//         />
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (lineChartCampaignObjectiveLoading) {
//     lineChartCampaignSection = (
//       <Wrapper>
//         <Loader loaderType="singleLineChartLoader" />
//       </Wrapper>
//     );

//     lineChartCampaignSection2 = (
//       <Wrapper>
//         <Loader loaderType="singleLineChartLoader" />
//       </Wrapper>
//     );
//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // DEVICE DISTRIBUTION

//   // TO FETCH DATA FROM API
//   const fetchDeviceDistributionHandler = async () => {
//     setDeviceDistributionIsLoading(true);
//     setDeviceDistributionIsLoaded(false);
//     setDeviceDistributionError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag:
//           selectedBucketsFromFilter.length === 0 ||
//             selectedBucketsFromFilter === undefined
//             ? null
//             : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/facebook/device-distribution/",
//         config
//       );
//       setDeviceDistribution(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.data === undefined || res.data.data.length === 0
//             ? []
//             : res.data.data.impressions === undefined ||
//               res.data.data.impressions.length === 0
//               ? []
//               : res.data.data.impressions
//       );
//       setDeviceDistributionAllDropdownsData(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.data === undefined || res.data.data.length === 0
//             ? []
//             : res.data.data
//       );
//       setDeviceDistributionIsLoading(false);
//       setDeviceDistributionIsLoaded(true);
//       setDeviceDistributionError(null);
//     } catch (error) {
//       setDeviceDistributionAllDropdownsData([]);
//       setDeviceDistributionIsLoading(false);
//       setDeviceDistributionIsLoaded(false);
//       setDeviceDistributionError(error.code);
//     }
//   };

//   // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
//   let deviceDistributionChart = (
//     <Wrapper>
//       <Loader loaderType="donutChartLoader" />
//     </Wrapper>
//   );

//   // IF DATA IS LOADED
//   if (isDeviceDistributionLoaded && !isDeviceDistributionLoading) {
//     if (NoDataAvailableChecker(deviceDistribution)) {
//       deviceDistributionChart = (
//         <Wrapper>
//           <NoDataAvailableLoader chartType="donutChartType" />
//         </Wrapper>
//       );
//     } else {
//       deviceDistributionChart = (
//         <DonutPieChart
//           chartId={"reactions_sentiment"}
//           chartClass={"section_card_chart"}
//           chartData={deviceDistribution}
//           showLegend={true}
//           showLabels={true}
//           showVerticalLabels={true}
//         />
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (deviceDistributionerror) {
//     deviceDistributionChart = (
//       <Wrapper>
//         <ServerErrorsLoader
//           chartType="donutChartType"
//           error={deviceDistributionerror}
//         />
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isDeviceDistributionLoading) {
//     deviceDistributionChart = (
//       <Wrapper>
//         <Loader loaderType="donutChartLoader" />
//       </Wrapper>
//     );
//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // AUDIENCE DISTRIBUTION: MALE / FEMALE

//   // TO FETCH MALE / FEMALE DATA FROM API
//   const fetchAudienceDistributionHandler = async () => {
//     setMaleCountIsLoading(true);
//     setMaleCountIsLoaded(false);
//     setMaleCountError(null);

//     setFemaleCountIsLoading(true);
//     setFemaleCountIsLoaded(false);
//     setFemaleCountError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag:
//           selectedBucketsFromFilter.length === 0 ||
//             selectedBucketsFromFilter === undefined
//             ? null
//             : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/facebook/audience-demographics/",
//         config
//       );

//       setMaleCount(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.male_list === undefined || res.data.male_list.length === 0
//             ? []
//             : res.data.male_list.data === undefined ||
//               res.data.male_list.data.length === 0
//               ? []
//               : res.data.male_list.data.impressions === undefined ||
//                 res.data.male_list.data.impressions.length === 0
//                 ? []
//                 : res.data.male_list.data.impressions
//       );
//       setMaleCountLabels(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.dimension === undefined || res.data.dimension.length === 0
//             ? []
//             : res.data.dimension.impressions === undefined ||
//               res.data.dimension.impressions.length === 0
//               ? []
//               : res.data.dimension.impressions
//       );
//       setTotalMaleCount(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.male_count === undefined ||
//             res.data.male_count.length === 0
//             ? []
//             : res.data.male_count.impressions === undefined ||
//               res.data.male_count.impressions.length === 0
//               ? []
//               : res.data.male_count.impressions
//       );
//       setFemaleCount(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.female_list === undefined ||
//             res.data.female_list.length === 0
//             ? []
//             : res.data.female_list.data.impressions === undefined ||
//               res.data.female_list.data.impressions.length === 0
//               ? []
//               : res.data.female_list.data.impressions
//       );
//       setFemaleCountLabels(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.dimension === undefined || res.data.dimension.length === 0
//             ? []
//             : res.data.dimension.impressions === undefined ||
//               res.data.dimension.impressions.length === 0
//               ? []
//               : res.data.dimension.impressions
//       );
//       setTotalFemaleCount(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.female_count === undefined ||
//             res.data.female_count.length === 0
//             ? []
//             : res.data.female_count.impressions === undefined ||
//               res.data.female_count.impressions.length === 0
//               ? []
//               : res.data.female_count.impressions
//       );

//       setMaleCountAllDropdownsData(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.male_list === undefined || res.data.male_list.length === 0
//             ? []
//             : res.data.male_list.data === undefined ||
//               res.data.male_list.data.length === 0
//               ? []
//               : res.data.male_list.data
//       );
//       setMaleCountAllDropdownsDataLabels(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.dimension === undefined || res.data.dimension.length === 0
//             ? []
//             : res.data.dimension
//       );
//       setTotalMaleCountAllDropdownsData(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.male_count === undefined ||
//             res.data.male_count.length === 0
//             ? []
//             : res.data.male_count
//       );
//       setMaleCountIsLoading(false);
//       setMaleCountIsLoaded(true);
//       setMaleCountError(null);

//       setFemaleCountAllDropdownsData(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.female_list === undefined ||
//             res.data.female_list.length === 0
//             ? []
//             : res.data.female_list.data === undefined ||
//               res.data.female_list.data.length === 0
//               ? []
//               : res.data.female_list.data
//       );
//       setFemaleCountAllDropdownsDataLabels(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.dimension === undefined || res.data.dimension.length === 0
//             ? []
//             : res.data.dimension
//       );
//       setTotalFemaleCountAllDropdownsData(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.female_count === undefined ||
//             res.data.female_count.length === 0
//             ? []
//             : res.data.female_count
//       );
//       setFemaleCountIsLoading(false);
//       setFemaleCountIsLoaded(true);
//       setFemaleCountError(null);
//     } catch (error) {
//       setMaleCountAllDropdownsData([]);
//       setMaleCountAllDropdownsDataLabels([]);
//       setTotalMaleCountAllDropdownsData([]);
//       setMaleCountIsLoading(false);
//       setMaleCountIsLoaded(false);
//       setMaleCountError(error.code);

//       setFemaleCountAllDropdownsData([]);
//       setFemaleCountAllDropdownsDataLabels([]);
//       setTotalFemaleCountAllDropdownsData([]);
//       setFemaleCountIsLoading(false);
//       setFemaleCountIsLoaded(false);
//       setFemaleCountError(error.code);
//     }
//   };

//   // AUDIENCE DISTRIBUTION: MALE
//   let maleAudienceDistribution = (
//     <Wrapper>
//       <Loader loaderType="barChartLoader" />
//     </Wrapper>
//   );
//   let maleAudienceDistributionIcon = (
//     <Wrapper>
//       <Loader loaderType="genderLoader" />
//     </Wrapper>
//   );

//   let totalMaleCountPercentage = 0;
//   if (isNaN(totalMaleCount / (totalMaleCount + totalFemaleCount)) === true) {
//     totalMaleCountPercentage = 0;
//   } else {
//     totalMaleCountPercentage =
//       totalMaleCount / (totalMaleCount + totalFemaleCount);
//   }

//   let totalFemaleCountPercentage = 0;
//   if (isNaN(totalFemaleCount / (totalMaleCount + totalFemaleCount)) === true) {
//     totalFemaleCountPercentage = 0;
//   } else {
//     totalFemaleCountPercentage =
//       totalFemaleCount / (totalMaleCount + totalFemaleCount);
//   }

//   // IF DATA IS AVAILABLE
//   if (isMaleCountLoaded && !isMaleCountLoading) {
//     if (
//       NoDataAvailableChecker(maleCount) ||
//       NoDataAvailableChecker(maleCountLabels)
//     ) {
//       maleAudienceDistribution = (
//         <Wrapper>
//           <NoDataAvailableLoader chartType="barChartType" />
//         </Wrapper>
//       );
//     } else {
//       maleAudienceDistribution = (
//         <Wrapper>
//           <ErrorBoundary>
//             <BarChart
//               chartId="audience_male"
//               chartClass="section_card_chart"
//               chartData={maleCount}
//               chartLabels={maleCountLabels}
//               showLegend={false}
//               /*  showLabels={true}
//                showHalfVerticalLabels={true}  */
//               showVerticalLabels={true}
//               minimumGridDistance={0}
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }

//     if (NoDataAvailableChecker(totalMaleCountPercentage)) {
//       maleAudienceDistributionIcon = (
//         <Wrapper>
//           <NoDataAvailableLoader chartType="genderChartType" />
//         </Wrapper>
//       );
//     } else {
//       maleAudienceDistributionIcon = (
//         <Wrapper>
//           <div className="section_icon flex column">
//             <ErrorBoundary chartType="genderChartType">
//               <MaleIcon percentage={totalMaleCountPercentage * 100} />
//             </ErrorBoundary>
//           </div>
//           <div className="">
//             <h3>
//               <ErrorBoundary>
//                 {+Math.abs(totalMaleCountPercentage * 100)
//                   .toFixed(2)
//                   .replace(/\.0$/, "") + "%"}
//               </ErrorBoundary>
//             </h3>
//           </div>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (maleCounterror) {
//     maleAudienceDistribution = (
//       <Wrapper>
//         {" "}
//         <ServerErrorsLoader chartType="barChartType" error={maleCounterror} />
//       </Wrapper>
//     );
//     maleAudienceDistributionIcon = (
//       <Wrapper>
//         <ServerErrorsLoader
//           chartType="genderChartType"
//           error={maleCounterror}
//         />
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isMaleCountLoading) {
//     maleAudienceDistribution = (
//       <Wrapper>
//         <Loader loaderType="barChartLoader" />
//       </Wrapper>
//     );
//     maleAudienceDistributionIcon = (
//       <Wrapper>
//         <Loader loaderType="genderLoader" />
//       </Wrapper>
//     );
//   }

//   // AUDIENCE DISTRIBUTION: FEMALE
//   let femaleAudienceDistribution = (
//     <Wrapper>
//       <Loader loaderType="barChartLoader" />
//     </Wrapper>
//   );
//   let femaleAudienceDistributionIocn = (
//     <Wrapper>
//       <Loader loaderType="genderLoader" />
//     </Wrapper>
//   );

//   // IF DATA IS AVAILABLE
//   if (isFemaleCountLoaded && !isFemaleCountLoading) {
//     if (
//       NoDataAvailableChecker(femaleCount) ||
//       NoDataAvailableChecker(femaleCountLabels)
//     ) {
//       femaleAudienceDistribution = (
//         <Wrapper>
//           <NoDataAvailableLoader chartType="barChartType" />
//         </Wrapper>
//       );
//     } else {
//       femaleAudienceDistribution = (
//         <Wrapper>
//           <ErrorBoundary chartType="barChartType">
//             <BarChart
//               chartId="audience_female"
//               chartClass="section_card_chart"
//               chartData={femaleCount}
//               chartLabels={femaleCountLabels}
//               showLegend={false}
//               /*  showLabels={true}
//                showHalfVerticalLabels={true}  */
//               showVerticalLabels={true}
//               minimumGridDistance={0}
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }

//     if (NoDataAvailableChecker(totalMaleCountPercentage)) {
//       femaleAudienceDistributionIocn = (
//         <Wrapper>
//           <NoDataAvailableLoader chartType="genderChartType" />
//         </Wrapper>
//       );
//     } else {
//       femaleAudienceDistributionIocn = (
//         <Wrapper>
//           <div className="section_icon flex column">
//             <ErrorBoundary chartType="genderChartType">
//               <FemaleIcon percentage={totalFemaleCountPercentage * 100} />
//             </ErrorBoundary>
//           </div>
//           <div className="">
//             <h3>
//               <ErrorBoundary>
//                 {+Math.abs(totalFemaleCountPercentage * 100)
//                   .toFixed(2)
//                   .replace(/\.0$/, "") + "%"}
//               </ErrorBoundary>
//             </h3>
//           </div>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (femaleCounterror) {
//     femaleAudienceDistribution = (
//       <Wrapper>
//         <ServerErrorsLoader chartType="barChartType" error={femaleCounterror} />
//       </Wrapper>
//     );
//     femaleAudienceDistributionIocn = (
//       <Wrapper>
//         <ServerErrorsLoader
//           chartType="genderChartType"
//           error={femaleCounterror}
//         />
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isFemaleCountLoading) {
//     femaleAudienceDistribution = (
//       <Wrapper>
//         <Loader loaderType="barChartLoader" />
//       </Wrapper>
//     );
//     femaleAudienceDistributionIocn = (
//       <Wrapper>
//         <Loader loaderType="genderLoader" />
//       </Wrapper>
//     );
//   }

//   const tabData = ["By Country", "By State"];
//   const [selectedTab, setSelectedTab] = useState(tabData[0]);
//   const handleCallback = (event) => {
//     if (selectedTab !== event) {
//       setSelectedTab(event);
//     }
//     IAEvent_Secondary_Tab_Visit(
//       "Marketing Intelligence",
//       "Campaign",
//       "Facebook",
//       "Overall",
//       event
//     );
//   };

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // COUNTRY MAP

//   const fetchCountryHandler = async () => {
//     setCountryDataIsLoading(true);
//     setCountryDataIsLoaded(false);
//     setCountryDataError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag:
//           selectedBucketsFromFilter.length === 0 ||
//             selectedBucketsFromFilter === undefined
//             ? null
//             : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     let apiURL;
//     if (companyID === HALEON_COMPANY_ID) {
//       apiURL = "performance/facebook/performance-table-by-country/";
//     } else {
//       apiURL = "performance/facebook/audience-country/";
//     }

//     try {
//       const res = await axios.get(apiURL, config);

//       if (companyID === HALEON_COMPANY_ID) {
//         setCountryData(
//           res.data === undefined || res.data.length === 0
//             ? []
//             : res.data.summary === undefined || res.data.summary.length === 0
//               ? []
//               : res.data.summary
//         );
//       } else {
//         setCountryData(
//           res.data === undefined || res.data.length === 0
//             ? []
//             : res.data.data === undefined || res.data.data.length === 0
//               ? []
//               : res.data.data.impressions === undefined ||
//                 res.data.data.impressions.length === 0
//                 ? []
//                 : res.data.data.impressions
//         );

//         setCountryAllDropdownsData(
//           res.data === undefined || res.data.length === 0
//             ? []
//             : res.data.data === undefined || res.data.data.length === 0
//               ? []
//               : res.data.data
//         );
//       }

//       setCountryDataIsLoading(false);
//       setCountryDataIsLoaded(true);
//       setCountryDataError(null);
//     } catch (error) {
//       /* setCountryAllDropdownsData([]); */
//       setCountryDataIsLoading(false);
//       setCountryDataIsLoaded(false);
//       setCountryDataError(error.code);
//     }
//   };

//   // AUDIENCE DISTRIBUTION: MALE
//   let countryMap = (
//     <Wrapper>
//       <div className="grid grid_cols_4 fullpage_section_card ">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <h2 className="section_card_title">By Country</h2>
//           </div>
//         </div>
//         <div className="grid col_span_4">
//           <div className="section_card_body">
//             <Loader loaderType="smallTableLoader" />
//           </div>
//         </div>
//       </div>
//     </Wrapper>
//   );

//   // IF DATA IS AVAILABLE
//   if (isCountryDataLoaded && !isCountryDataLoading) {
//     if (
//       NoDataAvailableChecker(countryData) ||
//       NoDataAvailableChecker(CountryTableDataHeaders)
//     ) {
//       countryMap = (
//         <Wrapper>
//           <div className="grid grid_cols_4 fullpage_section_card ">
//             <div className="grid col_span_4">
//               <div className="section_card_header">
//                 <h2 className="section_card_title">By Country</h2>
//               </div>
//             </div>
//             <div className="grid col_span_4">
//               <div className="section_card_body">
//                 <NoDataAvailableLoader chartType="tableChartType" />
//               </div>
//             </div>
//           </div>
//         </Wrapper>
//       );
//     } else {
//       countryMap = (
//         <Wrapper>
//           <ErrorBoundary chartType="tableChartType">
//             <Datatable
//               tableTitle="By Country"
//               /*  isHalfSection={true} */
//               isCountryFlagVisible={true}
//               tableHeader={CountryTableDataHeaders}
//               tableData={countryData}
//               tableLength={5}
//               isFooterShow={true}
//               searchPlaceHolder={"Search Country"}
//               tableID={"audience_distribution_country"}
//               tableExportDropdown={
//                 <ExportDropdown
//                   moduleName={pageModule}
//                   subModuleName={pageSubModule}
//                   platform={pagePlatform}
//                   tab={pagePrimaryTab}
//                   sectionId={"audience_distribution"}
//                   sectionName={`${companyID === HALEON_COMPANY_ID
//                     ? "HaleonCountryTable"
//                     : "CountryTableDynaLabels"
//                     }_Audience Distribution - Country_${selectedMidSectionDropdownOption.label
//                     }`}
//                   sectionData={countryData}
//                 />
//               }
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (countryDataerror) {
//     countryMap = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card ">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">By Country</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <ServerErrorsLoader
//                 chartType="tableChartType"
//                 error={countryDataerror}
//               />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isCountryDataLoading) {
//     countryMap = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">By Country</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <Loader loaderType="smallTableLoader" />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // STATE TABLE

//   const fetchStateHandler = async () => {
//     setStateDataIsLoading(true);
//     setStateDataIsLoaded(false);
//     setStateDataError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag:
//           selectedBucketsFromFilter.length === 0 ||
//             selectedBucketsFromFilter === undefined
//             ? null
//             : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     let apiURL;
//     if (companyID === HALEON_COMPANY_ID) {
//       apiURL = "performance/facebook/performance-table-by-region/";
//     } else {
//       apiURL = "performance/facebook/audience-region/";
//     }

//     try {
//       const res = await axios.get(apiURL, config);

//       if (companyID === HALEON_COMPANY_ID) {
//         setStateData(
//           res.data === undefined || res.data.length === 0
//             ? []
//             : res.data.summary === undefined || res.data.summary.length === 0
//               ? []
//               : res.data.summary
//         );
//       } else {
//         setStateData(
//           res.data === undefined || res.data.length === 0
//             ? []
//             : res.data.data === undefined || res.data.data.length === 0
//               ? []
//               : res.data.data.impressions === undefined ||
//                 res.data.data.impressions.length === 0
//                 ? []
//                 : res.data.data.impressions
//         );

//         setStateAllDropdownsData(
//           res.data === undefined || res.data.length === 0
//             ? []
//             : res.data.data === undefined || res.data.data.length === 0
//               ? []
//               : res.data.data
//         );
//       }

//       setStateDataIsLoading(false);
//       setStateDataIsLoaded(true);
//       setStateDataError(null);
//     } catch (error) {
//       /*  setStateAllDropdownsData([]); */
//       setStateDataIsLoading(false);
//       setStateDataIsLoaded(false);
//       setStateDataError(error.code);
//     }
//   };

//   // AUDIENCE DISTRIBUTION: MALE
//   let stateTable = (
//     <Wrapper>
//       <div className="grid grid_cols_4 fullpage_section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <h2 className="section_card_title">By State</h2>
//           </div>
//         </div>
//         <div className="grid col_span_4">
//           <div className="section_card_body">
//             <Loader loaderType="smallTableLoader" />
//           </div>
//         </div>
//       </div>
//     </Wrapper>
//   );

//   // IF DATA IS AVAILABLE
//   if (isStateDataLoaded && !isStateDataLoading) {
//     if (
//       NoDataAvailableChecker(stateData) ||
//       NoDataAvailableChecker(StateTableDataHeaders)
//     ) {
//       stateTable = (
//         <Wrapper>
//           <div className="grid grid_cols_4 fullpage_section_card ">
//             <div className="grid col_span_4">
//               <div className="section_card_header">
//                 <h2 className="section_card_title">By State</h2>
//               </div>
//             </div>
//             <div className="grid col_span_4">
//               <div className="section_card_body">
//                 <NoDataAvailableLoader chartType="tableChartType" />
//               </div>
//             </div>
//           </div>
//         </Wrapper>
//       );
//     } else {
//       stateTable = (
//         <Wrapper>
//           <ErrorBoundary chartType="tableChartType">
//             <Datatable
//               tableTitle="By State"
//               /*  isHalfSection={true} */
//               isDynamicFlagVisible={true}
//               tableHeader={StateTableDataHeaders}
//               tableData={stateData}
//               tableLength={5}
//               isFooterShow={true}
//               searchPlaceHolder={"Search State"}
//               tableID={"audience_distribution_state"}
//               tableExportDropdown={
//                 <ExportDropdown
//                   moduleName={pageModule}
//                   subModuleName={pageSubModule}
//                   platform={pagePlatform}
//                   tab={pagePrimaryTab}
//                   sectionId={"audience_distribution_state"}
//                   sectionName={`${companyID === HALEON_COMPANY_ID
//                     ? "HaleonStateTable"
//                     : "StateTableDynaLabels"
//                     }_Audience Distribution - State_${selectedMidSectionDropdownOption.label
//                     }`}
//                   sectionData={stateData}
//                 />
//               }
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (stateDataerror) {
//     stateTable = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card ">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">By State</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <ServerErrorsLoader
//                 chartType="tableChartType"
//                 error={stateDataerror}
//               />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isStateDataLoading) {
//     stateTable = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">By State</h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <Loader loaderType="smallTableLoader" />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // CAMPAIGN SUMMARY

//   // TO FETCH METRIC CARDS FROM API
//   const fetchCampaignSummaryHandler = async () => {
//     setCampaignSummaryIsLoading(true);
//     setCampaignSummaryIsLoaded(false);
//     setCampaignSummaryError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag:
//           selectedBucketsFromFilter.length === 0 ||
//             selectedBucketsFromFilter === undefined
//             ? null
//             : selectedBucketsFromFilter.join(","),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/facebook/performance-table/",
//         config
//       );

//       setCampaignSummary(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.campaign === undefined || res.data.campaign.length === 0
//             ? []
//             : res.data.campaign
//       );
//       setCampaignSummaryAllDropdownsData(
//         res.data === undefined || res.data.length === 0 ? [] : res.data
//       );
//       setCampaignSummaryIsLoading(false);
//       setCampaignSummaryIsLoaded(true);
//       setCampaignSummaryError(null);
//     } catch (error) {
//       setCampaignSummaryAllDropdownsData([]);
//       setCampaignSummaryIsLoading(false);
//       setCampaignSummaryIsLoaded(false);
//       setCampaignSummaryError(error.code);
//     }
//   };

//   let campaignIdsTemp = [];

//   // EXTRACT THE CAMPAIGN IDS FROM THE CAMPAIGN SUMMARY TABLE
//   const campaignIdsFromTable = useMemo(() => {
//     // TO GET THE UNIQUE CAMPAIGN IDS FROM THE SUMMARY TABLE
//     if (
//       campaignSummary === undefined ||
//       campaignSummary === null
//     )
//       return [];
//     campaignSummary.map((data) => {
//       campaignIdsTemp.push(data.id);
//     });

//     // JOIN THE LIST OF ARRAY OF CAMPAIGN IDS
//     return campaignIdsTemp.join(",");
//   }, [campaignSummary]); // UPDATES ONLY WHEN SUMMARY TABLE IS CHANGED

//   useEffect(() => {
//     campaignIdsFromTable.length !== 0 &&
//       selectedCampaignSummaryDropdownOption.value === "campaign" &&
//       (companyID === COKE_COMPANY_ID || companyID === HALEON_COMPANY_ID) &&
//       fetchReachCampaignSummaryHandler();
//   }, [campaignIdsFromTable]);

//   // TO FETCH METRIC CARDS FROM API
//   const fetchReachCampaignSummaryHandler = async () => {
//     setReachCampaignSummaryIsLoading(true);
//     setReachCampaignSummaryIsLoaded(false);
//     setReachCampaignSummaryError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag:
//           selectedBucketsFromFilter.length === 0 ||
//             selectedBucketsFromFilter === undefined
//             ? null
//             : selectedBucketsFromFilter.join(","),
//         campaign: campaignIdsFromTable,
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/facebook/facebook-instagram-campaign-reach/",
//         config
//       );

//       setReachCampaignSummary(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.facebook === undefined || res.data.facebook.length === 0
//             ? []
//             : res.data.facebook
//       );
//       setReachCampaignSummaryIsLoading(false);
//       setReachCampaignSummaryIsLoaded(true);
//       setReachCampaignSummaryError(null);
//     } catch (error) {
//       setReachCampaignSummary([]);
//       setReachCampaignSummaryIsLoading(false);
//       setReachCampaignSummaryIsLoaded(false);
//       setReachCampaignSummaryError(error.code);
//     }
//   };

//   campaignSummary.map((data, dataIndex) => {
//     if (isReachCampaignSummaryLoading && !isReachCampaignSummaryLoaded) {
//       campaignSummary[dataIndex].reach_rate = "loading";
//       campaignSummary[dataIndex].frequency = "loading";
//       campaignSummary[dataIndex]["reach"] = "loading";
//     } else if (reachCampaignSummaryerror) {
//       campaignSummary[dataIndex].reach_rate = "error";
//       campaignSummary[dataIndex].frequency = "error";
//       campaignSummary[dataIndex]["reach"] = "error";
//     } else {
//       if (reachCampaignSummary[data.id] !== undefined) {
//         campaignSummary[dataIndex].reach_rate =
//           reachCampaignSummary[data.id].reach_rate;
//         campaignSummary[dataIndex].frequency =
//           reachCampaignSummary[data.id].frequency;
//         campaignSummary[dataIndex]["reach"] =
//           reachCampaignSummary[data.id].reach;
//       } else {
//         campaignSummary[dataIndex].reach_rate = "error";
//         campaignSummary[dataIndex].frequency = "error";
//         campaignSummary[dataIndex]["reach"] = "error";
//       }
//     }
//   });

//   let campaignSummaryTable = (
//     <Wrapper>
//       <div className="grid grid_cols_4 fullpage_section_card section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <h2 className="section_card_title">
//               Summary
//               <SectionDefinationsBadge
//                 title={"Summary"}
//                 module={pageSubModule}
//                 platform={pagePlatform}
//                 section={pagePrimaryTab}
//               />
//             </h2>
//           </div>
//         </div>
//         <div className="grid col_span_4">
//           <div className="section_card_body">
//             <Loader loaderType="smallTableLoader" />
//           </div>
//         </div>
//       </div>
//     </Wrapper>
//   );

//   // let TableHeader = [];

//   // IF DATA IS LOADED
//   if (isCampaignSummaryLoaded && !isCampaignSummaryLoading) {
//     if (
//       NoDataAvailableChecker(campaignSummary) ||
//       NoDataAvailableChecker(campaignSummaryTableHeaders)
//     ) {
//       campaignSummaryTable = (
//         <Wrapper>
//           <div className="grid grid_cols_4 fullpage_section_card section_card">
//             <div className="grid col_span_4">
//               <div className="section_card_header">
//                 <h2 className="section_card_title">
//                   Summary
//                   <SectionDefinationsBadge
//                     title={"Summary"}
//                     module={pageSubModule}
//                     platform={pagePlatform}
//                     section={pagePrimaryTab}
//                   />
//                 </h2>
//               </div>
//             </div>
//             <div className="grid col_span_4">
//               <div className="section_card_body">
//                 <NoDataAvailableLoader chartType="tableChartType" />
//               </div>
//             </div>
//           </div>
//         </Wrapper>
//       );
//     } else {
//       campaignSummaryTable = (
//         <Wrapper>
//           <ErrorBoundary chartType="tableChartType">
//             <Datatable
//               tableTitle="Summary"
//               tablePagePlatform={pagePlatform}
//               tablePageSubModule={pageSubModule}
//               tablePagePrimaryTab={pagePrimaryTab}
//               tableHeader={campaignSummaryTableHeaders}
//               tableData={campaignSummary}
//               tableLength={10}
//               isFooterShow={true}
//               searchPlaceHolder={"Search Campaign"}
//               tableDropdown={
//                 <Dropdown
//                   ismulti={false}
//                   placeholder={"Select"}
//                   options={campaignSummaryOptions}
//                   className="form_dropdown section_dropdown"
//                   value={selectedCampaignSummaryDropdownOption}
//                   selectedOptions={selectedCampaignSummaryDropdownOption}
//                   setStatedropdown={campaignSummaryDropdownSelectionHandler}
//                 />
//               }
//               tableID={"search_campaign_section"}
//               tableExportDropdown={
//                 <ExportDropdown
//                   sectionId={"search_campaign_section"}
//                   moduleName={pageModule}
//                   subModuleName={pageSubModule}
//                   platform={pagePlatform}
//                   tab={pagePrimaryTab}
//                   sectionName={`${companyID === COKE_COMPANY_ID ||
//                     companyID === HALEON_COMPANY_ID
//                     ? "CampaignTableCokeHaleon"
//                     : "CampaignTableDynaLabels"
//                     }_Summary Table_${selectedCampaignSummaryDropdownOption.label.toLowerCase()}`}
//                   sectionData={campaignSummary}
//                 />
//               }
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (campaignSummaryerror) {
//     campaignSummaryTable = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">
//                 Summary
//                 <SectionDefinationsBadge
//                   title={"Summary"}
//                   module={pageSubModule}
//                   platform={pagePlatform}
//                   section={pagePrimaryTab}
//                 />
//               </h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <ServerErrorsLoader
//                 chartType="tableChartType"
//                 error={campaignSummaryerror}
//               />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isCampaignSummaryLoading) {
//     campaignSummaryTable = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <h2 className="section_card_title">
//                 Summary
//                 <SectionDefinationsBadge
//                   title={"Summary"}
//                   module={pageSubModule}
//                   platform={pagePlatform}
//                   section={pagePrimaryTab}
//                 />
//               </h2>
//             </div>
//           </div>
//           <div className="grid col_span_4">
//             <div className="section_card_body">
//               <Loader loaderType="smallTableLoader" />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   useEffect(() => {
//     fetchLineChartCampaignHandler();
//   }, [
//     BRAND_ID,
//     selectedDates,
//     childDonutSliceToggle,
//     selectedBucketsFromFilter,
//     selectedCampaigns,
//     selectedObjectives,
//     selectedIdandPlatform,
//     selectedPlatform,
//   ]);

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // PLANNED VS METRICS

//   // TO FETCH METRIC DATA FROM API
//   const fetchPlannedVsDeliveredMetricsHandler = async () => {
//     setPlannedVsDeliveredMetricsIsLoading(true);
//     setPlannedVsDeliveredMetricsIsLoaded(false);
//     setPlannedVsDeliveredMetricsError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag:
//           selectedBucketsFromFilter.length === 0 ||
//             selectedBucketsFromFilter === undefined
//             ? null
//             : selectedBucketsFromFilter.join(","),
//         drop_down_type:
//           selectedMarketTgDropdownOption === undefined ||
//             selectedMarketTgDropdownOption === null ||
//             selectedMarketTgDropdownOption === ""
//             ? ""
//             : selectedMarketTgDropdownOption.value.toLowerCase(),

//         drop_down_value:
//           selectedRegionDropdownOption === undefined ||
//             selectedRegionDropdownOption === null ||
//             selectedRegionDropdownOption === ""
//             ? ""
//             : selectedRegionDropdownOption.value.toLowerCase(),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };

//     try {
//       const res = await axios.get(
//         "performance/facebook/planned-delivered-wise-performance/v2/",
//         config
//       );

//       setPlannedVsDeliveredMetricsData(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.data === undefined || res.data.data.length === 0
//             ? []
//             : res.data.data
//       );
//       setPlannedVsDeliveredMetricsDataLabels(
//         res.data === undefined || res.data.length === 0
//           ? []
//           : res.data.label === undefined || res.data.label.length === 0
//             ? []
//             : res.data.label
//       );

//       setPlannedVsDeliveredMetricsIsLoading(false);
//       setPlannedVsDeliveredMetricsIsLoaded(true);
//       setPlannedVsDeliveredMetricsError(null);
//     } catch (error) {
//       setPlannedVsDeliveredMetricsData([]);
//       setPlannedVsDeliveredMetricsDataLabels([]);
//       setPlannedVsDeliveredMetricsIsLoading(false);
//       setPlannedVsDeliveredMetricsIsLoaded(false);
//       setPlannedVsDeliveredMetricsError(error.code);
//     }
//   };

//   let plannedVsDeliveredMetricsBarChart = (
//     <Wrapper>
//       <Loader loaderType="barChartLoader" />
//     </Wrapper>
//   );

//   // IF DATA IS AVAILABLE
//   if (
//     isplannedVsDeliveredMetricsLoaded &&
//     !isplannedVsDeliveredMetricsLoading
//   ) {
//     if (
//       NoDataAvailableChecker(plannedVsDeliveredMetricsData) ||
//       NoDataAvailableChecker(plannedVsDeliveredMetricsDataLabels)
//     ) {
//       plannedVsDeliveredMetricsBarChart = (
//         <Wrapper>
//           <NoDataAvailableLoader chartType="barChartType" />
//         </Wrapper>
//       );
//     } else {
//       plannedVsDeliveredMetricsBarChart = (
//         <Wrapper>
//           <ErrorBoundary chartType="barChartType">
//             <BarChart
//               chartId="plannedDeliveredBarChart"
//               chartClass="section_card_chart"
//               chartData={plannedVsDeliveredMetricsData}
//               chartLabels={plannedVsDeliveredMetricsDataLabels}
//               chartColorSet={BlueColorStart500}
//               showLabels={true}
//               minimumGridDistance={0}
//               showLegend={false}
//               percentageTooltip={true}
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (plannedVsDeliveredMetricsError) {
//     plannedVsDeliveredMetricsBarChart = (
//       <Wrapper>
//         <ServerErrorsLoader
//           chartType="barChartType"
//           error={plannedVsDeliveredMetricsError}
//         />
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isplannedVsDeliveredMetricsLoading) {
//     plannedVsDeliveredMetricsBarChart = (
//       <Wrapper>
//         <Loader loaderType="barChartLoader" />
//       </Wrapper>
//     );
//   }

//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // -----------------------------------------------------
//   // TABLE

//   // TO FETCH METRIC  FROM API
//   const fetchPlannedVsDeliveredTableDataHandler = async () => {
//     setPlannedVsDeliveredTableDataIsLoading(true);
//     setPlannedVsDeliveredTableDataIsLoaded(false);
//     setPlannedVsDeliveredTableDataError(null);

//     const config = {
//       params: {
//         brand_id: BRAND_ID,
//         start_date: selectedDates.startDate,
//         end_date: selectedDates.endDate,
//         previous_start_date: selectedDates.prevStartDate,
//         previous_end_date: selectedDates.prevEndDate,
//         objective: selectedObjectives,
//         platform_campaign: selectedIdandPlatform,
//         platform: selectedPlatform,
//         campaign: selectedCampaigns,
//         campaign_tag:
//           selectedBucketsFromFilter.length === 0 ||
//             selectedBucketsFromFilter === undefined
//             ? null
//             : selectedBucketsFromFilter.join(","),
//         drop_down_type:
//           selectedMarketTgDropdownOption === undefined ||
//             selectedMarketTgDropdownOption === null ||
//             selectedMarketTgDropdownOption === ""
//             ? ""
//             : selectedMarketTgDropdownOption.value.toLowerCase(),

//         drop_down_value:
//           selectedRegionDropdownOption === undefined ||
//             selectedRegionDropdownOption === null ||
//             selectedRegionDropdownOption === ""
//             ? ""
//             : selectedRegionDropdownOption.value.toLowerCase(),
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + authCtx.token,
//       },
//     };


//     try {
//       const res = await axios.get(
//         "performance/facebook/planned-delivered-table/",
//         config
//       );
//       setPlannedVsDeliveredTableData(
//         Object.values(res.data).length === 0 ||
//           res.data === undefined ||
//           res.data.length === 0
//           ? []
//           : res.data.data === undefined || res.data.data.length === 0
//             ? []
//             : res.data.data
//       );
//       setPlannedVsDeliveredTableDataHaeders(
//         Object.values(res.data).length === 0 ||
//           res.data === undefined ||
//           res.data.length === 0
//           ? []
//           : res.data.label === undefined || res.data.label.length === 0
//             ? []
//             : res.data.label
//       );

//       setPlannedVsDeliveredTableDataIsLoading(false);
//       setPlannedVsDeliveredTableDataIsLoaded(true);
//       setPlannedVsDeliveredTableDataError(null);
//     } catch (error) {
//       setPlannedVsDeliveredTableData([]);
//       setPlannedVsDeliveredTableDataHaeders([]);
//       setPlannedVsDeliveredTableDataIsLoading(false);
//       setPlannedVsDeliveredTableDataIsLoaded(false);
//       setPlannedVsDeliveredTableDataError(error.code);
//     }
//   };

//   let plannedVsDeliveredTable = (
//     <Wrapper>
//       <div className="grid grid_cols_4 fullpage_section_card section_card">
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <div className="section_info">
//               <h2 className="section_card_title">
//                 Campaign wise Planned v/s Delivered table
//                 {selectedMarketTgDropdownOption &&
//                   " : " + selectedMarketTgDropdownOption.label}
//                 {selectedRegionDropdownOption &&
//                   " : " + selectedRegionDropdownOption.label}

//               </h2>
//               <SectionDefinationsBadge
//                 title={"Campaign wise Planned v/s Delivered table"}
//                 module={pageSubModule}
//                 platform={pagePlatform}
//                 section={pagePrimaryTab}
//               />
//             </div>

//           </div>
//         </div>
//         <div className="grid col_span_4 section_card_body">
//           <div className="section_card_body">
//             <Loader loaderType="smallTableLoader" />
//           </div>
//         </div>
//       </div>
//     </Wrapper>
//   );

//   // IF DATA IS LOADED
//   if (
//     isPlannedVsDeliveredTableDataLoaded &&
//     !isPlannedVsDeliveredTableDataLoading
//   ) {
//     if (
//       NoDataAvailableChecker(plannedVsDeliveredTableData) ||
//       NoDataAvailableChecker(plannedVsDeliveredTableDataHeaders)
//     ) {
//       plannedVsDeliveredTable = (
//         <Wrapper>
//           <div className="grid grid_cols_4 fullpage_section_card section_card">
//             <div className="grid col_span_4">
//               <div className="section_card_header">
//                 <div className="section_info">
//                   <h2 className="section_card_title">
//                     Campaign wise Planned v/s Delivered table
//                     {selectedMarketTgDropdownOption &&
//                       " : " + selectedMarketTgDropdownOption.label}
//                     {selectedRegionDropdownOption &&
//                       " : " + selectedRegionDropdownOption.label}

//                   </h2>
//                   <SectionDefinationsBadge
//                     title={"Campaign wise Planned v/s Delivered table"}
//                     module={pageSubModule}
//                     platform={pagePlatform}
//                     section={pagePrimaryTab}
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className="grid col_span_4 section_card_body">
//               <div className="section_card_body">
//                 <NoDataAvailableLoader chartType="tableChartType" />
//               </div>
//             </div>
//           </div>
//         </Wrapper>
//       );
//     } else {
//       plannedVsDeliveredTable = (
//         <Wrapper>
//           <ErrorBoundary>
//             <Datatable
//               tableTitle={
//                 "Campaign wise Planned v/s Delivered table" +
//                 `${selectedMarketTgDropdownOption &&
//                 " : " + selectedMarketTgDropdownOption.label
//                 }
//             ${selectedRegionDropdownOption &&
//                 " : " + selectedRegionDropdownOption.label
//                 }`
//               }
//               tablePageSubModule={pageSubModule}
//               tablePagePlatform={pagePlatform}
//               tablePagePrimaryTab={pagePrimaryTab}
//               tableHeader={plannedVsDeliveredTableDataHeaders}
//               tableData={plannedVsDeliveredTableData}
//               tableLength={10}
//               isFooterShow={true}
//               searchPlaceHolder={"Search table"}
//               tableID="planned_vs_delivered_table"
//               tableExportDropdown={
//                 <ExportDropdown
//                   sectionId={"planned_vs_delivered_table"}
//                   sectionName={`TableDynaLabels_Planned vs Delivered Table_${` : ` + selectedMarketTgDropdownOption.label
//                     } ${selectedRegionDropdownOption !== undefined &&
//                       selectedRegionDropdownOption !== null &&
//                       selectedRegionDropdownOption !== ""
//                       ? `: ` + selectedRegionDropdownOption.label
//                       : ""
//                     }`}
//                   sectionData={plannedVsDeliveredTableData}
//                 />
//               }
//             />
//           </ErrorBoundary>
//         </Wrapper>
//       );
//     }
//   }

//   // IF THERE IS SOME ERROR FROM SERVER
//   if (plannedVsDeliveredTableDataerror) {
//     plannedVsDeliveredTable = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <div className="section_info">
//                 <h2 className="section_card_title">
//                   Campaign wise Planned v/s Delivered table
//                   {selectedMarketTgDropdownOption &&
//                     " : " + selectedMarketTgDropdownOption.label}
//                   {selectedRegionDropdownOption &&
//                     " : " + selectedRegionDropdownOption.label}

//                 </h2>
//                 <SectionDefinationsBadge
//                   title={"Campaign wise Planned v/s Delivered table"}
//                   module={pageSubModule}
//                   platform={pagePlatform}
//                   section={pagePrimaryTab}
//                 />
//               </div>
//             </div>
//           </div>
//           <div className="grid col_span_4 section_card_body">
//             <div className="section_card_body">
//               <ServerErrorsLoader
//                 error={plannedVsDeliveredTableDataerror}
//                 chartType="tableChartType"
//               />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }

//   // IF DATA LOADING
//   if (isPlannedVsDeliveredTableDataLoading) {
//     plannedVsDeliveredTable = (
//       <Wrapper>
//         <div className="grid grid_cols_4 fullpage_section_card section_card">
//           <div className="grid col_span_4">
//             <div className="section_card_header">
//               <div className="section_info">
//                 <h2 className="section_card_title">
//                   Campaign wise Planned v/s Delivered table
//                   {selectedMarketTgDropdownOption &&
//                     " : " + selectedMarketTgDropdownOption.label}
//                   {selectedRegionDropdownOption &&
//                     " : " + selectedRegionDropdownOption.label}

//                 </h2>
//                 <SectionDefinationsBadge
//                   title={"Campaign wise Planned v/s Delivered table"}
//                   module={pageSubModule}
//                   platform={pagePlatform}
//                   section={pagePrimaryTab}
//                 />
//               </div>
//             </div>
//           </div>
//           <div className="grid col_span_4 section_card_body">
//             <div className="section_card_body">
//               <Loader loaderType="smallTableLoader" />
//             </div>
//           </div>
//         </div>
//       </Wrapper>
//     );
//   }



//   useEffect(() => {
//     setChildDonutSliceToggle(null);
//   }, [
//     selectedBucketsFromFilter,
//     selectedCampaigns,
//     selectedObjectives,
//     selectedIdandPlatform,
//     selectedPlatform,
//   ]);

//   var campaignObjectCSV = {
//     "CampaignObjDonut_Campaign Objective Donut": childDonutCampaignObjective,
//     [`CampObjectiveDynaLabels_Campaign Objective Line_${childDonutSliceToggle === undefined || childDonutSliceToggle === null
//       ? "empty"
//       : childDonutSliceToggle.replace("_", "-")
//       }`]: lineChartCampaignObjective,
//   };

//   var maleFemaleDataCSV = {
//     "Audience Total Count": [
//       {
//         ["Male - " + selectedMidSectionDropdownOption.label]: totalMaleCount,
//         ["Female - " + selectedMidSectionDropdownOption.label]:
//           totalFemaleCount,
//       },
//     ],
//     [`AgeBarChartCampaignDynalabels_Audience Distribution - Male_${selectedMidSectionDropdownOption.value}`]:
//       maleCount,
//     [`AgeBarChartCampaignDynalabels_Audience Distribution - Female_${selectedMidSectionDropdownOption.value}`]:
//       femaleCount,
//   };

//   var deviceDistributionExport = {
//     [`CampaignDeviceDistDynaLabels_Device Distribution_${selectedMidSectionDropdownOption.value}`]:
//       deviceDistribution,
//   };


//   var barChartCSV = {
//     [`dvBar_PvsD BarChart_${` : ` + selectedMarketTgDropdownOption.label
//       } ${selectedRegionDropdownOption !== undefined &&
//         selectedRegionDropdownOption !== null &&
//         selectedRegionDropdownOption !== ""
//         ? `: ` + selectedRegionDropdownOption.label
//         : ""
//       }`]: plannedVsDeliveredMetricsData,
//   };

//   var tableCSV = {
//     [`TableDynaLabels_PvsD Table_${` : ` + selectedMarketTgDropdownOption.label
//       } ${selectedRegionDropdownOption !== undefined &&
//         selectedRegionDropdownOption !== null &&
//         selectedRegionDropdownOption !== ""
//         ? `: ` + selectedRegionDropdownOption.label
//         : ""
//       }`]: plannedVsDeliveredTableData,
//   };


//   var allData = {
//     ...topCardData,
//     "SummaryCard_Summary Card": combineMetricCardsData,
//     ...campaignObjectCSV,
//     ...maleFemaleDataCSV,
//     [`${companyID === HALEON_COMPANY_ID
//       ? "HaleonCountryTable"
//       : "CountryTableDynaLabels"
//       }_Audience Distribution - Country_${selectedMidSectionDropdownOption.label
//       }`]: countryData,
//     [`${companyID === HALEON_COMPANY_ID
//       ? "HaleonStateTable"
//       : "StateTableDynaLabels"
//       }_Audience Distribution - State_${selectedMidSectionDropdownOption.label}`]:
//       stateData,
//     ...deviceDistributionExport,
//     [`${companyID === COKE_COMPANY_ID || companyID === HALEON_COMPANY_ID
//       ? "CampaignTableCokeHaleon"
//       : "CampaignTableDynaLabels"
//       }_Summary Table_${selectedCampaignSummaryDropdownOption.label.toLowerCase()}`]:
//       campaignSummary,
//     ...barChartCSV,
//     ...tableCSV,
//   };

//   useEffect(() => {
//     setFullPageExport(allData);
//   }, [
//     combineMetricCardsData,
//     childDonutCampaignObjective,
//     lineChartCampaignObjective,
//     maleCount,
//     femaleCount,
//     stateData,
//     countryData,
//     deviceDistribution,
//     campaignSummary,
//     plannedVsDeliveredMetricsData,
//     plannedVsDeliveredTableData
//   ]);

//   useEffect(() => {

//     if (
//       (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
//       (childDonutCampaignObjectiveError !== null ? true : childDonutCampaignObjectiveLoaded && (childDonutCampaignObjectiveLoading === false)) &&
//       (lineChartCampaignObjectiveError !== null ? true : lineChartCampaignObjectiveLoaded && (lineChartCampaignObjectiveLoading === false)) &&
//       (maleCounterror !== null ? true : isMaleCountLoaded && (isMaleCountLoading === false)) &&
//       (femaleCounterror !== null ? true : isFemaleCountLoaded && (isFemaleCountLoading === false)) &&
//       (stateDataerror !== null ? true : isStateDataLoaded && (isStateDataLoading === false)) &&
//       (countryDataerror !== null ? true : isCountryDataLoaded && (isCountryDataLoading === false)) &&
//       (deviceDistributionerror !== null ? true : isDeviceDistributionLoaded && (isDeviceDistributionLoading === false)) &&
//       (campaignSummaryerror !== null ? true : isCampaignSummaryLoaded && (isCampaignSummaryLoading === false)) &&
//       (plannedVsDeliveredMetricsError !== null ? true : isplannedVsDeliveredMetricsLoaded && (isplannedVsDeliveredMetricsLoading === false)) &&
//       (plannedVsDeliveredTableDataerror !== null ? true : isPlannedVsDeliveredTableDataLoaded && (isPlannedVsDeliveredTableDataLoading === false))
//     ) {
//       setIsExportDisabled(false)
//     } else {
//       setIsExportDisabled(true)
//     }

//   }, [
//     metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
//     childDonutCampaignObjectiveError, childDonutCampaignObjectiveLoaded, childDonutCampaignObjectiveLoading,
//     lineChartCampaignObjectiveError, lineChartCampaignObjectiveLoaded, lineChartCampaignObjectiveLoading,
//     maleCounterror, isMaleCountLoaded, isMaleCountLoading,
//     femaleCounterror, isFemaleCountLoaded, isFemaleCountLoading,
//     stateDataerror, isStateDataLoaded, isStateDataLoading,
//     countryDataerror, isCountryDataLoaded, isCountryDataLoading,
//     deviceDistributionerror, isDeviceDistributionLoaded, isDeviceDistributionLoading,
//     campaignSummaryerror, isCampaignSummaryLoaded, isCampaignSummaryLoading,
//     plannedVsDeliveredMetricsError, isplannedVsDeliveredMetricsLoaded, isplannedVsDeliveredMetricsLoading,
//     plannedVsDeliveredTableDataerror, isPlannedVsDeliveredTableDataLoaded, isPlannedVsDeliveredTableDataLoading
//     ,])


//   return (
//     <Wrapper>
//       {metricCardsSection}
//       <div
//         id="campaign_objective_section"
//         className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
//       >
//         <div className="grid col_span_4">
//           <div className="section_card_header">
//             <div className="section_info">
//               <h2 className="section_card_title">
//                 Objective Wise Distribution
//               </h2>
//               <SectionDefinationsBadge
//                 title={"Objective Wise Distribution"}
//                 module="campaign"
//                 platform="facebook"
//                 section="overall"
//               />
//             </div>
//             <Modal
//               open={isLineChartCampaignOpen}
//               setOpen={setIsLineChartCampaignOpen}
//               exportDropdown={
//                 <ExportDropdown
//                   moduleName={pageModule}
//                   subModuleName={pageSubModule}
//                   platform={pagePlatform}
//                   tab={pagePrimaryTab}
//                   sectionId={"multiLineChart_2"}
//                   sectionName={"Campaign Objective Wise distribution"}
//                   sectionData={campaignObjectCSV}
//                 />}
//               parentId={"campaign_objective_section"}
//               title={`Objective Wise Distribution`}
//               chart={lineChartCampaignSection2}
//             />
//             <div data-html2canvas-ignore={true}>
//               {isMultiLineCluster && <button onClick={() => setIsLineChartCampaignOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
//                 <ExpandIcon />
//               </button>}
//               <ExportDropdown
//                 moduleName={pageModule}
//                 subModuleName={pageSubModule}
//                 platform={pagePlatform}
//                 tab={pagePrimaryTab}
//                 sectionId={"campaign_objective_section"}
//                 sectionName={"Campaign Objective Wise distribution"}
//                 sectionData={campaignObjectCSV}
//               />
//             </div>
//           </div>
//         </div>
//         <div className="grid grid_cols_4">
//           <div className="grid col_span_1 ">
//             <div className="section_card_body">
//               <div className="section_card_chart_wrapper">
//                 {childDonutCampaignChart}
//               </div>
//             </div>
//           </div>
//           <div className="grid col_span_3 ">
//             <div className="section_card_body">
//               <div className="section_card_chart_wrapper">
//                 {lineChartCampaignSection}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="section_card section_dropdown_header">
//         <div className="grid grid_cols_1">
//           <div className="section_card_header">
//             <h2 className="section_card_title">Performance By KPI</h2>
//             <Dropdown
//               ismulti={false}
//               placeholder={"Select"}
//               options={midSectionDropdonwOptions}
//               className="form_dropdown section_dropdown"
//               value={selectedMidSectionDropdownOption}
//               selectedOptions={selectedMidSectionDropdownOption}
//               setStatedropdown={midSectionDropdownSelectionHandler}
//             />
//           </div>
//         </div>
//       </div>
//       <div className="grid grid_cols_4 grid_margin_bottom">
//         <div
//           id="audience_distribution"
//           className="grid col_span_3 section_card"
//         >
//           <div className="section_card_header">
//             <div className="section_info">
//               <h2 className="section_card_title">
//                 {AudienceDistributionSectionTitle}{" "}
//                 {": " + selectedMidSectionDropdownOption.label}{" "}
//               </h2>
//               <SectionDefinationsBadge
//                 title={AudienceDistributionSectionTitle + " By Gender"}
//                 module="campaign"
//                 platform="facebook"
//                 section="overall"
//               />
//             </div>

//             <div data-html2canvas-ignore={true}>
//               <ExportDropdown
//                 moduleName={pageModule}
//                 subModuleName={pageSubModule}
//                 platform={pagePlatform}
//                 tab={pagePrimaryTab}
//                 sectionId={"audience_distribution"}
//                 sectionName={"Audience Distribution"}
//                 sectionData={maleFemaleDataCSV}
//               />
//             </div>
//           </div>
//           <div className="grid grid_cols_4">
//             <div className="grid col_span_2 card_border_right flex column">
//               <div className="section_card_header">
//                 <h2 className="section_card_title">Male</h2>
//               </div>
//               <div className="grid grid_cols_4 section_card_body">
//                 <div className="grid col_span_1 flex column justify_center align_center">
//                   {maleAudienceDistributionIcon}
//                 </div>
//                 <div className="grid col_span_3 section_card_chart_wrapper">
//                   {maleAudienceDistribution}
//                 </div>
//               </div>
//             </div>
//             <div className="grid col_span_2 flex column">
//               <div className="grid col_span_4 section_card_header">
//                 <h2 className="section_card_title">Female</h2>
//               </div>
//               <div className="grid grid_cols_4 section_card_body">
//                 <div className="grid col_span_1 flex column justify_center align_center">
//                   {femaleAudienceDistributionIocn}
//                 </div>
//                 <div className="grid col_span_3 section_card_chart_wrapper">
//                   {femaleAudienceDistribution}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div id="device_distribution" className="grid col_span_1 section_card">
//           <div className="section_card_header">
//             <div className="section_info">
//               <h2 className="section_card_title">
//                 Device Distribution
//                 {": " + selectedMidSectionDropdownOption.label}
//               </h2>
//               <SectionDefinationsBadge
//                 title={"Device Distribution"}
//                 module="campaign"
//                 platform="facebook"
//                 section="overall"
//               />
//             </div>

//             <div data-html2canvas-ignore={true}>
//               <ExportDropdown
//                 moduleName={pageModule}
//                 subModuleName={pageSubModule}
//                 platform={pagePlatform}
//                 tab={pagePrimaryTab}
//                 sectionId={"device_distribution"}
//                 sectionName={"CampaignDeviceDistDynaLabels_Device Distribution"}
//                 sectionData={deviceDistributionExport}
//               />
//             </div>
//           </div>
//           <div className="grid grid_cols_1 section_card_body">
//             <div className="section_card_chart_wrapper">
//               {deviceDistributionChart}
//             </div>
//           </div>
//         </div>
//       </div>
//       <div id="audience_distribution_place">
//         <div className="section_card section_dropdown_header">
//           <div className="grid grid_cols_1">
//             <div className="section_card_header">
//               <div className="section_info">
//                 <h2 className="section_card_title">
//                   {AudienceDistributionSectionTitle}{" "}
//                   {/* : {selectedMidSectionDropdownOption.label} */}
//                 </h2>
//                 <SectionDefinationsBadge
//                   title={AudienceDistributionSectionTitle}
//                   module="campaign"
//                   platform="facebook"
//                   section="overall"
//                 />
//               </div>
//             </div>
//             {/*  <div data-html2canvas-ignore={true}>
//               <ExportDropdown
//                 moduleName={pageModule}
//                 subModuleName={pageSubModule}
//                 platform={pagePlatform}
//                 tab={pagePrimaryTab}
//                 sectionId={"audience_distribution_place"}
//                 sectionName={"Audience Distribution"}
//                 sectionData={cityCountryData}
//               />
//             </div> */}
//           </div>
//         </div>

//         {companyID === HALEON_COMPANY_ID ? (
//           <Wrapper>
//             <div className="grid  grid_margin_bottom ">
//               <Tab
//                 varient={"primary"}
//                 tabData={tabData}
//                 activeTab={selectedTab}
//                 handleCallback={(event) => handleCallback(event)}
//               />
//             </div>
//             <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
//               {selectedTab === "By Country" && <Wrapper>{countryMap}</Wrapper>}
//               {selectedTab === "By State" && <Wrapper>{stateTable}</Wrapper>}
//             </div>
//           </Wrapper>
//         ) : (
//           <div className="grid grid_cols_4 grid_margin_bottom">
//             <div className="grid col_span_2 card_border_right flex column">
//               {countryMap}
//             </div>
//             <div className="grid col_span_2 flex column">{stateTable}</div>
//           </div>
//         )}
//       </div>
//       <div className="grid_margin_bottom">{campaignSummaryTable}</div>


//       <Wrapper>
//         <div className="section_card section_dropdown_header">
//           <div className="grid grid_cols_1">
//             <div className="section_card_header">
//               <h2 className="section_card_title">
//                 Planned Vs Delivered
//               </h2>
//               <div data-html2canvas-ignore={true}>
//                 <Dropdown
//                   ismulti={false}
//                   placeholder={"Select"}
//                   options={dropdownOptionsMarketTg}
//                   className="form_dropdown section_dropdown grid_margin_right"
//                   value={selectedMarketTgDropdownOption}
//                   selectedOptions={selectedMarketTgDropdownOption}
//                   setStatedropdown={dropdownMainSelectionHandler}

//                 />
//                 <Dropdown
//                   ismulti={false}
//                   dropdownLoading={regionDropdownOptionsIsLoading}
//                   placeholder={"Select"}
//                   options={regionDropdownOptionsOnLoad}
//                   className="form_dropdown section_dropdown"
//                   value={selectedRegionDropdownOption}
//                   selectedOptions={selectedRegionDropdownOption}
//                   setStatedropdown={dropdownRegionSelectionHandler}

//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="grid grid_cols_4 fullpage_section_card section_card grid_margin_bottom">
//           <div className="section_card_body">
//             <div id="planned_vs_delivered_metrics_section">
//               <div className="grid  section_card">
//                 <div className="section_card_header">
//                   <div className="section_info">
//                     <h2 className="section_card_title">
//                       Planned v/s Delivered Metrics Performance
//                       {selectedMarketTgDropdownOption &&
//                         " : " + selectedMarketTgDropdownOption.label}
//                       {selectedRegionDropdownOption &&
//                         " : " + selectedRegionDropdownOption.label}
//                     </h2>
//                     <SectionDefinationsBadge
//                       title={"Planned vs Delivered Metrics Performance"}
//                       module={pageSubModule}
//                       platform={pagePlatform}
//                       section={pagePrimaryTab}
//                     />
//                   </div>
//                   <div data-html2canvas-ignore={true}>
//                     <ExportDropdown
//                       moduleName={pageModule}
//                       subModuleName={pageSubModule}

//                       sectionId={"planned_vs_delivered_metrics_section"}
//                       sectionName={`dvBar_PvsD BarChart_${` : ` + selectedMarketTgDropdownOption.label
//                         } ${selectedRegionDropdownOption !== undefined &&
//                           selectedRegionDropdownOption !== null &&
//                           selectedRegionDropdownOption !== ""
//                           ? `: ` + selectedRegionDropdownOption.label
//                           : ""
//                         }`}
//                       sectionData={barChartCSV}
//                     />
//                   </div>
//                 </div>
//                 <div className="grid grid_cols_1 section_card_body">
//                   <div className="section_card_chart_wrapper">
//                     {plannedVsDeliveredMetricsBarChart}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {plannedVsDeliveredTable}
//       </Wrapper>

//     </Wrapper>
//   );
// };
// export default CampaignFacebookOverall;


import { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// CHARTS
import LineChart from "../../../../components/ui/charts/LineChart";
import Datatable from "../../../../components/ui/tables/Datatable";
import BarChart from "../../../../components/ui/charts/BarChart";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";

// ASSETS
import MaleIcon from "../../../../assets/icons/MaleIcon";
import FemaleIcon from "../../../../assets/icons/FemaleIcon";

// UTILS
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import {
  IAEvent_Dropdown_Visit,
  IAEvent_Secondary_Tab_Visit,
} from "../../../../utils/IAEvents";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import {
  BlueColorStart500,
  COKE_COMPANY_ID,
  HALEON_COMPANY_ID,
} from "../../../../constants/constants";
import MultiAxisMultiLine from "../../../../components/ui/charts/MultiAxisMultiLine";
import Tab from "../../../../components/ui/Tab";
import PartitionedLineChart from "../../../../components/ui/charts/PartitionedLineChart";
import ExpandIcon from "../../../../assets/icons/ExpandIcon";
import Modal from "../../../../components/ui/Modal";
import { concateTotals, newGetHeaderKeysWithLabels, getUniqueValuesFromArrofObj } from "../../../../utils/Utils";
import PaginatedDatatable from "../../../../components/ui/tables/PaginatedDatatable";

const CampaignFacebookOverall = ({ topCardData }) => {

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Campaign"
  let pagePlatform = "Facebook"
  let pagePrimaryTab = "Overall"

  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled } = useContext(FullPageExportContext);

  // USER DATA
  const userData = useSelector((state) => state.userData.user[0]);
  let companyID = userData?.company?.id ?? "";

  const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)
  const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)


  let AudienceDistributionSectionTitle = ""
  if (companyID === HALEON_COMPANY_ID) {
    AudienceDistributionSectionTitle = "Audience Distribution - Meta level";
  } else {
    AudienceDistributionSectionTitle = "Audience Distribution";
  }

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // GLOBAL CAMPAIGN DROPDOWN
  const selectedCampaignPlatformsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignPlatform
  );
  const selectedCampaignObjectivesFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignObjectives
  );
  const selectedCampaignsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaigns
  );
  const selectedBucketsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedBuckets);

  const selectedCampaignIdandPlatform = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignIDandPlatform
  );

  // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
  let selectedPlatform = null;
  if (selectedCampaignPlatformsFromFilter.length === 0 || selectedCampaignPlatformsFromFilter === undefined) {
    selectedPlatform = null;
  } else {
    selectedPlatform = selectedCampaignPlatformsFromFilter.join(",");
  }

  // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
  let selectedObjectives = null;
  if (selectedCampaignObjectivesFromFilter.length === 0 || selectedCampaignObjectivesFromFilter === undefined) {
    selectedObjectives = null;
  } else {
    selectedObjectives = selectedCampaignObjectivesFromFilter.join(",");
  }

  // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
  let selectedCampaigns = null;
  if (selectedCampaignsFromFilter.length === 0 || selectedCampaignsFromFilter === undefined) {
    selectedCampaigns = null;
  } else {
    selectedCampaigns = selectedCampaignsFromFilter.join(",");
  }

  let selectedIdandPlatform = null;
  if (selectedCampaignIdandPlatform === undefined || selectedCampaignIdandPlatform.length === 0) {
    selectedIdandPlatform = null;
  } else {
    selectedIdandPlatform = JSON.stringify(selectedCampaignIdandPlatform);
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // REACH METRIC CARDS
  const [reachMetricCards, setReachMetricCards] = useState([]);
  const [isReachMetricCardsLoading, setReachMetricCardsIsLoading] = useState(false);
  const [isReachMetricCardsLoaded, setReachMetricCardsIsLoaded] = useState(false);
  const [reachMetricCardserror, setReachMetricCardsError] = useState(null);

  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (DONUT)
  const [childDonutSliceToggle, setChildDonutSliceToggle] = useState(null);
  const [childDonutCampaignObjective, setChildDonutCampaignObjective] = useState([]);
  const [childDonutCampaignObjectiveLoading, setChildDonutCampaignObjectiveLoading] = useState([]);
  const [childDonutCampaignObjectiveLoaded, setChildDonutCampaignObjectiveLoaded] = useState([]);
  const [childDonutCampaignObjectiveError, setChildDonutCampaignObjectiveError] = useState([]);


  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (LINECHART)
  const [lineChartCampaignObjective, setLineChartCampaignObjective] = useState([]);
  const [lineChartCampaignObjectiveLabel, setLineChartCampaignObjectiveLabel] = useState([]);
  const [lineChartCampaignObjectiveLoading, setLineChartCampaignObjectiveLoading] = useState([]);
  const [lineChartCampaignObjectiveLoaded, setLineChartCampaignObjectiveLoaded] = useState([]);
  const [lineChartCampaignObjectiveError, setLineChartCampaignObjectiveError] = useState([]);


  // MID SECTION DROPDOWN
  // DROPDOWN OPTIONS
  const midSectionDropdonwOptions = [
    { value: "impressions", label: "Impressions" },
    { value: "clicks", label: "Clicks" },
    { value: "conversions", label: "Conversions" },
    { value: "engagements", label: "Engagements" },
    { value: "spends", label: "Spends" },
  ];

  // DROPDOWN DEFAULT STATE
  const [
    selectedMidSectionDropdownOption,
    setSelectedMidSectionDropdownDropdownOption,
  ] = useState(midSectionDropdonwOptions[0]);



  // DROPDOWN OPTIONS SELECTION HANDLER
  const midSectionDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedMidSectionDropdownDropdownOption("");
    } else {
      setSelectedMidSectionDropdownDropdownOption(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Facebook", "Overall", null, "Performance By KPI", e.label)

  };

  // DEVICE DISTRIBUTION
  const [deviceDistribution, setDeviceDistribution] = useState([]);
  const [deviceDistributionAllDropdownsData, setDeviceDistributionAllDropdownsData] = useState([]);
  const [isDeviceDistributionLoading, setDeviceDistributionIsLoading] = useState(false);
  const [isDeviceDistributionLoaded, setDeviceDistributionIsLoaded] = useState(false);
  const [deviceDistributionerror, setDeviceDistributionError] = useState(null);

  // MALE COUNT
  const [maleCount, setMaleCount] = useState([]);
  const [maleCountAllDropdownsData, setMaleCountAllDropdownsData] = useState([]);
  const [maleCountLabels, setMaleCountLabels] = useState([]);
  const [maleCountAllDropdownsDataLabels, setMaleCountAllDropdownsDataLabels] = useState([]);
  const [totalMaleCount, setTotalMaleCount] = useState(0);
  const [totalMaleCountAllDropdownsData, setTotalMaleCountAllDropdownsData] = useState([]);
  const [isMaleCountLoading, setMaleCountIsLoading] = useState(false);
  const [isMaleCountLoaded, setMaleCountIsLoaded] = useState(false);
  const [maleCounterror, setMaleCountError] = useState(null);

  // FEMALE COUNT
  const [femaleCount, setFemaleCount] = useState([]);
  const [femaleCountAllDropdownsData, setFemaleCountAllDropdownsData] = useState([]);
  const [femaleCountLabels, setFemaleCountLabels] = useState([]);
  const [femaleCountAllDropdownsDataLabels, setFemaleCountAllDropdownsDataLabels] = useState([]);
  const [totalFemaleCount, setTotalFemaleCount] = useState(0);
  const [totalFemaleCountAllDropdownsData, setTotalFemaleCountAllDropdownsData] = useState([]);
  const [isFemaleCountLoading, setFemaleCountIsLoading] = useState(false);
  const [isFemaleCountLoaded, setFemaleCountIsLoaded] = useState(false);
  const [femaleCounterror, setFemaleCountError] = useState(null);

  // COUNTRY
  const [countryData, setCountryData] = useState([]);
  const [countryAllDropdownsData, setCountryAllDropdownsData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  let CountryTableDataHeaders = [];

  let HaleonCountryTableDataHeaders = [
    { name: "Country", field: "country" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Conversions", field: "conversions" },
    { name: "Engagements", field: "total_engagement" },
    { name: "Spends", field: "spends" },
    { name: "Engagement Rate", field: "engagement_rate" },
    { name: "CTR", field: "ctr" },
    { name: "Interaction Rate", field: "interaction_rate" }
  ];

  let DefaultCountryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: selectedMidSectionDropdownOption.label, field: "value" },
    { name: "Change", field: "percentage_growth" },
  ];

  if (companyID === HALEON_COMPANY_ID) {
    CountryTableDataHeaders = HaleonCountryTableDataHeaders
  } else {
    CountryTableDataHeaders = DefaultCountryTableDataHeaders
  }

  // STATE
  const [stateData, setStateData] = useState([]);
  const [stateAllDropdownsData, setStateAllDropdownsData] = useState([]);
  const [isStateDataLoading, setStateDataIsLoading] = useState(false);
  const [isStateDataLoaded, setStateDataIsLoaded] = useState(false);
  const [stateDataerror, setStateDataError] = useState(null);

  let StateTableDataHeaders = [];

  let HaleonStateTableDataHeaders = [
    { name: "State", field: "region" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Conversions", field: "conversions" },
    { name: "Engagements", field: "total_engagement" },
    { name: "Spends", field: "spends" },
    { name: "Engagement Rate", field: "engagement_rate" },
    { name: "CTR", field: "ctr" },
    { name: "Interaction Rate", field: "interaction_rate" }
  ];

  let DefaultStateTableDataHeaders = [
    { name: "State", field: "name" },
    { name: selectedMidSectionDropdownOption.label, field: "value" },
    { name: "Change", field: "percentage_growth" }
  ];

  if (companyID === HALEON_COMPANY_ID) {
    StateTableDataHeaders = HaleonStateTableDataHeaders
  } else {
    StateTableDataHeaders = DefaultStateTableDataHeaders
  }

  // CAMPAIGN SUMMARY
  const [campaignSummary, setCampaignSummary] = useState([]);
  const [campaignSummaryTableHeaders, setCampaignSummaryTableHeaders] = useState([]);
  const [campaignSummaryExportData, setCampaignSummaryExportData] = useState([]);
  const [campaignSummaryAllDropdownsData, setCampaignSummaryAllDropdownsData] = useState([]);
  const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] = useState(false);
  const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
  const [campaignSummaryerror, setCampaignSummaryError] = useState(null);
  const [campaignSummaryTablePageData, setCampaignSummaryTablePageData] = useState({})


  // CAMPAIGN SUMMARY DROPDOWN
  // CAMPAIGN SUMMARY DROPDOWN OPTIONS
  const campaignSummaryOptions = [
    { value: "campaign", label: "Campaign" },
    { value: "ad", label: "Ad" },
    { value: "ad_group", label: "Ad Group" },
  ];

  const [campaignSummaryConfig, setCampaignSummaryConfig] = useState({ page_num: 1, page_size: 10, order_type: "desc", order_by: "clicks", search_value: null, dropdown_value: campaignSummaryOptions[0].value })


  // CAMPAIGN SUMMARY DROPDOWN DEFAULT STATE
  const [
    selectedCampaignSummaryDropdownOption,
    setSelectedCampaignSummaryDropdownOption,
  ] = useState(campaignSummaryOptions[0]);


  // CAMPAIGN SUMMARY DROPDOWN OPTIONS SELECTION HANDLER
  const campaignSummaryDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedCampaignSummaryDropdownOption("");
    } else {
      setSelectedCampaignSummaryDropdownOption(e);
      setCampaignSummaryConfig({ ...campaignSummaryConfig, dropdown_value: e.value, page_num: 1, search_value: null })
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Facebook", "Overall", null, "Summary", e.label)

  };

  // CHANGE CAMPAIGN SUMMARY DATA AS PER THE DROPDOWN VALUE
  const fetchCampaignSummaryDropdownHandler = async () => {
    if (selectedCampaignSummaryDropdownOption.value === "ad") {
      setCampaignSummary(campaignSummaryAllDropdownsData?.ad ?? []);
    } else if (selectedCampaignSummaryDropdownOption.value === "ad_group") {
      setCampaignSummary(campaignSummaryAllDropdownsData?.ad_group ?? []);
    } else if (selectedCampaignSummaryDropdownOption.value === "campaign") {
      setCampaignSummary(campaignSummaryAllDropdownsData?.campaign ?? []);
    } else {
      setCampaignSummary([]);
    }

  };


  useEffect(() => {
    // if (companyID === COKE_COMPANY_ID || companyID === HALEON_COMPANY_ID) {
    //   if (selectedCampaignSummaryDropdownOption.value === "campaign") {
    //     setCampaignSummaryTableHeaders(cokeCamapignSummaryTableHeaders)
    //   } else {
    //     setCampaignSummaryTableHeaders(cokeSummaryTableHeaders)
    //   }
    // } else {
    //   setCampaignSummaryTableHeaders(defaultSummaryTableHeaders)
    // }
  }, [
    BRAND_ID, selectedDates, selectedCampaignSummaryDropdownOption, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform
  ])

  // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchCampaignSummaryDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedCampaignSummaryDropdownOption, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);


  useEffect(() => {
    (metricCards !== undefined || metricCards.length !== 0) && (companyID === COKE_COMPANY_ID || companyID === HALEON_COMPANY_ID) && fetchReachMetricCardsHandler();
  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform, metricCards])

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchDeviceDistributionHandler();
    fetchAudienceDistributionHandler();
    fetchCountryHandler();
    fetchStateHandler();
    // fetchCampaignSummaryHandler();
    fetchChildDonutCampaignHandler();
    setSelectedCampaignSummaryDropdownOption(campaignSummaryOptions[0]); // TO SET THE SUMMARY TABLE DROPDOWN TO DEFAULT STATE
  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform]);

  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const fetchMidSectionDropdownHandler = async () => {
    if (selectedMidSectionDropdownOption.value === "clicks") {

      setDeviceDistribution(deviceDistributionAllDropdownsData?.clicks ?? []);
      setMaleCount(maleCountAllDropdownsData?.clicks ?? []);
      setMaleCountLabels(maleCountAllDropdownsDataLabels?.clicks ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.clicks ?? []);
      setFemaleCount(femaleCountAllDropdownsData?.clicks ?? []);
      setFemaleCountLabels(femaleCountAllDropdownsDataLabels?.clicks ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.clicks ?? []);

      if (companyID !== HALEON_COMPANY_ID) {
        setCountryData(countryAllDropdownsData?.clicks ?? []);
        setStateData(stateAllDropdownsData?.clicks ?? []);
      }
    } else if (selectedMidSectionDropdownOption.value === "conversions") {
      setDeviceDistribution(deviceDistributionAllDropdownsData?.conversions ?? []);
      setMaleCount(maleCountAllDropdownsData?.conversions ?? []);
      setMaleCountLabels(maleCountAllDropdownsDataLabels?.conversions ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.conversions ?? []);
      setFemaleCount(femaleCountAllDropdownsData?.conversions ?? []);
      setFemaleCountLabels(femaleCountAllDropdownsDataLabels?.conversions ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.conversions ?? []);
      if (companyID !== HALEON_COMPANY_ID) {
        setCountryData(countryAllDropdownsData?.conversions ?? []);
        setStateData(stateAllDropdownsData?.conversions ?? []);
      }
    } else if (selectedMidSectionDropdownOption.value === "engagements") {

      setDeviceDistribution(deviceDistributionAllDropdownsData.engagement);
      setMaleCount(maleCountAllDropdownsData?.engagement ?? []);
      setMaleCountLabels(maleCountAllDropdownsDataLabels?.engagement ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.engagement ?? []);
      setFemaleCount(femaleCountAllDropdownsData?.engagement ?? []);
      setFemaleCountLabels(femaleCountAllDropdownsDataLabels?.engagement ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.engagement ?? []);
      if (companyID !== HALEON_COMPANY_ID) {
        setCountryData(countryAllDropdownsData?.engagement ?? []);
        setStateData(stateAllDropdownsData?.engagement ?? []);
      }
    } else if (selectedMidSectionDropdownOption.value === "impressions") {

      setDeviceDistribution(deviceDistributionAllDropdownsData.impressions);
      setMaleCount(maleCountAllDropdownsData?.impressions ?? []);
      setMaleCountLabels(maleCountAllDropdownsDataLabels?.impressions ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.impressions ?? []);
      setFemaleCount(femaleCountAllDropdownsData?.impressions ?? []);
      setFemaleCountLabels(femaleCountAllDropdownsDataLabels?.impressions ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.impressions ?? []);
      if (companyID !== HALEON_COMPANY_ID) {
        setCountryData(countryAllDropdownsData?.impressions ?? []);
        setStateData(stateAllDropdownsData?.impressions ?? []);
      }
    } else if (selectedMidSectionDropdownOption.value === "spends") {

      setDeviceDistribution(deviceDistributionAllDropdownsData.spend);
      setMaleCount(maleCountAllDropdownsData?.spend ?? []);
      setMaleCountLabels(maleCountAllDropdownsDataLabels?.spend ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.spend ?? []);
      setFemaleCount(femaleCountAllDropdownsData?.spend ?? []);
      setFemaleCountLabels(femaleCountAllDropdownsDataLabels?.spend ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.spend ?? []);
      if (companyID !== HALEON_COMPANY_ID) {
        setCountryData(countryAllDropdownsData?.spend ?? []);
        setStateData(stateAllDropdownsData?.spend ?? []);
      }
    } else {
      setDeviceDistribution([]);
      setMaleCount([]);
      setMaleCountLabels([]);
      setTotalMaleCount([]);
      setFemaleCount([]);
      setFemaleCountLabels([]);
      setTotalFemaleCount([]);

      if (companyID !== HALEON_COMPANY_ID) {
        setCountryData([]);
        setStateData([]);
      }
    }
  };

  // CHNAGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchMidSectionDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedMidSectionDropdownOption, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/facebook/metric-card/", config);
      setMetricCards(res?.data?.data ?? []);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };


  // TO FETCH REACH METRIC CARDS FROM API
  const fetchReachMetricCardsHandler = async () => {
    setReachMetricCardsIsLoading(true);
    setReachMetricCardsIsLoaded(false);
    setReachMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/facebook/facebook-instagram-reach/", config);
      setReachMetricCards(res?.data?.facebook?.data ?? []);
      setReachMetricCardsIsLoading(false);
      setReachMetricCardsIsLoaded(true);
      setReachMetricCardsError(null);
    } catch (error) {
      setReachMetricCards([]);
      setReachMetricCardsIsLoading(false);
      setReachMetricCardsIsLoaded(false);
      setReachMetricCardsError(error.code);
    }
  };

  let static_data = [
    {
      "label": "Reach",
      "currentValue": "loading",
      "previousValue": "loading"
    },
    {
      "label": "Reach Rate",
      "currentValue": "loading",
      "previousValue": "loading"
    },
    {
      "label": "Frequency",
      "currentValue": "loading",
      "previousValue": "loading"
    }
  ]

  let error_static_data = [
    {
      "label": "Reach",
      "currentValue": "error",
      "previousValue": "error"
    },
    {
      "label": "Reach Rate",
      "currentValue": "error",
      "previousValue": "error"
    },
    {
      "label": "Frequency",
      "currentValue": "error",
      "previousValue": "error"
    }
  ]

  let combineMetricCardsData = useMemo(() => {
    let temp = [];

    if (isReachMetricCardsLoading && !isReachMetricCardsLoaded) {
      temp = metricCards.concat(static_data)
    } else if (reachMetricCardserror) {
      temp = metricCards.concat(error_static_data)
    } else {
      temp = metricCards.concat(reachMetricCards)
    }

    return temp
  }, [metricCards, reachMetricCards, isReachMetricCardsLoading, isReachMetricCardsLoaded]);


  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">
            <h2 className="section_card_title">Summary
            </h2>
          </div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(combineMetricCardsData)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">  <h2 className="section_card_title">Summary
              </h2></div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary"}>
              {combineMetricCardsData.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="campaign"
                    platform="facebook"
                    section="overall"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }

  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (DONUT)

  const fetchChildDonutCampaignHandler = async () => {
    setChildDonutCampaignObjectiveLoading(true);
    setChildDonutCampaignObjectiveLoaded(false);
    setChildDonutCampaignObjectiveError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/facebook/campaign-objective/",
        config
      );

      setChildDonutCampaignObjective(res?.data ?? []);
      setChildDonutCampaignObjectiveLoading(false);
      setChildDonutCampaignObjectiveLoaded(true);
      setChildDonutCampaignObjectiveError(null);
    } catch (error) {
      setChildDonutCampaignObjective([]);
      setChildDonutCampaignObjectiveLoading(false);
      setChildDonutCampaignObjectiveLoaded(false);
      setChildDonutCampaignObjectiveError(error.code);
    }
  };

  let childDonutCampaignChart = (
    <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (
    childDonutCampaignObjectiveLoaded &&
    !childDonutCampaignObjectiveLoading
  ) {
    if (
      NoDataAvailableChecker(childDonutCampaignObjective)
    ) {
      childDonutCampaignChart = (
        <Wrapper><NoDataAvailableLoader chartType="donutChartType" /></Wrapper>
      );
    } else {
      childDonutCampaignChart = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={childDonutCampaignObjective}
              chartId="CampaignDonutChild"
              onSliceClick={(data) => setChildDonutSliceToggle(data.object_key)}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (childDonutCampaignObjectiveError) {
    childDonutCampaignChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={childDonutCampaignObjectiveError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (childDonutCampaignObjectiveLoading) {
    childDonutCampaignChart = <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (LINECHART)
  const [isLineChartCampaignOpen, setIsLineChartCampaignOpen] = useState(false)


  let defaultLineChartCampaignDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultLineChartCampaignDisabledvalues = []
  } else {
    defaultLineChartCampaignDisabledvalues = ["Impressions", "Clicks", "Spends", "Conversions"]
  }

  const [lineChartCampaignDisabledvalues, setLineChartCampaignDisabledvalues] = useState(defaultLineChartCampaignDisabledvalues)


  const fetchLineChartCampaignHandler = async () => {
    setLineChartCampaignObjectiveLoading(true);
    setLineChartCampaignObjectiveLoaded(false);
    setLineChartCampaignObjectiveError(null);

    var objectiveConfig = (function () {
      if (selectedObjectives === null && (childDonutSliceToggle === null || childDonutSliceToggle === undefined)) {
        return { "objective": null }
      } else if (selectedObjectives !== null && (childDonutSliceToggle === undefined || childDonutSliceToggle === null)) {
        return { "objective": selectedObjectives }
      } else {
        return { "objective": childDonutSliceToggle }
      }
    })()

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        // objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
        // objective: childDonutSliceToggle,
        ...objectiveConfig
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/facebook/objective-linechart/",
        config
      );

      setLineChartCampaignObjective(res?.data?.data ?? []);
      setLineChartCampaignObjectiveLabel(res?.data?.dimension ?? []);
      setLineChartCampaignObjectiveLoading(false);
      setLineChartCampaignObjectiveLoaded(true);
      setLineChartCampaignObjectiveError(null);
    } catch (error) {
      setLineChartCampaignObjective([]);
      setLineChartCampaignObjectiveLabel([]);
      setLineChartCampaignObjectiveLoading(false);
      setLineChartCampaignObjectiveLoaded(false);
      setLineChartCampaignObjectiveError(error.code);
    }
  };

  let lineChartCampaignSection = (
    <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
  );
  let lineChartCampaignSection2 = (
    <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
  );
  // IF DATA IS LOADED
  if (lineChartCampaignObjectiveLoaded && !lineChartCampaignObjectiveLoading) {
    if (
      NoDataAvailableChecker(lineChartCampaignObjective) || NoDataAvailableChecker(lineChartCampaignObjectiveLabel)
    ) {
      lineChartCampaignSection = (
        <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
      lineChartCampaignSection2 = (
        <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
    } else {

      if (isMultiLineCluster) {
        lineChartCampaignSection = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartClass="section_card_chart"
                chartData={lineChartCampaignObjective}
                chartId="multiLineChart"
                chartLabels={lineChartCampaignObjectiveLabel}
                onBulletsClick={function noRefCheck() { }}
                showLabels
                showLegend
                impressionLabel="impression"
                showImpression={companyID === HALEON_COMPANY_ID ? true : false}
                disabledLegendArray={lineChartCampaignDisabledvalues}
                setDisabledValues={setLineChartCampaignDisabledvalues}
              />

            </ErrorBoundary>
          </Wrapper>
        );

        lineChartCampaignSection2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedLineChart
                chartClass="section_card_chart"
                chartData={lineChartCampaignObjective}
                chartId="multiLineChart_2"
                chartLabels={lineChartCampaignObjectiveLabel}
                showLegend={true}
                disabledLegendArray={lineChartCampaignDisabledvalues}
                setDisabledValues={setLineChartCampaignDisabledvalues}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      } else {
        lineChartCampaignSection = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartClass="section_card_chart"
                chartData={lineChartCampaignObjective}
                chartId="multiLineChart"
                chartLabels={lineChartCampaignObjectiveLabel}
                onBulletsClick={function noRefCheck() { }}
                showLabels
                showLegend
                impressionLabel="impression"
                showImpression={companyID === HALEON_COMPANY_ID ? true : false}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (lineChartCampaignObjectiveError) {
    lineChartCampaignSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={lineChartCampaignObjectiveError}
        />
      </Wrapper>
    );

    lineChartCampaignSection2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={lineChartCampaignObjectiveError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (lineChartCampaignObjectiveLoading) {
    lineChartCampaignSection = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
    lineChartCampaignSection2 = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>

  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // DEVICE DISTRIBUTION

  // TO FETCH DATA FROM API
  const fetchDeviceDistributionHandler = async () => {
    setDeviceDistributionIsLoading(true);
    setDeviceDistributionIsLoaded(false);
    setDeviceDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/facebook/device-distribution/",
        config
      );
      setDeviceDistribution(res?.data?.data?.impressions ?? []);
      setDeviceDistributionAllDropdownsData(res?.data?.data ?? []);
      setDeviceDistributionIsLoading(false);
      setDeviceDistributionIsLoaded(true);
      setDeviceDistributionError(null);
    } catch (error) {
      setDeviceDistributionAllDropdownsData([]);
      setDeviceDistributionIsLoading(false);
      setDeviceDistributionIsLoaded(false);
      setDeviceDistributionError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let deviceDistributionChart = (
    <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (isDeviceDistributionLoaded && !isDeviceDistributionLoading) {
    if (NoDataAvailableChecker(deviceDistribution)) {
      deviceDistributionChart = (
        <Wrapper><NoDataAvailableLoader chartType="donutChartType" /></Wrapper>
      );
    } else {
      deviceDistributionChart = (
        <DonutPieChart
          chartId={"reactions_sentiment"}
          chartClass={"section_card_chart"}
          chartData={deviceDistribution}
          showLegend={true}
          showLabels={true}
          showVerticalLabels={true}
        />
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (deviceDistributionerror) {
    deviceDistributionChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={deviceDistributionerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isDeviceDistributionLoading) {
    deviceDistributionChart = <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: MALE / FEMALE

  // TO FETCH MALE / FEMALE DATA FROM API
  const fetchAudienceDistributionHandler = async () => {
    setMaleCountIsLoading(true);
    setMaleCountIsLoaded(false);
    setMaleCountError(null);

    setFemaleCountIsLoading(true);
    setFemaleCountIsLoaded(false);
    setFemaleCountError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/facebook/audience-demographics/",
        config
      );

      setMaleCount(res?.data?.male_list?.data?.impressions ?? []);
      setMaleCountLabels(res?.data?.dimension?.impressions ?? []);
      setTotalMaleCount(res?.data?.male_count?.impressions ?? []);
      setFemaleCount(res?.data?.female_list?.data?.impressions ?? []);
      setFemaleCountLabels(res?.data?.dimension?.impressions ?? []);
      setTotalFemaleCount(res?.data?.female_count?.impressions ?? []);

      setMaleCountAllDropdownsData(res?.data?.male_list?.data ?? []);
      setMaleCountAllDropdownsDataLabels(res?.data?.dimension ?? []);
      setTotalMaleCountAllDropdownsData(res?.data?.male_count ?? []);

      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(true);
      setMaleCountError(null);

      setFemaleCountAllDropdownsData(res?.data?.female_list?.data ?? []);
      setFemaleCountAllDropdownsDataLabels(res?.data?.dimension ?? []);
      setTotalFemaleCountAllDropdownsData(res?.data?.female_count ?? []);
      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(true);
      setFemaleCountError(null);
    } catch (error) {
      setMaleCountAllDropdownsData([]);
      setMaleCountAllDropdownsDataLabels([]);
      setTotalMaleCountAllDropdownsData([]);
      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(false);
      setMaleCountError(error.code);

      setFemaleCountAllDropdownsData([]);
      setFemaleCountAllDropdownsDataLabels([]);
      setTotalFemaleCountAllDropdownsData([]);
      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(false);
      setFemaleCountError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let maleAudienceDistribution = (
    <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
  );
  let maleAudienceDistributionIcon = (
    <Wrapper><Loader loaderType="genderLoader" /></Wrapper>
  );

  let totalMaleCountPercentage = 0;
  if (isNaN(totalMaleCount / (totalMaleCount + totalFemaleCount)) === true) {
    totalMaleCountPercentage = 0;
  } else {
    totalMaleCountPercentage =
      totalMaleCount / (totalMaleCount + totalFemaleCount);
  }

  let totalFemaleCountPercentage = 0;
  if (isNaN(totalFemaleCount / (totalMaleCount + totalFemaleCount)) === true) {
    totalFemaleCountPercentage = 0;
  } else {
    totalFemaleCountPercentage =
      totalFemaleCount / (totalMaleCount + totalFemaleCount);
  }

  // IF DATA IS AVAILABLE
  if (isMaleCountLoaded && !isMaleCountLoading) {
    if (NoDataAvailableChecker(maleCount) || NoDataAvailableChecker(maleCountLabels)) {
      maleAudienceDistribution = (
        <Wrapper><NoDataAvailableLoader chartType="barChartType" /></Wrapper>
      );
    } else {
      maleAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary>
            <BarChart
              chartId="audience_male"
              chartClass="section_card_chart"
              chartData={maleCount}
              chartLabels={maleCountLabels}
              showLegend={false}
              /*  showLabels={true}
               showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }

    if (NoDataAvailableChecker(totalMaleCountPercentage)) {
      maleAudienceDistributionIcon = (
        <Wrapper>
          <NoDataAvailableLoader chartType="genderChartType" />
        </Wrapper>
      );
    } else {
      maleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <MaleIcon percentage={totalMaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalMaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (maleCounterror) {
    maleAudienceDistribution = (
      <Wrapper> <ServerErrorsLoader chartType="barChartType" error={maleCounterror} /></Wrapper>
    );
    maleAudienceDistributionIcon = (
      <Wrapper><ServerErrorsLoader chartType="genderChartType" error={maleCounterror} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMaleCountLoading) {
    maleAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
    maleAudienceDistributionIcon = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let femaleAudienceDistribution = (
    <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
  );
  let femaleAudienceDistributionIocn = (
    <Wrapper><Loader loaderType="genderLoader" /></Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isFemaleCountLoaded && !isFemaleCountLoading) {
    if (
      NoDataAvailableChecker(femaleCount) || NoDataAvailableChecker(femaleCountLabels)
    ) {
      femaleAudienceDistribution = (
        <Wrapper><NoDataAvailableLoader chartType="barChartType" /></Wrapper>
      );
    } else {
      femaleAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_female"
              chartClass="section_card_chart"
              chartData={femaleCount}
              chartLabels={femaleCountLabels}
              showLegend={false}
              /*  showLabels={true}
               showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }

    if (NoDataAvailableChecker(totalMaleCountPercentage)) {
      femaleAudienceDistributionIocn = (
        <Wrapper>
          <NoDataAvailableLoader chartType="genderChartType" />
        </Wrapper>
      );
    } else {
      femaleAudienceDistributionIocn = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <FemaleIcon percentage={totalFemaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalFemaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (femaleCounterror) {
    femaleAudienceDistribution = (
      <Wrapper>
        <ServerErrorsLoader chartType="barChartType" error={femaleCounterror} />
      </Wrapper>
    );
    femaleAudienceDistributionIocn = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="genderChartType"
          error={femaleCounterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isFemaleCountLoading) {
    femaleAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>;
    femaleAudienceDistributionIocn = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>;
  }



  const tabData = ["By Country", "By State"];
  const [selectedTab, setSelectedTab] = useState(tabData[0]);
  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Secondary_Tab_Visit(
      "Marketing Intelligence",
      "Campaign",
      "Facebook",
      "Overall",
      event
    );
  };




  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY MAP

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    let apiURL;
    if (companyID === HALEON_COMPANY_ID) {
      apiURL = "performance/facebook/performance-table-by-country/"
    } else {
      apiURL = "performance/facebook/audience-country/"
    }

    try {
      const res = await axios.get(apiURL, config);

      if (companyID === HALEON_COMPANY_ID) {
        setCountryData(res?.data?.summary ?? []);
      } else {
        setCountryData(res?.data?.data?.impressions ?? []);

        setCountryAllDropdownsData(res?.data?.data ?? []);
      }

      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };





  // AUDIENCE DISTRIBUTION: MALE
  let countryMap = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card ">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By Country</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (
      NoDataAvailableChecker(countryData) ||
      NoDataAvailableChecker(CountryTableDataHeaders)
    ) {
      countryMap = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card ">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By Country</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      countryMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle="By Country"
              /*  isHalfSection={true} */
              isCountryFlagVisible={true}
              tableHeader={CountryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
              tableID={"audience_distribution_country"}
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"audience_distribution"}
                  sectionName={`${(companyID === HALEON_COMPANY_ID) ? "HaleonCountryTable" : "CountryTableDynaLabels"}_Audience Distribution - Country_${selectedMidSectionDropdownOption.label}`}
                  sectionData={countryData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    countryMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={countryDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    countryMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // STATE TABLE

  const fetchStateHandler = async () => {
    setStateDataIsLoading(true);
    setStateDataIsLoaded(false);
    setStateDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    let apiURL;
    if (companyID === HALEON_COMPANY_ID) {
      apiURL = "performance/facebook/performance-table-by-region/"
    } else {
      apiURL = "performance/facebook/audience-region/"
    }

    try {
      const res = await axios.get(apiURL, config);

      if (companyID === HALEON_COMPANY_ID) {
        setStateData(res?.data?.summary ?? []);
      } else {
        setStateData(res?.data?.data?.impressions ?? []);

        setStateAllDropdownsData(res?.data?.data ?? []);
      }

      setStateDataIsLoading(false);
      setStateDataIsLoaded(true);
      setStateDataError(null);
    } catch (error) {
      setStateDataIsLoading(false);
      setStateDataIsLoaded(false);
      setStateDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let stateTable = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By State</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isStateDataLoaded && !isStateDataLoading) {
    if (
      NoDataAvailableChecker(stateData) ||
      NoDataAvailableChecker(StateTableDataHeaders)
    ) {
      stateTable = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card ">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By State</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      stateTable = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle="By State"
              /*  isHalfSection={true} */
              isDynamicFlagVisible={true}
              tableHeader={StateTableDataHeaders}
              tableData={stateData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search State"}
              tableID={"audience_distribution_state"}
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"audience_distribution_state"}
                  sectionName={`${(companyID === HALEON_COMPANY_ID) ? "HaleonStateTable" : "StateTableDynaLabels"}_Audience Distribution - State_${selectedMidSectionDropdownOption.label}`}
                  sectionData={stateData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (stateDataerror) {
    stateTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By State</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={stateDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isStateDataLoading) {
    stateTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By State</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN SUMMARY


  useEffect(() => {

    const fetchCampaignSummaryHandler = async () => {


      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          dropdown_value: selectedCampaignSummaryDropdownOption?.value ?? '',
          campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
          ...campaignSummaryConfig,
          page_size: -1,
          page_num: 1,

        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/facebook/performance-table/v2/", config);

        var allData = res?.data?.data?.results ?? [];
        // var headerKeys = campaignSummaryTableHeaders.map((x) => x.field)

        // if (selectedCampaignSummaryDropdownOption.value === 'campaign') {
        //   headerKeys.push('id')
        // } else {
        //   headerKeys.filter(e => e !== 'id')
        // }

        // var formattedData = allData.map((row) => {
        //   var newData = {};
        //   Object.keys(row).forEach((x, y) => {
        //     if (headerKeys.includes(x)) {
        //       newData[x] = row[x]
        //     }
        //   })
        //   return newData
        // })

        setCampaignSummaryExportData(allData ?? []);

      } catch (error) {
        setCampaignSummaryExportData([]);

      }
    };
    fetchCampaignSummaryHandler();

  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform, selectedCampaignSummaryDropdownOption, campaignSummaryConfig.dropdown_value])


  useEffect(() => {
    const fetchCampaignSummaryHandler = async () => {
      setCampaignSummaryIsLoading(true);
      setCampaignSummaryIsLoaded(false);
      setCampaignSummaryError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
          ...campaignSummaryConfig,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/facebook/performance-table/v2/", config
        );

        let allData = res.data.data.results;
        let uniqueHeaders = getUniqueValuesFromArrofObj(allData)
        let formattedHeaders = []
        uniqueHeaders.map((header) => {
          formattedHeaders.push({
            name: newGetHeaderKeysWithLabels(header),
            field: header,
          })
        })

        setCampaignSummaryTablePageData(res?.data?.data?.page ?? {})
        setCampaignSummary(allData ?? []);
        setCampaignSummaryTableHeaders(formattedHeaders ?? [])
        setCampaignSummaryIsLoading(false);
        setCampaignSummaryIsLoaded(true);
        setCampaignSummaryError(null);
      } catch (error) {
        setCampaignSummary([]);
        setCampaignSummaryTableHeaders([])
        setCampaignSummaryIsLoading(false);
        setCampaignSummaryIsLoaded(false);
        setCampaignSummaryError(error.code);
      }
    };
    fetchCampaignSummaryHandler();
  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform, campaignSummaryConfig])




  let campaignIdsTemp = [];

  // EXTRACT THE CAMPAIGN IDS FROM THE CAMPAIGN SUMMARY TABLE
  const campaignIdsFromTable = useMemo(() => {

    // TO GET THE UNIQUE CAMPAIGN IDS FROM THE SUMMARY TABLE
    if (campaignSummaryExportData === undefined || campaignSummaryExportData.length === 0 || campaignSummaryExportData === null) return [];
    campaignSummaryExportData.map((data) => {
      campaignIdsTemp.push(data.id)
    })

    // JOIN THE LIST OF ARRAY OF CAMPAIGN IDS
    return campaignIdsTemp.join(",");
  }, [campaignSummaryExportData]);  // UPDATES ONLY WHEN SUMMARY TABLE IS CHANGED

  let campaignSummaryTable = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">Summary
              <SectionDefinationsBadge
                title={"Summary"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // let TableHeader = [];

  // IF DATA IS LOADED
  if (isCampaignSummaryLoaded && !isCampaignSummaryLoading) {
    // if (NoDataAvailableChecker(campaignSummary) || NoDataAvailableChecker(campaignSummaryTableHeaders)) {
    //   campaignSummaryTable =
    //     (
    //       <Wrapper>
    //         <div className="grid grid_cols_4 fullpage_section_card section_card">
    //           <div className="grid col_span_4">
    //             <div className="section_card_header">
    //               <h2 className="section_card_title">Summary
    //                 <SectionDefinationsBadge
    //                   title={"Summary"}
    //                   module={pageSubModule}
    //                   platform={pagePlatform}
    //                   section={pagePrimaryTab}
    //                 />
    //               </h2>
    //             </div>
    //           </div>
    //           <div className="grid col_span_4">
    //             <div className="section_card_body">
    //               <NoDataAvailableLoader chartType="tableChartType" />
    //             </div>
    //           </div>
    //         </div>
    //       </Wrapper>
    //     )

    // } else {
    campaignSummaryTable = (
      <Wrapper>
        <ErrorBoundary chartType="tableChartType">
          <PaginatedDatatable
            tableTitle="Summary"
            tablePagePlatform={pagePlatform}
            tablePageSubModule={pageSubModule}
            tablePagePrimaryTab={pagePrimaryTab}
            tableHeader={campaignSummaryTableHeaders}
            tableData={campaignSummary}
            tableLength={campaignSummaryConfig?.page_size ?? 10}
            isFooterShow={true}
            searchPlaceHolder={"Search Campaign"}
            tableConfigs={campaignSummaryConfig}
            setTableConfigs={setCampaignSummaryConfig}
            tablePaginationData={campaignSummaryTablePageData}
            tableDropdown={
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={campaignSummaryOptions}
                className="form_dropdown section_dropdown"
                value={selectedCampaignSummaryDropdownOption}
                selectedOptions={selectedCampaignSummaryDropdownOption}
                setStatedropdown={campaignSummaryDropdownSelectionHandler}
              />
            }
            tableID={"search_campaign_section"}
            tableExportDropdown={
              <ExportDropdown
                sectionId={"search_campaign_section"}
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionName={`${(companyID === COKE_COMPANY_ID || companyID === HALEON_COMPANY_ID) ? "CampaignTableCokeHaleon" : "CampaignTableDynaLabels"}_Summary Table _${selectedCampaignSummaryDropdownOption.label}`}
                sectionData={campaignSummaryExportData}
              />
            }
          />
        </ErrorBoundary>
      </Wrapper>
    );
    // }

  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignSummaryerror) {
    campaignSummaryTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">Summary
                <SectionDefinationsBadge
                  title={"Summary"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={campaignSummaryerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCampaignSummaryLoading) {
    campaignSummaryTable =
      (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">Summary
                  <SectionDefinationsBadge
                    title={"Summary"}
                    module={pageSubModule}
                    platform={pagePlatform}
                    section={pagePrimaryTab}
                  />
                </h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <Loader loaderType="smallTableLoader" />
              </div>
            </div>
          </div>
        </Wrapper>
      )
  }


  useEffect(() => {
    fetchLineChartCampaignHandler();
  }, [BRAND_ID, selectedDates, childDonutSliceToggle, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

  useEffect(() => {
    setChildDonutSliceToggle(null)
  }, [selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

  var campaignObjectCSV = {
    "CampaignObjDonut_Campaign Objective Donut": childDonutCampaignObjective,
    [`CampObjectiveDynaLabels_Campaign Objective Line_${childDonutSliceToggle === undefined || childDonutSliceToggle === null ? "empty" : childDonutSliceToggle.replace("_", "-")}`]: lineChartCampaignObjective,
  };

  var maleFemaleDataCSV = {
    "Audience Total Count": [{ ["Male - " + selectedMidSectionDropdownOption.label]: totalMaleCount, ["Female - " + selectedMidSectionDropdownOption.label]: totalFemaleCount }],
    [`AgeBarChartCampaignDynalabels_Audience Distribution - Male_${selectedMidSectionDropdownOption.value}`]: maleCount,
    [`AgeBarChartCampaignDynalabels_Audience Distribution - Female_${selectedMidSectionDropdownOption.value}`]: femaleCount,
  };



  var deviceDistributionExport = {
    [`CampaignDeviceDistDynaLabels_Device Distribution_${selectedMidSectionDropdownOption.value}`]: deviceDistribution,
  }



  var allData = {
    ...topCardData,
    "SummaryCard_Summary Card": combineMetricCardsData,
    ...campaignObjectCSV,
    ...maleFemaleDataCSV,
    [`${(companyID === HALEON_COMPANY_ID) ? "HaleonCountryTable" : "CountryTableDynaLabels"}_Audience Distribution - Country_${selectedMidSectionDropdownOption.label}`]: countryData,
    [`${(companyID === HALEON_COMPANY_ID) ? "HaleonStateTable" : "StateTableDynaLabels"}_Audience Distribution - State_${selectedMidSectionDropdownOption.label}`]: stateData,
    ...deviceDistributionExport,
    [`${(companyID === COKE_COMPANY_ID || companyID === HALEON_COMPANY_ID) ? "CampaignTableCokeHaleon" : "CampaignTableDynaLabels"}_Summary Table _${selectedCampaignSummaryDropdownOption.label}`]: campaignSummaryExportData,
  };

  useEffect(() => {
    setFullPageExport(allData);
  }, [
    combineMetricCardsData,
    childDonutCampaignObjective,
    lineChartCampaignObjective,
    maleCount,
    femaleCount,
    stateData,
    countryData,
    deviceDistribution,
    campaignSummary,
    campaignSummaryExportData
  ]);


  useEffect(() => {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (childDonutCampaignObjectiveError !== null ? true : childDonutCampaignObjectiveLoaded && (childDonutCampaignObjectiveLoading === false)) &&
      (lineChartCampaignObjectiveError !== null ? true : lineChartCampaignObjectiveLoaded && (lineChartCampaignObjectiveLoading === false)) &&
      (maleCounterror !== null ? true : isMaleCountLoaded && (isMaleCountLoading === false)) &&
      (femaleCounterror !== null ? true : isFemaleCountLoaded && (isFemaleCountLoading === false)) &&
      (stateDataerror !== null ? true : isStateDataLoaded && (isStateDataLoading === false)) &&
      (countryDataerror !== null ? true : isCountryDataLoaded && (isCountryDataLoading === false)) &&
      (deviceDistributionerror !== null ? true : isDeviceDistributionLoaded && (isDeviceDistributionLoading === false)) &&
      (campaignSummaryerror !== null ? true : isCampaignSummaryLoaded && (isCampaignSummaryLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    childDonutCampaignObjectiveError, childDonutCampaignObjectiveLoaded, childDonutCampaignObjectiveLoading,
    lineChartCampaignObjectiveError, lineChartCampaignObjectiveLoaded, lineChartCampaignObjectiveLoading,
    maleCounterror, isMaleCountLoaded, isMaleCountLoading,
    femaleCounterror, isFemaleCountLoaded, isFemaleCountLoading,
    stateDataerror, isStateDataLoaded, isStateDataLoading,
    countryDataerror, isCountryDataLoaded, isCountryDataLoading,
    deviceDistributionerror, isDeviceDistributionLoaded, isDeviceDistributionLoading,
    campaignSummaryerror, isCampaignSummaryLoaded, isCampaignSummaryLoading,
  ])


  return (
    <Wrapper>
      {metricCardsSection}
      <div
        id="campaign_objective_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">Objective Wise Distribution</h2>
              <SectionDefinationsBadge
                title={"Objective Wise Distribution"}
                module="campaign"
                platform="facebook"
                section="overall"
              />
            </div>
            <Modal
              open={isLineChartCampaignOpen}
              setOpen={setIsLineChartCampaignOpen}
              exportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"multiLineChart_2"}
                  sectionName={"Campaign Objective Wise distribution"}
                  sectionData={campaignObjectCSV}
                />}
              parentId={"campaign_objective_section"}
              title={`Objective Wise Distribution`}
              chart={lineChartCampaignSection2}
            />
            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && <button onClick={() => setIsLineChartCampaignOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                <ExpandIcon />
              </button>}
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"campaign_objective_section"}
                sectionName={"Campaign Objective Wise distribution"}
                sectionData={campaignObjectCSV}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_1 ">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {childDonutCampaignChart}
              </div>
            </div>
          </div>
          <div className="grid col_span_3 ">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {lineChartCampaignSection}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section_card section_dropdown_header">
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">Performance By KPI</h2>
              <SectionDefinationsBadge
                title={"Performance By KPI"}
                module="campaign"
                platform="facebook"
                section="overall"
              />
            </div>
            <Dropdown
              ismulti={false}
              placeholder={"Select"}
              options={midSectionDropdonwOptions}
              className="form_dropdown section_dropdown"
              value={selectedMidSectionDropdownOption}
              selectedOptions={selectedMidSectionDropdownOption}
              setStatedropdown={midSectionDropdownSelectionHandler}
            />
          </div>
        </div>
      </div>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div
          id="audience_distribution"
          className="grid col_span_3 section_card"
        >
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">
                {AudienceDistributionSectionTitle} {": " + selectedMidSectionDropdownOption.label} </h2>
              <SectionDefinationsBadge
                title={AudienceDistributionSectionTitle + " By Gender"}
                module="campaign"
                platform="facebook"
                section="overall"
              />
            </div>

            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"audience_distribution"}
                sectionName={"Audience Distribution"}
                sectionData={maleFemaleDataCSV}

              />
            </div>
          </div>
          <div className="grid grid_cols_4">
            <div className="grid col_span_2 card_border_right flex column">
              <div className="section_card_header">
                <h2 className="section_card_title">Male

                </h2>
              </div>
              <div className="grid grid_cols_4 section_card_body">
                <div className="grid col_span_1 flex column justify_center align_center">
                  {maleAudienceDistributionIcon}
                </div>
                <div className="grid col_span_3 section_card_chart_wrapper">
                  {maleAudienceDistribution}
                </div>
              </div>
            </div>
            <div className="grid col_span_2 flex column">
              <div className="grid col_span_4 section_card_header">
                <h2 className="section_card_title">Female

                </h2>
              </div>
              <div className="grid grid_cols_4 section_card_body">
                <div className="grid col_span_1 flex column justify_center align_center">
                  {femaleAudienceDistributionIocn}
                </div>
                <div className="grid col_span_3 section_card_chart_wrapper">
                  {femaleAudienceDistribution}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="device_distribution" className="grid col_span_1 section_card">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">
                Device Distribution{": " + selectedMidSectionDropdownOption.label}</h2>
              <SectionDefinationsBadge
                title={"Device Distribution"}
                module="campaign"
                platform="facebook"
                section="overall"
              />
            </div>

            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"device_distribution"}
                sectionName={"CampaignDeviceDistDynaLabels_Device Distribution"}
                sectionData={deviceDistributionExport}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {deviceDistributionChart}
            </div>
          </div>
        </div>
      </div>
      <div
        id="audience_distribution_place"

      >
        <div className="section_card section_dropdown_header">
          <div className="grid grid_cols_1">
            <div className="section_card_header">
              <div className="section_info">
                <h2 className="section_card_title">
                  {AudienceDistributionSectionTitle} {/* : {selectedMidSectionDropdownOption.label} */}
                </h2>
                <SectionDefinationsBadge
                  title={AudienceDistributionSectionTitle}
                  module="campaign"
                  platform="facebook"
                  section="overall"
                />
              </div>
            </div>
            {/*  <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"audience_distribution_place"}
                sectionName={"Audience Distribution"}
                sectionData={cityCountryData}
              />
            </div> */}
          </div>
        </div>

        {companyID === HALEON_COMPANY_ID ?
          <Wrapper>
            <div className="grid  grid_margin_bottom ">
              <Tab
                varient={"primary"}
                tabData={tabData}
                activeTab={selectedTab}
                handleCallback={(event) => handleCallback(event)}
              />
            </div>
            <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
              {selectedTab === "By Country" && (
                <Wrapper>
                  {countryMap}
                </Wrapper>
              )}
              {selectedTab === "By State" && (
                <Wrapper>
                  {stateTable}
                </Wrapper>
              )}
            </div>
          </Wrapper>
          :
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_2 card_border_right flex column">
              {countryMap}
            </div>
            <div className="grid col_span_2 flex column">
              {stateTable}
            </div>
          </div>
        }

      </div>
      {campaignSummaryTable}
    </Wrapper>
  );
};

export const CampaignFacebookOverallCoke = ({ topCardData }) => {
  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence";
  let pageSubModule = "Campaign";
  let pagePlatform = "Facebook";
  let pagePrimaryTab = "Overall";

  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled } = useContext(FullPageExportContext);

  const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)
  const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)

  // USER DATA
  const userData = useSelector((state) => state.userData.user[0]);
  let companyID = userData?.company?.id ?? "";

  let AudienceDistributionSectionTitle = "";
  if (companyID === HALEON_COMPANY_ID) {
    AudienceDistributionSectionTitle = "Audience Distribution - Meta level";
  } else {
    AudienceDistributionSectionTitle = "Audience Distribution";
  }

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (
    globalSelectedBrandIds.length === 0 ||
    globalSelectedBrandIds === undefined
  ) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // GLOBAL CAMPAIGN DROPDOWN
  const selectedCampaignPlatformsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignPlatform
  );
  const selectedCampaignObjectivesFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignObjectives
  );
  const selectedCampaignsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaigns
  );
  const selectedBucketsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedBuckets
  );
  const selectedCampaignIdandPlatform = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignIDandPlatform
  );

  // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
  let selectedPlatform = null;
  if (
    selectedCampaignPlatformsFromFilter.length === 0 ||
    selectedCampaignPlatformsFromFilter === undefined
  ) {
    selectedPlatform = null;
  } else {
    selectedPlatform = selectedCampaignPlatformsFromFilter.join(",");
  }

  // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
  let selectedObjectives = null;
  if (
    selectedCampaignObjectivesFromFilter.length === 0 ||
    selectedCampaignObjectivesFromFilter === undefined
  ) {
    selectedObjectives = null;
  } else {
    selectedObjectives = selectedCampaignObjectivesFromFilter.join(",");
  }

  // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
  let selectedCampaigns = null;
  if (
    selectedCampaignsFromFilter.length === 0 ||
    selectedCampaignsFromFilter === undefined
  ) {
    selectedCampaigns = null;
  } else {
    selectedCampaigns = selectedCampaignsFromFilter.join(",");
  }

  let selectedIdandPlatform = null;
  if (selectedCampaignIdandPlatform === undefined || selectedCampaignIdandPlatform.length === 0) {
    selectedIdandPlatform = null;
  } else {
    selectedIdandPlatform = JSON.stringify(selectedCampaignIdandPlatform);
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // REACH METRIC CARDS
  const [reachMetricCards, setReachMetricCards] = useState([]);
  const [isReachMetricCardsLoading, setReachMetricCardsIsLoading] =
    useState(false);
  const [isReachMetricCardsLoaded, setReachMetricCardsIsLoaded] =
    useState(false);
  const [reachMetricCardserror, setReachMetricCardsError] = useState(null);

  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (DONUT)
  const [childDonutSliceToggle, setChildDonutSliceToggle] = useState(null);
  const [childDonutCampaignObjective, setChildDonutCampaignObjective] =
    useState([]);
  const [
    childDonutCampaignObjectiveLoading,
    setChildDonutCampaignObjectiveLoading,
  ] = useState([]);
  const [
    childDonutCampaignObjectiveLoaded,
    setChildDonutCampaignObjectiveLoaded,
  ] = useState([]);
  const [
    childDonutCampaignObjectiveError,
    setChildDonutCampaignObjectiveError,
  ] = useState([]);

  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (LINECHART)
  const [lineChartCampaignObjective, setLineChartCampaignObjective] = useState(
    []
  );
  const [lineChartCampaignObjectiveLabel, setLineChartCampaignObjectiveLabel] =
    useState([]);
  const [
    lineChartCampaignObjectiveLoading,
    setLineChartCampaignObjectiveLoading,
  ] = useState([]);
  const [
    lineChartCampaignObjectiveLoaded,
    setLineChartCampaignObjectiveLoaded,
  ] = useState([]);
  const [lineChartCampaignObjectiveError, setLineChartCampaignObjectiveError] =
    useState([]);

  // MID SECTION DROPDOWN
  // DROPDOWN OPTIONS
  const midSectionDropdonwOptions = [
    { value: "impressions", label: "Impressions" },
    { value: "clicks", label: "Clicks" },
    { value: "conversions", label: "Conversions" },
    { value: "engagements", label: "Engagements" },
    { value: "spends", label: "Spends" },
  ];

  // DROPDOWN DEFAULT STATE
  const [
    selectedMidSectionDropdownOption,
    setSelectedMidSectionDropdownDropdownOption,
  ] = useState(midSectionDropdonwOptions[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const midSectionDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedMidSectionDropdownDropdownOption("");
    } else {
      setSelectedMidSectionDropdownDropdownOption(e);
    }
    IAEvent_Dropdown_Visit(
      "Marketing Intelligence",
      "Campaigns",
      "Facebook",
      "Overall",
      null,
      "Performance By KPI",
      e.label
    );
  };

  // DEVICE DISTRIBUTION
  const [deviceDistribution, setDeviceDistribution] = useState([]);
  const [
    deviceDistributionAllDropdownsData,
    setDeviceDistributionAllDropdownsData,
  ] = useState([]);
  const [isDeviceDistributionLoading, setDeviceDistributionIsLoading] =
    useState(false);
  const [isDeviceDistributionLoaded, setDeviceDistributionIsLoaded] =
    useState(false);
  const [deviceDistributionerror, setDeviceDistributionError] = useState(null);

  // MALE COUNT
  const [maleCount, setMaleCount] = useState([]);
  const [maleCountAllDropdownsData, setMaleCountAllDropdownsData] = useState(
    []
  );
  const [maleCountLabels, setMaleCountLabels] = useState([]);
  const [maleCountAllDropdownsDataLabels, setMaleCountAllDropdownsDataLabels] =
    useState([]);
  const [totalMaleCount, setTotalMaleCount] = useState(0);
  const [totalMaleCountAllDropdownsData, setTotalMaleCountAllDropdownsData] =
    useState([]);
  const [isMaleCountLoading, setMaleCountIsLoading] = useState(false);
  const [isMaleCountLoaded, setMaleCountIsLoaded] = useState(false);
  const [maleCounterror, setMaleCountError] = useState(null);

  // FEMALE COUNT
  const [femaleCount, setFemaleCount] = useState([]);
  const [femaleCountAllDropdownsData, setFemaleCountAllDropdownsData] =
    useState([]);
  const [femaleCountLabels, setFemaleCountLabels] = useState([]);
  const [
    femaleCountAllDropdownsDataLabels,
    setFemaleCountAllDropdownsDataLabels,
  ] = useState([]);
  const [totalFemaleCount, setTotalFemaleCount] = useState(0);
  const [
    totalFemaleCountAllDropdownsData,
    setTotalFemaleCountAllDropdownsData,
  ] = useState([]);
  const [isFemaleCountLoading, setFemaleCountIsLoading] = useState(false);
  const [isFemaleCountLoaded, setFemaleCountIsLoaded] = useState(false);
  const [femaleCounterror, setFemaleCountError] = useState(null);

  // COUNTRY
  const [countryData, setCountryData] = useState([]);
  const [countryAllDropdownsData, setCountryAllDropdownsData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  let CountryTableDataHeaders = [];

  let HaleonCountryTableDataHeaders = [
    { name: "Country", field: "country" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Conversions", field: "conversions" },
    { name: "Engagements", field: "total_engagement" },
    { name: "Spends", field: "spends" },
    { name: "Engagement Rate", field: "engagement_rate" },
    { name: "CTR", field: "ctr" },
    { name: "Interaction Rate", field: "interaction_rate" },
  ];

  let DefaultCountryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: selectedMidSectionDropdownOption.label, field: "value" },
    { name: "Change", field: "percentage_growth" },
  ];

  if (companyID === HALEON_COMPANY_ID) {
    CountryTableDataHeaders = HaleonCountryTableDataHeaders;
  } else {
    CountryTableDataHeaders = DefaultCountryTableDataHeaders;
  }

  // STATE
  const [stateData, setStateData] = useState([]);
  const [stateAllDropdownsData, setStateAllDropdownsData] = useState([]);
  const [isStateDataLoading, setStateDataIsLoading] = useState(false);
  const [isStateDataLoaded, setStateDataIsLoaded] = useState(false);
  const [stateDataerror, setStateDataError] = useState(null);

  let StateTableDataHeaders = [];

  let HaleonStateTableDataHeaders = [
    { name: "State", field: "region" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Conversions", field: "conversions" },
    { name: "Engagements", field: "total_engagement" },
    { name: "Spends", field: "spends" },
    { name: "Engagement Rate", field: "engagement_rate" },
    { name: "CTR", field: "ctr" },
    { name: "Interaction Rate", field: "interaction_rate" },
  ];

  let DefaultStateTableDataHeaders = [
    { name: "State", field: "name" },
    { name: selectedMidSectionDropdownOption.label, field: "value" },
    { name: "Change", field: "percentage_growth" },
  ];

  if (companyID === HALEON_COMPANY_ID) {
    StateTableDataHeaders = HaleonStateTableDataHeaders;
  } else {
    StateTableDataHeaders = DefaultStateTableDataHeaders;
  }

  // CAMPAIGN SUMMARY
  const [campaignSummary, setCampaignSummary] = useState([]);
  const [campaignSummaryTableHeaders, setCampaignSummaryTableHeaders] = useState([]);
  const [campaignSummaryExportData, setCampaignSummaryExportData] = useState([]);
  const [campaignSummaryAllDropdownsData, setCampaignSummaryAllDropdownsData] = useState([]);
  const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] = useState(false);
  const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
  const [campaignSummaryerror, setCampaignSummaryError] = useState(null);
  const [campaignSummaryTablePageData, setCampaignSummaryTablePageData] = useState({})


  // CAMPAIGN SUMMARY DROPDOWN
  // CAMPAIGN SUMMARY DROPDOWN OPTIONS
  const campaignSummaryOptions = [
    { value: "campaign", label: "Campaign" },
    { value: "ad", label: "Ad" },
    { value: "ad_group", label: "Ad Group" },
  ];

  const [campaignSummaryConfig, setCampaignSummaryConfig] = useState({ page_num: 1, page_size: 10, order_type: "desc", order_by: "clicks", search_value: null, dropdown_value: campaignSummaryOptions[0].value })

  // CAMPAIGN SUMMARY DROPDOWN DEFAULT STATE
  const [
    selectedCampaignSummaryDropdownOption,
    setSelectedCampaignSummaryDropdownOption,
  ] = useState(campaignSummaryOptions[0]);

  // TABLE HEADERS FOR COKE
  let cokeSummaryTableHeaders = [
    { name: `${selectedCampaignSummaryDropdownOption.label + " Name"}`, field: "campaign_name" },
    { name: "Objective", field: "objective" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Spends", field: "spends" },
    { name: "Conversions", field: "conversions" },
    { name: "CTR", field: "ctr" },
    { name: "CPM", field: "cpm" },
    { name: "Benchmark CPM", field: "benchmark_cpm" },
    { name: "CPC", field: "cpc" },
    { name: "Benchmark CPC", field: "benchmark_cpc" },
    { name: "CPV", field: "cpv" },
    { name: "Benchmark CPV", field: "benchmark_cpv" },
    { name: "CR", field: "cr" },
    { name: "Views", field: "views" },
    { name: "View Rate", field: "view_rate" },
    { name: "Benchmark View Rate", field: "benchmark_view_rate" },
    { name: "Frequency", field: "frequency" },
    { name: "Reach Rate", field: "reach_rate" },
    { name: "Engagement Rate", field: "engagement_rate" },
  ];

  let cokeCamapignSummaryTableHeaders = [
    { name: `${selectedCampaignSummaryDropdownOption.label + " Name"}`, field: "campaign_name" },
    { name: "Objective", field: "objective" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Spends", field: "spends" },
    { name: "Conversions", field: "conversions" },
    { name: "CTR", field: "ctr" },
    { name: "CPM", field: "cpm" },
    { name: "Benchmark CPM", field: "benchmark_cpm" },
    { name: "CPC", field: "cpc" },
    { name: "Benchmark CPC", field: "benchmark_cpc" },
    { name: "CPV", field: "cpv" },
    { name: "Benchmark CPV", field: "benchmark_cpv" },
    { name: "CR", field: "cr" },
    { name: "Views", field: "views" },
    { name: "View Rate", field: "view_rate" },
    { name: "Benchmark View Rate", field: "benchmark_view_rate" },
    { name: "Frequency", field: "frequency" },
    { name: "Reach", field: "reach" },
    { name: "Reach Rate", field: "reach_rate" },
    { name: "Engagement Rate", field: "engagement_rate" },
  ];

  let defaultSummaryTableHeaders = [
    { name: `${selectedCampaignSummaryDropdownOption.label + " Name"}`, field: "campaign_name" },
    { name: "Objective", field: "objective" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Spends", field: "spends" },
    { name: "Conversions", field: "conversions" },
    { name: "CTR", field: "ctr" },
    { name: "CPM", field: "cpm" },
    { name: "CPC", field: "cpc" },
    { name: "CPV", field: "cpv" },
    { name: "CR", field: "cr" },
    { name: "Views", field: "views" },
    { name: "Engagement Rate", field: "engagement_rate" },
  ];


  // CAMPAIGN SUMMARY DROPDOWN OPTIONS SELECTION HANDLER
  const campaignSummaryDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedCampaignSummaryDropdownOption("");
    } else {
      setSelectedCampaignSummaryDropdownOption(e);
      setCampaignSummaryConfig({ ...campaignSummaryConfig, dropdown_value: e.value, page_num: 1, search_value: null })

    }
    IAEvent_Dropdown_Visit(
      "Marketing Intelligence",
      "Campaigns",
      "Facebook",
      "Overall",
      null,
      "Summary",
      e.label
    );
  };

  // CHANGE CAMPAIGN SUMMARY DATA AS PER THE DROPDOWN VALUE
  const fetchCampaignSummaryDropdownHandler = async () => {
    if (selectedCampaignSummaryDropdownOption.value === "ad") {

      setCampaignSummary(campaignSummaryAllDropdownsData?.ad ?? []);

    } else if (selectedCampaignSummaryDropdownOption.value === "ad_group") {

      setCampaignSummary(campaignSummaryAllDropdownsData?.ad_group ?? []);

    } else if (selectedCampaignSummaryDropdownOption.value === "campaign") {

      setCampaignSummary(campaignSummaryAllDropdownsData?.campaign ?? []);

    } else {
      setCampaignSummary([]);
    }
  };


  useEffect(() => {
    // if (companyID === COKE_COMPANY_ID || companyID === HALEON_COMPANY_ID) {
    //   if (selectedCampaignSummaryDropdownOption.value === "campaign") {
    //     setCampaignSummaryTableHeaders(cokeCamapignSummaryTableHeaders);
    //   } else {
    //     setCampaignSummaryTableHeaders(cokeSummaryTableHeaders);
    //   }
    // } else {
    //   setCampaignSummaryTableHeaders(defaultSummaryTableHeaders);
    // }
  }, [
    BRAND_ID,
    selectedDates,
    selectedCampaignSummaryDropdownOption,
    selectedBucketsFromFilter,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform
  ])


  // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchCampaignSummaryDropdownHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedCampaignSummaryDropdownOption,
    selectedBucketsFromFilter,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
  ]);

  useEffect(() => {
    (metricCards !== undefined || metricCards.length !== 0) &&
      fetchReachMetricCardsHandler();
  }, [
    BRAND_ID,
    metricCards,
    selectedDates,
    selectedBucketsFromFilter,
    selectedObjectives,
    selectedIdandPlatform,
    selectedCampaigns,
    selectedPlatform,
  ]);

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchDeviceDistributionHandler();
    fetchAudienceDistributionHandler();
    fetchCountryHandler();
    fetchStateHandler();
    // fetchCampaignSummaryHandler();
    fetchChildDonutCampaignHandler();
    setSelectedCampaignSummaryDropdownOption(campaignSummaryOptions[0]); // TO SET THE SUMMARY TABLE DROPDOWN TO DEFAULT STATE
  }, [
    BRAND_ID,
    selectedDates,
    selectedBucketsFromFilter,
    selectedObjectives,
    selectedIdandPlatform,
    selectedCampaigns,
    selectedPlatform,
  ]);

  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const fetchMidSectionDropdownHandler = async () => {
    if (selectedMidSectionDropdownOption.value === "clicks") {
      setDeviceDistribution(deviceDistributionAllDropdownsData?.clicks ?? []);
      setMaleCount(maleCountAllDropdownsData?.clicks ?? []);
      setMaleCountLabels(maleCountAllDropdownsDataLabels?.clicks ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.clicks ?? []);
      setFemaleCount(femaleCountAllDropdownsData?.clicks ?? []);
      setFemaleCountLabels(femaleCountAllDropdownsDataLabels?.clicks ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.clicks ?? []);

      if (companyID !== HALEON_COMPANY_ID) {
        setCountryData(countryAllDropdownsData?.clicks ?? []);
        setStateData(stateAllDropdownsData?.clicks ?? []);
      }
    } else if (selectedMidSectionDropdownOption.value === "conversions") {
      setDeviceDistribution(deviceDistributionAllDropdownsData?.conversions ?? []);
      setMaleCount(maleCountAllDropdownsData?.conversions ?? []);
      setMaleCountLabels(maleCountAllDropdownsDataLabels?.conversions ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.conversions ?? []);
      setFemaleCount(femaleCountAllDropdownsData?.conversions ?? []);
      setFemaleCountLabels(femaleCountAllDropdownsDataLabels?.conversions ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.conversions ?? []);
      if (companyID !== HALEON_COMPANY_ID) {
        setCountryData(countryAllDropdownsData?.conversions ?? []);
        setStateData(stateAllDropdownsData?.conversions ?? []);
      }
    } else if (selectedMidSectionDropdownOption.value === "engagements") {
      setDeviceDistribution(deviceDistributionAllDropdownsData?.engagement ?? []);
      setMaleCount(maleCountAllDropdownsData?.engagement ?? []);
      setMaleCountLabels(maleCountAllDropdownsDataLabels?.engagement ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.engagement ?? []);
      setFemaleCount(femaleCountAllDropdownsData?.engagement ?? []);
      setFemaleCountLabels(femaleCountAllDropdownsDataLabels?.engagement ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.engagement ?? []);
      if (companyID !== HALEON_COMPANY_ID) {
        setCountryData(countryAllDropdownsData?.engagement ?? []);
        setStateData(stateAllDropdownsData?.engagement ?? []);
      }
    } else if (selectedMidSectionDropdownOption.value === "impressions") {

      setDeviceDistribution(deviceDistributionAllDropdownsData?.impressions ?? []);
      setMaleCount(maleCountAllDropdownsData?.impressions ?? []);
      setMaleCountLabels(maleCountAllDropdownsDataLabels?.impressions ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.impressions ?? []);
      setFemaleCount(femaleCountAllDropdownsData?.impressions ?? []);
      setFemaleCountLabels(femaleCountAllDropdownsDataLabels?.impressions ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.impressions ?? []);
      if (companyID !== HALEON_COMPANY_ID) {
        setCountryData(countryAllDropdownsData?.impressions ?? []);
        setStateData(stateAllDropdownsData?.impressions ?? []);
      }

    } else if (selectedMidSectionDropdownOption.value === "spends") {

      setDeviceDistribution(deviceDistributionAllDropdownsData?.spend ?? []);
      setMaleCount(maleCountAllDropdownsData?.spend ?? []);
      setMaleCountLabels(maleCountAllDropdownsDataLabels?.spend ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.spend ?? []);
      setFemaleCount(femaleCountAllDropdownsData?.spend ?? []);
      setFemaleCountLabels(femaleCountAllDropdownsDataLabels?.spend ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.spend ?? []);
      if (companyID !== HALEON_COMPANY_ID) {
        setCountryData(countryAllDropdownsData?.spend ?? []);
        setStateData(stateAllDropdownsData?.spend ?? []);
      }

    } else {
      setDeviceDistribution([]);
      setMaleCount([]);
      setMaleCountLabels([]);
      setTotalMaleCount([]);
      setFemaleCount([]);
      setFemaleCountLabels([]);
      setTotalFemaleCount([]);

      if (companyID !== HALEON_COMPANY_ID) {
        setCountryData([]);
        setStateData([]);
      }
    }
  };

  // CHNAGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchMidSectionDropdownHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedMidSectionDropdownOption,
    selectedBucketsFromFilter,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
  ]);

  // PLANNED VS DELIVERED TABLE

  /*  const plannedVsDeliveredTableHeaders = [
     { name: "Campaign Name", field: "campaign_names" },
     { name: "Start date", field: "start_date" },
     { name: "End date", field: "end_date" },
 
     { name: "Market/TG", field: "type" },
     { name: "Markets(State)", field: "region" },
 
     { name: "Planned Reach", field: "planned_reach" },
     { name: "Delivered Reach", field: "delivered_reach" },
     { name: "% achieved", field: "achieved_reach" },
 
     { name: "Planned Impressions", field: "planned_impressions" },
     { name: "Delivered Impressions", field: "delivered_impressions" },
     { name: "% achieved", field: "achieved_impressions" },
 
     { name: "Planned Frequency", field: "planned_frequency" },
     { name: "Delivered Frequency", field: "delivered_frequency" },
     { name: "% achieved", field: "achieved_frequency" },
 
     { name: "Planned CTR %", field: "planned_ctr" },
     { name: "Delivered CTR %", field: "delivered_ctr" },
     { name: "% achieved", field: "achieved_ctr" },
 
     { name: "Planned VTR %", field: "planned_vtr" },
     { name: "Delivered VTR %", field: "delivered_vtr" },
     { name: "% achieved", field: "achieved_vtr" },
 
     { name: "Planned Spends", field: "planned_spends" },
     { name: "Delivered Spends", field: "delivered_vtr" },
     { name: "% achieved", field: "achieved_spend" },
   ]; */




  // PLANNED VS DELIVERED METRICS DATA
  const [plannedVsDeliveredMetricsData, setPlannedVsDeliveredMetricsData] =
    useState([]);
  const [
    plannedVsDeliveredMetricsDataLabels,
    setPlannedVsDeliveredMetricsDataLabels,
  ] = useState([]);

  const [
    isplannedVsDeliveredMetricsLoading,
    setPlannedVsDeliveredMetricsIsLoading,
  ] = useState(false);
  const [
    isplannedVsDeliveredMetricsLoaded,
    setPlannedVsDeliveredMetricsIsLoaded,
  ] = useState(false);
  const [plannedVsDeliveredMetricsError, setPlannedVsDeliveredMetricsError] =
    useState(null);

  const [plannedVsDeliveredTableData, setPlannedVsDeliveredTableData] = useState([]);
  const [plannedVsDeliveredTableDataTotal, setPlannedVsDeliveredTableDataTotal] = useState([]);
  const [plannedVsDeliveredTableDataHeaders, setPlannedVsDeliveredTableDataHaeders] = useState([]);
  const [
    isPlannedVsDeliveredTableDataLoading,
    setPlannedVsDeliveredTableDataIsLoading,
  ] = useState(false);
  const [
    isPlannedVsDeliveredTableDataLoaded,
    setPlannedVsDeliveredTableDataIsLoaded,
  ] = useState(false);
  const [
    plannedVsDeliveredTableDataerror,
    setPlannedVsDeliveredTableDataError,
  ] = useState(null);

  let dropdownOptionsMarketTg = [
    { value: "market", label: "Market" },
    { value: "tg", label: "TG" },
  ];

  let tgDropdownOptions = [
    { value: "18-34 male female", label: "18-34 Male / Female" },
    { value: "18-64 male female", label: "18-64 Male / Female" },

  ]

  // DROPDOWN STATE
  const [selectedMarketTgDropdownOption, setSelectedMarketTgDropdownOption] =
    useState(dropdownOptionsMarketTg[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownMainSelectionHandler = (e) => {
    if (e === null) {
      setSelectedMarketTgDropdownOption("");
    } else {
      setSelectedMarketTgDropdownOption(e);
    }
  };


  const [regionDropdownOptionsOnLoad, setRegionDropdownOptionsOnLoad] =
    useState([]);
  const [regionDropdownOptions, setRegionDropdownOptions] = useState([]);
  const [regionDropdownOptionsIsLoading, setRegionDropdownOptionsIsLoading] =
    useState(true);
  const [regionDropdownOptionsIsLoaded, setRegionDropdownOptionsIsLoaded] =
    useState(false);

  const fetchRegionDropdownHandler = async () => {
    setRegionDropdownOptionsIsLoading(true);
    setRegionDropdownOptionsIsLoaded(false);

    let getDropdownType = selectedMarketTgDropdownOption.value === 'tg' ? 'tg' : 'market';
    let getIsTg = selectedMarketTgDropdownOption.value === 'tg' ? true : false;

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        drop_down_type: getDropdownType,
        is_tg: getIsTg
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/summary/planned-delivered-dropdown/",
        config
      );

      const regionsDDOpt = res?.data.map((item) => ({
        label: item.planned_label,
        value: item.mapping_id
      }))

      setRegionDropdownOptions(regionsDDOpt ?? []);
      setRegionDropdownOptionsOnLoad(regionsDDOpt ?? []);
      setSelectedRegionDropdownOption(regionsDDOpt[0] ?? []);
      setRegionDropdownOptionsIsLoading(false);
      setRegionDropdownOptionsIsLoaded(true);

    } catch (error) {
      setRegionDropdownOptions([]);
      setRegionDropdownOptionsIsLoading(false);
      setRegionDropdownOptionsIsLoaded(false);

      setPlannedVsDeliveredMetricsIsLoading(false);
      setPlannedVsDeliveredMetricsIsLoaded(false);
      setPlannedVsDeliveredMetricsError(error.code);

      setPlannedVsDeliveredTableDataIsLoading(false);
      setPlannedVsDeliveredTableDataIsLoaded(false);
      setPlannedVsDeliveredTableDataError(error.code);
    }
  };

  useEffect(() => {
    fetchRegionDropdownHandler();
  }, [
    selectedBucketsFromFilter,
    selectedObjectives,
    selectedIdandPlatform,
    selectedCampaigns,
    selectedPlatform,
    selectedMarketTgDropdownOption
  ]);

  // DROPDOWN STATE
  const [selectedRegionDropdownOption, setSelectedRegionDropdownOption] =
    useState([]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownRegionSelectionHandler = (e) => {
    if (e === null) {
      setSelectedRegionDropdownOption("");
    } else {
      setSelectedRegionDropdownOption(e);
    }

    IAEvent_Dropdown_Visit(
      pageModule,
      pageSubModule,
      pagePlatform,
      pagePrimaryTab,
      null,
      "PlannedVsDelivered",
      e.label
    );
  };

  // DROPDOWN STATE

  // TO CHANGE DATA AS PER THE DROPDOWN VALUE
  // const fetchMarketTgDropdownHandler = () => {
  //   if (selectedMarketTgDropdownOption.value === "market") {
  //     setRegionDropdownOptionsOnLoad(regionDropdownOptions);
  //     setSelectedRegionDropdownOption(regionDropdownOptions[0]);
  //   } else if (selectedMarketTgDropdownOption.value === "tg") {
  //     setRegionDropdownOptionsOnLoad(tgDropdownOptions);
  //     setSelectedRegionDropdownOption(tgDropdownOptions[0]);
  //   } else {
  //     setRegionDropdownOptionsOnLoad([]);
  //   }
  // };

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
  // useEffect(() => {
  //   fetchMarketTgDropdownHandler();
  // }, [
  //   BRAND_ID,
  //   selectedDates,
  //   selectedBucketsFromFilter,
  //   selectedObjectives,
  //   selectedIdandPlatform,
  //   selectedCampaigns,
  //   selectedPlatform,
  //   selectedMarketTgDropdownOption
  // ]);

  // useEffect(() => {
  //   if (selectedMarketTgDropdownOption.value === "market") {
  //     setRegionDropdownOptionsOnLoad(regionDropdownOptions);
  //     setSelectedRegionDropdownOption(regionDropdownOptions[0]);
  //   } else if (selectedMarketTgDropdownOption.value === "tg") {
  //     setRegionDropdownOptionsOnLoad(tgDropdownOptions);
  //     setSelectedRegionDropdownOption(tgDropdownOptions[0]);
  //   }
  // }, [BRAND_ID, selectedDates, selectedMarketTgDropdownOption]);
  useEffect(() => {
    if (!regionDropdownOptionsIsLoading && regionDropdownOptionsIsLoaded) {
      fetchPlannedVsDeliveredMetricsHandler();
      fetchPlannedVsDeliveredTableDataHandler();
    }
  }, [
    regionDropdownOptionsIsLoaded,
    regionDropdownOptionsIsLoading,
    selectedRegionDropdownOption
  ]);

  // useEffect(() => {
  //   setSelectedMarketTgDropdownOption({ value: "market", label: "Market" });
  //   setSelectedRegionDropdownOption(regionDropdownOptions[0]);
  // }, [
  //   BRAND_ID,
  //   selectedDates,
  //   selectedBucketsFromFilter,
  //   selectedObjectives,
  //   selectedIdandPlatform,
  //   selectedCampaigns,
  //   selectedPlatform
  // ]);


  // useEffect(() => {
  //   // setSelectedMarketTgDropdownOption({ value: "market", label: "Market" });
  //   // setSelectedRegionDropdownOption(regionDropdownOptions[0]);
  //   if (selectedMarketTgDropdownOption.value === "market" && selectedRegionDropdownOption.value === "India") {
  //     fetchPlannedVsDeliveredMetricsHandler();
  //     fetchPlannedVsDeliveredTableDataHandler();
  //   }
  // }, []);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0 ||
            selectedBucketsFromFilter === undefined
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/facebook/metric-card/", config);
      setMetricCards(res?.data?.data ?? []);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // TO FETCH REACH METRIC CARDS FROM API
  const fetchReachMetricCardsHandler = async () => {
    setReachMetricCardsIsLoading(true);
    setReachMetricCardsIsLoaded(false);
    setReachMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0 ||
            selectedBucketsFromFilter === undefined
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/facebook/facebook-instagram-reach/",
        config
      );
      setReachMetricCards(res?.data?.facebook?.data ?? []);
      setReachMetricCardsIsLoading(false);
      setReachMetricCardsIsLoaded(true);
      setReachMetricCardsError(null);
    } catch (error) {
      setReachMetricCards([]);
      setReachMetricCardsIsLoading(false);
      setReachMetricCardsIsLoaded(false);
      setReachMetricCardsError(error.code);
    }
  };

  let static_data = [
    {
      label: "Reach",
      currentValue: "loading",
      previousValue: "loading",
    },
    {
      label: "Reach Rate",
      currentValue: "loading",
      previousValue: "loading",
    },
    {
      label: "Frequency",
      currentValue: "loading",
      previousValue: "loading",
    },
  ];

  let error_static_data = [
    {
      label: "Reach",
      currentValue: "error",
      previousValue: "error",
    },
    {
      label: "Reach Rate",
      currentValue: "error",
      previousValue: "error",
    },
    {
      label: "Frequency",
      currentValue: "error",
      previousValue: "error",
    },
  ];

  let combineMetricCardsData = useMemo(() => {
    let temp = [];

    if (isReachMetricCardsLoading && !isReachMetricCardsLoaded) {
      temp = metricCards.concat(static_data);
    } else if (reachMetricCardserror) {
      temp = metricCards.concat(error_static_data);
    } else {
      temp = metricCards.concat(reachMetricCards);
    }

    return temp;
  }, [
    metricCards,
    reachMetricCards,
    isReachMetricCardsLoading,
    isReachMetricCardsLoaded,
  ]);

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">
            <h2 className="section_card_title">Summary</h2>
          </div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(combineMetricCardsData)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">
                {" "}
                <h2 className="section_card_title">Summary</h2>
              </div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary"}>
              {combineMetricCardsData.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="campaign"
                    platform="facebook"
                    section="overall"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">
              {" "}
              <h2 className="section_card_title">Summary</h2>
            </div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">
              <h2 className="section_card_title">Summary</h2>
            </div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (DONUT)

  const fetchChildDonutCampaignHandler = async () => {
    setChildDonutCampaignObjectiveLoading(true);
    setChildDonutCampaignObjectiveLoaded(false);
    setChildDonutCampaignObjectiveError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0 ||
            selectedBucketsFromFilter === undefined
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/facebook/campaign-objective/",
        config
      );

      setChildDonutCampaignObjective(res?.data ?? []);
      setChildDonutCampaignObjectiveLoading(false);
      setChildDonutCampaignObjectiveLoaded(true);
      setChildDonutCampaignObjectiveError(null);
    } catch (error) {
      setChildDonutCampaignObjective([]);
      setChildDonutCampaignObjectiveLoading(false);
      setChildDonutCampaignObjectiveLoaded(false);
      setChildDonutCampaignObjectiveError(error.code);
    }
  };

  let childDonutCampaignChart = (
    <Wrapper>
      <Loader loaderType="donutChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (
    childDonutCampaignObjectiveLoaded &&
    !childDonutCampaignObjectiveLoading
  ) {
    if (NoDataAvailableChecker(childDonutCampaignObjective)) {
      childDonutCampaignChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="donutChartType" />
        </Wrapper>
      );
    } else {
      childDonutCampaignChart = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={childDonutCampaignObjective}
              chartId="CampaignDonutChild"
              onSliceClick={(data) => setChildDonutSliceToggle(data.object_key)}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (childDonutCampaignObjectiveError) {
    childDonutCampaignChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={childDonutCampaignObjectiveError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (childDonutCampaignObjectiveLoading) {
    childDonutCampaignChart = (
      <Wrapper>
        <Loader loaderType="donutChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (LINECHART)
  const [isLineChartCampaignOpen, setIsLineChartCampaignOpen] = useState(false)

  let defaultLineChartCampaignDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultLineChartCampaignDisabledvalues = []
  } else {
    defaultLineChartCampaignDisabledvalues = ["Impressions", "Clicks", "Spends", "Conversions"]
  }

  const [lineChartCampaignDisabledvalues, setLineChartCampaignDisabledvalues] = useState(defaultLineChartCampaignDisabledvalues)

  const fetchLineChartCampaignHandler = async () => {
    setLineChartCampaignObjectiveLoading(true);
    setLineChartCampaignObjectiveLoaded(false);
    setLineChartCampaignObjectiveError(null);

    var objectiveConfig = (function () {
      if (
        selectedObjectives === null &&
        (childDonutSliceToggle === null || childDonutSliceToggle === undefined)
      ) {
        return { objective: null };
      } else if (
        selectedObjectives !== null &&
        (childDonutSliceToggle === undefined || childDonutSliceToggle === null)
      ) {
        return { objective: selectedObjectives };
      } else {
        return { objective: childDonutSliceToggle };
      }
    })();

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        // objective: selectedObjectives,
        platform: selectedPlatform,
        platform_campaign: selectedIdandPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0 ||
            selectedBucketsFromFilter === undefined
            ? null
            : selectedBucketsFromFilter.join(","),
        // objective: childDonutSliceToggle,
        ...objectiveConfig,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/facebook/objective-linechart/",
        config
      );

      setLineChartCampaignObjective(res?.data?.data ?? []);
      setLineChartCampaignObjectiveLabel(res?.data?.dimension ?? []);
      setLineChartCampaignObjectiveLoading(false);
      setLineChartCampaignObjectiveLoaded(true);
      setLineChartCampaignObjectiveError(null);
    } catch (error) {
      setLineChartCampaignObjective([]);
      setLineChartCampaignObjectiveLabel([]);
      setLineChartCampaignObjectiveLoading(false);
      setLineChartCampaignObjectiveLoaded(false);
      setLineChartCampaignObjectiveError(error.code);
    }
  };

  let lineChartCampaignSection = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  let lineChartCampaignSection2 = (
    <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (lineChartCampaignObjectiveLoaded && !lineChartCampaignObjectiveLoading) {
    if (
      NoDataAvailableChecker(lineChartCampaignObjective) ||
      NoDataAvailableChecker(lineChartCampaignObjectiveLabel)
    ) {
      lineChartCampaignSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );

      lineChartCampaignSection2 = (
        <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
    } else {
      if (isMultiLineCluster) {
        lineChartCampaignSection = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartClass="section_card_chart"
                chartData={lineChartCampaignObjective}
                chartId="multiLineChart"
                chartLabels={lineChartCampaignObjectiveLabel}
                onBulletsClick={function noRefCheck() { }}
                showLabels
                showLegend
                impressionLabel="impression"
                showImpression={companyID === HALEON_COMPANY_ID ? true : false}
                disabledLegendArray={lineChartCampaignDisabledvalues}
                setDisabledValues={setLineChartCampaignDisabledvalues}
              />

            </ErrorBoundary>
          </Wrapper>
        );

        lineChartCampaignSection2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedLineChart
                chartClass="section_card_chart"
                chartData={lineChartCampaignObjective}
                chartId="multiLineChart_2"
                chartLabels={lineChartCampaignObjectiveLabel}
                showLegend={true}
                disabledLegendArray={lineChartCampaignDisabledvalues}
                setDisabledValues={setLineChartCampaignDisabledvalues}
                showLabels={true}

              />
            </ErrorBoundary>
          </Wrapper>
        );
      } else {

        lineChartCampaignSection = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartClass="section_card_chart"
                chartData={lineChartCampaignObjective}
                chartId="multiLineChart"
                chartLabels={lineChartCampaignObjectiveLabel}
                onBulletsClick={function noRefCheck() { }}
                showLabels
                showLegend
                impressionLabel="impression"
                showImpression={companyID === HALEON_COMPANY_ID ? true : false}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (lineChartCampaignObjectiveError) {
    lineChartCampaignSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={lineChartCampaignObjectiveError}
        />
      </Wrapper>
    );

    lineChartCampaignSection2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={lineChartCampaignObjectiveError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (lineChartCampaignObjectiveLoading) {
    lineChartCampaignSection = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );

    lineChartCampaignSection2 = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // DEVICE DISTRIBUTION

  // TO FETCH DATA FROM API
  const fetchDeviceDistributionHandler = async () => {
    setDeviceDistributionIsLoading(true);
    setDeviceDistributionIsLoaded(false);
    setDeviceDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0 ||
            selectedBucketsFromFilter === undefined
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/facebook/device-distribution/",
        config
      );
      setDeviceDistribution(res?.data?.data?.impressions ?? []);
      setDeviceDistributionAllDropdownsData(res?.data?.data ?? []);
      setDeviceDistributionIsLoading(false);
      setDeviceDistributionIsLoaded(true);
      setDeviceDistributionError(null);
    } catch (error) {
      setDeviceDistributionAllDropdownsData([]);
      setDeviceDistributionIsLoading(false);
      setDeviceDistributionIsLoaded(false);
      setDeviceDistributionError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let deviceDistributionChart = (
    <Wrapper>
      <Loader loaderType="donutChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isDeviceDistributionLoaded && !isDeviceDistributionLoading) {
    if (NoDataAvailableChecker(deviceDistribution)) {
      deviceDistributionChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="donutChartType" />
        </Wrapper>
      );
    } else {
      deviceDistributionChart = (
        <DonutPieChart
          chartId={"reactions_sentiment"}
          chartClass={"section_card_chart"}
          chartData={deviceDistribution}
          showLegend={true}
          showLabels={true}
          showVerticalLabels={true}
        />
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (deviceDistributionerror) {
    deviceDistributionChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={deviceDistributionerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isDeviceDistributionLoading) {
    deviceDistributionChart = (
      <Wrapper>
        <Loader loaderType="donutChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: MALE / FEMALE

  // TO FETCH MALE / FEMALE DATA FROM API
  const fetchAudienceDistributionHandler = async () => {
    setMaleCountIsLoading(true);
    setMaleCountIsLoaded(false);
    setMaleCountError(null);

    setFemaleCountIsLoading(true);
    setFemaleCountIsLoaded(false);
    setFemaleCountError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0 ||
            selectedBucketsFromFilter === undefined
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/facebook/audience-demographics/",
        config
      );

      setMaleCount(res?.data?.male_list?.data?.impressions ?? []);
      setMaleCountLabels(res?.data?.dimension?.impressions ?? []);
      setTotalMaleCount(res?.data?.male_count?.impressions ?? []);
      setFemaleCount(res?.data?.female_list?.data?.impressions ?? []);
      setFemaleCountLabels(res?.data?.dimension?.impressions ?? []);
      setTotalFemaleCount(res?.data?.female_count?.impressions ?? []);

      setMaleCountAllDropdownsData(res?.data?.male_list?.data ?? []);
      setMaleCountAllDropdownsDataLabels(res?.data?.dimension ?? []);
      setTotalMaleCountAllDropdownsData(res?.data?.male_count ?? []);
      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(true);
      setMaleCountError(null);

      setFemaleCountAllDropdownsData(res?.data?.female_list?.data ?? []);
      setFemaleCountAllDropdownsDataLabels(res?.data?.dimension ?? []);
      setTotalFemaleCountAllDropdownsData(res?.data?.female_count ?? []);

      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(true);
      setFemaleCountError(null);
    } catch (error) {
      setMaleCountAllDropdownsData([]);
      setMaleCountAllDropdownsDataLabels([]);
      setTotalMaleCountAllDropdownsData([]);
      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(false);
      setMaleCountError(error.code);

      setFemaleCountAllDropdownsData([]);
      setFemaleCountAllDropdownsDataLabels([]);
      setTotalFemaleCountAllDropdownsData([]);
      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(false);
      setFemaleCountError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let maleAudienceDistribution = (
    <Wrapper>
      <Loader loaderType="barChartLoader" />
    </Wrapper>
  );
  let maleAudienceDistributionIcon = (
    <Wrapper>
      <Loader loaderType="genderLoader" />
    </Wrapper>
  );

  let totalMaleCountPercentage = 0;
  if (isNaN(totalMaleCount / (totalMaleCount + totalFemaleCount)) === true) {
    totalMaleCountPercentage = 0;
  } else {
    totalMaleCountPercentage =
      totalMaleCount / (totalMaleCount + totalFemaleCount);
  }

  let totalFemaleCountPercentage = 0;
  if (isNaN(totalFemaleCount / (totalMaleCount + totalFemaleCount)) === true) {
    totalFemaleCountPercentage = 0;
  } else {
    totalFemaleCountPercentage =
      totalFemaleCount / (totalMaleCount + totalFemaleCount);
  }

  // IF DATA IS AVAILABLE
  if (isMaleCountLoaded && !isMaleCountLoading) {
    if (
      NoDataAvailableChecker(maleCount) ||
      NoDataAvailableChecker(maleCountLabels)
    ) {
      maleAudienceDistribution = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      maleAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary>
            <BarChart
              chartId="audience_male"
              chartClass="section_card_chart"
              chartData={maleCount}
              chartLabels={maleCountLabels}
              showLegend={false}
              /*  showLabels={true}
               showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }

    if (NoDataAvailableChecker(totalMaleCountPercentage)) {
      maleAudienceDistributionIcon = (
        <Wrapper>
          <NoDataAvailableLoader chartType="genderChartType" />
        </Wrapper>
      );
    } else {
      maleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <MaleIcon percentage={totalMaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalMaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (maleCounterror) {
    maleAudienceDistribution = (
      <Wrapper>
        {" "}
        <ServerErrorsLoader chartType="barChartType" error={maleCounterror} />
      </Wrapper>
    );
    maleAudienceDistributionIcon = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="genderChartType"
          error={maleCounterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMaleCountLoading) {
    maleAudienceDistribution = (
      <Wrapper>
        <Loader loaderType="barChartLoader" />
      </Wrapper>
    );
    maleAudienceDistributionIcon = (
      <Wrapper>
        <Loader loaderType="genderLoader" />
      </Wrapper>
    );
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let femaleAudienceDistribution = (
    <Wrapper>
      <Loader loaderType="barChartLoader" />
    </Wrapper>
  );
  let femaleAudienceDistributionIocn = (
    <Wrapper>
      <Loader loaderType="genderLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isFemaleCountLoaded && !isFemaleCountLoading) {
    if (
      NoDataAvailableChecker(femaleCount) ||
      NoDataAvailableChecker(femaleCountLabels)
    ) {
      femaleAudienceDistribution = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      femaleAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_female"
              chartClass="section_card_chart"
              chartData={femaleCount}
              chartLabels={femaleCountLabels}
              showLegend={false}
              /*  showLabels={true}
               showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }

    if (NoDataAvailableChecker(totalMaleCountPercentage)) {
      femaleAudienceDistributionIocn = (
        <Wrapper>
          <NoDataAvailableLoader chartType="genderChartType" />
        </Wrapper>
      );
    } else {
      femaleAudienceDistributionIocn = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <FemaleIcon percentage={totalFemaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalFemaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (femaleCounterror) {
    femaleAudienceDistribution = (
      <Wrapper>
        <ServerErrorsLoader chartType="barChartType" error={femaleCounterror} />
      </Wrapper>
    );
    femaleAudienceDistributionIocn = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="genderChartType"
          error={femaleCounterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isFemaleCountLoading) {
    femaleAudienceDistribution = (
      <Wrapper>
        <Loader loaderType="barChartLoader" />
      </Wrapper>
    );
    femaleAudienceDistributionIocn = (
      <Wrapper>
        <Loader loaderType="genderLoader" />
      </Wrapper>
    );
  }

  const tabData = ["By Country", "By State"];
  const [selectedTab, setSelectedTab] = useState(tabData[0]);
  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Secondary_Tab_Visit(
      "Marketing Intelligence",
      "Campaign",
      "Facebook",
      "Overall",
      event
    );
  };

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY MAP

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0 ||
            selectedBucketsFromFilter === undefined
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    let apiURL;
    if (companyID === HALEON_COMPANY_ID) {
      apiURL = "performance/facebook/performance-table-by-country/";
    } else {
      apiURL = "performance/facebook/audience-country/";
    }

    try {
      const res = await axios.get(apiURL, config);

      if (companyID === HALEON_COMPANY_ID) {
        setCountryData(res?.data?.summary ?? []);
      } else {
        setCountryData(res?.data?.data?.impressions ?? []);

        setCountryAllDropdownsData(res?.data?.data ?? []);
      }

      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      /* setCountryAllDropdownsData([]); */
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let countryMap = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card ">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By Country</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (
      NoDataAvailableChecker(countryData) ||
      NoDataAvailableChecker(CountryTableDataHeaders)
    ) {
      countryMap = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card ">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By Country</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      countryMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle="By Country"
              /*  isHalfSection={true} */
              isCountryFlagVisible={true}
              tableHeader={CountryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
              tableID={"audience_distribution_country"}
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"audience_distribution"}
                  sectionName={`${companyID === HALEON_COMPANY_ID
                    ? "HaleonCountryTable"
                    : "CountryTableDynaLabels"
                    }_Audience Distribution - Country_${selectedMidSectionDropdownOption.label
                    }`}
                  sectionData={countryData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    countryMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader
                chartType="tableChartType"
                error={countryDataerror}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    countryMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // STATE TABLE

  const fetchStateHandler = async () => {
    setStateDataIsLoading(true);
    setStateDataIsLoaded(false);
    setStateDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0 ||
            selectedBucketsFromFilter === undefined
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    let apiURL;
    if (companyID === HALEON_COMPANY_ID) {
      apiURL = "performance/facebook/performance-table-by-region/";
    } else {
      apiURL = "performance/facebook/audience-region/";
    }

    try {
      const res = await axios.get(apiURL, config);

      if (companyID === HALEON_COMPANY_ID) {
        setStateData(res?.data?.summary ?? []);
      } else {
        setStateData(res?.data?.data?.impressions ?? []);

        setStateAllDropdownsData(res?.data?.data ?? []);
      }

      setStateDataIsLoading(false);
      setStateDataIsLoaded(true);
      setStateDataError(null);
    } catch (error) {
      /*  setStateAllDropdownsData([]); */
      setStateDataIsLoading(false);
      setStateDataIsLoaded(false);
      setStateDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let stateTable = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By State</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isStateDataLoaded && !isStateDataLoading) {
    if (
      NoDataAvailableChecker(stateData) ||
      NoDataAvailableChecker(StateTableDataHeaders)
    ) {
      stateTable = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card ">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By State</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      stateTable = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle="By State"
              /*  isHalfSection={true} */
              isDynamicFlagVisible={true}
              tableHeader={StateTableDataHeaders}
              tableData={stateData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search State"}
              tableID={"audience_distribution_state"}
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"audience_distribution_state"}
                  sectionName={`${companyID === HALEON_COMPANY_ID
                    ? "HaleonStateTable"
                    : "StateTableDynaLabels"
                    }_Audience Distribution - State_${selectedMidSectionDropdownOption.label
                    }`}
                  sectionData={stateData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (stateDataerror) {
    stateTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By State</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader
                chartType="tableChartType"
                error={stateDataerror}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isStateDataLoading) {
    stateTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By State</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN SUMMARY
  useEffect(() => {

    const fetchCampaignSummaryHandler = async () => {


      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          dropdown_value: selectedCampaignSummaryDropdownOption?.value ?? '',
          campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
          ...campaignSummaryConfig,
          page_size: -1,
          page_num: 1,

        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/facebook/performance-table/v2/", config);

        var allData = res?.data?.data?.results ?? [];

        // var headerKeys = campaignSummaryTableHeaders.map((x) => x.field)

        // if (selectedCampaignSummaryDropdownOption.value === 'campaign') {
        //   headerKeys.push('id')
        // } else {
        //   headerKeys.filter(e => e !== 'id')
        // }

        // var formattedData = allData.map((row) => {

        //   var newData = {};
        //   Object.keys(row).forEach((x, y) => {
        //     if (headerKeys.includes(x)) {
        //       newData[x] = row[x]
        //     }
        //   })
        //   return newData
        // })

        setCampaignSummaryExportData(allData ?? []);

      } catch (error) {
        setCampaignSummaryExportData([]);

      }
    };
    fetchCampaignSummaryHandler();

  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform, selectedCampaignSummaryDropdownOption, campaignSummaryConfig.dropdown_value])


  useEffect(() => {
    const fetchCampaignSummaryHandler = async () => {
      setCampaignSummaryIsLoading(true);
      setCampaignSummaryIsLoaded(false);
      setCampaignSummaryError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          campaign_tag:
            selectedBucketsFromFilter.length === 0 ||
              selectedBucketsFromFilter === undefined
              ? null
              : selectedBucketsFromFilter.join(","),
          ...campaignSummaryConfig

        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/facebook/performance-table/v2/",
          config
        );


        let allData = res.data.data.results;
        let uniqueHeaders = getUniqueValuesFromArrofObj(allData)
        let formattedHeaders = []

        uniqueHeaders.map((header) => {
          formattedHeaders.push({
            name: newGetHeaderKeysWithLabels(header),
            field: header,
          })
        })

        setCampaignSummaryTablePageData(res?.data?.data?.page ?? {})
        setCampaignSummary(allData ?? []);
        setCampaignSummaryTableHeaders(formattedHeaders ?? [])

        setCampaignSummaryIsLoading(false);
        setCampaignSummaryIsLoaded(true);
        setCampaignSummaryError(null);
      } catch (error) {
        setCampaignSummary([]);
        setCampaignSummaryTableHeaders([])
        setCampaignSummaryIsLoading(false);
        setCampaignSummaryIsLoaded(false);
        setCampaignSummaryError(error.code);
      }
    };
    fetchCampaignSummaryHandler();
  }, [
    BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform, campaignSummaryConfig
  ])




  let campaignIdsTemp = [];

  // EXTRACT THE CAMPAIGN IDS FROM THE CAMPAIGN SUMMARY TABLE
  const campaignIdsFromTable = useMemo(() => {
    // TO GET THE UNIQUE CAMPAIGN IDS FROM THE SUMMARY TABLE
    if (
      campaignSummaryExportData === undefined ||
      campaignSummaryExportData.length === 0 ||
      campaignSummaryExportData === null
    )
      return [];
    campaignSummaryExportData.map((data) => {
      campaignIdsTemp.push(data.id);
    });

    // JOIN THE LIST OF ARRAY OF CAMPAIGN IDS
    return campaignIdsTemp.join(",");
  }, [campaignSummaryExportData]); // UPDATES ONLY WHEN SUMMARY TABLE IS CHANGED

  let campaignSummaryTable = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Summary
              <SectionDefinationsBadge
                title={"Summary"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // let TableHeader = [];

  // IF DATA IS LOADED
  if (isCampaignSummaryLoaded && !isCampaignSummaryLoading) {
    campaignSummaryTable = (
      <Wrapper>
        <ErrorBoundary chartType="tableChartType">
          <Datatable
            tableTitle="Summary"
            tablePagePlatform={pagePlatform}
            tablePageSubModule={pageSubModule}
            tablePagePrimaryTab={pagePrimaryTab}
            tableHeader={campaignSummaryTableHeaders}
            tableData={campaignSummary}
            tableLength={10}
            isFooterShow={true}
            searchPlaceHolder={"Search Campaign"}
            tableConfigs={campaignSummaryConfig}
            setTableConfigs={setCampaignSummaryConfig}
            tablePaginationData={campaignSummaryTablePageData}
            tableDropdown={
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={campaignSummaryOptions}
                className="form_dropdown section_dropdown"
                value={selectedCampaignSummaryDropdownOption}
                selectedOptions={selectedCampaignSummaryDropdownOption}
                setStatedropdown={campaignSummaryDropdownSelectionHandler}
              />
            }
            tableID={"search_campaign_section"}
            tableExportDropdown={
              <ExportDropdown
                sectionId={"search_campaign_section"}
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionName={`${companyID === COKE_COMPANY_ID ||
                  companyID === HALEON_COMPANY_ID
                  ? "CampaignTableCokeHaleon"
                  : "CampaignTableDynaLabels"
                  }_Summary Table _${selectedCampaignSummaryDropdownOption.label}`}
                sectionData={campaignSummaryExportData}
              />
            }
          />
        </ErrorBoundary>
      </Wrapper>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignSummaryerror) {
    campaignSummaryTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Summary
                <SectionDefinationsBadge
                  title={"Summary"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader
                chartType="tableChartType"
                error={campaignSummaryerror}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCampaignSummaryLoading) {
    campaignSummaryTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Summary
                <SectionDefinationsBadge
                  title={"Summary"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  useEffect(() => {
    fetchLineChartCampaignHandler();
  }, [
    BRAND_ID,
    selectedDates,
    childDonutSliceToggle,
    selectedBucketsFromFilter,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
  ]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PLANNED VS METRICS

  // TO FETCH METRIC DATA FROM API
  const fetchPlannedVsDeliveredMetricsHandler = async () => {
    setPlannedVsDeliveredMetricsIsLoading(true);
    setPlannedVsDeliveredMetricsIsLoaded(false);
    setPlannedVsDeliveredMetricsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0 ||
            selectedBucketsFromFilter === undefined
            ? null
            : selectedBucketsFromFilter.join(","),
        drop_down_type:
          selectedMarketTgDropdownOption === undefined ||
            selectedMarketTgDropdownOption === null ||
            selectedMarketTgDropdownOption === ""
            ? ""
            : selectedMarketTgDropdownOption.value.toLowerCase(),

        drop_down_value:
          selectedRegionDropdownOption === undefined ||
            selectedRegionDropdownOption === null ||
            selectedRegionDropdownOption === ""
            ? ""
            : selectedRegionDropdownOption.value.toLowerCase(),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/summary/planned-delivered-chart/v3/facebook",
        config
      );

      setPlannedVsDeliveredMetricsData(res?.data?.data ?? []);
      setPlannedVsDeliveredMetricsDataLabels(res?.data?.label ?? []);

      setPlannedVsDeliveredMetricsIsLoading(false);
      setPlannedVsDeliveredMetricsIsLoaded(true);
      setPlannedVsDeliveredMetricsError(null);
    } catch (error) {
      setPlannedVsDeliveredMetricsData([]);
      setPlannedVsDeliveredMetricsDataLabels([]);
      setPlannedVsDeliveredMetricsIsLoading(false);
      setPlannedVsDeliveredMetricsIsLoaded(false);
      setPlannedVsDeliveredMetricsError(error.code);
    }
  };

  let plannedVsDeliveredMetricsBarChart = (
    <Wrapper>
      <Loader loaderType="barChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (
    isplannedVsDeliveredMetricsLoaded &&
    !isplannedVsDeliveredMetricsLoading
  ) {
    if (
      NoDataAvailableChecker(plannedVsDeliveredMetricsData) ||
      NoDataAvailableChecker(plannedVsDeliveredMetricsDataLabels)
    ) {
      plannedVsDeliveredMetricsBarChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      plannedVsDeliveredMetricsBarChart = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="plannedDeliveredBarChart"
              chartClass="section_card_chart"
              chartData={plannedVsDeliveredMetricsData}
              chartLabels={plannedVsDeliveredMetricsDataLabels}
              chartColorSet={BlueColorStart500}
              showLabels={true}
              minimumGridDistance={0}
              showLegend={false}
              percentageTooltip={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (plannedVsDeliveredMetricsError) {
    plannedVsDeliveredMetricsBarChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="barChartType"
          error={plannedVsDeliveredMetricsError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isplannedVsDeliveredMetricsLoading) {
    plannedVsDeliveredMetricsBarChart = (
      <Wrapper>
        <Loader loaderType="barChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TABLE

  // TO FETCH METRIC  FROM API
  const fetchPlannedVsDeliveredTableDataHandler = async () => {
    setPlannedVsDeliveredTableDataIsLoading(true);
    setPlannedVsDeliveredTableDataIsLoaded(false);
    setPlannedVsDeliveredTableDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0 ||
            selectedBucketsFromFilter === undefined
            ? null
            : selectedBucketsFromFilter.join(","),
        drop_down_type:
          selectedMarketTgDropdownOption === undefined ||
            selectedMarketTgDropdownOption === null ||
            selectedMarketTgDropdownOption === ""
            ? ""
            : selectedMarketTgDropdownOption.value.toLowerCase(),

        drop_down_value:
          selectedRegionDropdownOption === undefined ||
            selectedRegionDropdownOption === null ||
            selectedRegionDropdownOption === ""
            ? ""
            : selectedRegionDropdownOption.value.toLowerCase(),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };


    try {
      const res = await axios.get(
        "performance/summary/planned-delivered-table/v3/facebook",
        config
      );
      setPlannedVsDeliveredTableData(res?.data?.data ?? []);
      setPlannedVsDeliveredTableDataHaeders(res?.data?.label ?? []);
      setPlannedVsDeliveredTableDataTotal(res?.data?.row_summary ?? [])
      setPlannedVsDeliveredTableDataIsLoading(false);
      setPlannedVsDeliveredTableDataIsLoaded(true);
      setPlannedVsDeliveredTableDataError(null);
    } catch (error) {
      setPlannedVsDeliveredTableData([]);
      setPlannedVsDeliveredTableDataTotal([])
      setPlannedVsDeliveredTableDataHaeders([]);
      setPlannedVsDeliveredTableDataIsLoading(false);
      setPlannedVsDeliveredTableDataIsLoaded(false);
      setPlannedVsDeliveredTableDataError(error.code);
    }
  };

  let plannedVsDeliveredTable = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">
                Campaign wise Planned v/s Delivered table
                {selectedMarketTgDropdownOption &&
                  " : " + selectedMarketTgDropdownOption.label}
                {selectedRegionDropdownOption &&
                  " : " + selectedRegionDropdownOption.label}

              </h2>
              <SectionDefinationsBadge
                title={"Campaign wise Planned v/s Delivered table"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </div>

          </div>
        </div>
        <div className="grid col_span_4 section_card_body">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (
    isPlannedVsDeliveredTableDataLoaded &&
    !isPlannedVsDeliveredTableDataLoading
  ) {
    if (
      NoDataAvailableChecker(plannedVsDeliveredTableData) ||
      NoDataAvailableChecker(plannedVsDeliveredTableDataHeaders)
    ) {
      plannedVsDeliveredTable = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <div className="section_info">
                  <h2 className="section_card_title">
                    Campaign wise Planned v/s Delivered table
                    {selectedMarketTgDropdownOption &&
                      " : " + selectedMarketTgDropdownOption.label}
                    {selectedRegionDropdownOption &&
                      " : " + selectedRegionDropdownOption.label}

                  </h2>
                  <SectionDefinationsBadge
                    title={"Campaign wise Planned v/s Delivered table"}
                    module={pageSubModule}
                    platform={pagePlatform}
                    section={pagePrimaryTab}
                  />
                </div>
              </div>
            </div>
            <div className="grid col_span_4 section_card_body">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      plannedVsDeliveredTable = (
        <Wrapper>
          <ErrorBoundary>
            <Datatable
              tableTitle={
                "Campaign wise Planned v/s Delivered table" +
                `${selectedMarketTgDropdownOption &&
                " : " + selectedMarketTgDropdownOption.label
                }
            ${selectedRegionDropdownOption &&
                " : " + selectedRegionDropdownOption.label
                }`
              }
              tablePageSubModule={pageSubModule}
              tablePagePlatform={pagePlatform}
              tablePagePrimaryTab={pagePrimaryTab}
              tableHeader={plannedVsDeliveredTableDataHeaders}
              tableData={plannedVsDeliveredTableData}
              tableLength={10}
              isFooterShow={true}
              searchPlaceHolder={"Search table"}
              tableID="planned_vs_delivered_table"
              showTotalRow={plannedVsDeliveredTableDataTotal}
              showFixedSpend={true}
              tableExportDropdown={
                <ExportDropdown
                  sectionId={"planned_vs_delivered_table"}
                  sectionName={`TableDynaLabels_Planned vs Delivered Table_${` : ` + selectedMarketTgDropdownOption.label
                    } ${selectedRegionDropdownOption !== undefined &&
                      selectedRegionDropdownOption !== null &&
                      selectedRegionDropdownOption !== ""
                      ? `: ` + selectedRegionDropdownOption.label
                      : ""
                    }`}
                  sectionData={concateTotals(plannedVsDeliveredTableData, plannedVsDeliveredTableDataTotal)}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (plannedVsDeliveredTableDataerror) {
    plannedVsDeliveredTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <div className="section_info">
                <h2 className="section_card_title">
                  Campaign wise Planned v/s Delivered table
                  {selectedMarketTgDropdownOption &&
                    " : " + selectedMarketTgDropdownOption.label}
                  {selectedRegionDropdownOption &&
                    " : " + selectedRegionDropdownOption.label}

                </h2>
                <SectionDefinationsBadge
                  title={"Campaign wise Planned v/s Delivered table"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_body">
              <ServerErrorsLoader
                error={plannedVsDeliveredTableDataerror}
                chartType="tableChartType"
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPlannedVsDeliveredTableDataLoading) {
    plannedVsDeliveredTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <div className="section_info">
                <h2 className="section_card_title">
                  Campaign wise Planned v/s Delivered table
                  {selectedMarketTgDropdownOption &&
                    " : " + selectedMarketTgDropdownOption.label}
                  {selectedRegionDropdownOption &&
                    " : " + selectedRegionDropdownOption.label}

                </h2>
                <SectionDefinationsBadge
                  title={"Campaign wise Planned v/s Delivered table"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }



  useEffect(() => {
    setChildDonutSliceToggle(null);
  }, [
    selectedBucketsFromFilter,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
  ]);

  var campaignObjectCSV = {
    "CampaignObjDonut_Campaign Objective Donut": childDonutCampaignObjective,
    [`CampObjectiveDynaLabels_Campaign Objective Line_${childDonutSliceToggle === undefined || childDonutSliceToggle === null
      ? "empty"
      : childDonutSliceToggle.replace("_", "-")
      }`]: lineChartCampaignObjective,
  };

  var maleFemaleDataCSV = {
    "Audience Total Count": [
      {
        ["Male - " + selectedMidSectionDropdownOption.label]: totalMaleCount,
        ["Female - " + selectedMidSectionDropdownOption.label]:
          totalFemaleCount,
      },
    ],
    [`AgeBarChartCampaignDynalabels_Audience Distribution - Male_${selectedMidSectionDropdownOption.value}`]:
      maleCount,
    [`AgeBarChartCampaignDynalabels_Audience Distribution - Female_${selectedMidSectionDropdownOption.value}`]:
      femaleCount,
  };

  var deviceDistributionExport = {
    [`CampaignDeviceDistDynaLabels_Device Distribution_${selectedMidSectionDropdownOption.value}`]:
      deviceDistribution,
  };


  var barChartCSV = {
    [`dvBar_PvsD BarChart_${` : ` + selectedMarketTgDropdownOption.label
      } ${selectedRegionDropdownOption !== undefined &&
        selectedRegionDropdownOption !== null &&
        selectedRegionDropdownOption !== ""
        ? `: ` + selectedRegionDropdownOption.label
        : ""
      }`]: plannedVsDeliveredMetricsData,
  };

  var tableCSV = {
    [`TableDynaLabels_PvsD Table_${` : ` + selectedMarketTgDropdownOption.label
      } ${selectedRegionDropdownOption !== undefined &&
        selectedRegionDropdownOption !== null &&
        selectedRegionDropdownOption !== ""
        ? `: ` + selectedRegionDropdownOption.label
        : ""
      }`]: concateTotals(plannedVsDeliveredTableData, plannedVsDeliveredTableDataTotal),
  };


  var allData = {
    ...topCardData,
    "SummaryCard_Summary Card": combineMetricCardsData,
    ...campaignObjectCSV,
    ...maleFemaleDataCSV,
    [`${companyID === HALEON_COMPANY_ID
      ? "HaleonCountryTable"
      : "CountryTableDynaLabels"
      }_Audience Distribution - Country_${selectedMidSectionDropdownOption.label
      }`]: countryData,
    [`${companyID === HALEON_COMPANY_ID
      ? "HaleonStateTable"
      : "StateTableDynaLabels"
      }_Audience Distribution - State_${selectedMidSectionDropdownOption.label}`]:
      stateData,
    ...deviceDistributionExport,
    [`${companyID === COKE_COMPANY_ID || companyID === HALEON_COMPANY_ID
      ? "CampaignTableCokeHaleon"
      : "CampaignTableDynaLabels"
      }_Summary Table _${selectedCampaignSummaryDropdownOption.label}`]:
      campaignSummaryExportData,
    ...barChartCSV,
    ...tableCSV,
  };

  useEffect(() => {
    setFullPageExport(allData);
  }, [
    combineMetricCardsData,
    childDonutCampaignObjective,
    lineChartCampaignObjective,
    maleCount,
    femaleCount,
    stateData,
    countryData,
    deviceDistribution,
    campaignSummary,
    campaignSummaryExportData,
    plannedVsDeliveredMetricsData,
    plannedVsDeliveredTableData,
    plannedVsDeliveredTableDataTotal
  ]);

  useEffect(() => {

    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (childDonutCampaignObjectiveError !== null ? true : childDonutCampaignObjectiveLoaded && (childDonutCampaignObjectiveLoading === false)) &&
      (lineChartCampaignObjectiveError !== null ? true : lineChartCampaignObjectiveLoaded && (lineChartCampaignObjectiveLoading === false)) &&
      (maleCounterror !== null ? true : isMaleCountLoaded && (isMaleCountLoading === false)) &&
      (femaleCounterror !== null ? true : isFemaleCountLoaded && (isFemaleCountLoading === false)) &&
      (stateDataerror !== null ? true : isStateDataLoaded && (isStateDataLoading === false)) &&
      (countryDataerror !== null ? true : isCountryDataLoaded && (isCountryDataLoading === false)) &&
      (deviceDistributionerror !== null ? true : isDeviceDistributionLoaded && (isDeviceDistributionLoading === false)) &&
      (campaignSummaryerror !== null ? true : isCampaignSummaryLoaded && (isCampaignSummaryLoading === false)) &&
      (plannedVsDeliveredMetricsError !== null ? true : isplannedVsDeliveredMetricsLoaded && (isplannedVsDeliveredMetricsLoading === false)) &&
      (plannedVsDeliveredTableDataerror !== null ? true : isPlannedVsDeliveredTableDataLoaded && (isPlannedVsDeliveredTableDataLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }

  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    childDonutCampaignObjectiveError, childDonutCampaignObjectiveLoaded, childDonutCampaignObjectiveLoading,
    lineChartCampaignObjectiveError, lineChartCampaignObjectiveLoaded, lineChartCampaignObjectiveLoading,
    maleCounterror, isMaleCountLoaded, isMaleCountLoading,
    femaleCounterror, isFemaleCountLoaded, isFemaleCountLoading,
    stateDataerror, isStateDataLoaded, isStateDataLoading,
    countryDataerror, isCountryDataLoaded, isCountryDataLoading,
    deviceDistributionerror, isDeviceDistributionLoaded, isDeviceDistributionLoading,
    campaignSummaryerror, isCampaignSummaryLoaded, isCampaignSummaryLoading,
    plannedVsDeliveredMetricsError, isplannedVsDeliveredMetricsLoaded, isplannedVsDeliveredMetricsLoading,
    plannedVsDeliveredTableDataerror, isPlannedVsDeliveredTableDataLoaded, isPlannedVsDeliveredTableDataLoading
    ,])


  return (
    <Wrapper>
      {metricCardsSection}
      <div
        id="campaign_objective_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">
                Objective Wise Distribution
              </h2>
              <SectionDefinationsBadge
                title={"Objective Wise Distribution"}
                module="campaign"
                platform="facebook"
                section="overall"
              />
            </div>
            <Modal
              open={isLineChartCampaignOpen}
              setOpen={setIsLineChartCampaignOpen}
              exportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"multiLineChart_2"}
                  sectionName={"Campaign Objective Wise distribution"}
                  sectionData={campaignObjectCSV}
                />}
              parentId={"campaign_objective_section"}
              title={`Objective Wise Distribution`}
              chart={lineChartCampaignSection2}
            />
            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && <button onClick={() => setIsLineChartCampaignOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                <ExpandIcon />
              </button>}
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"campaign_objective_section"}
                sectionName={"Campaign Objective Wise distribution"}
                sectionData={campaignObjectCSV}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_1 ">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {childDonutCampaignChart}
              </div>
            </div>
          </div>
          <div className="grid col_span_3 ">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {lineChartCampaignSection}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section_card section_dropdown_header">
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">Performance By KPI</h2>
              <SectionDefinationsBadge
                title={"Performance By KPI"}
                module="campaign"
                platform="facebook"
                section="overall"
              />
            </div>
            <Dropdown
              ismulti={false}
              placeholder={"Select"}
              options={midSectionDropdonwOptions}
              className="form_dropdown section_dropdown"
              value={selectedMidSectionDropdownOption}
              selectedOptions={selectedMidSectionDropdownOption}
              setStatedropdown={midSectionDropdownSelectionHandler}
            />
          </div>
        </div>
      </div>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div
          id="audience_distribution"
          className="grid col_span_3 section_card"
        >
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">
                {AudienceDistributionSectionTitle}{" "}
                {": " + selectedMidSectionDropdownOption.label}{" "}
              </h2>
              <SectionDefinationsBadge
                title={AudienceDistributionSectionTitle + " By Gender"}
                module="campaign"
                platform="facebook"
                section="overall"
              />
            </div>

            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"audience_distribution"}
                sectionName={"Audience Distribution"}
                sectionData={maleFemaleDataCSV}
              />
            </div>
          </div>
          <div className="grid grid_cols_4">
            <div className="grid col_span_2 card_border_right flex column">
              <div className="section_card_header">
                <h2 className="section_card_title">Male</h2>
              </div>
              <div className="grid grid_cols_4 section_card_body">
                <div className="grid col_span_1 flex column justify_center align_center">
                  {maleAudienceDistributionIcon}
                </div>
                <div className="grid col_span_3 section_card_chart_wrapper">
                  {maleAudienceDistribution}
                </div>
              </div>
            </div>
            <div className="grid col_span_2 flex column">
              <div className="grid col_span_4 section_card_header">
                <h2 className="section_card_title">Female</h2>
              </div>
              <div className="grid grid_cols_4 section_card_body">
                <div className="grid col_span_1 flex column justify_center align_center">
                  {femaleAudienceDistributionIocn}
                </div>
                <div className="grid col_span_3 section_card_chart_wrapper">
                  {femaleAudienceDistribution}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="device_distribution" className="grid col_span_1 section_card">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">
                Device Distribution
                {": " + selectedMidSectionDropdownOption.label}
              </h2>
              <SectionDefinationsBadge
                title={"Device Distribution"}
                module="campaign"
                platform="facebook"
                section="overall"
              />
            </div>

            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"device_distribution"}
                sectionName={"CampaignDeviceDistDynaLabels_Device Distribution"}
                sectionData={deviceDistributionExport}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {deviceDistributionChart}
            </div>
          </div>
        </div>
      </div>
      <div id="audience_distribution_place">
        <div className="section_card section_dropdown_header">
          <div className="grid grid_cols_1">
            <div className="section_card_header">
              <div className="section_info">
                <h2 className="section_card_title">
                  {AudienceDistributionSectionTitle}{" "}
                  {/* : {selectedMidSectionDropdownOption.label} */}
                </h2>
                <SectionDefinationsBadge
                  title={AudienceDistributionSectionTitle}
                  module="campaign"
                  platform="facebook"
                  section="overall"
                />
              </div>
            </div>
            {/*  <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"audience_distribution_place"}
                sectionName={"Audience Distribution"}
                sectionData={cityCountryData}
              />
            </div> */}
          </div>
        </div>

        {companyID === HALEON_COMPANY_ID ? (
          <Wrapper>
            <div className="grid  grid_margin_bottom ">
              <Tab
                varient={"primary"}
                tabData={tabData}
                activeTab={selectedTab}
                handleCallback={(event) => handleCallback(event)}
              />
            </div>
            <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
              {selectedTab === "By Country" && <Wrapper>{countryMap}</Wrapper>}
              {selectedTab === "By State" && <Wrapper>{stateTable}</Wrapper>}
            </div>
          </Wrapper>
        ) : (
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_2 card_border_right flex column">
              {countryMap}
            </div>
            <div className="grid col_span_2 flex column">{stateTable}</div>
          </div>
        )}
      </div>
      <div className="grid_margin_bottom">{campaignSummaryTable}</div>


      <Wrapper>
        <div className="section_card section_dropdown_header">
          <div className="grid grid_cols_1">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Planned Vs Delivered
              </h2>
              <div data-html2canvas-ignore={true}>
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={dropdownOptionsMarketTg}
                  className="form_dropdown section_dropdown grid_margin_right"
                  value={selectedMarketTgDropdownOption}
                  selectedOptions={selectedMarketTgDropdownOption}
                  setStatedropdown={dropdownMainSelectionHandler}

                />
                <Dropdown
                  ismulti={false}
                  dropdownLoading={regionDropdownOptionsIsLoading}
                  placeholder={"Select"}
                  options={regionDropdownOptionsOnLoad}
                  className="form_dropdown section_dropdown"
                  value={selectedRegionDropdownOption}
                  selectedOptions={selectedRegionDropdownOption}
                  setStatedropdown={dropdownRegionSelectionHandler}

                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4 fullpage_section_card section_card grid_margin_bottom">
          <div className="section_card_body">
            <div id="planned_vs_delivered_metrics_section">
              <div className="grid  section_card">
                <div className="section_card_header">
                  <div className="section_info">
                    <h2 className="section_card_title">
                      Planned v/s Delivered Metrics Performance
                      {selectedMarketTgDropdownOption &&
                        " : " + selectedMarketTgDropdownOption.label}
                      {selectedRegionDropdownOption &&
                        " : " + selectedRegionDropdownOption.label}
                    </h2>
                    <SectionDefinationsBadge
                      title={"Planned vs Delivered Metrics Performance"}
                      module={pageSubModule}
                      platform={pagePlatform}
                      section={pagePrimaryTab}
                    />
                  </div>
                  <div data-html2canvas-ignore={true}>
                    <ExportDropdown
                      moduleName={pageModule}
                      subModuleName={pageSubModule}

                      sectionId={"planned_vs_delivered_metrics_section"}
                      sectionName={`dvBar_PvsD BarChart_${` : ` + selectedMarketTgDropdownOption.label
                        } ${selectedRegionDropdownOption !== undefined &&
                          selectedRegionDropdownOption !== null &&
                          selectedRegionDropdownOption !== ""
                          ? `: ` + selectedRegionDropdownOption.label
                          : ""
                        }`}
                      sectionData={barChartCSV}
                    />
                  </div>
                </div>
                <div className="grid grid_cols_1 section_card_body">
                  <div className="section_card_chart_wrapper">
                    {plannedVsDeliveredMetricsBarChart}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {plannedVsDeliveredTable}
      </Wrapper>

    </Wrapper>
  );
};
export default CampaignFacebookOverall;
