import React, { Component } from 'react';
import { connect } from "react-redux";
import Wrapper from "../../../helpers/wrapper";
import { Tab } from "../../../shared/components/tabs/tab";
import { TabNav } from "../../../shared/components/tabs/tabnav";
import IconUserGroup from "../../../../assets/images/icons/usergroupIcon";
import IconWebsite from '../../../../assets/images/icons/websiteIcon';
import { CustomerStudy } from './RiCustomerStudy';
import { CustomerTracker } from './RiCustomerTracker';
import Col from "react-bootstrap/esm/Col";
import IconDownload from '../../../../assets/images/icons/downloadIcon'

import { Breadcrumb, Container, Image, ProgressBar } from 'react-bootstrap';
import RiMapFilter from '../ri-mapfilter/RiMapFilter';
import IndiaMap from '../../../shared/components/map/indiamap/indiamap';
import { Loader } from '../../../shared/components/loader/loader';
import IconPdf from "../../../../assets/images/icons/pdfIcon";
import DatePicker from "../../../shared/components/datepicker/datepicker";
import RiCustomerFilter from "../../../layout/ri-pageheader/ri-customerfilter"
import StickyLogo from "../../../../assets/images/icons/icon.png";
import UKMap from "../../../shared/components/map/ukmap/ukmap";

import BRAND1 from "../../../../assets/images/brands/brand1.png";
import BRAND2 from "../../../../assets/images/brands/brand2.png";
import BRAND3 from "../../../../assets/images/brands/brand3.png";
import BRAND4 from "../../../../assets/images/brands/brand4.png";
import BRAND5 from "../../../../assets/images/brands/brand5.png";
import BrandDropdown from "../../../shared/components/dropdown/brandDropdown";
import PageHeader from '../../../../../components/layout/pageHeader';
import USMap from '../../../shared/components/map/usmap/usmap';
import { API_URL } from '../../../../../constants/constants';

export class CustomerOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {

      brandsDropdownOptions: [
        {
          value: "pulsar",
          label: "Pulsar",
          icon: (
            <img
              src={BRAND1}
              style={{ width: "25px", height: "25px", borderRadius: "100%" }}
            />
          ),
        },
        {
          value: "platina",
          label: "Platina",
          icon: (
            <img
              src={BRAND2}
              style={{ width: "25px", height: "25px", borderRadius: "100%" }}
            />
          ),
        },
        {
          value: "ct",
          label: "CT",
          icon: (
            <img
              src={BRAND3}
              style={{ width: "25px", height: "25px", borderRadius: "100%" }}
            />
          ),
        },
        {
          value: "avenger",
          label: "Avenger",
          icon: (
            <img
              src={BRAND4}
              style={{ width: "25px", height: "25px", borderRadius: "100%" }}
            />
          ),
        },
        {
          value: "dominar",
          label: "Dominar",
          icon: (
            <img
              src={BRAND5}
              style={{ width: "25px", height: "25px", borderRadius: "100%" }}
            />
          ),
        }
      ],

      selectedBrandDropdownOption: "",

      selectedSubNav: 'Customer Study',

      first_start_date: props.start_date,
      first_end_date: props.end_date,
      second_start_date: props.previous_start_date,
      second_end_date: props.previous_end_date,
      userData: props.userData,

      isDatePickerOpen: false,
      is_scroll: false,

      // MAP
      map_zone: '',
      map_state: '',
      map_city: '',
      map_store_code: "",
      map_store_name: '',


      // MAP FILTER
      map_store_category: '',
      map_store_type: '',
      map_store_size: '',

      mapBreadcrumbList: [],
      mapBreadcrumbsNorthPoint: '',
      mapBreadcrumbsSouthPoint: '',
      mapBreadcrumbsCenterPoint: '',

      data: "",
      MapMarker: "",
      North_point: [72.6416015625, 37.71859032558816],
      South_point: [93.1640625, 5.7908968128719565],
      map_level: 0,
      center_point: [0, 0],

      custom_current_basket_size: 5.67,
      custom_last_basket_size: 5.35,
      map_filter_category_list: [
        { name: 'Metro', checked: false },
        { name: 'Non-Metro', checked: false },
      ],
      map_filter_storetype_list: [
        { name: 'New', checked: false },
        { name: 'LTL', checked: false },
        { name: 'Mature', checked: false },
      ],
      map_filter_storesize_list: [],
    }

  }

  componentWillReceiveProps(nextProps) {
    if (this.props != nextProps) {
      this.setState({
        first_start_date: nextProps.start_date,
        first_end_date: nextProps.end_date,
        second_start_date: nextProps.previous_start_date,
        second_end_date: nextProps.previous_end_date,
      });
    }
  }

  componentDidMount() {
    this.state.is_India_map_loaded = false;

    this.state.country = this.props.country;

    this.getMapdetails();
    this.forceUpdate();

    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.start_date !== this.props.start_date ||
      prevProps.end_date !== this.props.end_date ||
      prevProps.previous_start_date !== this.props.previous_start_date ||
      prevProps.previous_end_date !== this.props.previous_end_date
    ) {
      this.state.is_India_map_loaded = false;

      this.getMapdetails();
    }
  }

  isNull(object) {
    for (const [key, value] of Object.entries(object)) {
      if (typeof value === "object" && value !== null) {
        this.isNull(value);
      } else if (!value) {
        object[key] = 0;
      }
    }
    return object;
  }

  //India map details
  getMapdetails() {
    let result = [];

    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    // let previous_start_date = this.state.second_start_date;
    // let previous_end_date = this.state.second_end_date;

    //map filter
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;
    let country = this.state.country != null ? (this.state.country).toUpperCase() : "IN";

    const url = API_URL + "ri/zones/";

    fetch(url, {
      method: "POST",
      body: "start_date=" + start_date +
        "&end_date=" + end_date +
        "&category=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size +
        "&country=" + country,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then((res) => res.json())
      .then(
        (result1) => {
          let varIndiaMapData = result1.data;
          this.setState({
            MapMarker: varIndiaMapData,
            is_India_map_loaded: true
          });
        },
        (error) => { }
      );

  }


  getUrlCountryParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if ((sParameterName[0]).toLowerCase() === sParam.toLowerCase()) {
        return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
    return "";
  }

  getIndiaMapCityDetails() {
    let result = [];

    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let map_zone = this.state.map_zone;
    //map filter
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;

    const url = API_URL + "ri/cities/";

    fetch(url, {
      method: "POST",
      body: "start_date=" + start_date +
        "&end_date=" + end_date +
        "&map_zone=" + map_zone +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => res.json())
      .then(
        (result1) => {
          let varIndiaMapData = result1.data;
          this.setState({
            MapMarker: varIndiaMapData,
            is_India_map_loaded: true,
            map_level: 1
          });
        },
        (error) => { }
      );

  }

  getIndiaMapStoresDetails() {
    let result = [];

    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let map_zone = this.state.map_zone;
    let map_city = this.state.map_city;
    //map filter
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;

    const url = API_URL + "ri/stores/";

    fetch(url, {
      method: "POST",
      body: "start_date=" + start_date +
        "&end_date=" + end_date +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => res.json())
      .then(
        (result1) => {
          let varIndiaMapData = result1.data;
          this.setState({
            MapMarker: varIndiaMapData,
            is_India_map_loaded: true,
            map_level: 2
          });
        },
        (error) => { }
      );

  }

  setSelectedSubNav = (tab) => {
    this.setState({ selectedSubNav: tab });
  }

  handleRiMapFilterCallback = (childData) => {

    let storeCategory = [];
    let storeType = [];
    let storeSize = [];

    Object.keys(childData[0]).forEach(key => {
      if (childData[0][key] == true) {
        storeCategory.push(key);
      }
    });
    Object.keys(childData[1]).forEach(key => {
      if (childData[1][key] == true) {
        storeType.push(key);
      }
    });
    // Object.keys(childData[2]).forEach(key => {
    //   if (childData[2][key] == true) {
    //     storeSize.push(key);
    //   }
    // });

    storeSize.push(childData[2]);

    this.state.map_store_category = storeCategory;
    this.state.map_store_type = storeType;
    this.state.map_store_size = storeSize;

    //0 zone level
    //get city
    if (this.state.map_level == 0) {
      this.state.is_India_map_loaded = false;
      this.getMapdetails();
    }

    //1 city level
    else if (this.state.map_level == 1) {
      this.state.is_India_map_loaded = false;
      this.getIndiaMapCityDetails();
    }

    //2 store level
    else if (this.state.map_level == 2) {
      this.state.is_India_map_loaded = false;
      this.getIndiaMapStoresDetails();
    }

    //3 store code level data 
    else if (this.state.map_level == 3) {
    }


    this.forceUpdate();

  };

  handleRISalesSummaryIndiaMapCallBack = (childData) => {

    this.state.North_point = childData[0];
    this.state.South_point = childData[1];
    this.state.center_point = childData[2];
    this.state.map_zone = childData[3];
    this.state.map_city = childData[4];
    this.state.map_store_code = childData[5];
    this.state.map_store_name = childData[6];


    //0 zone level
    //get city
    if (this.state.map_level == 0) {
      this.state.is_India_map_loaded = false;
      this.getIndiaMapCityDetails();
      this.state.mapBreadcrumbList = ['All ZONES', this.state.map_zone];
    }

    //1 city level
    else if (this.state.map_level == 1) {
      this.state.is_India_map_loaded = false;
      this.getIndiaMapStoresDetails();
      this.state.mapBreadcrumbList = ['All ZONES', this.state.map_zone, this.state.map_city];
    }

    //2 store level
    else if (this.state.map_level == 2) {
      this.state.mapBreadcrumbList = ['All ZONES', this.state.map_zone, this.state.map_city, this.state.map_store_name];
      // this.getIndiaMapStoresDetails();
    }
    //3 store code level data 
    else if (this.state.map_level == 3) {
      this.state.mapBreadcrumbList = ['All ZONES', this.state.map_zone, this.state.map_city, this.state.map_store_name];
      // this.getIndiaMapStoresDetails();
    }

    this.state.mapBreadcrumbsCenterPoint = this.state.center_point;
    this.state.mapBreadcrumbsNorthPoint = this.state.North_point;
    this.state.mapBreadcrumbsSouthPoint = this.state.South_point;


    this.forceUpdate();
  };

  handleCallback = (childData) => {

    this.state.first_start_date = childData[0];
    this.state.first_end_date = childData[1];
    this.state.second_start_date = childData[2];
    this.state.second_end_date = childData[3];

    this.state.is_India_map_loaded = false;

    this.getMapdetails();
    this.forceUpdate();
    this.handleCallback2();
  };

  handleCallback2 = () => {
    this.setState({ isDatePickerOpen: !this.state.isDatePickerOpen });
  };

  handleScroll = () => {
    if (window.pageYOffset > 1) {
      if (!this.state.is_scroll) {
        this.setState({ is_scroll: true });
      }
    } else {
      if (this.state.is_scroll) {
        this.setState({ is_scroll: false });
      }
    }
  };

  onClickRiExport = () => {
    window.print('height=2000,width=2000')
  }

  // BRAND DROPDOWN
  brandDropdownSelectionHandler = (e) => {
    if (e === null) {
      this.state.selectedBrandDropdownOption = "";
    } else {
      this.state.selectedBrandDropdownOption = e;
    }
    this.forceUpdate();
  };

  render() {

    const now = 40;
    const progress1 = 65; const progress2 = 25; const progress3 = 10;

    return (
      <Wrapper>
        <div className="grid drawer_grid ri_summary_bsImport">
          <div className="left_drawer col_span_1 fixed-left-grid mapRightWrapper" id="india_map">
            <div className="icz-rimapsection">
              {this.state.is_India_map_loaded == true ?
                <Wrapper>
                  <Col lg={12} className="icz-rimapsectionheader">
                    <Wrapper>
                      <Wrapper>
                        <Col lg={4} md={4} sm={12} className="icz-rimaptitle">
                          {this.state.country != null ? (this.state.country.toUpperCase() == "UK" ? "UK" : this.state.country.toUpperCase() == "US" ? "US" : "India") : "India"}
                        </Col>
                        <Col lg={8} md={8} sm={12} className="icz-rimapfilter">
                          <RiMapFilter parentCallback={this.handleRiMapFilterCallback}
                            map_filter_category_list={this.state.map_filter_category_list}
                            map_filter_storetype_list={this.state.map_filter_storetype_list}
                            map_filter_storesize_list={this.state.map_filter_storesize_list}
                          />
                          <div className="icz-ribtnwrapper">
                            <button className="icz-rithemebtn">
                              <IconDownload className="icz-riicon" /> </button>
                          </div>
                        </Col>
                      </Wrapper>
                      <div className="breadcrumb-no-padding">
                        <Breadcrumb>
                          {this.state.mapBreadcrumbList.map((item, index) => (
                            <Breadcrumb.Item onClick={() => { this.mapBreadcrumbs(item, index + 1) }} key={index}>{item}</Breadcrumb.Item>
                          ))}
                        </Breadcrumb>
                      </div>
                    </Wrapper>
                  </Col>

                  <Col lg={12} className="icz-rimapcontainer">
                    {this.state.is_India_map_loaded == true ?
                      <>
                        {(() => {
                          switch (this.state.country) {
                            case "india": return <IndiaMap data={this.state.MapMarker}
                              parentCallback={this.handleRISalesSummaryIndiaMapCallBack}
                              North_point={this.state.North_point}
                              South_point={this.state.South_point}
                              Center_point={this.state.center_point}
                              map_level={this.state.map_level}
                              rangetype={this.state.rangetype}
                              ref={this.printRef}
                            />;
                            case "UK": return <UKMap
                              data={this.state.MapMarker}
                              parentCallback={this.handleRISalesSummaryIndiaMapCallBack}
                              North_point={this.state.North_point}
                              South_point={this.state.South_point}
                              Center_point={this.state.center_point}
                              map_level={this.state.map_level}
                              rangetype={this.state.rangetype}
                              ref={this.printRef}
                            ></UKMap>;
                            case "US": return <USMap
                              data={this.state.MapMarker}
                              parentCallback={this.handleRISalesSummaryIndiaMapCallBack}
                              North_point={this.state.North_point}
                              South_point={this.state.South_point}
                              Center_point={this.state.center_point}
                              map_level={this.state.map_level}
                              rangetype={this.state.rangetype}
                              ref={this.printRef}
                            ></USMap>;



                            default: return <IndiaMap data={this.state.MapMarker}
                              parentCallback={this.handleRISalesSummaryIndiaMapCallBack}
                              North_point={this.state.North_point}
                              South_point={this.state.South_point}
                              Center_point={this.state.center_point}
                              map_level={this.state.map_level}
                              rangetype={this.state.rangetype}
                              ref={this.printRef}
                            />;
                          }
                        })()}
                      </>
                      : <Loader />}
                  </Col>

                  <div className="icz-rimapfooter">
                    <Col lg={12}>
                      <ul className="icz-rilist-inline">
                        <li><div className="icz-rilist-bullet1" /> North</li>
                        <li><div className="icz-rilist-bullet2" /> West</li>
                        <li><div className="icz-rilist-bullet3" /> South</li>
                        <li><div className="icz-rilist-bullet4" /> East</li>
                        <li><div className="icz-rilist-bullet5" /> APTS</li>
                      </ul>
                    </Col>

                    <Col lg={12} className="icz-rimapprogressbar">
                      <h4 className="icz-riprogresstitle">Footfalls Achievement</h4>
                      <ProgressBar>
                        <ProgressBar className="range1" variant="range1" now={progress1} key={1} label="0-69" />
                        <ProgressBar className="range2" variant="range2" now={progress2} key={2} label="70-94" />
                        <ProgressBar className="range3" variant="range3" now={progress3} key={3} label="95+" />
                      </ProgressBar>
                    </Col>
                  </div>
                </Wrapper>
                : <Loader />}
            </div>
          </div>
          <div className="right_side col_span_3 relative-right-grid icz-ridatacontainer" style={{ padding: "0" }}>
            <div className="content">
              <div className="content_section">
                <div className='grid col_span_4'>
                  <div className='grid col_span_2'>
                    <PageHeader pageTitle={"Customer"} />
                  </div>
                  <div className='grid col_span_2' style={{ padding: "20px", display: "flex", justifyContent: "flex-end" }}>
                    <RiCustomerFilter />
                  </div>
                </div>
                <div className="page_content">
                  <div className="dashboard_home icz-datacontainer">
                    <div className="icz-sectiontabscontainer">
                      <div className="icz-tabscontrols">
                        <TabNav tabs={['Customer Study', 'Customer Tracker']} icon={[<IconUserGroup className="icz-tabicon" />, <IconWebsite className="icz-tabicon" />]} selected={this.state.selectedSubNav} setSelected={this.setSelectedSubNav}>
                          <Tab isSelected={this.state.selectedSubNav === 'Customer Study'}>
                            <div className='grid grid_cols_1'>
                              <CustomerStudy />
                            </div>
                          </Tab>
                          <Tab isSelected={this.state.selectedSubNav === 'Customer Tracker'}>
                            <div className='grid grid_cols_1'>
                              <CustomerTracker user={this.state.userData} />
                            </div>
                          </Tab>
                        </TabNav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Container className="p-0" fluid>
          <div className="d-flex flex-row">
            <Col lg={3} md={3} sm={12}>

            </Col>

            <Col lg={9} md={9} sm={12} className="icz-ridatacontainer">
              <Container className={this.state.is_scroll ? "icz-section-padding icz-pageheaderwrapper sticky" : "icz-section-padding icz-pageheaderwrapper"} fluid>
                <div className="icz-pageheadercontainer">
                  <div className="icz-stickylogowrapper">
                    <Image src={StickyLogo} className="icz-stickylogo" />
                  </div>
                  <div className="p-0 icz-headerdetails d-flex justify-content-between">
                    <Col lg={4} xs={12} md={12} className="p-0">
                      <h2 className="icz-pagetitle">Customer</h2>
                    </Col>

                    <Col lg={8} xs={12} md={12} className="p-0 icz-pagefiltersection">

                      <RiCustomerFilter />

                      <div className="icz-btnwrapper">
                        <button className="icz-themebtn" onClick={() => this.onClickRiExport()}>
                          <IconPdf className="icz-ribtnicon" />{" "}
                        </button>
                      </div>
                    </Col>
                  </div>
                </div>
              </Container>

              <Col lg={12}>
                <div className="icz-datacontainer">
                  
                </div>
              </Col>
            </Col>
          </div>
        </Container> */}
      </Wrapper>
    )
  }
}

function ReduxStateToProps(state) {
  return {
    start_date: state.date.startDate,
    end_date: state.date.endDate,
    previous_start_date: state.date.prevStartDate,
    previous_end_date: state.date.prevEndDate,
    country: state.userData.user.country_code,
    userData: state.userData.user,
  };
}

export default connect(ReduxStateToProps)(CustomerOverview)