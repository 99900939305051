import React, { Component } from "react";
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import Wrapper from "../../../helpers/wrapper";
import { Tab } from "../../../shared/components/tabs/tab";
import { RiTabCardNav } from "../../../shared/components/tabs/ritabcardnav";
import SolidGaugeChart from "../../../shared/components/charts/SolidGaugeChart";
import MultipartGaugeChart from "../../../shared/components/charts/MultipartGaugeChart";
import Linegraph_sparkline from "../../../shared/components/charts/Linegraph_sparkline";
import GaugechartLegends from "./SellthrougGaugechartLegends";
import BusinessSummary from "./BusinessSummary";
import SellSummary from "./SellSummary";
import StoreHealthSummary from "./StoreHealthSummary";
import { Breadcrumb, Col, Image } from "react-bootstrap";
import DatePicker from "../../../shared/components/datepicker/datepicker";
import IconPdf from "../../../../assets/images/icons/pdfIcon";
import IndiaMap from '../../../shared/components/map/indiamap/indiamap'
import { ProgressBar } from 'react-bootstrap';
import RiMapFilter from "../ri-mapfilter/RiMapFilter";
import IconDownload from '../../../../assets/images/icons/downloadIcon'
import StickyLogo from "../../../../assets/images/icons/icon.png";

import { Loader } from '../../../shared/components/loader/loader';

import '../../../../../App.scss';
import '../../../layout/ri-pageheader/ri-pageheader.scss';
import '../../../shared/components/loader/loader.scss';
import UKMap from "../../../shared/components/map/ukmap/ukmap";
import USMap from "../../../shared/components/map/usmap/usmap";

import BRAND1 from "../../../../assets/images/brands/brand1.png";
import BRAND2 from "../../../../assets/images/brands/brand2.png";
import BRAND3 from "../../../../assets/images/brands/brand3.png";
import BRAND4 from "../../../../assets/images/brands/brand4.png";
import BRAND5 from "../../../../assets/images/brands/brand5.png";
import BrandDropdown from "../../../shared/components/dropdown/brandDropdown";
import PageHeader from "../../../../../components/layout/pageHeader";
import { API_URL } from "../../../../../constants/constants";

export class SalesSummaryOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      business: [],
      sale: [],
      brandsDropdownOptions: [
        {
          value: "pulsar",
          label: "Pulsar",
          icon: (
            <img
              src={BRAND1}
              style={{ width: "25px", height: "25px", borderRadius: "100%" }}
            />
          ),
        },
        {
          value: "platina",
          label: "Platina",
          icon: (
            <img
              src={BRAND2}
              style={{ width: "25px", height: "25px", borderRadius: "100%" }}
            />
          ),
        },
        {
          value: "ct",
          label: "CT",
          icon: (
            <img
              src={BRAND3}
              style={{ width: "25px", height: "25px", borderRadius: "100%" }}
            />
          ),
        },
        {
          value: "avenger",
          label: "Avenger",
          icon: (
            <img
              src={BRAND4}
              style={{ width: "25px", height: "25px", borderRadius: "100%" }}
            />
          ),
        },
        {
          value: "dominar",
          label: "Dominar",
          icon: (
            <img
              src={BRAND5}
              style={{ width: "25px", height: "25px", borderRadius: "100%" }}
            />
          ),
        }
      ],

      selectedBrandDropdownOption: "",

      gaugedata: [{ "hand1": 0 }, { "hand2": 0 }, { "hand3": 0 }, { "hand4": 0 }],

      hand1Value: 0,
      hand2Value: 0,
      hand3Value: 0,
      hand4Value: 0,

      linesparkgraph: [],

      selected: "Business Overview",

      is_storcode_loaded: false,
      is_sellthrough_ytd_loaded_business: false,
      is_sellthrough_ytd_loaded_store_healthNew: false,
      is_businessOverviewSparkLine: false,
      is_storeHealthSparkLine: false,

      // DATEPICKER
      first_start_date: props.start_date,
      first_end_date: props.end_date,
      second_start_date: props.previous_start_date,
      second_end_date: props.previous_end_date,
      isDatePickerOpen: false,

      // MAP
      map_zone: '',
      map_state: '',
      map_city: '',
      map_store_code: "",
      map_store_name: '',

      // MAP FILTER
      map_store_category: '',
      map_store_type: '',
      map_store_size: '',

      mapBreadcrumbList: [],
      mapBreadcrumbsNorthPoint: '',
      mapBreadcrumbsSouthPoint: '',
      mapBreadcrumbsCenterPoint: '',

      data: "",
      MapMarker: "",
      North_point: [72.6416015625, 37.71859032558816],
      South_point: [93.1640625, 5.7908968128719565],
      map_level: 0,
      center_point: [0, 0],
      graph_pointer_business: "",
      cardScore: [],
      is_India_map_loaded: false,
      rangetype: "nsv_ach",
      //map filter
      map_filter_category_list: [
        { name: 'Metro', checked: false },
        { name: 'Non-Metro', checked: false },
      ],
      map_filter_storetype_list: [
        { name: 'New', checked: false },
        { name: 'LTL', checked: false },
        { name: 'Mature', checked: false },
      ],
      map_filter_storesize_list: [],

    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props != nextProps) {
      this.setState({
        first_start_date: nextProps.start_date,
        first_end_date: nextProps.end_date,
        second_start_date: nextProps.previous_start_date,
        second_end_date: nextProps.previous_end_date,
      });
    }
  }

  componentDidMount() {
    this.state.is_storcode_loaded = false;
    this.state.is_sellthrough_ytd_loaded_business = false;
    this.state.is_sellthrough_ytd_loaded_store_healthNew = false;
    this.state.is_businessOverviewSparkLine = false;
    this.state.is_storeHealthSparkLine = false;
    this.state.is_MultipartGaugeChart_loaded = false;

    this.state.country = this.props.country;
    this.getMapdetails();
    this.getSellThroughYTD();

    this.forceUpdate();

    window.addEventListener("scroll", this.handleScroll);
  }

  getUrlCountryParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if ((sParameterName[0]).toLowerCase() === sParam.toLowerCase()) {
        return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
    return "";
  }

  setSelected = (tab) => {
    this.setState({ selected: tab });

  };

  isNull(object) {
    for (const [key, value] of Object.entries(object)) {
      if (typeof value === "object" && value !== null) {
        this.isNull(value);
      } else if (!value) {
        object[key] = 0;
      }
    }
    return object;
  }

  getMapdetails() {
    let result = [];

    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    //map filter
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;
    let country = this.state.country != null ? (this.state.country).toUpperCase() : "IN";


    const url = API_URL + "ri/zones/";

    fetch(url, {
      method: "POST",
      body: "start_date=" + start_date +
        "&end_date=" + end_date +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size +
        "&country=" + country,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then((res) => res.json())
      .then(
        (result1) => {
          let varIndiaMapData = result1.data;
          this.setState({
            MapMarker: varIndiaMapData,
            is_India_map_loaded: true
          });
        },
        (error) => { }
      );

  }

  getIndiaMapCityDetails() {
    let result = [];

    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let map_zone = this.state.map_zone;
    //map filter
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;

    const url = API_URL + "ri/cities/";

    fetch(url, {
      method: "POST",
      body: "start_date=" + start_date +
        "&end_date=" + end_date +
        "&map_zone=" + map_zone +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then((res) => res.json())
      .then(
        (result1) => {
          let varIndiaMapData = result1.data;
          this.setState({
            MapMarker: varIndiaMapData,
            is_India_map_loaded: true,
            map_level: 1
          });
        },
        (error) => { }
      );

  }

  getIndiaMapStoresDetails() {
    let result = [];

    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let map_zone = this.state.map_zone;
    let map_city = this.state.map_city;
    //map filter
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;

    const url = API_URL + "ri/stores/";

    fetch(url, {
      method: "POST",
      body: "start_date=" + start_date +
        "&end_date=" + end_date +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then((res) => res.json())
      .then(
        (result1) => {
          let varIndiaMapData = result1.data;
          this.setState({
            MapMarker: varIndiaMapData,
            is_India_map_loaded: true,
            map_level: 2
          });
        },
        (error) => { }
      );

  }

  getSellThroughYTD() {

    let result = [];

    // DATEPICKER
    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;

    //MAP
    let map_zone = this.state.map_zone;
    let map_city = this.state.map_city;
    let storecode = this.state.map_store_code;
    let storename = this.state.map_store_name;
    let state = this.state.map_state;

    //MAP FILTER
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;

    const url = API_URL + "ri/sales_sellthrough_YTDChart/"

    fetch(url, {

      method: 'POST',
      body: 'start_date=' + start_date +
        '&end_date=' + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&storecode=" + storecode +
        "&storename=" + storename +
        "&state=" + state +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,

      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then(res => res.json())
      .then((result) => {

        let sellthrough_ytd_overall_gaugedata = result['sellthrough_ytd_overall_soh'];

        let sellthrough_ytd_zone_gaugedata = result['sellthrough_ytd_zone_soh']

        let sellthrough_ytd_city_gaugedata = result['sellthrough_ytd_city_soh']

        let sellthrough_ytd_store_gaugedata = result['sellthrough_ytd_store_soh']

        // let chartHand1Value = parseInt(((parseFloat(sellthrough_ytd_overall_gaugedata['units_sold']) / parseFloat(sellthrough_ytd_overall_gaugedata['soh']) * 100).toFixed(2)));
        // let chartHand2Value = sellthrough_ytd_zone_gaugedata['soh'] ? parseInt(((parseFloat(sellthrough_ytd_zone_gaugedata['units_sold']) / parseFloat(sellthrough_ytd_zone_gaugedata['soh']) * 100).toFixed(2))) + '%' : 'n/a';
        // let chartHand3Value = sellthrough_ytd_city_gaugedata['soh'] ? parseInt(((parseFloat(sellthrough_ytd_city_gaugedata['units_sold']) / parseFloat(sellthrough_ytd_city_gaugedata['soh']) * 100).toFixed(2))) + '%' : 'n/a';
        // let chartHand4Value = sellthrough_ytd_store_gaugedata['soh'] ? parseInt(((parseFloat(sellthrough_ytd_store_gaugedata['units_sold']) / parseFloat(sellthrough_ytd_store_gaugedata['soh']) * 100).toFixed(2))) + '%' : 'n/a';

        let chartHand1Value = parseInt(((parseFloat(sellthrough_ytd_overall_gaugedata['units_sold']) / parseFloat(sellthrough_ytd_overall_gaugedata['soh']) * 100).toFixed(2)));
        let chartHand2Value = parseInt(((parseFloat(sellthrough_ytd_zone_gaugedata['units_sold']) / parseFloat(sellthrough_ytd_zone_gaugedata['soh']) * 100).toFixed(2)));
        let chartHand3Value = parseInt(((parseFloat(sellthrough_ytd_city_gaugedata['units_sold']) / parseFloat(sellthrough_ytd_city_gaugedata['soh']) * 100).toFixed(2)));
        let chartHand4Value = parseInt(((parseFloat(sellthrough_ytd_store_gaugedata['units_sold']) / parseFloat(sellthrough_ytd_store_gaugedata['soh']) * 100).toFixed(2)));



        this.setState({
          // is_sellthrough_ytd_loaded: true,
          gaugedata: [{ "hand1": chartHand1Value }, { "hand2": chartHand2Value }, { "hand3": chartHand3Value }, { "hand4": chartHand4Value }],
          // hand1Value: chartHand1Value,
          // hand2Value: chartHand2Value,
          // hand3Value: chartHand3Value,
          // hand4Value: chartHand4Value,
          is_MultipartGaugeChart_loaded: true,
        });
      })

    //business overview//
    const url1 = API_URL + "ri/get_business-score/"

    fetch(url1, {

      method: 'POST',
      body: 'start_date=' + start_date +
        '&end_date=' + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&storecode=" + storecode +
        "&storename=" + storename +
        "&state=" + state +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,

      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then(res => res.json())
      .then((result1) => {

        let businessNew = result1.business;
        let graph_pointer_business = Math.round(result1.graph_pointer.business);
        let cardScore = graph_pointer_business;


        this.setState({
          business: businessNew,
          cardScore_business: cardScore,
          is_sellthrough_ytd_loaded_business: true,
        });
      });


    //Business Score Sparkline//
    const url2 = API_URL + "ri/sales_businesssparkline/"

    fetch(url2, {

      method: 'POST',
      body: 'start_date=' + start_date +
        '&end_date=' + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&storecode=" + storecode +
        "&storename=" + storename +
        "&state=" + state +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,

      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then(res => res.json())
      .then((result2) => {
        let business_sparkline_list = result2["business_sparkline"];
        let businessOverviewSparkLine_list = [];

        business_sparkline_list.map((info, index) => {
          try {
            let sales_data = {
              date: info['date'],
              value: info['value'],
            };
            businessOverviewSparkLine_list.push(sales_data)
          } catch (e) { }
        });

        this.setState({
          businessOverviewSparkLine: businessOverviewSparkLine_list,
          is_businessOverviewSparkLine: true,
        });

      });


    //Store Health Sparkline//
    const url3 = API_URL + "ri/sales_storehealthsparkline/"

    fetch(url3, {

      method: 'POST',
      body: 'start_date=' + start_date +
        '&end_date=' + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&storecode=" + storecode +
        "&storename=" + storename +
        "&state=" + state +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,

      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then(res => res.json())
      .then((result2) => {
        let store_health_sparkline_list = result2["business_sparkline"];
        let storeHealthSparkLine_list = [];

        store_health_sparkline_list.map((info, index) => {
          try {
            let sales_data = {
              date: info['datetime'],
              value: info['value'],
            };
            storeHealthSparkLine_list.push(sales_data)
          } catch (e) { }
        });

        this.setState({
          storeHealthSparkLine: storeHealthSparkLine_list,
          is_storeHealthSparkLine: true,
        });

      });


    //business overview//
    const url4 = API_URL + "ri/sales_storescore/"

    fetch(url4, {

      method: 'POST',
      body: 'start_date=' + start_date +
        '&end_date=' + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&storecode=" + storecode +
        "&storename=" + storename +
        "&state=" + state +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,

      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': ' Bearer ' + localStorage.getItem("token")
      },
    })
      .then(res => res.json())
      .then((result14) => {
        let store_healthNew = result14.store_health;
        let graph_pointer_store_health = Math.round(result14.graph_pointer.store_health);
        let cardScore_Store_Health = graph_pointer_store_health

        this.setState({
          store_healthNew: store_healthNew,
          cardScore_Store_Health: cardScore_Store_Health,
          is_sellthrough_ytd_loaded_store_healthNew: true,
        });
      });

  }

  componentDidUpdate(prevStates) {
    if (prevStates.startDate !== this.state.startDate || prevStates.endDate !== this.state.endDate) {
      this.handleCallback();
    }
    if (prevStates.mapStoreCode !== this.state.mapStoreCode) {
      this.handleRISalesSummaryMapCallBack();
    }
    if (prevStates.mapStoreCategory !== this.state.mapStoreCategory || prevStates.mapStoreType !== this.state.mapStoreType || prevStates.mapStoreSize !== this.state.mapStoreSize) {
      this.handleRiMapFilterCallback();
    }
  }

  handleCallback = (childData) => {
    this.state.first_start_date = childData[0];
    this.state.first_end_date = childData[1];
    this.state.second_start_date = childData[2];
    this.state.second_end_date = childData[3];

    this.state.is_sellthrough_ytd_loaded_business = false;
    this.state.is_sellthrough_ytd_loaded_store_healthNew = false;
    this.state.is_businessOverviewSparkLine = false;
    this.state.is_storeHealthSparkLine = false;
    this.state.is_MultipartGaugeChart_loaded = false;

    this.getSellThroughYTD();
    this.forceUpdate();
    this.handleCallback2();
  };

  handleCallback2 = () => {
    this.setState({ isDatePickerOpen: !this.state.isDatePickerOpen });
  };

  handleScroll = () => {
    if (window.pageYOffset > 75) {
      if (!this.state.is_scroll) {
        this.setState({ is_scroll: true });
      }
    } else {
      if (this.state.is_scroll) {
        this.setState({ is_scroll: false });
      }
    }
  };

  handleRISalesSummaryMapCallBack = (childData) => {
    this.state.map_store_code = childData;

    this.state.is_sellthrough_ytd_loaded_business = false;
    this.state.is_sellthrough_ytd_loaded_store_healthNew = false;
    this.state.is_businessOverviewSparkLine = false;
    this.state.is_storeHealthSparkLine = false;
    this.state.is_MultipartGaugeChart_loaded = false;
    this.getSellThroughYTD();
    this.forceUpdate();
  }

  handleRiMapFilterCallback = (childData) => {

    let storeCategory = [];
    let storeType = [];
    let storeSize = [];

    // Object.keys(childData[0]).forEach(key => {
    //   if (childData[0][key] == true) {
    //     storeCategory.push(key);
    //   }
    // });
    // Object.keys(childData[1]).forEach(key => {
    //   if (childData[1][key] == true) {
    //     storeType.push(key);
    //   }
    // });
    // Object.keys(childData[2]).forEach(key => {
    //   if (childData[2][key] == true) {
    //     storeSize.push(key);
    //   }
    // });
    storeCategory.push(childData[0]);
    storeType.push(childData[1]);
    storeSize.push(childData[2]);


    this.state.map_store_category = storeCategory;
    this.state.map_store_type = storeType;
    this.state.map_store_size = storeSize;

    //0 zone level
    //get city
    if (this.state.map_level == 0) {
      this.state.is_India_map_loaded = false;
      this.getMapdetails();

    }

    //1 city level
    else if (this.state.map_level == 1) {
      this.state.is_India_map_loaded = false;
      this.getIndiaMapCityDetails();

    }

    //2 store level
    else if (this.state.map_level == 2) {
      this.state.is_India_map_loaded = false;
      this.getIndiaMapStoresDetails();
    }
    //3 store code level data 
    else if (this.state.map_level == 3) {
    }
    this.state.is_sellthrough_ytd_loaded_business = false;
    this.state.is_sellthrough_ytd_loaded_store_healthNew = false;
    this.state.is_businessOverviewSparkLine = false;
    this.state.is_storeHealthSparkLine = false;
    this.state.is_MultipartGaugeChart_loaded = false;


    this.getSellThroughYTD();
    this.forceUpdate();


  };

  handleCallbackIndiamap = () => {
    this.getSellThroughYTD();
    this.forceUpdate();

  };

  handleRISalesSummaryIndiaMapCallBack = (childData) => {
    this.state.North_point = childData[0];
    this.state.South_point = childData[1];
    this.state.center_point = childData[2];
    this.state.map_zone = childData[3];
    this.state.map_city = childData[4];
    this.state.map_store_code = childData[5];
    this.state.map_store_name = childData[6];


    //0 zone level
    //get city
    if (this.state.map_level == 0) {
      this.state.is_India_map_loaded = false;
      this.getIndiaMapCityDetails();
      this.state.mapBreadcrumbList = ['All ZONES', this.state.map_zone];
    }

    //1 city level
    else if (this.state.map_level == 1) {
      this.state.is_India_map_loaded = false;
      this.getIndiaMapStoresDetails();
      this.state.mapBreadcrumbList = ['All ZONES', this.state.map_zone, this.state.map_city];
    }

    //2 store level
    else if (this.state.map_level == 2) {
      this.state.mapBreadcrumbList = ['All ZONES', this.state.map_zone, this.state.map_city, this.state.map_store_name];
      // this.getIndiaMapStoresDetails();
    }
    //3 store code level data 
    else if (this.state.map_level == 3) {
      this.state.mapBreadcrumbList = ['All ZONES', this.state.map_zone, this.state.map_city, this.state.map_store_name];
      // this.getIndiaMapStoresDetails();
    }


    this.state.mapBreadcrumbsCenterPoint = this.state.center_point;
    this.state.mapBreadcrumbsNorthPoint = this.state.North_point;
    this.state.mapBreadcrumbsSouthPoint = this.state.South_point;
    this.state.is_sellthrough_ytd_loaded_business = false;
    this.state.is_sellthrough_ytd_loaded_store_healthNew = false;
    this.state.is_businessOverviewSparkLine = false;
    this.state.is_storeHealthSparkLine = false;
    this.getSellThroughYTD();
    this.forceUpdate();
  };

  mapBreadcrumbs = (evt, index) => {

    this.state.mapBreadcrumbList.splice(index);

    // level 0
    // all zone
    if (index == 1) {
      this.state.map_zone = '';
      this.state.map_city = '';
      this.state.map_store_code = '';
      this.state.map_store_name = '';
      this.state.map_store_category = '';
      this.state.map_store_type = '';
      this.state.map_store_size = '';
      this.state.map_level = 0;
      this.state.center_point = [0, 0];
      this.state.North_point = [72.6416015625, 37.71859032558816];
      this.state.South_point = [93.1640625, 5.7908968128719565];
      this.state.is_India_map_loaded = false;
      this.getMapdetails();
    }
    // level 1
    //city level
    if (index == 2) {
      // this.state.map_zone = '';
      this.state.map_city = '';
      this.state.map_store_code = '';
      this.state.map_store_name = '';
      this.state.map_level = 0;
      this.state.center_point = [0, 0];
      this.state.North_point = [72.6416015625, 37.71859032558816];
      this.state.South_point = [93.1640625, 5.7908968128719565];
      this.state.is_India_map_loaded = false;
      this.getIndiaMapCityDetails();

    }
    // level 3
    //Store level
    if (index == 3) {
      // this.state.map_zone = '';
      //this.state.map_city = '';
      this.state.map_store_code = '';
      this.state.map_store_name = '';
      this.state.map_level = 0;
      this.state.center_point = [0, 0];
      this.state.North_point = [72.6416015625, 37.71859032558816];
      this.state.South_point = [93.1640625, 5.7908968128719565];
      this.state.is_India_map_loaded = false;

      this.state.is_customer_metrices_loaded = false;
      this.state.is_sales_metrices_loaded = false;
      this.state.is_sales_graph_loaded = false;
      this.state.is_customer_graph_loaded = false;
      this.getIndiaMapStoresDetails();

    }
    this.state.is_sellthrough_ytd_loaded_business = false;
    this.state.is_sellthrough_ytd_loaded_store_healthNew = false;
    this.state.is_businessOverviewSparkLine = false;
    this.state.is_storeHealthSparkLine = false;
    this.state.is_MultipartGaugeChart_loaded = false;
    this.getSellThroughYTD();

    this.forceUpdate();
  }

  onClickRiExport = () => {
    window.print('height=2000,width=2000')
  }

  // BRAND DROPDOWN
  brandDropdownSelectionHandler = (e) => {
    if (e === null) {
      this.state.selectedBrandDropdownOption = "";
    } else {
      this.state.selectedBrandDropdownOption = e;
    }
    this.forceUpdate();
  };

  render() {
    const progress1 = 65; const progress2 = 25; const progress3 = 10;

    return (
      <Wrapper>
        <div className="grid drawer_grid ri_summary_bsImport">
          <div className="left_drawer col_span_1 fixed-left-grid mapRightWrapper">
            <div className="icz-rimapsection">
              <Wrapper>
                <Col lg={12} className="icz-rimapsectionheader">
                  <Wrapper>
                    <Wrapper>
                      <Col lg={4} className="icz-rimaptitle">
                        {this.state.country != null ? (this.state.country.toUpperCase() == "UK" ? "UK" : this.state.country.toUpperCase() == "US" ? "US" : "India") : "India"}
                      </Col>
                      <Col lg={8} className="icz-rimapfilter">
                        <RiMapFilter
                          parentCallback={this.handleRiMapFilterCallback}
                          map_filter_category_list={this.state.map_filter_category_list}
                          map_filter_storetype_list={this.state.map_filter_storetype_list}
                          map_filter_storesize_list={this.state.map_filter_storesize_list} />


                        <div className="icz-ribtnwrapper">
                          <button className="icz-rithemebtn">
                            <IconDownload className="icz-riicon" /> </button>
                        </div>
                      </Col>
                    </Wrapper>
                    <div className="breadcrumb-no-padding">
                      <Breadcrumb>
                        {this.state.mapBreadcrumbList.map((item, index) => (
                          <Breadcrumb.Item onClick={() => { this.mapBreadcrumbs(item, index + 1) }} key={index}>{item}</Breadcrumb.Item>
                        ))}
                      </Breadcrumb>
                    </div>
                  </Wrapper>
                </Col>

                <Col lg={12} className="icz-rimapcontainer">
                  {/* {this.state.is_India_map_loaded == true ?
                      <IndiaMap data={this.state.MapMarker}
                        parentCallback={this.handleRISalesSummaryIndiaMapCallBack}
                        North_point={this.state.North_point}
                        South_point={this.state.South_point}
                        Center_point={this.state.center_point}
                        map_level={this.state.map_level}
                        rangetype={this.state.rangetype}
                      />
                      : <Loader />} */}
                  {this.state.is_India_map_loaded == true ?
                    <>
                      {(() => {
                        switch (this.state.country) {
                          case "india": return <IndiaMap data={this.state.MapMarker}
                            parentCallback={this.handleRISalesSummaryIndiaMapCallBack}
                            North_point={this.state.North_point}
                            South_point={this.state.South_point}
                            Center_point={this.state.center_point}
                            map_level={this.state.map_level}
                            rangetype={this.state.rangetype}
                            ref={this.printRef}
                          />;
                          case "UK": return <UKMap
                            data={this.state.MapMarker}
                            parentCallback={this.handleRISalesSummaryIndiaMapCallBack}
                            North_point={this.state.North_point}
                            South_point={this.state.South_point}
                            Center_point={this.state.center_point}
                            map_level={this.state.map_level}
                            rangetype={this.state.rangetype}
                            ref={this.printRef}
                          ></UKMap>;
                          case "US": return <USMap
                            data={this.state.MapMarker}
                            parentCallback={this.handleRISalesSummaryIndiaMapCallBack}
                            North_point={this.state.North_point}
                            South_point={this.state.South_point}
                            Center_point={this.state.center_point}
                            map_level={this.state.map_level}
                            rangetype={this.state.rangetype}
                            ref={this.printRef}
                          ></USMap>;

                          default: return <IndiaMap data={this.state.MapMarker}
                            parentCallback={this.handleRISalesSummaryIndiaMapCallBack}
                            North_point={this.state.North_point}
                            South_point={this.state.South_point}
                            Center_point={this.state.center_point}
                            map_level={this.state.map_level}
                            rangetype={this.state.rangetype}
                            ref={this.printRef}
                          />;
                        }
                      })()}
                    </>
                    : <Loader />}
                </Col>

                <div className="icz-rimapfooter">
                  <Col lg={12}>
                    <ul className="icz-rilist-inline">
                      <li><div className="icz-rilist-bullet1" /> North</li>
                      <li><div className="icz-rilist-bullet2" /> West</li>
                      <li><div className="icz-rilist-bullet3" /> South</li>
                      <li><div className="icz-rilist-bullet4" /> East</li>
                      <li><div className="icz-rilist-bullet5" /> APTS</li>
                    </ul>
                  </Col>
                  <Col lg={12} className="icz-rimapprogressbar">
                    <h4 className="icz-riprogresstitle">NSV Achievement Percentage</h4>
                    <ProgressBar>
                      <ProgressBar className="range1" variant="range1" now={progress1} key={1} label="0-69" />
                      <ProgressBar className="range2" variant="range2" now={progress2} key={2} label="70-94" />
                      <ProgressBar className="range3" variant="range3" now={progress3} key={3} label="95+" />
                    </ProgressBar>
                  </Col>
                </div>

              </Wrapper>
            </div>
          </div>
          <div className="right_side col_span_3 relative-right-grid icz-ridatacontainer" style={{ padding: "0" }}>
            <div className="content">
              <div className="content_section">
                <PageHeader pageTitle={"Sales Summary"} />
                <div className="page_content">
                  <div className="dashboard_home">
                    <div className="grid grid_cols_1 d-flex flex-column">
                      {
                        this.state.is_sellthrough_ytd_loaded_business === true &&
                          this.state.is_sellthrough_ytd_loaded_store_healthNew === true &&
                          this.state.is_businessOverviewSparkLine === true &&
                          this.state.is_storeHealthSparkLine === true &&
                          this.state.is_MultipartGaugeChart_loaded === true ?
                          <RiTabCardNav
                            tabs={["Business Overview", "Sell Through - YTD", "Store Health"]}
                            CardScore={[this.state.cardScore_business, "", this.state.cardScore_Store_Health]}
                            Chart={[
                              <SolidGaugeChart
                                chart_id="business_score"
                                chart_class="icz-ritabchart icz-cursor-pointer"
                                graph-data={this.state.business}
                              />,
                              <MultipartGaugeChart
                                chart_id="gauge_mtd"
                                chart_class="icz-ritabchart icz-cursor-pointer"
                                graph-data={this.state.gaugedata}
                              // hand1Value={this.state.hand1Value}
                              // hand2Value={this.state.hand2Value}
                              // hand3Value={this.state.hand3Value}
                              // hand4Value={this.state.hand4Value}
                              />
                              ,
                              <SolidGaugeChart
                                chart_id="sale_score"
                                chart_class="icz-ritabchart icz-cursor-pointer"
                                graph-data={this.state.store_healthNew}
                              />,
                            ]}
                            ChartBottom={[
                              <Linegraph_sparkline
                                card_id="icz-business"
                                card_class="icz-ritabcardchart"
                                graph-data={this.state.businessOverviewSparkLine}
                                isnumber={'number'}
                              />,
                              <GaugechartLegends />,
                              <Linegraph_sparkline
                                card_id="icz-health"
                                card_class="icz-ritabcardchart"
                                graph-data={this.state.storeHealthSparkLine}
                                isnumber={'number'}
                              />,
                            ]}
                            DataMetrics={[
                              "Business Overview",
                              "Sell Through - YTD",
                              "Store Health",
                            ]}
                            selected={this.state.selected}
                            setSelected={this.setSelected}
                            isDisabled={[
                              "notdisabled",
                              "notdisabled",
                              "disabled",
                              "disabled",
                            ]}
                          >
                            <Tab isSelected={this.state.selected === "Business Overview"}>
                              <BusinessSummary
                                mapZone={this.state.map_zone}
                                mapState={this.state.map_state}
                                mapCity={this.state.map_city}
                                mapStoreCode={this.state.map_store_code}
                                mapStoreName={this.state.map_store_name}
                                mapStoreCategory={this.state.map_store_category}
                                mapStoreType={this.state.map_store_type}
                                mapStoreSize={this.state.map_store_size}
                                startDate={this.state.first_start_date}
                                endDate={this.state.first_end_date}
                                lastStartDate={this.state.second_start_date}
                                lastEndDate={this.state.second_end_date} />
                            </Tab>
                            <Tab isSelected={this.state.selected === "Sell Through - YTD"}>
                              <SellSummary
                                mapZone={this.state.map_zone}
                                mapState={this.state.map_state}
                                mapCity={this.state.map_city}
                                mapStoreCode={this.state.map_store_code}
                                mapStoreName={this.state.map_store_name}
                                mapStoreCategory={this.state.map_store_category}
                                mapStoreType={this.state.map_store_type}
                                mapStoreSize={this.state.map_store_size}
                                startDate={this.state.first_start_date}
                                endDate={this.state.first_end_date}
                                lastStartDate={this.state.second_start_date}
                                lastEndDate={this.state.second_end_date} />
                            </Tab>
                            <Tab isSelected={this.state.selected === "Store Health"}>
                              <StoreHealthSummary
                                mapZone={this.state.map_zone}
                                mapState={this.state.map_state}
                                mapCity={this.state.map_city}
                                mapStoreCode={this.state.map_store_code}
                                mapStoreName={this.state.map_store_name}
                                mapStoreCategory={this.state.map_store_category}
                                mapStoreType={this.state.map_store_type}
                                mapStoreSize={this.state.map_store_size}
                                startDate={this.state.first_start_date}
                                endDate={this.state.first_end_date}
                                lastStartDate={this.state.second_start_date}
                                lastEndDate={this.state.second_end_date} />
                            </Tab>
                          </RiTabCardNav>
                          : <Loader />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

function ReduxStateToProps(state) {
  return {
    start_date: state.date.startDate,
    end_date: state.date.endDate,
    previous_start_date: state.date.prevStartDate,
    previous_end_date: state.date.prevEndDate,
    country: state.userData.user.country_code
  };
}

export default connect(ReduxStateToProps)(SalesSummaryOverview)