import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import { connect } from "react-redux";
import Converstion_funnel from '../../shared/components/charts/Converstion_funnel';
import Piechart from '../../shared/components/charts/Piechart';
import "../ecom/ecom.scss";
// import SummaryDropdown from './Summary/SummaryDropdown';
// import Dropdown from './Summary//Dropdown';
// import BarChart from '../../shared/components/charts/BarChart';
import { Link } from 'react-router-dom';
import IconWebsite from '../../../assets/images/icons/websiteIcon';
import '../../shared/components/cards/card.scss';
import { ChartCard } from '../../shared/components/cards/chartcard/chartcard';
import { Carousel } from '../../shared/components/carousel/carousel';
import { BarChart } from '../../shared/components/charts/BarChart';
import Linegraph_sparkline from '../../shared/components/charts/Linegraph_sparkline';
import { DailydataDropdown } from '../../shared/components/dropdown/dailydatadropdown';
// import ChartExport} from "../../../shared/components/export/ChartExport";
import ChartExport from '../../shared/components/export/ChartExport';

import PageHeader from '../../../../components/layout/pageHeader';
import { CountryCode } from '../../../../constants/countryCurrency';
import Wrapper from '../../helpers/wrapper';
import '../../layout/pageheader/pageheader.scss';
import { DetailsCard } from '../../shared/components/cards/detailscard/detailscard';
import { Loader } from '../../shared/components/loader/loader';
import { API_URL } from '../../../../constants/constants';


const sortObject = obj => {
	const arr = Object.keys(obj).map(el => {
		return obj[el];
	});
	arr.sort((a, b) => {
		return a - b;
	});
	return arr;
};

export class Ecom extends Component {
	constructor(props) {
		super(props);
		this.state = {
			trafficeChannel: '',
			trafficpievalue: [],
			// '[{"name": "Social","value": 30},{"name": "Direct","value": 30},{ "name": "Website","value": 50},{ "name": "Email","value": 40},{ "name": "Organic","value": 60}]',
			bargraphsession: '[{"name": "Jan", "points": 100}, {"name": "Feb", "points": 200},{"name": "march", "points": 110},{"name": "April", "points": 100},{"name": "May", "points": 100},{"name": "June", "points": 100},{"name": "July", "points": 100},{"name": "Aug", "points": 100},{"name": "Sep", "points": 130},{"name": "Oct", "points": 140},{"name": "Nov", "points": 200},{"name": "Dec", "points": 180}]',
			linesparkgraph: '[{"date":"2021-05-01","value":590887},{"date":"2021-05-02","value":563594},{"date":"2021-05-03","value":265531},{"date":"2021-05-04","value":197982},{"date":"2021-05-05","value":362524},{"date":"2021-05-06","value":339153},{"date":"2021-05-07","value":353475},{"date":"2021-05-08","value":406614},{"date":"2021-05-09","value":410095},{"date":"2021-05-10","value":103192},{"date":"2021-05-11","value":97085},{"date":"2021-05-12","value":142234},{"date":"2021-05-13","value":319419},{"date":"2021-05-14","value":258862},{"date":"2021-05-15","value":279252},{"date":"2021-05-16","value":200358},{"date":"2021-05-17","value":65448},{"date":"2021-05-18","value":71861},{"date":"2021-05-19","value":109825},{"date":"2021-05-20","value":203801},{"date":"2021-05-21","value":75118},{"date":"2021-05-22","value":69015},{"date":"2021-05-23","value":51354},{"date":"2021-05-24","value":44399},{"date":"2021-05-25","value":62825},{"date":"2021-05-26","value":154277},{"date":"2021-05-27","value":292812},{"date":"2021-05-28","value":81882},{"date":"2021-05-29","value":39089},{"date":"2021-05-30","value":43483}]',
			scroll: '',
			funnelgraph: '[{ "name": "Sessions with Category Page Views", "value": 600},{ "name": "Sessions with Product detail page views", "value": 300},{ "name": "Sessions with Add to Cart","value": 200},{"name": "Sessions with visit to Checkout Page","value": 180},{"name": "Sessions with Visit to Address Page", "value": 50} ,{ "name": "Sessions with Visit to Payment Page","value": 20},{"name": "Sessions with Purchase","value": 10}]',
			is_metrices_loaded: false,
			metrices_data: [],
			is_funnel_loaded: false,
			funnel_graph_list: [],
			is_graph_loaded: false,
			is_pie_loaded: false,
			channel_dropdown_option: [{ "value": "channel", "label": "Traffic Channels" }, { "value": " Category", "label": " Category" }],
			is_channel_data_loaded: false,
			dropdown_channel_name: "channel",
			pie_click_data: "",
			daily_metrics_name: "Channel",

			is_scroll: false,

			first_start_date: props.start_date,
			first_end_date: props.end_date,
			second_start_date: props.previous_start_date,
			second_end_date: props.previous_end_date,
			isDatePickerOpen: false,

		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props != nextProps) {
			this.setState({
				first_start_date: nextProps.start_date,
				first_end_date: nextProps.end_date,
				second_start_date: nextProps.previous_start_date,
				second_end_date: nextProps.previous_end_date,
			});
		}
	}

	componentDidMount() {
		this.loadSummaryData();
		window.addEventListener("scroll", this.handleScroll);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.start_date !== this.props.start_date ||
			prevProps.end_date !== this.props.end_date ||
			prevProps.previous_start_date !== this.props.previous_start_date ||
			prevProps.previous_end_date !== this.props.previous_end_date
		) {

			this.state.is_social_card_loaded = false;
			this.state.is_metrices_loaded = false;
			this.state.is_getall_metrices_calc_loaded = false;
			this.state.is_graph_loaded = false;
			this.state.is_funnel_loaded = false;

			this.state.is_metrices_category_wise_loaded = false;
			this.state.is_metrices_new_loaded = false;
			this.state.is_category_wise_graph_loaded = false;

			this.setState({
				is_pie_loaded: false
			})

			// this.getPiechart()
			// this.getPiecategorychart()
			this.getChannelData();
			this.getCategoryData();

			this.loadSummaryData();
		}
	}

	loadSummaryData = () => {
		if (this.state.dropdown_channel_name == "channel") {
			this.getChannelData();
		} else {
			this.getCategoryData();
		}
	}

	getChannelData = () => {
		this.getPiechart();
		this.getallMetrices();
		this.getallMetricesCalc();
		this.getlinesparkgraph();
		this.getFunnel();
	}

	getCategoryData = () => {
		this.getPiecategorychart();
		this.getallMetricesCategoryWise();
		this.getallMetricesNew();
		this.getlinesparkgraphCategoryWise();
		this.getCategoryFunnel();
	}

	getallMetricesCategoryWise() {

		let start_date = this.state.first_start_date;
		let end_date = this.state.first_end_date;
		let previous_start_date = this.state.second_start_date;
		let previous_end_date = this.state.second_end_date;
		let social_platform = 'FACEBOOK';
		let sales_by = this.state.dropdown_channel_name;
		let pie_click_data = this.state.pie_click_data;

		const url = API_URL + "ecom/summary-metrices-category/"

		fetch(url, {
			method: 'POST',
			body: "start_date=" + start_date +
				"&end_date=" + end_date +
				"&last_start_date=" + previous_start_date +
				"&last_end_date=" + previous_end_date +
				"&social_platform=" + social_platform +
				"&sales_by=" + sales_by +
				"&pie_click_data=" + pie_click_data,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': ' Bearer ' + localStorage.getItem("token")
			},
		}).then(res => res.json()).then((result) => {

			// unique_page_views
			// bounce
			// entrances
			// product_add_to_cart
			// item_revenue
			// unique_purchases
			// product_refund

			// product_add_to_cart
			let current_unique_page_views = result['current_ecom_metrices_list'][0]['unique_page_views'];
			let current_bounces = result['current_ecom_metrices_list'][0]['bounce'];
			let current_entrances = result['current_ecom_metrices_list'][0]['entrances'];
			let current_product_adds_to_cart = result['current_ecom_metrices_list'][0]['product_add_to_cart'];
			let current_item_revenue = result['current_ecom_metrices_list'][0]['item_revenue'];
			let current_unique_purchases = result['current_ecom_metrices_list'][0]['unique_purchases'];
			let current_product_refund = result['current_ecom_metrices_list'][0]['product_refund'];



			let last_unique_page_views = result['last_ecom_metrices_list'][0]['unique_page_views'];
			let last_bounces = result['last_ecom_metrices_list'][0]['bounce'];
			let last_entrances = result['last_ecom_metrices_list'][0]['entrances'];
			let last_product_adds_to_cart = result['last_ecom_metrices_list'][0]['product_add_to_cart'];
			let last_item_revenue = result['last_ecom_metrices_list'][0]['item_revenue'];
			let last_unique_purchases = result['last_ecom_metrices_list'][0]['unique_purchases'];
			let last_product_refund = result['last_ecom_metrices_list'][0]['product_refund'];


			let current_add_to_cart_rate = current_product_adds_to_cart / current_unique_page_views;
			let current_sales = result['current_ecom_metrices_list'][0]['item_revenue'];
			let current_total_order = current_unique_purchases;
			let current_avg_order_value = (current_item_revenue / current_unique_purchases);
			let current_cancellations = result['current_ecom_metrices_list'][0]['product_refund'];
			let current_conversion_rate = (current_unique_purchases / current_unique_page_views) * 100;
			let current_sessions = result['current_ecom_metrices_list'][0]['unique_page_views'];
			let current_bounce_rate = (current_bounces / current_entrances) * 100;


			let last_add_to_cart_rate = last_product_adds_to_cart / last_unique_page_views;
			let last_sales = result['last_ecom_metrices_list'][0]['item_revenue'];
			let last_total_order = last_unique_purchases;
			let last_avg_order_value = (last_item_revenue / last_unique_purchases);
			let last_cancellations = result['last_ecom_metrices_list'][0]['product_refund'];
			let last_conversion_rate = (last_unique_purchases / last_unique_page_views) * 100;
			let last_sessions = result['last_ecom_metrices_list'][0]['unique_page_views'];
			let last_bounce_rate = (last_bounces / last_entrances) * 100;


			this.setState({
				is_metrices_category_wise_loaded: true,


				current_add_to_cart_rat: current_add_to_cart_rate,
				current_sales: current_sales,
				current_total_order: current_total_order,
				current_avg_order_value: current_avg_order_value,
				current_cancellations: current_cancellations,
				current_conversion_rate: current_conversion_rate,
				current_sessions: current_sessions,
				current_bounce_rate: current_bounce_rate,


				last_add_to_cart_rat: last_add_to_cart_rate,
				last_sales: last_sales,
				last_total_order: last_total_order,
				last_avg_order_value: last_avg_order_value,
				last_cancellations: last_cancellations,
				last_conversion_rate: last_conversion_rate,
				last_sessions: last_sessions,
				last_bounce_rate: last_bounce_rate,

				// value set to 0


				current_avg_session_duration: 0,
				last_avg_session_duration: 0,
				current_avg_Page_Sessions: 0,
				last_avg_Page_Sessions: 0,
				current_sessions_with_search_percentage: 0,
				last_sessions_with_search_percentage: 0,
				current_users: 0,
				last_users: 0,
				current_new_user: 0,
				last_new_user: 0,

				current_mobile_users_metrices_list: 0,
				last_mobile_users_metrices_list: 0,
				current_top_landing_page_metrices_list: 0,
				current_top_landing_page_sessions: 0,
				last_top_landing_page_metrices_list: 0,





			});
		})

	}

	getallMetricesNew() {

		let start_date = this.state.first_start_date;
		let end_date = this.state.first_end_date;
		let previous_start_date = this.state.second_start_date;
		let previous_end_date = this.state.second_end_date;
		let pie_click_data = this.state.pie_click_data;

		const url = API_URL + "ecom/ecom-metrices-new/"

		fetch(url, {
			method: 'POST',
			body: "start_date=" + start_date +
				"&end_date=" + end_date +
				"&last_start_date=" + previous_start_date +
				"&last_end_date=" + previous_end_date +
				"&pie_click_data=" + pie_click_data,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': ' Bearer ' + localStorage.getItem("token")
			},
		}).then(res => res.json()).then((result) => {

			let current_avg_session_duration = result['current_avg_session_duration'][0]['avg_session_duration']
			let last_avg_session_duration = result['last_avg_session_duration'][0]['avg_session_duration']

			let current_avg_pg_per_sess = result['current_avg_pg_per_sess'][0]['avg_pg_per_sess']
			let last_avg_pg_per_sess = result['last_avg_pg_per_sess'][0]['avg_pg_per_sess']

			let sparkline_list = result['avg_pg_per_sess_list']
			let sparkline_list_1 = result['avg_session_duration_list']

			let avg_session_duration_list = [];
			let avg_pg_per_sess_list = [];


			sparkline_list.map((info, index) => {
				try {

					let a = {
						"name": info['session_date'],
						"points": info['avg_pg_per_sess'].toFixed(2) ? info['avg_pg_per_sess'].toFixed(2) : 0,
					}
					avg_pg_per_sess_list.push(a);


				} catch {

				}
			});

			sparkline_list_1.map((info, index) => {
				try {

					let b = {
						"name": info['session_date'],
						"points": info['avg_session_duration'].toFixed(2) ? info['avg_session_duration'].toFixed(2) : 0,
					}
					avg_session_duration_list.push(b);
				} catch {
				}
			});




			this.setState({
				is_metrices_new_loaded: true,
				current_avg_session_duration: current_avg_session_duration,
				last_avg_session_duration: last_avg_session_duration,
				current_avg_Page_Sessions: current_avg_pg_per_sess,
				last_avg_Page_Sessions: last_avg_pg_per_sess,
				avg_session_duration_graph: avg_session_duration_list,
				avg_Page_Sessions_graph: avg_pg_per_sess_list,








			});


		})

	}
	getlinesparkgraphCategoryWise() {
		let start_date = this.state.first_start_date;
		let end_date = this.state.first_end_date;
		let previous_start_date = this.state.second_start_date;
		let previous_end_date = this.state.second_end_date;
		let social_platform = 'FACEBOOK';
		let sales_by = this.state.dropdown_channel_name;
		let pie_click_data = this.state.pie_click_data;

		const url = API_URL + "ecom/summary-metrices-category/"
		fetch(url, {
			method: 'POST',
			body: "start_date=" + start_date +
				"&end_date=" + end_date +
				"&last_start_date=" + previous_start_date +
				"&last_end_date=" + previous_end_date +
				"&social_platform=" + social_platform +
				"&sales_by=" + sales_by +
				"&pie_click_data=" + pie_click_data,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': ' Bearer ' + localStorage.getItem("token")
			},
		}).then(res => res.json()).then((result) => {
			let sparkline_list = result['ecom_metrices_sparkline_list']

			let sales_spark_list = [];

			let total_order_list = [];

			let cancellations_list = [];

			let avg_order_value_list = [];

			let conversion_list = [];

			let sessions_list = [];

			let bounce_rate_list = [];

			let add_to_cart_rate_list = [];


			let avg_session_duration_list = [];
			let avg_Page_Sessions_list = [];
			let sessions_with_search_percentage_list = [];
			let users_list = [];
			let new_user_list = [];

			sparkline_list.map((info, index) => {



				try {
					let f = {
						"date": info['date'],
						"value": info['item_revenue']
					}
					sales_spark_list.push(f)

					let g = {
						"date": info['date'],
						"value": info['unique_purchases']
					}
					total_order_list.push(g)

					let j = {
						"date": info['date'],
						"value": info['product_refund']
					}
					cancellations_list.push(j)

					let h = {
						"date": info['date'],
						"value": (info['item_revenue'] / info['unique_purchases']).toFixed(2)
					}
					avg_order_value_list.push(h)

					let i = {
						"date": info['date'],
						"value": ((info['unique_purchases'] / info['unique_page_views']) * 100).toFixed(2)
					}
					conversion_list.push(i)

					let a = {
						"date": info['date'],
						"value": info['unique_page_views']
					}
					sessions_list.push(a)

					let d = {
						"name": info['date'],
						"points": ((info['bounce'] / info['entrances']) * 100).toFixed(2)
					}
					bounce_rate_list.push(d)

					let e = {
						"date": info['date'],
						"value": (info['product_adds_to_cart'] / info['unique_page_views']).toFixed(2)
					}
					add_to_cart_rate_list.push(e)

				}
				catch (e) {

				}

			})

			this.setState({

				is_category_wise_graph_loaded: true,

				sales_spark_graph: sales_spark_list,
				total_order_graph: total_order_list,
				cancellations_graph: cancellations_list,
				avg_order_value_graph: avg_order_value_list,
				conversion_graph: conversion_list,
				bounce_rate_graph: bounce_rate_list,
				sessions_graph: sessions_list,
				add_to_cart_without_view_graph: add_to_cart_rate_list,

				avg_session_duration_graph: avg_session_duration_list,
				avg_Page_Sessions_graph: avg_Page_Sessions_list,
				sessions_with_search_percentage_graph: sessions_with_search_percentage_list,
				users_graph: users_list,
				new_user_graph: new_user_list,
				mobile_users_sparkline_graph: [],


			});
		})
	}
	getallMetrices() {

		let start_date = this.state.first_start_date;
		let end_date = this.state.first_end_date;
		let previous_start_date = this.state.second_start_date;
		let previous_end_date = this.state.second_end_date;
		let social_platform = 'FACEBOOK';
		let pie_click_data = this.state.pie_click_data;

		const url = API_URL + "ecom/ecom-metrices/"
		fetch(url, {
			method: 'POST',
			body: "start_date=" + start_date +
				"&end_date=" + end_date +
				"&last_start_date=" + previous_start_date +
				"&last_end_date=" + previous_end_date +
				"&social_platform=" + social_platform +
				"&pie_click_data=" + pie_click_data,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': ' Bearer ' + localStorage.getItem("token")
			},
		}).then(res => res.json()).then((result) => {
			let current_avg_order_value;
			let last_avg_order_value;
			let current_conversion_rate;
			let last_conversion_rate;
			let current_avg_session_duration;
			let last_avg_session_duration;
			let current_avg_Page_Sessions;
			let last_avg_Page_Sessions;
			let current_sessions_with_search_percentage;
			let last_sessions_with_search_percentage;
			let current_bounce_rate;
			let last_bounce_rate;



			let current_sales = result['current_ecom_metrices_list'][0]['sales']
			let current_total_order = result['current_ecom_metrices_list'][0]['total_order']
			let current_cancellations = result['current_ecom_metrices_list'][0]['cancellations']
			let current_sessions = result['current_ecom_metrices_list'][0]['sessions']
			let current_session_duration_in_seconds = result['current_ecom_metrices_list'][0]['session_duration_in_seconds']
			let current_page_views = result['current_ecom_metrices_list'][0]['page_views']
			let current_search_session = result['current_ecom_metrices_list'][0]['search_session']
			let current_bounce = result['current_ecom_metrices_list'][0]['bounce']
			let current_users = result['current_ecom_metrices_list'][0]['users']
			let current_new_user = result['current_new_user_list'][0]['new_user']


			let last_sales = result['last_ecom_metrices_list'][0]['sales']
			let last_total_order = result['last_ecom_metrices_list'][0]['total_order']
			let last_cancellations = result['last_ecom_metrices_list'][0]['cancellations']
			let last_sessions = result['last_ecom_metrices_list'][0]['sessions']
			let last_session_duration_in_seconds = result['last_ecom_metrices_list'][0]['session_duration_in_seconds']
			let last_page_views = result['last_ecom_metrices_list'][0]['page_views']
			let last_search_session = result['last_ecom_metrices_list'][0]['search_session']
			let last_bounce = result['last_ecom_metrices_list'][0]['bounce']
			let last_users = result['last_ecom_metrices_list'][0]['users']
			let last_new_user = result['last_new_user_list'][0]['new_user']



			current_avg_order_value = (current_sales / current_total_order);
			last_avg_order_value = (last_sales / last_total_order);
			current_conversion_rate = (current_total_order / current_sessions) * 100;
			last_conversion_rate = (last_total_order / last_sessions) * 100;
			current_avg_session_duration = (current_session_duration_in_seconds / current_sessions);
			last_avg_session_duration = (last_session_duration_in_seconds / last_sessions);
			current_avg_Page_Sessions = (current_page_views / current_sessions);
			last_avg_Page_Sessions = (last_page_views / last_sessions);
			current_sessions_with_search_percentage = (current_search_session / current_sessions) * 100;
			last_sessions_with_search_percentage = (last_search_session / last_sessions) * 100;
			current_bounce_rate = (current_bounce / current_sessions) * 100;
			last_bounce_rate = (last_bounce / last_sessions) * 100;




			this.setState({
				is_metrices_loaded: true,
				metrices_data: result,
				current_sales: current_sales,
				last_sales: last_sales,
				current_total_order: current_total_order,
				last_total_order: last_total_order,
				current_cancellations: current_cancellations,
				last_cancellations: last_cancellations,
				current_avg_order_value: current_avg_order_value,
				last_avg_order_value: last_avg_order_value,
				current_conversion_rate: current_conversion_rate,
				last_conversion_rate: last_conversion_rate,
				current_avg_session_duration: current_avg_session_duration,
				last_avg_session_duration: last_avg_session_duration,
				current_avg_Page_Sessions: current_avg_Page_Sessions,
				last_avg_Page_Sessions: last_avg_Page_Sessions,
				current_sessions_with_search_percentage: current_sessions_with_search_percentage,
				last_sessions_with_search_percentage: last_sessions_with_search_percentage,
				current_bounce_rate: current_bounce_rate,
				last_bounce_rate: last_bounce_rate,
				current_sessions: current_sessions,
				last_sessions: last_sessions,
				current_users: current_users,
				last_users: last_users,
				current_new_user: current_new_user,
				last_new_user: last_new_user,

			});
		})

	}

	getlinesparkgraph() {
		let start_date = this.state.first_start_date;
		let end_date = this.state.first_end_date;
		let previous_start_date = this.state.second_start_date;
		let previous_end_date = this.state.second_end_date;
		let social_platform = 'FACEBOOK';

		const url = API_URL + "ecom/ecom-metrices/"
		fetch(url, {
			method: 'POST',
			body: "start_date=" + start_date +
				"&end_date=" + end_date +
				"&last_start_date=" + previous_start_date +
				"&last_end_date=" + previous_end_date +
				"&social_platform=" + social_platform,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': ' Bearer ' + localStorage.getItem("token")
			},
		}).then(res => res.json()).then((result) => {

			let sparkline_list = result['ecom_metrices_sparkline_list']
			let sparkline_new_user_list = result['new_user_sparkline_list']

			let sales_spark_list = [];
			let total_order_list = [];
			let cancellations_list = [];
			let avg_order_value_list = [];
			let conversion_list = [];
			let avg_session_duration_list = [];
			let avg_Page_Sessions_list = [];
			let sessions_with_search_percentage_list = [];
			let bounce_rate_list = [];
			let sessions_list = [];
			let users_list = [];
			let new_user_list = [];

			sparkline_new_user_list.map((info, index) => {

				try {
					let l = {
						"date": info['date'],
						"value": info['new_user']

					}
					new_user_list.push(l);

				}
				catch (e) {

				}
			})

			sparkline_list.map((info, index) => {
				try {
					let a = {
						"date": info['date'],
						"value": info['sales']
					}
					sales_spark_list.push(a);

					let b = {
						"date": info['date'],
						"value": info['total_order']

					}
					total_order_list.push(b);

					let c = {
						"date": info['date'],
						"value": info['cancellations']

					}
					cancellations_list.push(c);

					let d = {
						"date": info['date'],
						"value": (info['sales'] / info['total_order']).toFixed()

					}
					avg_order_value_list.push(d);

					let e = {
						"date": info['date'],
						"value": ((info['total_order'] / info['sessions']) * 100).toFixed(2)

					}
					conversion_list.push(e);

					let f = {
						"name": info['date'],
						"points": (info['session_duration_in_seconds'] / info['sessions']).toFixed(2)
					}
					avg_session_duration_list.push(f);

					let g = {
						"name": info['date'],
						"points": (info['page_views'] / info['sessions']).toFixed(2)
					}
					avg_Page_Sessions_list.push(g);

					let h = {
						"name": info['date'],
						"points": ((info['search_session'] / info['sessions'])).toFixed(2)
					}
					sessions_with_search_percentage_list.push(h);

					let i = {
						"name": info['date'],
						"points": ((info['bounce'] / info['sessions']) * 100).toFixed(2)
					}
					bounce_rate_list.push(i);

					let j = {
						"date": info['date'],
						"value": info['sessions']

					}
					sessions_list.push(j);

					let k = {
						"date": info['date'],
						"value": info['users']

					}
					users_list.push(k);









				} catch (e) {

				}



			})


			this.setState({
				is_graph_loaded: true,
				sales_spark_graph: sales_spark_list,
				total_order_graph: total_order_list,
				cancellations_graph: cancellations_list,
				avg_order_value_graph: avg_order_value_list,
				conversion_graph: conversion_list,
				avg_session_duration_graph: avg_session_duration_list,
				avg_Page_Sessions_graph: avg_Page_Sessions_list,
				sessions_with_search_percentage_graph: sessions_with_search_percentage_list,
				bounce_rate_graph: bounce_rate_list,
				sessions_graph: sessions_list,
				users_graph: users_list,
				new_user_graph: new_user_list,



			});
		})

	}
	// calc graph
	getallMetricesCalc() {
		let start_date = this.state.first_start_date;
		let end_date = this.state.first_end_date;
		let previous_start_date = this.state.second_start_date;
		let previous_end_date = this.state.second_end_date;
		let pie_click_data = this.state.pie_click_data;

		const url = API_URL + "ecom/ecom-metrices-calc/"
		fetch(url, {
			method: 'POST',
			body: "start_date=" + start_date +
				"&end_date=" + end_date +
				"&last_start_date=" + previous_start_date +
				"&last_end_date=" + previous_end_date +
				"&pie_click_data=" + pie_click_data,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': ' Bearer ' + localStorage.getItem("token")
			},
		}).then(res => res.json()).then((result) => {
			let mobile_users_graph_sparkline_list = [];
			let add_to_cart_graph_sparkline_list = [];
			let add_to_cart_without_view_graph_sparkline_list = [];

			// let last_top_landing_page_metrices_list = result['last_top_landing_page_metrices_list'][0]['sessions']
			let current_top_landing_page_metrices_list = result.current_top_landing_page_metrices_list[0] ? result['current_top_landing_page_metrices_list'][0].landing_page : 0;
			let current_top_landing_page_sessions = result.current_top_landing_page_metrices_list[0] ? result['current_top_landing_page_metrices_list'][0].sessions : 0;

			let current_mobile_users_metrices_list = result['current_mobile_users_metrices_list'][0]['users']
			let last_mobile_users_metrices_list = result['last_mobile_users_metrices_list'][0]['users']

			let current_add_to_cart_metrices_list = result['current_add_to_cart_metrices_list'][0]['sessions']
			let last_add_to_cart_metrices_list = result['last_add_to_cart_metrices_list'][0]['sessions']

			let current_add_to_cart_without_view_metrices_list = result['current_add_to_cart_without_view_metrices_list'][0]['sessions']
			let last_add_to_cart_without_view__metrices_list = result['last_add_to_cart_without_view__metrices_list'][0]['sessions']

			let sparkline_list = result['mobile_users_sparkline_list']
			let add_to_cart_sparkline_list_1 = result['add_to_cart_sparkline_list']

			add_to_cart_sparkline_list_1.map((info, index) => {
				try {
					let a = {
						"name": info['date'],
						"points": info['calculated'].toFixed(2)

					}
					add_to_cart_without_view_graph_sparkline_list.push(a);

				} catch (e) {

				}

			})

			sparkline_list.map((info, index) => {
				try {
					let a = {
						"date": info['date'],
						"value": info['users']

					}
					mobile_users_graph_sparkline_list.push(a);

				} catch (e) {

				}
			})

			let add_to_cart_sparkline_list = result['add_to_cart_sparkline_list']

			add_to_cart_sparkline_list.map((info, index) => {
				try {
					let a = {
						"date": info['date'],
						"value": info['users']

					}
					add_to_cart_graph_sparkline_list.push(a);

				} catch (e) {

				}
			})


			this.setState({
				is_getall_metrices_calc_loaded: true,

				current_top_landing_page_metrices_list: current_top_landing_page_metrices_list,
				current_top_landing_page_sessions: current_top_landing_page_sessions,
				// last_top_landing_page_metrices_list: last_top_landing_page_metrices_list,

				current_mobile_users_metrices_list: current_mobile_users_metrices_list,
				last_mobile_users_metrices_list: last_mobile_users_metrices_list,

				mobile_users_sparkline_graph: mobile_users_graph_sparkline_list,

				current_add_to_cart_metrices_list: current_add_to_cart_metrices_list,
				last_add_to_cart_metrices_list: last_add_to_cart_metrices_list,

				current_add_to_cart_without_view_metrices_list: current_add_to_cart_without_view_metrices_list,
				last_add_to_cart_without_view__metrices_list: last_add_to_cart_without_view__metrices_list,

				add_to_cart_sparkline_graph: add_to_cart_graph_sparkline_list,

				add_to_cart_without_view_graph: add_to_cart_without_view_graph_sparkline_list




			});
		})

	}

	getFunnel() {
		let start_date = this.state.first_start_date;
		let end_date = this.state.first_end_date;
		let previous_start_date = this.state.second_start_date;
		let previous_end_date = this.state.second_end_date;
		let pie_click_data = this.state.pie_click_data;
		let daily_metrics_name = this.state.daily_metrics_name;

		const url = API_URL + "ecom/summary-funnel/"
		fetch(url, {
			method: 'POST',
			body: "start_date=" + start_date +
				"&end_date=" + end_date +
				"&last_start_date=" + previous_start_date +
				"&last_end_date=" + previous_end_date +
				"&pie_click_data=" + pie_click_data +
				"&daily_metrics_name=" + daily_metrics_name,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': ' Bearer ' + localStorage.getItem("token")
			},
		}).then(res => res.json()).then((result) => {
			let funnel_list = result
			const shopping_stage = ["ALL_VISITS", "ADD_TO_CART", "CHECKOUT_WITH_CART_ADDITION", "CHECKOUT_WITHOUT_CART_ADDITION", "TRANSACTION", "TRANSACTION_WITHOUT_CHECKOUT"]
			let funnel_graph_list = []

			let a = {
				"name": "Sessions",
				"value": result['Sessions_list']
			}
			funnel_graph_list.push(a);
			a = {
				"name": "Sessions with category page view",
				"value": result['Sessions_with_category_page_view_list']
			}

			funnel_graph_list.push(a);
			a = {
				"name": "Sessions with sub-category page view",
				"value": result['Sessions_with_sub_category_page_view_list']
			}
			funnel_graph_list.push(a);

			a = {
				"name": "Sessions with product view ",
				"value": result['Sessions_with_product_view_list']
			}
			funnel_graph_list.push(a);
			a = {
				"name": "Sessions with Checkout",
				"value": result['Sessions_with_Proceed_to_Checkout_list']
			}
			funnel_graph_list.push(a);
			a = {
				"name": "Sessions with transactions",
				"value": result['Sessions_with_successful_transactions_list']
			}
			funnel_graph_list.push(a);

			// funnel_graph_list.sort(function (a, b) {
			//     return b.value - a.value;
			// });



			this.setState({
				is_funnel_loaded: true,
				funnelgraph: funnel_graph_list,


			});
		})

	}

	getCategoryFunnel() {
		let start_date = this.state.first_start_date;
		let end_date = this.state.first_end_date;
		let previous_start_date = this.state.second_start_date;
		let previous_end_date = this.state.second_end_date;
		let pie_click_data = this.state.pie_click_data;
		let daily_metrics_name = this.state.daily_metrics_name;

		const url = API_URL + "ecom/summary-category-funnel/"
		fetch(url, {
			method: 'POST',
			body: "start_date=" + start_date +
				"&end_date=" + end_date +
				"&last_start_date=" + previous_start_date +
				"&last_end_date=" + previous_end_date +
				"&pie_click_data=" + pie_click_data +
				"&daily_metrics_name=" + daily_metrics_name,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': ' Bearer ' + localStorage.getItem("token")
			},
		}).then(res => res.json()).then((result) => {
			let funnel_list = result
			const shopping_stage = ["ALL_VISITS", "ADD_TO_CART", "CHECKOUT_WITH_CART_ADDITION", "CHECKOUT_WITHOUT_CART_ADDITION", "TRANSACTION", "TRANSACTION_WITHOUT_CHECKOUT"]
			let funnel_graph_list = []

			let a = {
				"name": "Sessions",
				"value": result['Sessions_list']
			}
			funnel_graph_list.push(a);
			a = {
				"name": "Sessions with category page view",
				"value": result['Sessions_with_category_page_view_list']
			}

			funnel_graph_list.push(a);
			a = {
				"name": "Sessions with sub-category page view",
				"value": result['Sessions_with_sub_category_page_view_list']
			}
			funnel_graph_list.push(a);

			a = {
				"name": "Sessions with product view ",
				"value": result['Sessions_with_product_view_list']
			}
			funnel_graph_list.push(a);
			a = {
				"name": "Sessions with Checkout",
				"value": result['Sessions_with_Proceed_to_Checkout_list']
			}
			funnel_graph_list.push(a);
			a = {
				"name": "Sessions with Transactions",
				"value": result['Sessions_with_successful_transactions_list']
			}
			funnel_graph_list.push(a);

			// funnel_graph_list.sort(function (a, b) {
			//     return b.value - a.value;
			// });



			this.setState({
				is_funnel_loaded: true,
				funnelgraph: funnel_graph_list,


			});
		})

	}

	getPiechart() {

		let start_date = this.state.first_start_date;
		let end_date = this.state.first_end_date;
		let previous_start_date = this.state.second_start_date;
		let previous_end_date = this.state.second_end_date;
		let social_platform = 'FACEBOOK';

		const url = API_URL + "ecom/ecom-pie/"
		fetch(url, {
			method: 'POST',
			body: "start_date=" + start_date +
				"&end_date=" + end_date +
				"&last_start_date=" + previous_start_date +
				"&last_end_date=" + previous_end_date +
				"&social_platform=" + social_platform,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': ' Bearer ' + localStorage.getItem("token")
			},
		}).then(res => res.json()).then((result) => {
			let pie_list = result['pie_list'];
			let pie_graph_list = [];
			// {    "name": "Social","value": 30}
			pie_list.map((info, index) => {
				if (info['total_sales'] > 0) {
					let a = {
						"name": info['channel_name'],
						"value": info['total_sales'],
					}
					pie_graph_list.push(a);

				}

			})
			this.setState({
				is_pie_loaded: true,
				trafficpievalue: pie_graph_list,


			});



		})


	}



	getPiecategorychart() {

		let start_date = this.state.first_start_date;
		let end_date = this.state.first_end_date;
		let previous_start_date = this.state.second_start_date;
		let previous_end_date = this.state.second_end_date;
		let social_platform = 'FACEBOOK';

		const url = API_URL + "ecom/ecom-pie-category/"
		fetch(url, {
			method: 'POST',
			body: "start_date=" + start_date +
				"&end_date=" + end_date +
				"&last_start_date=" + previous_start_date +
				"&last_end_date=" + previous_end_date +
				"&social_platform=" + social_platform,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': ' Bearer ' + localStorage.getItem("token")
			},
		}).then(res => res.json()).then((result) => {
			let pie_list = result['category_list'];
			let pie_graph_list = [];
			// {    "name": "Social","value": 30}
			pie_list.map((info, index) => {

				let a = {
					"name": info['category_1'],
					"value": info['item_revenue'],
				}
				pie_graph_list.push(a);
			})
			this.setState({
				is_pie_loaded: true,
				trafficpievalue: pie_graph_list,


			});



		})


	}


	handleCallback = (childData) => {
		this.setState({ daily_metrics_name: childData, is_daily_data_loaded: false });
	}
	handleChannelCallback = (childData) => {
		this.state.dropdown_channel_name = childData;
		this.state.pie_click_data = "";
		this.state.trafficeChannel = "";
		this.setState({
			is_channel_data_loaded: false,
			is_pie_loaded: false,
			is_metrices_loaded: false,
			is_graph_loaded: false,
			is_funnel_loaded: false,
			is_getall_metrices_calc_loaded: false,
			is_metrices_category_wise_loaded: false,
			is_metrices_new_loaded: false,
			is_category_wise_graph_loaded: false,

		});
		this.loadSummaryData();
	}

	handlePieclick = (childData) => {


		if (this.state.pie_click_data !== childData) {
			this.state.pie_click_data = childData;
			this.state.trafficeChannel = childData;
		} else {
			this.state.pie_click_data = "";
			this.state.trafficeChannel = "";
		}

		this.setState({
			is_channel_data_loaded: false,
			is_metrices_loaded: false,
			is_graph_loaded: false,
			is_funnel_loaded: false,
			is_getall_metrices_calc_loaded: false,
			is_metrices_category_wise_loaded: false,
			is_metrices_new_loaded: false,
			is_category_wise_graph_loaded: false,
		});
		this.loadSummaryData();
	}

	// handlePieclick = (childData) => {
	//     this.state.pie_click_data = childData;
	//     this.setState({ is_channel_data_loaded: false, is_metrices_loaded: false });
	//     this.loadSummaryData();
	//     // this.setState({name: childData})
	// }
	// loadSummaryData = () =>
	datepickerHandleCallback = (childData) => {

		this.state.first_start_date = childData[0];
		this.state.first_end_date = childData[1];
		this.state.second_start_date = childData[2];
		this.state.second_end_date = childData[3];


		// this.state.is_channel_data_loaded= false;
		// this.state.is_metrices_loaded= false;
		// this.state.is_graph_loaded= false;
		// this.state.is_funnel_loaded= false;
		// this.state.is_getall_metrices_calc_loaded= false;
		// this.state.is_metrices_category_wise_loaded= false;
		// this.state.is_category_wise_graph_loaded= false;

		this.state.is_social_card_loaded = false;
		this.state.is_metrices_loaded = false;
		this.state.is_getall_metrices_calc_loaded = false;
		this.state.is_graph_loaded = false;
		this.state.is_funnel_loaded = false;
		this.state.is_metrices_category_wise_loaded = false;
		this.state.is_metrices_new_loaded = false;
		this.state.is_category_wise_graph_loaded = false;

		this.setState({
			is_pie_loaded: false
		})

		// this.getPiechart()
		// this.getPiecategorychart()
		this.getChannelData();
		this.getCategoryData();

		this.loadSummaryData();

		this.forceUpdate();
		this.datepickerHandleCallback2();
	};

	datepickerHandleCallback2 = () => {
		this.setState({ isDatePickerOpen: !this.state.isDatePickerOpen });
	};

	handleScroll = () => {
		if (window.pageYOffset > 1) {
			if (!this.state.is_scroll) {
				this.setState({ is_scroll: true });
			}
		} else {
			if (this.state.is_scroll) {
				this.setState({ is_scroll: false });
			}
		}
	};

	getCountryCurrency = () => {

		let userCountryCode = localStorage.getItem('static-country-code');
		var currValue = ''

		CountryCode.map((data) => {
			if (data.countryCode === userCountryCode) {
				//   country = data;
				currValue = data.currencySymbol
			}
		});

		return currValue;
	}



	render() {

		const CarouselSettings = {
			arrows: true,
			slidesToShow: 2,
			slidesToScroll: 1,
			initialSlide: 0,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						arrows: true,
						slidesToShow: 3,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 800,
					settings: {
						arrows: true,
						slidesToShow: 1,
						slidesToScroll: 1,

					}
				},
				{
					breakpoint: 480,
					settings: {
						arrows: true,
						slidesToShow: 1,
						slidesToScroll: 1,

					}
				}
			]
		}

		return (
			<Wrapper>
				<div className="grid grid_cols_4 drawer_grid ri_summary_bsImport">
					<div className="left_drawer col_span_1 fixed-left-grid mapRightWrapper">
						<div className="icz-rimapsection bg-white">
							<Col sm={12} className="icz-cardheader p-20 d-flex">
								<Col className="icz-cardtitle">
									<div className="icz-title-wrap p-0">
										<div className="text-left">
											<h5 className="icz-cardtitle text-start mb-0">Sales By</h5>
										</div>
									</div>
								</Col>
								<Col className="icz-pagefilter">
									<div className="icz-page-dropdown justify-content-end p-0">
										<DailydataDropdown
											dropdown_options={this.state.channel_dropdown_option}
											dropdown_placeholder="Traffic Channels"
											parentCallback={this.handleChannelCallback}
											selected_options={this.state.channel_dropdown_option[0]} />
									</div>
								</Col>
							</Col>
							{this.state.is_pie_loaded === true ?
								<div className="h-100 d-flex justify-content-center align-items-center">
									<Piechart card_id="icz-traffic-piechart"
										card_class="icz-leftsectionchart"
										graph-data={this.state.trafficpievalue} parentCallback={this.handlePieclick} />
								</div>
								: <Loader />}
						</div>
					</div>
					<div className="right_side col_span_3 relative-right-grid icz-ridatacontainer">
						<div className="content">
							<div className="content_section">
								<PageHeader pageTitle={"Summary"} />
								<div className="page_content">
									<div className="dashboard_home">
										{this.state.trafficeChannel === '' ? '' :
											<div className="icz-title-wrap d-flex">
												<Col sm={12}>
													<div className="icz-row">
														<Col><h5 className="icz-card-title align-items-start">{this.state.trafficeChannel}</h5></Col>
													</div>
												</Col>
											</div>
										}
										<div className="icz-title-wrap d-flex">
											<Col sm={12}>
												<div className="icz-row">
													<Col><h5 className="icz-card-title align-items-start">Sales</h5></Col>
													<Col className="align-items-end text-end"><Link to='/ecom/sales' className="icz-link">View More</Link></Col>
												</div>
											</Col>
										</div>

										<div className="icz-carouselcontainer">
											{(this.state.is_metrices_loaded && this.state.is_graph_loaded && this.state.is_getall_metrices_calc_loaded) || (this.state.is_metrices_category_wise_loaded && this.state.is_category_wise_graph_loaded) ?
												<Carousel
													Settings={CarouselSettings}
													class={"icz-cardcontainer"}
													SliderCards={[
														<ChartCard
															index="1"
															CardClass="icz-cardwrapper"
															CardIcon={<IconWebsite className="icz-icon" />}
															CardTitle={`Sales (${this.getCountryCurrency()})`}
															RowOneCurrentValue={(this.state.current_sales ? this.state.current_sales : 0)}
															RowOnePreviousValue={(this.state.last_sales ? this.state.last_sales : 0)}
															RowOneTitle="sales"
															IsNumber={'money'}
															IsStandard={true}
															chart={<Linegraph_sparkline card_id="icz-sales" card_class="icz-cardchart" graph-data={this.state.sales_spark_graph} />}
														/>,

														<ChartCard
															index="2"
															CardClass="icz-cardwrapper"
															CardIcon={<IconWebsite className="icz-icon" />}
															CardTitle="Total Orders"
															RowOneCurrentValue={(this.state.current_total_order ? this.state.current_total_order.toFixed(2) : 0)}
															RowOnePreviousValue={(this.state.last_total_order ? this.state.last_total_order.toFixed(2) : 0)}
															RowOneTitle="sales"
															IsNumber={'number'}
															IsStandard={true}
															chart={<Linegraph_sparkline card_id="icz-total-orders" card_class="icz-cardchart" graph-data={this.state.total_order_graph} />}
														/>,
														<ChartCard
															index="3"
															CardClass="icz-cardwrapper"
															CardIcon={<IconWebsite className="icz-icon" />}
															CardTitle={`Avg Order Value (${this.getCountryCurrency()})`}
															RowOneCurrentValue={(this.state.current_avg_order_value ? this.state.current_avg_order_value.toFixed(2) : 0)}
															RowOnePreviousValue={(this.state.last_avg_order_value ? this.state.last_avg_order_value.toFixed(2) : 0)}
															RowOneTitle="sales"
															IsNumber={'money'}
															IsStandard={true}
															chart={<Linegraph_sparkline card_id="icz-avgorder-value" card_class="icz-cardchart" graph-data={this.state.avg_order_value_graph} />}
														/>,
														<ChartCard
															index="4"
															CardClass="icz-cardwrapper"
															CardIcon={<IconWebsite className="icz-icon" />}
															CardTitle="Cancellations"
															RowOneCurrentValue={(this.state.current_cancellations ? this.state.current_cancellations : 0)}
															RowOnePreviousValue={(this.state.last_cancellations ? this.state.last_cancellations : 0)}
															RowOneTitle="sales"
															IsNumber={'number'}
															IsStandard={false}
															chart={<Linegraph_sparkline card_id="icz-cancellations" card_class="icz-cardchart" graph-data={this.state.cancellations_graph} />}
														/>,
														<ChartCard
															index="5"
															CardClass="icz-cardwrapper"
															CardIcon={<IconWebsite className="icz-icon" />}
															CardTitle="Conversion Rate"
															RowOneCurrentValue={(this.state.current_conversion_rate ? this.state.current_conversion_rate.toFixed(2) : 0)}
															RowOnePreviousValue={(this.state.last_conversion_rate ? this.state.last_conversion_rate.toFixed(2) : 0)}
															RowOneTitle="Traffic"
															IsNumber={'percentage'}
															IsStandard={true}
															chart={<Linegraph_sparkline card_id="icz-converstion-rate" isnumber={"decimal_percentage"} card_class="icz-cardchart" graph-data={this.state.conversion_graph} />}
														/>,

													]}>

												</Carousel>
												: <Loader />}

										</div>

										<div className="icz-title-wrap d-flex">
											<Col sm={12}>
												<div className="icz-row">
													<Col><h5 className="icz-card-title align-items-start">Engagement</h5></Col>
													<Col className="align-items-end text-end"><Link to='/ecom/engagement' className='icz-link'>View More</Link></Col>
												</div>
											</Col>
										</div>

										<div className="icz-carouselcontainer">

											{(this.state.is_metrices_loaded && this.state.is_graph_loaded && this.state.is_getall_metrices_calc_loaded) || (this.state.is_metrices_category_wise_loaded && this.state.is_category_wise_graph_loaded && this.state.is_metrices_new_loaded) ?

												<Carousel
													Settings={CarouselSettings}
													class={"icz-cardcontainer"}
													SliderCards={[
														<ChartCard
															index="1"
															CardClass="icz-cardwrapper"
															CardIcon={<IconWebsite className="icz-icon" />}
															CardTitle="Avg Session Duration"
															RowOneCurrentValue={(this.state.current_avg_session_duration ? this.state.current_avg_session_duration.toFixed(2) : 0)}
															RowOnePreviousValue={(this.state.last_avg_session_duration ? this.state.last_avg_session_duration.toFixed(2) : 0)}
															RowOneTitle="enagagement"
															IsNumber={'number'}
															IsStandard={true}
															chart={<BarChart card_id="icz-avg-session-duration" card_class="icz-cardchart" graph-data={this.state.avg_session_duration_graph} />}
														/>,

														<ChartCard
															index="2"
															CardClass="icz-cardwrapper"
															CardIcon={<IconWebsite className="icz-icon" />}
															CardTitle="Average Pages Per Session"
															RowOneCurrentValue={(this.state.current_avg_Page_Sessions ? this.state.current_avg_Page_Sessions.toFixed(2) : 0)}
															RowOnePreviousValue={(this.state.last_avg_Page_Sessions ? this.state.last_avg_Page_Sessions.toFixed(2) : 0)}
															RowOneTitle="enagagement"
															IsNumber={'number'}
															IsStandard={true}
															chart={<BarChart card_id="icz-avgpage-session" card_class="icz-cardchart" graph-data={this.state.avg_Page_Sessions_graph} />}
														/>,
														<ChartCard
															index="3"
															CardClass="icz-cardwrapper"
															CardIcon={<IconWebsite className="icz-icon" />}
															CardTitle="Add To Cart Rate"
															RowOneCurrentValue={(this.state.current_add_to_cart_metrices_list ? this.state.current_add_to_cart_metrices_list : 0)}
															RowOnePreviousValue={(this.state.last_add_to_cart_metrices_list ? this.state.last_add_to_cart_metrices_list : 0)}
															RowOneTitle="enagagement"
															IsNumber={'percentage'}
															IsStandard={true}
															chart={<BarChart card_id="Add To Card Rate" card_class="icz-cardchart" graph-data={this.state.add_to_cart_without_view_graph} />}
														/>,
														<ChartCard
															index="4"
															CardClass="icz-cardwrapper"
															CardIcon={<IconWebsite className="icz-icon" />}
															CardTitle=" %Session With Search"
															RowOneCurrentValue={(this.state.current_sessions_with_search_percentage ? this.state.current_sessions_with_search_percentage.toFixed(2) : 0)}
															RowOnePreviousValue={(this.state.last_sessions_with_search_percentage ? this.state.last_sessions_with_search_percentage.toFixed(2) : 0)}
															RowOneTitle="enagagement"
															IsNumber={'percentage'}
															IsStandard={true}
															chart={<BarChart card_id="icz-session-search" card_class="icz-cardchart" graph-data={this.state.sessions_with_search_percentage_graph} />}
														/>,

														<ChartCard
															index="5"
															CardClass="icz-cardwrapper"
															CardIcon={<IconWebsite className="icz-icon" />}
															CardTitle="Bounce Rate"
															RowOneCurrentValue={(this.state.current_bounce_rate ? this.state.current_bounce_rate.toFixed(2) : 0)}
															RowOnePreviousValue={(this.state.last_bounce_rate ? this.state.last_bounce_rate.toFixed(2) : 0)}
															RowOneTitle="enagagement"
															IsNumber={'percentage'}
															IsStandard={false}
															chart={<BarChart card_id="icz-bounce-rate" isnumber={'decimal_percentage'} card_class="icz-cardchart" graph-data={this.state.bounce_rate_graph} />}
														/>

													]}>

												</Carousel>
												: <Loader />}
										</div>

										<div className="icz-title-wrap d-flex">
											<Col sm={12}>
												<div className="icz-row">
													<Col ><h5 className="icz-card-title align-items-start">Traffic</h5></Col>
													<Col className="align-items-end text-end"><Link to='/ecom/traffic' className='icz-link'>View More</Link></Col>
												</div>
											</Col>
										</div>

										<div className="icz-carouselcontainer">
											{(this.state.is_metrices_loaded && this.state.is_graph_loaded && this.state.is_getall_metrices_calc_loaded) || (this.state.is_metrices_category_wise_loaded && this.state.is_category_wise_graph_loaded) ?
												<Carousel
													Settings={CarouselSettings}
													class={"icz-cardcontainer"}
													SliderCards={[
														<ChartCard
															index="1"
															CardClass="icz-cardwrapper"
															CardIcon={<IconWebsite className="icz-icon" />}
															CardTitle="Sessions"
															RowOneCurrentValue={(this.state.current_sessions ? this.state.current_sessions : 0)}
															RowOnePreviousValue={(this.state.last_sessions ? this.state.last_sessions : 0)}
															RowOneTitle="Traffic"
															IsNumber={'number'}
															IsStandard={true}
															chart={<Linegraph_sparkline card_id="icz-session" card_class="icz-cardchart" graph-data={this.state.sessions_graph} />}
														/>,

														<ChartCard
															index="2"
															CardClass="icz-cardwrapper"
															CardIcon={<IconWebsite className="icz-icon" />}
															CardTitle="Users"
															RowOneCurrentValue={(this.state.current_users ? this.state.current_users : 0)}
															RowOnePreviousValue={(this.state.last_users ? this.state.last_users : 0)}
															RowOneTitle="Traffic"
															IsNumber={'number'}
															IsStandard={true}
															chart={<Linegraph_sparkline card_id="icz-user" card_class="icz-cardchart" graph-data={this.state.users_graph} />}
														/>,
														<ChartCard
															index="3"
															CardClass="icz-cardwrapper"
															CardIcon={<IconWebsite className="icz-icon" />}
															CardTitle="New Users"
															RowOneCurrentValue={(this.state.current_new_user ? this.state.current_new_user : 0)}
															RowOnePreviousValue={(this.state.last_new_user ? this.state.last_new_user : 0)}
															RowOneTitle="Traffic"
															IsNumber={'number'}
															IsStandard={true}
															chart={<Linegraph_sparkline card_id="icz-new-users" card_class="icz-cardchart" graph-data={this.state.new_user_graph} />}
														/>,
														<ChartCard
															index="4"
															CardClass="icz-cardwrapper"
															CardIcon={<IconWebsite className="icz-icon" />}
															CardTitle="Mobile Users"
															RowOneCurrentValue={(this.state.current_mobile_users_metrices_list ? this.state.current_mobile_users_metrices_list : 0)}
															RowOnePreviousValue={(this.state.last_mobile_users_metrices_list ? this.state.last_mobile_users_metrices_list : 0)}
															RowOneTitle="Traffic"
															IsNumber={'number'}
															IsStandard={true}
															chart={<Linegraph_sparkline card_id="icz-mobile-user" card_class="icz-cardchart" graph-data={this.state.mobile_users_sparkline_graph} />}
														/>,
														<DetailsCard
															index="5"
															CardClass="icz-cardwrapper"
															CardIcon={<IconWebsite className="icz-icon" />}
															CardTitle="Top Landing Page"
															RowOneCurrentValue={(this.state.current_top_landing_page_metrices_list ? this.state.current_top_landing_page_metrices_list : 0)}
															RowOnePreviousValue={(this.state.current_top_landing_page_sessions ? this.state.current_top_landing_page_sessions : 0)}
															RowOneTitle="Sessions"
															IsNumber={'number'}
															showPercentMetricChange={false}
														/>,
													]}>
												</Carousel>
												: <Loader />}
										</div>

										<div className="icz-row">
											<Col className="icz-sectioncardwrapper pt-3" sm={12} lg={12}>
												<div className="icz-sectioncard">
													<div className="icz-cardheader">
														<Col className="icz-cardtitle">
															Conversion Funnel
														</Col>
														<Col className="icz-cardfilter icz-chartexport">
															<ChartExport ChartId='converstion_funnel' export_data={this.state.funnelgraph} downloadName="Conversion Funnel" />
														</Col>
													</div>
													{this.state.is_funnel_loaded ?
														<div className="">
															<Converstion_funnel card_id="converstion_funnel" card_class="icz-sectionchart" graph-data={this.state.funnelgraph} />
														</div>
														: <Loader />}
												</div>
											</Col>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</Wrapper>
		)
	}
}

function ReduxStateToProps(state) {
	return {
		start_date: state.date.startDate,
		end_date: state.date.endDate,
		previous_start_date: state.date.prevStartDate,
		previous_end_date: state.date.prevEndDate,
	};
}

export default connect(ReduxStateToProps)(Ecom)